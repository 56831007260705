import React from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { GetTypographyContent } from "../shared/Typography/Typography";
import {
  CreateAccountCenterContainerPaddedTop,
  Spinner,
} from "../CreateAccount/CreateAccountForm.styles";
import { Button } from "../shared/Button/Button";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { LANDING_PAGE_URL, LOGIN_PAGE_URL } from "../../utils/urlConstants";
import loading from "../../assets/icons/loading.svg";
import { getUserAuth } from "../../recoil/selectors";

interface VerifyEmailConfirmedProps {
  email: string;
}

export const VerifyEmailConfirmedSection = (
  props: VerifyEmailConfirmedProps,
): React.ReactElement => {
  const { isDesktop } = useDeviceHook();
  const history = useHistory();
  const auth = useRecoilValue(getUserAuth);

  if (!props.email) {
    return (
      <Spinner>
        <img src={loading} alt="Loading" />
      </Spinner>
    );
  }

  return (
    <CreateAccountCenterContainerPaddedTop isDesktop={isDesktop}>
      {GetTypographyContent({
        content: "Your account is verified",
        size: "h3",
        isBold: true,
        textColor: "neutral700",
      })}
      <br />
      {GetTypographyContent({
        content: "Your email",
        size: "medium",
        textColor: "neutral700",
      })}
      {GetTypographyContent({
        content: props.email,
        size: "medium",
        isBold: true,
        textColor: "greenDark",
      })}
      {GetTypographyContent({
        content: "is now verified and your account has been activated",
        size: "medium",
        textColor: "neutral700",
        padding: "0px 0 25px 0",
      })}
      {auth ? (
        <Button
          type="submit"
          label="continue to account"
          size="medium"
          onClick={() => history.push(LANDING_PAGE_URL)}
        />
      ) : (
        <Button
          type="submit"
          label="login"
          size="medium"
          onClick={() => history.push(LOGIN_PAGE_URL)}
        />
      )}
    </CreateAccountCenterContainerPaddedTop>
  );
};
