export const HELP_PAGE_URL = "/client/help";
export const LANDING_PAGE_URL = "/client/dashboard";
export const LOGIN_PAGE_URL = "/client/login";
export const TERMS_OF_USE_URL = "/client/terms_of_use";
export const EMAIL_REPORT_URL_PREFIX = "/client/emailReport";
export const PROFILE_URL = "/client/profile";
export const BILLING_URL = "/client/bill";
export const BILLING_MULTIPLE_URL = "/client/bills";
export const BABYPEEK_URL = "/client/babypeek";
export const BABYPEEK_URL_FAQ = "/client/babypeek-faq";
export const BABYPEEK_URL_FEEDBACK = "/client/babypeek-feedback";
export const PUBLIC_BABYPEEK_URL = "/client/public/babypeek";
export const PUBLIC_BABYPEEK_TRAIL_URL = "/c/babypeek";
export const PUBLIC_TRAIT_BABYPEEK_URL = "/c/bp/trait";
export const REGISTER_URL = "/c/s"; // short link for text messages
export const PRETEST_REGISTER_URL = "/client/signup";
export const VERIFY_EMAIL_CONFIRMED_URL = "/client/verifyEmailConfirm";
export const RESET_PASSWORD_URL = "/client/login/resetPassword";
export const RESET_PASSWORD_SENT_URL = "/client/login/resetPassword/sent";
export const SCHEDULING_URL = "/client/scheduling";
export const CONTACT_US_URL = "/client/contactUs";
export const VERIFY_EMAIL_URL = "/client/verifyEmail";
export const VERIFY_EMAIL_SEND_AGAIN_URL = "/client/verifyEmailSendAgain";
export const ANEUPLOIDY_PAGE_URL = "/client/aneuploidyResults";
export const CARRIER_PAGE_URL = "/client/carrierResults";
export const EXTERNAL_FORWARD_FETAL_SEX = "/client/forward/fetalSex";
export const TEST_INFO_URL = "/testSetup";
export const VIDEO_PAGE_URL = "/client/videos";
