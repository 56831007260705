import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import FormField from "../shared/TextField/FormField";
import { FullAddress, statesArray } from "./helpers";
import {
  FormatedAddress,
  GetGooglePlacesAddress,
  GetGooglePlacesAddressDetails,
} from "./Services/UpdateProfileInfoService";
import {
  PatientInformationStreetGrid,
  PatientInformationTextField,
  PatientInformationStateGrid,
  PatientInformationWrapper,
  ProfileFieldModalGrid,
  ProfileFieldRow,
  ProfileFieldRowTitle,
} from "./Profile.styles";
import { getUserAuth } from "../../recoil/selectors";
import { patientProfileState } from "../../recoil/atoms";
import { dateFormatter } from "../../utils/helpers";
import { GetTypographyContent } from "../shared/Typography/Typography";
import { WarningRounded } from "../../assets/iconComponents/WarningRounded";
import { ProfileInsuranceTextFieldLabel } from "./ProfileInsuranceModal.styles";

const PatientInformationAddress: React.FC<{
  fullAddress: FullAddress;
  setFullAddress: React.Dispatch<React.SetStateAction<FullAddress>>;
  isError: boolean;
}> = ({ fullAddress, setFullAddress, isError }) => {
  const auth = useRecoilValue(getUserAuth);
  const [patientInfo] = useRecoilState(patientProfileState);
  const [address2, setAddress] = useState("");

  const [autocompleteAddressList, setAutocompleteAddressList] = useState<
    FormatedAddress[]
  >([]);
  const [selectedAutoCompleteAddress, setSelectedAutoCompleteAddress] =
    useState<FormatedAddress>();

  useEffect(() => {
    const getAutocompleteAddresses = async () => {
      if (address2.length && auth) {
        const addresses = await GetGooglePlacesAddress(
          address2,
          auth.access_token,
        );
        setAutocompleteAddressList(addresses);
      }
    };

    getAutocompleteAddresses();
  }, [address2]);

  useEffect(() => {
    const getAutocompleteAddressesDetails = async () => {
      if (selectedAutoCompleteAddress && auth) {
        const details = await GetGooglePlacesAddressDetails(
          selectedAutoCompleteAddress,
          auth.access_token,
        );

        if (details) {
          setFullAddress({
            fullAddress: details.fullAddress || "",
            streetAddress: `${details.houseNumber} ${details.streetName}` || "",
            state:
              statesArray.find((state) =>
                state.split("-")[0].includes(details.state),
              ) || details.state,
            city: details.city || "",
            zipCode: details.zip || "",
            aptUnit: "",
          });
          setSelectedAutoCompleteAddress(undefined);
          setAutocompleteAddressList([]);
        }
      }
    };
    getAutocompleteAddressesDetails();
  }, [selectedAutoCompleteAddress]);

  return (
    <PatientInformationWrapper>
      {patientInfo && (
        <ProfileFieldModalGrid>
          <ProfileFieldRow>
            <ProfileFieldRowTitle>Name</ProfileFieldRowTitle>

            {GetTypographyContent({
              content: `${patientInfo?.phi.first_name} ${patientInfo?.phi.last_name}`,
              size: "medium",
              textColor: "black",
            })}
          </ProfileFieldRow>
          <ProfileFieldRow>
            <ProfileFieldRowTitle>Date of Birth</ProfileFieldRowTitle>
            {GetTypographyContent({
              content: dateFormatter(patientInfo?.phi.dob),
              size: "medium",
              textColor: "black",
            })}
          </ProfileFieldRow>
          <ProfileFieldRow>
            <ProfileFieldRowTitle>Estimated Due Date</ProfileFieldRowTitle>
            {GetTypographyContent({
              content: dateFormatter(patientInfo?.phi.estimated_due_date),
              size: "medium",
              textColor: "black",
            })}
          </ProfileFieldRow>
        </ProfileFieldModalGrid>
      )}
      <Grid
        container
        spacing={3}
        sx={{ py: 2 }}
        columns={{ xs: 4, sm: 10, md: 12 }}
      >
        <PatientInformationStreetGrid $addPadding item md={8} sm={6} xs={4}>
          <FormField
            fieldTitle="Street Address*"
            isError={isError && !fullAddress.streetAddress}
            autoCompletePlaceholder="Street Address"
            fieldType="autocomplete"
            newRequisition
            autoCompleteDropdownStyle
            autoCompleteOptions={autocompleteAddressList.map(
              (address) => address.fullAddress,
            )}
            fieldValue={fullAddress.streetAddress}
            onFieldChange={(val) => {
              const selectedAddress = autocompleteAddressList.find(
                (address) => address.fullAddress === val,
              );

              if (selectedAddress) {
                setFullAddress({
                  ...fullAddress,
                  fullAddress: selectedAddress.fullAddress || "",
                  streetAddress:
                    `${selectedAddress.houseNumber} ${selectedAddress.streetName}` ||
                    "",
                });
                setSelectedAutoCompleteAddress(selectedAddress);
              } else {
                setAddress(val as string);
              }
            }}
            edit
            autoCompleteFreeSolo
          />
        </PatientInformationStreetGrid>

        <Grid item md={4} sm={4} xs={4}>
          <PatientInformationTextField
            id="new-test-patient-apt-suite"
            label="Apt / Unit / Suite"
            autoComplete="nope"
            variant="standard"
            size="small"
            placeholder="Apt / Unit / Suite"
            fullWidth
            focused
            value={fullAddress.aptUnit}
            name="aptUnit"
            onChange={(e) =>
              setFullAddress({ ...fullAddress, aptUnit: e.target.value })
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        sx={{ py: 2 }}
        columns={{ xs: 4, sm: 9, md: 12 }}
      >
        <Grid item md={4} sm={3} xs={4}>
          <PatientInformationTextField
            id="new-test-patient-city"
            label={
              <ProfileInsuranceTextFieldLabel>
                City*
                {isError && !fullAddress.city && <WarningRounded />}
              </ProfileInsuranceTextFieldLabel>
            }
            error={isError && !fullAddress.city ? "Error" : ""}
            autoComplete="nope"
            variant="standard"
            size="small"
            placeholder="City name"
            fullWidth
            focused
            value={fullAddress.city}
            name="city"
            onChange={(e) =>
              setFullAddress({ ...fullAddress, city: e.target.value })
            }
          />
        </Grid>

        <PatientInformationStateGrid item md={6} sm={4} xs={4}>
          <FormField
            fieldTitle="State*"
            autoCompletePlaceholder="State"
            isError={isError && !fullAddress.state}
            fieldType="autocomplete"
            newRequisition
            autoCompleteDropdownStyle
            autoCompleteOptions={statesArray}
            fieldValue={fullAddress.state}
            onFieldChange={(val) => {
              setFullAddress({
                ...fullAddress,
                state: val as string,
              });
            }}
            edit
          />
        </PatientInformationStateGrid>
        <Grid item md={2} sm={2} xs={4}>
          <PatientInformationTextField
            id="new-test-patient-zip"
            label={
              <ProfileInsuranceTextFieldLabel>
                Zip code*
                {isError && !fullAddress.zipCode && <WarningRounded />}
              </ProfileInsuranceTextFieldLabel>
            }
            error={isError && !fullAddress.zipCode ? "Error" : ""}
            autoComplete="nope"
            variant="standard"
            size="small"
            placeholder="Zip code"
            fullWidth
            focused
            value={fullAddress.zipCode}
            name="zipCode"
            onChange={(e) =>
              setFullAddress({ ...fullAddress, zipCode: e.target.value })
            }
          />
        </Grid>
      </Grid>
    </PatientInformationWrapper>
  );
};
export default PatientInformationAddress;
