import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import { Formik, FormikProps } from "formik";
import { Button } from "../shared/Button/Button";
import { GetTypographyContent } from "../shared/Typography/Typography";
import {
  FormFieldInput,
  ResetPasswordButtonContainer,
  ResetPasswordCenterContainerPaddedTop,
  ResetPasswordFormGrid,
  ResetPasswordInput,
  Spinner,
} from "./ResetPassword.styles";
import { ResetPasswordRequest } from "./Services/ResetPasswordService";
import loading from "../../assets/icons/loading.svg";
import { RESET_PASSWORD_SENT_URL } from "../../utils/urlConstants";
import { resetPwEmail } from "../../recoil/atoms";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { validateEmail } from "../../utils/hooks/helpers";
import { ErrorPopup } from "../shared/ErrorPopup/ErrorPopup";
import { networkErrorMessage } from "../../utils/helpers";

interface ResetPasswordFormValues {
  email: string;
}

export const ResetPasswordForm = (): React.ReactElement => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const { deviceType } = useDeviceHook();
  const [isLoading, setIsLoading] = useState(false);
  const [, setNewResetPwEmail] = useRecoilState(resetPwEmail);

  const onSubmit = async ({
    email,
  }: ResetPasswordFormValues): Promise<void> => {
    try {
      const response = await ResetPasswordRequest(email);
      setIsLoading(false);
      setNewResetPwEmail(email);
      if (response?.ok) {
        history.push(RESET_PASSWORD_SENT_URL);
      } else {
        setErrorMessage("Unexpected error occured");
      }
    } catch (e) {
      setIsLoading(false);
      setErrorMessage(networkErrorMessage);
    }
  };

  return (
    <ResetPasswordCenterContainerPaddedTop>
      {GetTypographyContent({
        content: "Reset your password",
        size: "h3",
        isBold: true,
        textColor: "neutral700",
        padding: "0 0 30px 0",
      })}
      {GetTypographyContent({
        content:
          "Enter an email address and we'll send a link to reset your password",
        size: "medium",
        textColor: "neutral700",
        padding: "0 0 15px 0",
      })}
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={(values) => {
          setIsLoading(true);
          onSubmit(values);
        }}
        validateOnChange={false}
        validate={(values) => {
          if (!values.email) {
            return { email: "Required" };
          }
          if (!validateEmail(values.email)) {
            return { email: "Invalid email" };
          }
        }}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          setFieldError,
          errors,
        }: FormikProps<ResetPasswordFormValues>) => (
          <ResetPasswordFormGrid>
            <FormFieldInput deviceType={deviceType}>
              <ResetPasswordInput
                type="email"
                name="email"
                placeholder="Email"
                value={values.email}
                onChange={(event) => {
                  setFieldError("email", "");
                  setFieldValue("email", event.target.value);
                }}
                label="Email"
                size="small"
                error={Boolean(errors.email)}
                helperText={errors.email}
              />
            </FormFieldInput>
            <ResetPasswordButtonContainer>
              {isLoading && (
                <Spinner>
                  <img src={loading} alt="Loading" />
                </Spinner>
              )}
              {!isLoading && errorMessage ? (
                <ErrorPopup
                  message={errorMessage}
                  hideMessage={setErrorMessage}
                />
              ) : (
                <Button
                  type="submit"
                  label="Send Reset Email"
                  size="medium"
                  onClick={handleSubmit}
                />
              )}
            </ResetPasswordButtonContainer>
          </ResetPasswordFormGrid>
        )}
      </Formik>
    </ResetPasswordCenterContainerPaddedTop>
  );
};
