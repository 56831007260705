import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  UpfrontPaymentButtonRow,
  UpfrontPaymentCost,
  UpfrontPaymentDesktopContainer,
  UpfrontPaymentFinancialAssistance,
  UpfrontPaymentFinancialAssistanceWrapper,
  UpfrontPaymentFinancialAssitancePhone,
  UpfrontPaymentInfoBillContent,
  UpfrontPaymentMainText,
  UpfrontPaymentPlanTitle,
  UpfrontPaymentReceiptTitle,
  UpfrontPaymentSolidLine,
} from "./UpfrontPayment.styles";

import { useDeviceHook } from "../../utils/hooks/useDeviceHook";

import { Button } from "../shared/Button/Button";
import {
  getCentsToDollars,
  GetPreviewUpfrontPaymentPlan,
  GetUpfrontPaymentPlan,
  RemoveUpfrontPayment,
  UpfrontPaymentPlan,
  UpfrontPaymentType,
} from "./Services/UpfrontPaymentService";
import { UpfrontPaymentPlanSteps } from "./UpfrontPaymentPlanSteps";
import { BillingSupportNumber, StepperIndex } from "./types";
import { BabyPeekRadioButton } from "../BabyPeek/BabyPeekRadioButton";
import { getCardIcon } from "../BabyPeek/BabyPeekStripe";
import {
  BabyPeekPaymentPlanTitle,
  BabyPeekPSavedCardGridRow,
  BabyPeekStripeIconPointGridText,
  BabyPeekStripeIconPointViewSampleTax,
} from "../BabyPeek/BabyPeekStripe.styles";
import { getUserAuth } from "../../recoil/selectors";
import { SavedCardType } from "../BabyPeek/Services/BabyPeekService";
import { BabyPeekIntroFAQAccordionWrapper } from "./PaymentPlanNumPayments.styles";
import { UpfrontPaymentPaperStatements } from "./UpfrontPaymentPaperStatements";
import UpfrontPaymentPhoneLink from "./UpfrontPaymentPhoneLink";

export const UpfrontPaymentPlanBillingSummary: React.FC<{
  setPaymentOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setStripeOpen: (open: boolean) => Promise<void>;
  setStep: React.Dispatch<React.SetStateAction<StepperIndex>>;
  upfrontBill: UpfrontPaymentType;
  savedPayments?: SavedCardType[];
  setSavedPayments: React.Dispatch<
    React.SetStateAction<SavedCardType[] | undefined>
  >;
  setUsedSavePayment: React.Dispatch<React.SetStateAction<string | undefined>>;
  useSavedPayment?: string;
  useNewCard: boolean;
  setUseNewCard: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  setPaymentOpen,
  setStripeOpen,
  setStep,
  upfrontBill,
  savedPayments,
  setUsedSavePayment,
  setUseNewCard,
  useNewCard,
  useSavedPayment,
  setSavedPayments,
}) => {
  const { deviceType } = useDeviceHook();

  const { billingPK, adminID } = useParams<{
    billingPK: string;
    adminID?: string;
  }>();

  const [paymentPlan, setPaymentPlan] = useState<UpfrontPaymentPlan[]>();
  const auth = useRecoilValue(getUserAuth);
  const [numberPayments, setNumberPayments] = useState<"6">();

  useEffect(() => {
    const getUpfrontPaymentPlanDetails = async () => {
      if (upfrontBill.is_payment_plan_enabled) {
        const paymentPlanResponse = await GetUpfrontPaymentPlan(
          billingPK,
          adminID,
        );
        const paymentPlanJson = await paymentPlanResponse.json();
        setPaymentPlan(paymentPlanJson);
        if (paymentPlanJson) {
          setNumberPayments(paymentPlanJson.length.toString());
        }
      } else {
        const previewPaymentPlanResponse = await GetPreviewUpfrontPaymentPlan(
          billingPK,
          adminID,
        );
        const previewPaymentPlanJson = await previewPaymentPlanResponse.json();
        setPaymentPlan(previewPaymentPlanJson);
        setNumberPayments(previewPaymentPlanJson.length.toString());
      }
    };
    getUpfrontPaymentPlanDetails();
  }, []);

  return (
    <>
      <UpfrontPaymentDesktopContainer deviceType={deviceType}>
        <UpfrontPaymentReceiptTitle>
          {`Pay in ${numberPayments || 6} Interest-Free Payments`}
        </UpfrontPaymentReceiptTitle>

        {upfrontBill && (
          <>
            <UpfrontPaymentInfoBillContent marginBottom={8}>
              <UpfrontPaymentPlanTitle>
                <UpfrontPaymentCost>
                  {getCentsToDollars(
                    upfrontBill?.total_patient_responsibility_amount,
                  )}
                </UpfrontPaymentCost>
                <UpfrontPaymentMainText>
                  {`  split up monthly`}
                </UpfrontPaymentMainText>
              </UpfrontPaymentPlanTitle>
              <BabyPeekIntroFAQAccordionWrapper>{`${
                numberPayments || 6
              } Payments`}</BabyPeekIntroFAQAccordionWrapper>
            </UpfrontPaymentInfoBillContent>
            <UpfrontPaymentInfoBillContent>
              {paymentPlan && <UpfrontPaymentPlanSteps steps={paymentPlan} />}
            </UpfrontPaymentInfoBillContent>

            <UpfrontPaymentSolidLine />
            <UpfrontPaymentInfoBillContent style={{ marginTop: "10px" }}>
              <UpfrontPaymentMainText>No interest</UpfrontPaymentMainText>
              <UpfrontPaymentMainText>
                {`Total: ${getCentsToDollars(
                  upfrontBill?.total_patient_responsibility_amount,
                )}`}
              </UpfrontPaymentMainText>
            </UpfrontPaymentInfoBillContent>
            {Boolean(savedPayments?.length) &&
              upfrontBill.portal_bill_status === "outstanding" && (
                <div>
                  <hr />
                  <BabyPeekPaymentPlanTitle>Payment</BabyPeekPaymentPlanTitle>
                  {savedPayments
                    ?.filter((saved) => saved.is_chargeable)
                    .map((saved) => (
                      <BabyPeekPSavedCardGridRow key={saved.payment_method_id}>
                        <BabyPeekRadioButton
                          clicked={useSavedPayment === saved.payment_method_id}
                          setClick={() => {
                            setUsedSavePayment(saved.payment_method_id);
                            setUseNewCard(false);
                          }}
                        />
                        <BabyPeekStripeIconPointGridText
                          onClick={() => {
                            setUsedSavePayment(saved.payment_method_id);
                            setUseNewCard(false);
                          }}
                        >
                          <img src={getCardIcon(saved.card_brand)} alt="card" />
                          {`•••• ${saved.last_four_digits}`}
                        </BabyPeekStripeIconPointGridText>
                        {!saved.is_used_for_payment_plan && (
                          <BabyPeekStripeIconPointViewSampleTax
                            deviceType={deviceType}
                            onClick={async () => {
                              const removeResponse = await RemoveUpfrontPayment(
                                billingPK,
                                saved.payment_method_id,
                                auth?.access_token,
                              );

                              const savedCards = await removeResponse.json();
                              setSavedPayments(savedCards.payment_methods);
                            }}
                          >
                            Remove
                          </BabyPeekStripeIconPointViewSampleTax>
                        )}
                      </BabyPeekPSavedCardGridRow>
                    ))}
                  <BabyPeekPSavedCardGridRow>
                    <BabyPeekRadioButton
                      clicked={useNewCard}
                      setClick={() => {
                        setUseNewCard(true);
                        setUsedSavePayment(undefined);
                      }}
                    />
                    <BabyPeekStripeIconPointGridText
                      onClick={() => {
                        setUseNewCard(true);
                        setUsedSavePayment(undefined);
                      }}
                    >
                      Pay with new card
                    </BabyPeekStripeIconPointGridText>
                  </BabyPeekPSavedCardGridRow>
                </div>
              )}
            {!upfrontBill.is_payment_plan_enabled && (
              <>
                <UpfrontPaymentButtonRow>
                  <Button
                    type="submit"
                    label={
                      useSavedPayment
                        ? "Schedule Payments"
                        : "Continue to Payment"
                    }
                    size="medium"
                    onClick={() => setStripeOpen(true)}
                    backgroundColor="greenDark"
                  />
                </UpfrontPaymentButtonRow>
                <UpfrontPaymentButtonRow bottomRow tertiary>
                  <Button
                    label="Cancel"
                    size="medium"
                    reverse
                    onClick={() => setStep(StepperIndex.Main)}
                    backgroundColor="greyMedium"
                  />
                </UpfrontPaymentButtonRow>{" "}
                <UpfrontPaymentFinancialAssitancePhone>
                  {`Looking for a different payment plan? Call us at `}
                  <UpfrontPaymentPhoneLink />
                </UpfrontPaymentFinancialAssitancePhone>
              </>
            )}

            {upfrontBill.show_financial_assistance && (
              <UpfrontPaymentFinancialAssistanceWrapper
                onClick={() => setPaymentOpen(true)}
              >
                <UpfrontPaymentFinancialAssistance>
                  Apply For Financial Assistance
                </UpfrontPaymentFinancialAssistance>
              </UpfrontPaymentFinancialAssistanceWrapper>
            )}
          </>
        )}
      </UpfrontPaymentDesktopContainer>

      <UpfrontPaymentPaperStatements upfrontBill={upfrontBill} />
    </>
  );
};
