import { FliptEvaluationClient } from "@flipt-io/flipt-client-browser";

const fliptBaseUrl = import.meta.env.VITE_FLIPT_BASE_URL;
const fliptClientToken = import.meta.env.VITE_FLIPT_TOKEN;

let fliptClient: FliptEvaluationClient | null = null;

export const getFliptClient = async (): Promise<FliptEvaluationClient> => {
  if (!fliptBaseUrl || !fliptClientToken) {
    return Promise.reject(Error("Flipt environment variables are not set!"));
  }

  if (!fliptClient) {
    fliptClient = await FliptEvaluationClient.init("prenatal-patient-portal", {
      url: fliptBaseUrl,
      authentication: {
        clientToken: fliptClientToken,
      },
    });
  }
  return fliptClient;
};

export enum FeatureFlags {
  VideoLibrary = "video-library",
}
