import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { Formik, FormikErrors, FormikProps } from "formik";
import { Button } from "../shared/Button/Button";
import {
  Spinner,
  CreateAccountPasswordInfoContainer,
  CreateAccountPrivacyPolicy,
  CreateAccountCenterFormPreTestTitle,
} from "./CreateAccountForm.styles";

import {
  auth0ResponseMessages,
  CreateAccountPreTestRequest,
} from "./Services/CreateAccountService";
import { CreateAccountValuesType } from "./types";
import {
  LANDING_PAGE_URL,
  LOGIN_PAGE_URL,
  VERIFY_EMAIL_URL,
} from "../../utils/urlConstants";
import { createAccountInitialValues } from "./Services/CreateAccountServiceHelpers";
import { CreateAccountPasswordInfo } from "./CreateAccountPasswordInfo";
import loading from "../../assets/icons/loading.svg";
import { patientState } from "../../recoil/atoms";
import { UserAuthClientResponse } from "../../recoil/types";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { validateEmail } from "../../utils/hooks/helpers";
import { ErrorPopup } from "../shared/ErrorPopup/ErrorPopup";
import { getUserAuth } from "../../recoil/selectors";
import { networkErrorMessage } from "../../utils/helpers";

import { StyledInput } from "../shared/UI-Library/StyledInput";
import {
  CreateAccountCenterPreTestContainer,
  CreateAccountCenterFormPreTestContainer,
  CreateAccountFormPreTestStyledContainer,
  CreateAccountFormPreTestStyled,
  CreateAccountCenterFormPreTestRowWrapper,
  CreateAccountPretestButtonContainer,
  CreateAccountPretestLogin,
  CreateAccountPretestLoginContent,
  CreateAccountPretestLoginButton,
  CreateAccountFormPreTestStyledWrapper,
  CreateAccountFormPreTestStyledFormWrapper,
  CreateAccountCenterFormPreTestError,
} from "./CreateAccountPreTestForm.styles";
import { StyledUISelect } from "../shared/UI-Library/StyledUISelect";
import { WarningHollowRound } from "../../assets/iconComponents/WarningHollowRound";
import { setCookieAccessToken } from "../../utils/requestUtils";

export const CreateAccountFormContentPreTest: React.FC<{
  setPathname: () => void;
}> = ({ setPathname }) => {
  const history = useHistory();
  const auth = useRecoilValue(getUserAuth);

  useEffect(() => {
    const unlisten = history.listen(() => {
      setPathname();
    });
    return () => {
      unlisten();
    };
  }, []);

  useEffect(() => {
    if (auth) {
      history.push(LANDING_PAGE_URL);
    }
  }, [auth]);

  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, setPatientInfo] = useRecoilState(patientState);

  const [isPw8Chars, setIsPw8Chars] = useState(false);
  const [isPwUpperChar, setIsPwUpperChar] = useState(false);
  const [isPwLowerChar, setIsPwLowerChar] = useState(false);
  const [isPwDigit, setIsPwDigit] = useState(false);
  const { deviceType } = useDeviceHook();
  const { limsId } = useParams<{ limsId: string | undefined }>();

  const checkPasswordRequirements = (input: string): boolean => {
    const pw8Chars = input.length >= 8;
    const pwUpperChar = /[A-Z]/.test(input);
    const pwLowerChar = /[a-z]/.test(input);
    const pwDigit = /[0-9]/.test(input);
    setIsPw8Chars(pw8Chars);
    setIsPwUpperChar(pwUpperChar);
    setIsPwLowerChar(pwLowerChar);
    setIsPwDigit(pwDigit);
    return pw8Chars && pwUpperChar && pwLowerChar && pwDigit;
  };

  const onSubmit = async ({
    first_name,
    last_name,
    middle_name,
    gender,
    referral,
    zip,
    dob,
    email,
    password,
  }: CreateAccountValuesType): Promise<void> => {
    setIsSubmitting(true);
    try {
      const response = await CreateAccountPreTestRequest({
        first_name,
        last_name,
        dob,
        email,
        password,
        middle_name,
        gender,
        referral,
        zip,
        lims_id: limsId,
      });
      setIsSubmitting(false);
      const statusCode: number = response[0];
      const newUserInfo: UserAuthClientResponse | undefined = response[1];
      if (newUserInfo?.auth0.access_token) {
        setCookieAccessToken(newUserInfo.auth0.access_token);
        setPatientInfo(newUserInfo);
        history.push(VERIFY_EMAIL_URL);
      } else {
        const msg = `${auth0ResponseMessages[statusCode]}`;
        setErrorMessage(msg);
      }
    } catch (e) {
      setIsSubmitting(false);
      setErrorMessage(networkErrorMessage);
    }
  };

  return (
    <CreateAccountCenterPreTestContainer
      data-testid="CreateAccountForm"
      deviceType={deviceType}
    >
      <ErrorPopup message={errorMessage} hideMessage={setErrorMessage} onTop />
      <CreateAccountCenterFormPreTestContainer>
        <CreateAccountCenterFormPreTestTitle id="title">
          Sign up for UNITY
        </CreateAccountCenterFormPreTestTitle>
        <Formik
          initialValues={createAccountInitialValues}
          onSubmit={(values) => {
            onSubmit(values);
          }}
          validateOnChange={false}
          validate={(values) => {
            const errors: FormikErrors<CreateAccountValuesType> = {};
            if (!values.first_name) {
              errors.first_name = "Required";
            }
            if (!values.last_name) {
              errors.last_name = "Required";
            }
            if (!values.dob) {
              errors.dob = "Required";
            }

            if (!values.email) {
              errors.email = "Required";
            } else if (!validateEmail(values.email)) {
              errors.email = "Invalid email";
            }

            if (!values.password) {
              errors.password = "Required";
            } else if (!checkPasswordRequirements(values.password)) {
              errors.password = "Password not valid";
            }

            if (!values.confirm_password) {
              errors.confirm_password = "Required";
            } else if (values.confirm_password !== values.password) {
              errors.confirm_password = "Password do not match";
            }

            if (Object.keys(errors).length) {
              document.getElementById("title")?.scrollIntoView();
            }

            return errors;
          }}
        >
          {({
            values,
            errors,
            handleSubmit,
            setFieldValue,
            setFieldError,
          }: FormikProps<CreateAccountValuesType>) => (
            <CreateAccountFormPreTestStyledWrapper deviceType={deviceType}>
              <CreateAccountFormPreTestStyledFormWrapper
                deviceType={deviceType}
              >
                {Boolean(
                  Object.keys(errors).filter((error) =>
                    Boolean(errors[error as keyof CreateAccountValuesType]),
                  ).length,
                ) && (
                  <CreateAccountCenterFormPreTestError>
                    <WarningHollowRound />
                    Please scroll all the way to the bottom to fill out the
                    required fields.
                  </CreateAccountCenterFormPreTestError>
                )}
                <CreateAccountFormPreTestStyledContainer
                  deviceType={deviceType}
                >
                  <CreateAccountFormPreTestStyled
                    deviceType={deviceType}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !isSubmitting) {
                        handleSubmit();
                      }
                    }}
                  >
                    <CreateAccountCenterFormPreTestRowWrapper>
                      <StyledInput
                        fieldTitle="First name"
                        type="text"
                        name="first_name"
                        value={values.first_name}
                        onChange={(event) => {
                          setFieldError("first_name", "");
                          setFieldValue("first_name", event.target.value);
                        }}
                        error={errors.first_name}
                      />
                      <StyledInput
                        fieldTitle="Middle name"
                        type="text"
                        name="middle_name"
                        value={values.middle_name}
                        onChange={(event) => {
                          setFieldError("middle_name", "");
                          setFieldValue("middle_name", event.target.value);
                        }}
                        optional
                      />
                    </CreateAccountCenterFormPreTestRowWrapper>
                    <CreateAccountCenterFormPreTestRowWrapper
                      style={{ width: "100%" }}
                    >
                      <StyledInput
                        fieldTitle="Last name"
                        type="text"
                        name="last_name"
                        value={values.last_name}
                        onChange={(event) => {
                          setFieldError("last_name", "");
                          setFieldValue("last_name", event.target.value);
                        }}
                        error={errors.last_name}
                      />
                      <StyledUISelect
                        fieldTitle="Gender"
                        optional
                        minWidth="200px"
                        name="gender"
                        value={values.gender}
                        onChange={(event) => {
                          setFieldError("gender", "");
                          setFieldValue("gender", event.target.value);
                        }}
                      >
                        <option>Male</option>
                        <option>Female</option>
                      </StyledUISelect>
                    </CreateAccountCenterFormPreTestRowWrapper>
                    <CreateAccountCenterFormPreTestRowWrapper>
                      <StyledInput
                        fieldTitle="Email"
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={(event) => {
                          setFieldError("email", "");
                          setFieldValue("email", event.target.value);
                        }}
                        error={errors.email}
                      />
                      <StyledInput
                        fieldTitle="Date of birth"
                        type="date"
                        name="dob"
                        value={values.dob}
                        onChange={(event) => {
                          setFieldError("dob", "");
                          setFieldValue("dob", event.target.value);
                        }}
                        error={errors.dob}
                      />
                    </CreateAccountCenterFormPreTestRowWrapper>
                    <CreateAccountCenterFormPreTestRowWrapper>
                      <StyledInput
                        fieldTitle="Zip code"
                        optional
                        type="text"
                        name="zip"
                        value={values.zip}
                        onChange={(event) => {
                          setFieldError("zip", "");
                          setFieldValue("zip", event.target.value);
                        }}
                      />
                      <StyledInput
                        fieldTitle="Referral code"
                        optional
                        type="text"
                        name="referral"
                        value={values.referral}
                        onChange={(event) => {
                          setFieldError("referral", "");
                          setFieldValue("referral", event.target.value);
                        }}
                        error={errors.referral}
                      />
                    </CreateAccountCenterFormPreTestRowWrapper>
                    <CreateAccountCenterFormPreTestRowWrapper>
                      <StyledInput
                        fieldTitle="Password"
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={(event) => {
                          setFieldError("password", "");
                          checkPasswordRequirements(event.target.value);
                          setFieldValue("password", event.target.value);
                        }}
                        error={errors.password}
                      />
                      <StyledInput
                        fieldTitle="Confirm password"
                        type="password"
                        name="confirm_password"
                        value={values.confirm_password}
                        onChange={(event) => {
                          setFieldError("confirm_password", "");
                          setFieldValue("confirm_password", event.target.value);
                        }}
                        error={errors.confirm_password}
                      />
                    </CreateAccountCenterFormPreTestRowWrapper>

                    <CreateAccountPasswordInfoContainer>
                      <CreateAccountPasswordInfo
                        isPw8Chars={isPw8Chars}
                        isPwDigit={isPwDigit}
                        isPwUpperChar={isPwUpperChar}
                        isPwLowerChar={isPwLowerChar}
                        isFlatLayout={deviceType === "desktop"}
                      />
                    </CreateAccountPasswordInfoContainer>

                    <CreateAccountPrivacyPolicy>
                      <p>
                        {`By clicking 'Create Account' you agree to our `}
                        <a
                          style={{ textDecoration: "underline" }}
                          href="https://billiontoone.com/privacy-policy/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>
                        {` and `}
                        <a
                          style={{ textDecoration: "underline" }}
                          href="../../client/terms_of_use"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Terms of Use.
                        </a>
                      </p>
                    </CreateAccountPrivacyPolicy>
                  </CreateAccountFormPreTestStyled>
                </CreateAccountFormPreTestStyledContainer>
              </CreateAccountFormPreTestStyledFormWrapper>

              <CreateAccountPretestButtonContainer deviceType={deviceType}>
                {isSubmitting ? (
                  <Spinner>
                    <img src={loading} alt="Loading" />
                  </Spinner>
                ) : (
                  <Button
                    type="submit"
                    label="Create Account"
                    size="medium"
                    onClick={handleSubmit}
                    backgroundColor="greenDark"
                  />
                )}
                <CreateAccountPretestLogin deviceType={deviceType}>
                  <CreateAccountPretestLoginContent>
                    Already have an account?
                  </CreateAccountPretestLoginContent>
                  <CreateAccountPretestLoginButton
                    onClick={() => history.push(LOGIN_PAGE_URL)}
                  >
                    Log in
                  </CreateAccountPretestLoginButton>
                </CreateAccountPretestLogin>
              </CreateAccountPretestButtonContainer>
            </CreateAccountFormPreTestStyledWrapper>
          )}
        </Formik>
      </CreateAccountCenterFormPreTestContainer>
    </CreateAccountCenterPreTestContainer>
  );
};
