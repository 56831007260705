import { HelpFAQAnswerWrapper } from "../../pages/Help/Help.styles";
import { AccordionProps } from "../shared/Accordion/Accordions";
import { GetTypographyContent } from "../shared/Typography/Typography";

export const upfrontPaymentAccordionData: AccordionProps[] = [
  {
    title: "Why did I receive a notification about an open balance?",
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content: `Recently, you visited your doctor and had testing ordered to be performed by our laboratory. We worked with your insurance and determined that you are responsible for a portion of the charges due to a deductible, co-insurance, copay, or non-covered procedure, in accordance with your insurance policy.  To view an itemized breakdown, please click 'Itemized Bill' to see charge details and insurance coverage.`,
          size: "medium",
        })}
      </HelpFAQAnswerWrapper>
    ),
    key: "openBalanceNotification",
  },
  {
    title: "Why do I have an open balance?",
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content: `Our laboratory (BillionToOne Inc.) performed testing services that were ordered by your doctor. As the service provider, and as a courtesy to patients, we work with your insurance company directly to seek reimbursement.  However, due to the fact that insurance plans vary in coverage between copays, deductibles, and co-insurance, you are sometimes responsible for an additional unpaid portion. Your open balance is the portion of the test that you are responsible for based on your specific insurance policy.`,
          size: "medium",
        })}
      </HelpFAQAnswerWrapper>
    ),
    key: "openBalance",
  },
  {
    title: "Why didn’t my insurance cover my bill?",
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content: `As the service provider, and as a courtesy to patients, we work with your insurance company directly to seek reimbursement.  However, due to the fact that insurance plans vary in coverage between copays, deductibles, and co-insurance, you are sometimes responsible for an additional unpaid portion. As an example, a common insurance policy may cover 80% of the cost and the additional 20% of the cost is the responsibility of the policyholder. Your insurance covers the test in accordance with the specific insurance policy that you hold and the amount owed is your portion of the test cost.`,
          size: "medium",
        })}
      </HelpFAQAnswerWrapper>
    ),

    key: "insuranceCover",
  },
  {
    title: "How can I view my itemized bill?",
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content: `To view an itemized bill or paper statement you will need to either log in to your existing patient portal account or create a new account. Click 'Itemized Bill' for more details.`,
          size: "medium",
        })}
      </HelpFAQAnswerWrapper>
    ),

    key: "itemizedBill",
  },
  {
    title: "What if I can’t pay the full amount right now?",
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content: `If you are unable to make a payment for the full balance, please select the 'Build Payment Plan' option to split the balance into 6 equal, no-interest payments.`,
          size: "medium",
        })}
      </HelpFAQAnswerWrapper>
    ),

    key: "fullAmount",
  },
];
