import React from "react";
import { useHistory } from "react-router-dom";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { StyledButton } from "../shared/Button/Button.styles";
import { GetTypographyContent } from "../shared/Typography/Typography";
import {
  DetailedResultsFetalSexForwardConfirmButton,
  DetailedResultsFetalSexForwardConfirmWrapper,
} from "./DetailedResults.styles";

export const DetailedResultsFetalSexForwardConfirm: React.FC<{
  confirmedEmail: string;
}> = ({ confirmedEmail }) => {
  const { deviceType } = useDeviceHook();
  const history = useHistory();
  return (
    <DetailedResultsFetalSexForwardConfirmWrapper deviceType={deviceType}>
      <div>
        {GetTypographyContent({
          content: "Fetal sex sent",
          size: "h3",
          textColor: "greyMedium",
          isBold: true,
        })}
      </div>
      <div>
        {GetTypographyContent({
          content: "Thank you. The fetal sex has been sent to:",
          size: "large",
          textColor: "greyMedium",
        })}
        {GetTypographyContent({
          content: confirmedEmail,
          size: "large",
          textColor: "greyMedium",
          isBold: true,
        })}
      </div>
      <DetailedResultsFetalSexForwardConfirmButton>
        <StyledButton
          variant="outlined"
          size="small"
          $backgroundColor="white"
          $textColor="redMedium"
          onClick={() => history.push("/client/dashboard")}
        >
          RETURN TO HOMEPAGE
        </StyledButton>
      </DetailedResultsFetalSexForwardConfirmButton>
    </DetailedResultsFetalSexForwardConfirmWrapper>
  );
};
