import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { LightOrDarkHairTrait } from "./Traits/LightOrDarkHair/LightOrDarkHairTrait";
import { RedHairTrait } from "./Traits/RedHair/RedHairTrait";
import { CurlyHairTrait } from "./Traits/CurlyHair/CurlyHairTrait";
import { HairThicknessTrait } from "./Traits/HairThickness/HairThicknessTrait";
import { EyeColorTrait } from "./Traits/EyeColor/EyeColorTrait";
import { AsparagusTrait } from "./Traits/Asparagus/AsparagusTrait";
import { BabyTeethTrait } from "./Traits/BabyTeeth/BabyTeethTrait";
import { FreckleTrait } from "./Traits/Freckles/FreckleTrait";
import { CilantroTrait } from "./Traits/Cilantro/CilantroTrait";
import { BitterTrait } from "./Traits/BitterTaste/BitterTrait";
import { SweetSaltyTrait } from "./Traits/SweetSalty/SweetSaltyTrait";
import { SweetTrait } from "./Traits/Sweet/SweetTrait";
import {
  BabyPeekButtonNames,
  BabyPeekFeedbackResponse,
  BabyPeekTraitResponse,
  GetBabyPeekDataRequest,
  GetBabyPeekFeedback,
  LowerCasePageIndex,
  PostBabyPeekAction,
  Trait,
} from "./Services/BabyPeekService";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { SpinnerLoading } from "../shared/Spinner/SpinnerLoading";
import {
  LANDING_PAGE_URL,
  PUBLIC_BABYPEEK_URL,
} from "../../utils/urlConstants";

import { getUserAuth } from "../../recoil/selectors";
import { BabyPeekViewResultsMobile } from "./BabyPeekViewResultsMobile";

export const BabyPeekPublic: React.FC = () => {
  const [isFromEmailLink, setIsFromEmailLink] = useState(false);
  const [traitData, setTraitData] = useState<BabyPeekTraitResponse>();
  const [lastButtonClicked, setLastButtonClicked] =
    useState<BabyPeekButtonNames>();
  const [feedbackData, setFeedback] = useState<BabyPeekFeedbackResponse>();
  const { deviceType } = useDeviceHook();

  const { babypeekPK } = useParams<{
    babypeekPK: string;
    pageIndex?:
      | Trait
      | "Intro"
      | "Advocacy"
      | "Summary"
      | "Trait"
      | "Checkout"
      | "SocialPromo"
      | "SampleReport";
  }>();

  let { pageIndex } = useParams<{
    babypeekPK: string;
    pageIndex?:
      | Trait
      | "Intro"
      | "Advocacy"
      | "Summary"
      | "Trait"
      | "Checkout"
      | "SocialPromo"
      | "SampleReport"
      | LowerCasePageIndex;
  }>();

  pageIndex = pageIndex?.toLowerCase() as LowerCasePageIndex;
  const history = useHistory();
  const auth = useRecoilValue(getUserAuth);

  const setViewTrait = (viewTrait?: Trait) => {
    history.push(`${PUBLIC_BABYPEEK_URL}/${babypeekPK}/${viewTrait ?? ""}`);
  };

  useEffect(() => {
    try {
      const params = new URLSearchParams(window.location.search);
      const fromEmail = params.get("e"); // e is set from a sign up email link
      setIsFromEmailLink(fromEmail === "True");
    } catch (e) {
      // from sms
      setIsFromEmailLink(false);
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      const response = await GetBabyPeekDataRequest(babypeekPK);
      if (response?.ok) {
        const responseJson = (await response.json()) as BabyPeekTraitResponse;
        setTraitData(responseJson);
      } else {
        history.push(LANDING_PAGE_URL);
      }
    };
    getData();
  }, []);

  const getTraitFeedback = async () => {
    const response = await GetBabyPeekFeedback(babypeekPK);
    if (response?.ok) {
      const responseJson = (await response.json()) as BabyPeekFeedbackResponse;
      setFeedback(responseJson);
    }
  };

  useEffect(() => {
    const sendPageAction = async () => {
      window.scrollTo(0, 0);
      let pageAction: string | undefined;
      if (traitData) {
        switch (pageIndex) {
          case "sweet":
            pageAction = "ViewedSweetTaste";
            break;

          case "sweetsalty":
            pageAction = "ViewedSweetVsSaltyTaste";
            break;
          case "bitter":
            pageAction = "ViewedBitterTaste";
            break;
          case "cilantro":
            pageAction = "ViewedCilantro";
            break;
          case "freckle":
            pageAction = "ViewedFreckles";
            break;

          case "babyteeth":
            pageAction = "ViewedTeeth";
            break;

          case "asparagus":
            pageAction = "ViewedAsparagus";
            break;

          case "eyecolor":
            pageAction = "ViewedEyeColor";
            break;

          case "hairthickness":
            pageAction = "ViewedHairThickness";
            break;

          case "curlyhair":
            pageAction = "ViewedCurlyHair";
            break;

          case "redhair":
            pageAction = "ViewedRedHair";
            break;

          case "lightordarkhair":
            pageAction = "ViewedLightOrDarkHair";
            break;

          default:
        }
      } else {
        pageAction = "ViewedSummaryPage";
      }

      if (pageAction) {
        await PostBabyPeekAction(
          babypeekPK,
          pageAction,
          deviceType,
          isFromEmailLink,
          false,
          {
            lastButtonClicked,
            sharedVersion: "true",
            is_logged_in: auth ? "true" : "false",
          },
        );
        setLastButtonClicked(undefined);
      }
    };
    sendPageAction();
  }, [pageIndex, traitData]);

  const getPage = () => {
    if (traitData) {
      switch (pageIndex) {
        case "sweet": {
          return (
            <SweetTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
            />
          );
        }
        case "sweetsalty": {
          return (
            <SweetSaltyTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
            />
          );
        }
        case "bitter": {
          return (
            <BitterTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
            />
          );
        }
        case "cilantro": {
          return (
            <CilantroTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
            />
          );
        }
        case "freckle": {
          return (
            <FreckleTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
            />
          );
        }
        case "babyteeth": {
          return (
            <BabyTeethTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
            />
          );
        }
        case "asparagus": {
          return (
            <AsparagusTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
            />
          );
        }
        case "eyecolor": {
          return (
            <EyeColorTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
            />
          );
        }
        case "hairthickness": {
          return (
            <HairThicknessTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
            />
          );
        }
        case "curlyhair": {
          return (
            <CurlyHairTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
            />
          );
        }
        case "redhair": {
          return (
            <RedHairTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
            />
          );
        }
        case "lightordarkhair": {
          return (
            <LightOrDarkHairTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
            />
          );
        }
        default:
          return (
            <BabyPeekViewResultsMobile
              setCurrentTrait={setViewTrait}
              feedbackGiven={feedbackData?.has_general_feedback}
              getTraitFeedback={getTraitFeedback}
              traitData={traitData}
              // setShareModalOpen={setShareTraitModal}
            />
          );
      }
    }
    return (
      <BabyPeekViewResultsMobile
        setCurrentTrait={setViewTrait}
        feedbackGiven={feedbackData?.has_general_feedback}
        getTraitFeedback={getTraitFeedback}
        //   traitData={traitData}
        // setShareModalOpen={setShareTraitModal}
      />
    );
  };

  return (
    <>
      <div style={{ maxWidth: "99%", display: "grid" }}>
        {!pageIndex || !traitData ? <SpinnerLoading /> : getPage()}
      </div>
    </>
  );
};
