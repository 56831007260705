import * as React from "react";

export const BannerWarningIcon = (): React.ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.10322 20.576C2.66805 20.576 2.34957 20.3948 2.14778 20.0325C1.94664 19.6695 1.95083 19.3027 2.16035 18.932L11.033 3.55605C11.2425 3.18535 11.5648 3 12 3C12.4352 3 12.7575 3.18535 12.967 3.55605L21.8396 18.932C22.0492 19.3027 22.0534 19.6695 21.8522 20.0325C21.6504 20.3948 21.332 20.576 20.8968 20.576H3.10322ZM12 9.91435C11.726 9.91435 11.4965 10.0069 11.3115 10.1919C11.1258 10.3776 11.033 10.6074 11.033 10.8814V13.71C11.033 13.984 11.1258 14.2135 11.3115 14.3985C11.4965 14.5842 11.726 14.677 12 14.677C12.274 14.677 12.5038 14.5842 12.6895 14.3985C12.8745 14.2135 12.967 13.984 12.967 13.71V10.8814C12.967 10.6074 12.8745 10.3776 12.6895 10.1919C12.5038 10.0069 12.274 9.91435 12 9.91435ZM12 17.554C12.274 17.554 12.5077 17.4573 12.7011 17.2639C12.8945 17.0705 12.9912 16.8368 12.9912 16.5628C12.9912 16.2888 12.8945 16.0551 12.7011 15.8617C12.5077 15.6682 12.274 15.5715 12 15.5715C11.726 15.5715 11.4923 15.6682 11.2989 15.8617C11.1055 16.0551 11.0088 16.2888 11.0088 16.5628C11.0088 16.8368 11.1055 17.0705 11.2989 17.2639C11.4923 17.4573 11.726 17.554 12 17.554Z"
      fill="#C0250F"
    />
  </svg>
);
