import styled from "styled-components/macro";

import { DeviceType } from "../../../../utils/hooks/useDeviceHook";
import { BabyPeekBarHorizontal } from "../../BabyPeekPercentage.styles";

export const BabyPeekTraitWrapper = styled.div<{ deviceType: DeviceType }>`
  display: grid;
  max-width: 650px;
  justify-self: center;
  img {
    max-width: ${({ deviceType }) =>
      deviceType === "mobile" ? "375px" : undefined};
  }
  background-color: white;

  .MuiAccordionSummary-root {
    padding: 0px 0px !important;
  }

  .MuiAccordionDetails-root {
    padding: 0px 0px !important;
  }

  hr {
    height: 1px;
    border: 0;
    border-top: 1px solid #cbd5e0;
    margin: 16px 0px;
    padding: 0px 25px;
    width: calc(100% - 100px);
    justify-self: center;
  }
`;

export const BabyPeekTraitPercetangeGridMobile = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 20px;

  ${BabyPeekBarHorizontal} {
    height: 8px;
  }
`;

export const BabyPeekTraitPercetangeGridMobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  grid-gap: 24px;
  margin-bottom: 8px;
`;

export const BabyPeekTraitPercetangeGridLabelMobile = styled.div<{
  $selected: boolean;
}>`
  color: var(--Grey-700, #2d3748);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ $selected }) => ($selected ? 600 : 400)};
  line-height: 23.782px;
`;

export const BabyPeekV2HeaderTitleWrapper = styled.div<{
  deviceType: DeviceType;
  windowWidth: number;
}>`
  padding: 20px 16px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 4px;
  border-radius: 20px;
  background: #fff;
  max-width: 650px;
  width: ${({ deviceType, windowWidth }) =>
    deviceType === "desktop" ? "646px" : `${windowWidth - 70}px`};
  z-index: 10;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
`;

export const BabyPeekV2HeaderIconShareTitle = styled.div`
  color: var(--Grey-700, #2d3748);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
  cursor: pointer;
  /* text-decoration: underline;
  text-decoration-color: '#008777 !important';
  text-decoration-style: 'dotted'; */
`;

export const BabyPeekV2HeaderIconShare = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BabyPeekV2HeaderIconGrid = styled.div`
  display: grid;
  grid-template-columns: min-content max-content;
  grid-gap: 8px;
  align-items: center;
  justify-content: center;
  justify-self: baseline;
  font-size: 16px;
  img {
    width: 32px;
  }
`;

export const BabyPeekV2HeaderTitleWrapperIcon = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    margin-top: -3px;
  }
`;

export const BabyPeekV2HeaderTitleTop = styled.div`
  color: var(--Grey-900, #171923);
  font-feature-settings: "case" on;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
  margin-bottom: 4px;
  text-align: left;
`;

export const BabyPeekV2HeaderTitleBottom = styled.div`
  color: var(--Grey-700, #2d3748);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 135%; /* 43.2px */
  text-align: left;
`;

export const BabyPeekV2Section = styled.div`
  br {
    display: block;
    margin: 16px 0;
    content: " ";
  }
`;

export const BabyPeekV2SectionTitle = styled.div`
  color: var(--Grey-700, #2d3748);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  margin-bottom: 8px;
`;

export const BabyPeekV2SectionText = styled.div`
  color: var(--Grey-700, #2d3748);
  font-feature-settings: "case" on;
  /* Text/Regular/Mobile */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  .italic {
    font-style: italic;
  }

  span {
    font-weight: 600;
  }
  ul {
    padding-top: 12px;
  }
`;

export const BabyPeekV2SectionTextLink = styled.div`
  color: blue;
  text-decoration: underline;
  display: inline;
`;
