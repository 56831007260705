import {
  PhiInsuranceStatus,
  UpdateUserEmailSerializer,
  UpdateUserPasswordSerializer,
} from "../../../recoil/types";
import { BASE_URL } from "../../../utils/requestUtils";

export const UpdateEmailRequest = async (
  newEmail: UpdateUserEmailSerializer,
  access_token?: string,
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/account/email`, {
    body: JSON.stringify(newEmail),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });

export const UpdatePasswordRequest = async (
  passwordInfo: UpdateUserPasswordSerializer,
  access_token?: string,
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/account/password`, {
    body: JSON.stringify(passwordInfo),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });

export const UpdateNotifications = async (
  is_marketing_subscribed: boolean,
  is_new_report_subscribed: boolean,
  access_token?: string,
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/account/notifications`, {
    body: JSON.stringify({
      is_marketing_subscribed,
      is_new_report_subscribed,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });

export const GetPhiRequest = async (access_token?: string): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/users/get_phi/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });

export const uploadProfileInsuranceImageForProcessing = async (
  file_data: string,
  token: string,
): Promise<{ fileName: string; valid: boolean }> => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/v1/insurance_info/upload_insurance_card/`,
      {
        method: "POST",
        body: JSON.stringify({ file_data }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (response?.ok) {
      const responseData = await response.json();
      return { fileName: responseData.file_name as string, valid: true };
    }
    if (response.status === 400) {
      const responseData = await response.json();
      return { fileName: responseData.error as string, valid: false };
    }
    return { fileName: "", valid: false };
  } catch (e) {
    return { fileName: "network error", valid: false };
  }
};

export const submitProfileInsuranceInfo = async (
  ins_provider: string,
  ins_group: string,
  ins_number: string,
  s3_urls: string[],
  subscriber_name: string,
  subscriber_date_of_birth: string,
  relationship_to_insured: "Self" | "Dependent" | "Other",
  ins_type: "Image" | "Manual",
  street_address_1: string,
  street_address_2: string,
  city: string,
  state: string,
  zip: string,
  access_token: string,
  ins_status: PhiInsuranceStatus,
  address_status: PhiInsuranceStatus,
  prebilling_status: PhiInsuranceStatus,
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/insurance_info/submit_insurance_info/`, {
    method: "POST",
    body: JSON.stringify({
      ins_provider,
      ins_group,
      ins_number,
      s3_urls,
      ins_type,
      subscriber_name,
      subscriber_date_of_birth,
      relationship_to_insured,
      street_address_1,
      street_address_2,
      city,
      state,
      zip,
      ins_status,
      address_status,
      prebilling_status,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });

interface GooglePlacesAddress {
  description: string;
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
  terms: {
    offset: number;
    value: string;
  }[];
}

export interface FormatedAddress {
  houseNumber: string;
  streetName: string;
  city: string;
  state: string;
  fullAddress: string;
  value: string;
  placeID: string;
  zip?: string;
}

interface GoogleAddressDetails {
  address_components: {
    long_name: string;
    short_name: string;
    types: string[];
  }[];
}

export const GetGooglePlacesAddress = async (
  input: string,
  token: string,
): Promise<FormatedAddress[]> => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/address_lookup/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ lookup_input: input }),
    });

    if (response?.ok) {
      const responseData = (await response.json())
        .predictions as GooglePlacesAddress[];

      return responseData.map((address) => ({
        fullAddress: address.description,
        houseNumber: address.terms[0].value,
        streetName: address.terms[1].value,
        city: address.terms[2].value,
        state: address.terms[3].value,
        value: `${address.terms[0].value} ${address.terms[1].value}`,
        placeID: address.place_id,
      }));
    }
    return [];
  } catch (e) {
    return [];
  }
};

export const GetGooglePlacesAddressDetails = async (
  formattedAddress: FormatedAddress,
  token: string,
): Promise<FormatedAddress | undefined> => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/address_detail_lookup/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ place_id: formattedAddress.placeID }),
    });

    if (response?.ok) {
      const responseData = (await response.json())
        .result as GoogleAddressDetails;
      const houseNumber =
        responseData.address_components.find((component) =>
          component.types.includes("street_number"),
        )?.long_name || formattedAddress.houseNumber;

      const streetName =
        responseData.address_components.find((component) =>
          component.types.includes("route"),
        )?.long_name || formattedAddress.streetName;
      const zip = responseData.address_components.find((component) =>
        component.types.includes("postal_code"),
      )?.long_name;

      const city =
        responseData.address_components.find(
          (component) =>
            component.types.includes("locality") ||
            component.types.includes("sublocality"),
        )?.long_name || formattedAddress.city;

      const state =
        responseData.address_components.find((component) =>
          component.types.includes("administrative_area_level_1"),
        )?.short_name || formattedAddress.state;

      return {
        ...formattedAddress,
        houseNumber,
        streetName,
        city,
        state,
        zip,
      };
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};

export const GetInsuranceList = async (token: string): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/insurance_pick_list`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

export const SubmitABNInfo = async (
  abn_confirmed: boolean,
  req_identifier: string,
  access_token?: string,
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/abn_form`, {
    body: JSON.stringify({ abn_confirmed, req_identifier }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });
