import React from "react";
import { GetTypographyContent } from "../shared/Typography/Typography";

export const FooterAccreditationInfoTop = (): React.ReactElement => (
  <>
    {GetTypographyContent({
      content: "ACCREDITATIONS:",
      size: "small",
    })}
    {GetTypographyContent({
      content: "CLIA: 05D2167800",
      size: "small",
      link: "https://billiontoone.com/wp-content/uploads/2024/06/Prenatal-CLIA-License-2024-2026-with-specialties.pdf",
      externalLink: true,
      textColor: "greenDark",
    })}
    {GetTypographyContent({
      content: "|",
      size: "small",
    })}
    {GetTypographyContent({
      content: "CA:",
      size: "small",
      link: "https://billiontoone.com/wp-content/uploads/2023/09/License-Certificate_CDF-00353741_20230515T203704.344-GMT.pdf",
      externalLink: true,
      textColor: "greenDark",
    })}
  </>
);
