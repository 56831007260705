import React from "react";
import { TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { StyledTextField } from "./TextField.styles";

interface TextFieldProps extends Omit<MuiTextFieldProps, "children" | "error"> {
  label: React.ReactNode;
  error?: string;
  width?: string;
}

export const TextField: React.FC<TextFieldProps> = ({
  value,
  onChange,
  variant,
  helperText,
  error,
  size,
  label,
  ...props
}): React.ReactElement => (
  <StyledTextField
    value={value}
    onChange={onChange}
    variant={variant || "outlined"}
    helperText={error || helperText}
    size={size}
    label={label}
    error={Boolean(error)}
    {...props}
  />
);
