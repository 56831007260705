import React from "react";

import { useWindowWidth } from "@react-hook/window-size";
import { faArrowUpFromBracket } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";

import {
  BabyPeekTraitWrapper,
  BabyPeekV2HeaderTitleWrapper,
  BabyPeekV2HeaderTitleTop,
  BabyPeekV2HeaderTitleBottom,
  BabyPeekV2HeaderIconGrid,
  BabyPeekV2SectionText,
  BabyPeekV2SectionTitle,
  BabyPeekV2Section,
  BabyPeekV2HeaderIconShare,
  BabyPeekV2HeaderIconShareTitle,
  BabyPeekV2HeaderTitleWrapperIcon,
} from "../Shared/BabyPeekTrait.styles";
import EyeColorBackground from "./EyeColorBackground.png";
import EyeIcon from "./EyeIcon.png";
import blueLineGif from "../../AnimatedGifs/blue-line.gif";
import greenLineGif from "../../AnimatedGifs/green-line.gif";
import brownLineGif from "../../AnimatedGifs/brown-line.gif";

import {
  BabyPeekTraitResponse,
  Trait,
  convertPercentage,
} from "../../Services/BabyPeekService";
import { useDeviceHook } from "../../../../utils/hooks/useDeviceHook";
import { TraitGradientMobile } from "../Shared/TraitGradientMobile";
import { TraitNavigationRight } from "../Shared/TraitNavigationRight";
import {
  BabyPeekMobileButtonSticky,
  BabyPeekTraitNavigationWrapper,
  BabyPeekTraitNavigationButtonWrapper,
  BabyPeekDesktopButtonSticky,
} from "../../BabyPeekStripe.styles";
import { BabyPeekButton } from "../../BabyPeekButton";
import { TraitMenu } from "../Shared/TraitMenu";
import { BabyPeekTraitAccordion } from "../Shared/BabyPeekTraitAccordion";
import { EyeColorGene } from "./EyeColorGene";
import { EyeColorMelanin } from "./EyeColorMelanin";
import { EyeColorOtherFactors } from "./EyeColorOtherFactors";
import { TraitNavigationLeft } from "../Shared/TraitNavigationLeft";
import { ShareUnderline } from "../../BabyPeekIcons/ShareUnderline";
import { BabyPeekSingleFeedback } from "../../BabyPeekSingleFeedback";

const HowItWorks = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>
      How do eyes get their color?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      The colored part of the eye is called the iris. The color of the iris is
      determined by a naturally occurring pigment called <span>melanin</span>.
      There are two types of melanin: eumelanin and pheomelanin.{" "}
      <span>Eumelanin</span> is a dark color while <span>pheomelanin</span> is
      reddish/yellow.
      <br />
      An iris that produces high levels of eumelanin looks brown. When eumelanin
      levels are low, the eyes are blue. Pheomelanin is important too, determing
      if eyes appear hazel or green!
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        {" "}
        <EyeColorMelanin />{" "}
      </div>
      <br />
    </BabyPeekV2SectionText>
    <br />
    <BabyPeekV2SectionTitle>
      How did we determine your baby’s eye color?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      We looked at 6 different genetic variations in multiple genes to determine
      how much eumelanin and pheomelanin are produced in the iris. Genetic
      changes in two genes called <span className="italic">HERC2</span> and{" "}
      <span className="italic">OCA2</span> are the most well studied and play
      the biggest role in determining your baby’s most likely eye color.
      <br />
      <BabyPeekV2SectionTitle>The genes we peeked at</BabyPeekV2SectionTitle>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {" "}
        <EyeColorGene />{" "}
      </div>
      <br />
    </BabyPeekV2SectionText>
  </BabyPeekV2Section>
);

const OtherFactors = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>
      What else can affect eye color?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      A person’s traits are often determined by a combination of genetic and
      environmental factors. While many of the genetic variations that influence
      these traits are well-studied, there are some genetic factors that are
      still unknown to scientists.
      <br />
      Other factors that can affect eye color include:
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}
      >
        {" "}
        <EyeColorOtherFactors />{" "}
      </div>
      <br />
    </BabyPeekV2SectionText>
    <br />
  </BabyPeekV2Section>
);

const AboutBabyPeek = (
  <BabyPeekV2SectionText>
    BabyPeek results are:
    <ul>
      <li>
        A <span style={{ fontWeight: 700 }}>predictor</span> of what traits your
        baby will have. These results do not mean that your baby will have these
        traits with absolute certainty and a baby may have different traits than
        what is predicted.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT medical test results.</span> These
        results should not be used to make medical decisions about your
        pregnancy. The genetic changes mentioned in these results do not affect
        a baby’s health.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT paternity testing.</span> These
        results should not be used to determine paternity. Given the complex
        genetics of these traits, a baby may be born with different traits from
        the biological mother or father.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>
          NOT managed by your healthcare provider.
        </span>{" "}
        These results are unlocked by the patient directly. Please do not
        contact your healthcare provider regarding your BabyPeek results.
      </li>
    </ul>
    If you have questions about BabyPeek results, please contact BillionToOne
    directly at
    <a href={`tel:${"(650) 460-2551"}`} target="_blank" rel="noreferrer">
      {` (650) 460-2551`}
    </a>
    .
    <br />
  </BabyPeekV2SectionText>
);

const EyeColorFAQ = (
  <BabyPeekV2Section id="eyeFAQ">
    <BabyPeekV2SectionTitle>
      My baby is predicted to most likely have a different eye color than me and
      my partner – is this possible?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      Yes!
      <br />
      There is no way to predict a baby’s eye color based on the parent’s eye
      colors alone. Our genes determine eye color, and the way these genes
      interact and affect eye color is complex.
      <br />
      The genetic variations that cause darker eye colors typically overshadow
      those that cause lighter colors (blue and green). So, if one or both
      parents have brown eyes, their child’s most likely eye color is brown –
      but blue and green eyes are possible. A child’s chances to have blue or
      green eyes increases significantly if both parents have blue or green eyes
      – but brown eyes still often occur in children of blue- or green-eyed
      parents.
      <br />
      This is because there is not just one simple genetic variation that
      affects eye color. BabyPeek looks at six different genetic variations
      associated with eye color. A parent with brown eyes may have one or two
      genetic variations that can increase the probability of blue or green eyes
      – sometimes the blue- and green-eyed variations are passed on to the baby
      rather than the variations associated with brown eyes. Similarly, parents
      with blue or green eyes may have one or two genetic variations that
      increase the probability of brown eyes – if the baby gets these brown-eyed
      variations from both parents, then the baby can end up with brown eyes!
      <br />
      The effect of these different genetic variations is almost like combining
      different paint colors to arrive at a final color – there are many
      different combinations and possibilities. When it comes to the genetics of
      eye color, there’s more than meets the eye!
    </BabyPeekV2SectionText>
    <br />
  </BabyPeekV2Section>
);

export const EyeColorTrait: React.FC<{
  setCurrentTrait?: (trait: Trait | undefined) => void;
  traitData: BabyPeekTraitResponse;
  setShareModal?: (share: boolean) => void;
  publicTrait?: boolean;
  hideTraitMenu: boolean;
  modalPreview?: boolean;
  showAnimation?: boolean;
}> = ({
  setCurrentTrait,
  traitData,
  setShareModal,
  hideTraitMenu,
  publicTrait,
  modalPreview = false,
  showAnimation = false,
}) => {
  const { deviceType } = useDeviceHook();
  const windowWidth = useWindowWidth();
  const [showTraitMenu, setShowTraitMenu] = React.useState(false);
  const StickyWrapper =
    deviceType !== "desktop"
      ? BabyPeekMobileButtonSticky
      : BabyPeekDesktopButtonSticky;

  const eyeTraits = [
    {
      color: "#7F4704",
      gradient: "#7F4704",
      name: "Brown",
      percentage: convertPercentage(traitData.brown_eyes_prob),
      gif: brownLineGif,
    },
    {
      color: "#82C2E6",
      gradient: "#82C2E6",
      name: "Blue",
      percentage: convertPercentage(traitData.blue_eyes_prob),
      gif: blueLineGif,
    },
    {
      color: "#319A00",
      gradient: "#A2B35B",
      name: "Green/Hazel",
      percentage: convertPercentage(traitData.inter_eyes_prob),
      textColor: "#000000",
      gif: greenLineGif,
    },
  ].sort((a, b) => b.percentage - a.percentage);

  const checkOverHundred = (eyeTraitArray: typeof eyeTraits) => {
    if (
      eyeTraitArray[0].percentage +
        eyeTraitArray[1].percentage +
        eyeTraitArray[2].percentage >
      100
    ) {
      const newEyeTraitArray = [...eyeTraitArray];
      newEyeTraitArray[2] = {
        ...eyeTraitArray[2],
        percentage: Math.max(
          100 - (eyeTraitArray[0].percentage + eyeTraitArray[1].percentage),
          0,
        ),
      };
      return newEyeTraitArray;
    }
    return eyeTraitArray;
  };

  return (
    <>
      {" "}
      <div
        style={{
          backgroundImage: `url(${EyeColorBackground})`,
          height: "260px",
          backgroundSize: "cover",
          padding: "20px",
          display: "grid",
          justifyContent: deviceType !== "mobile" ? "center" : "flex-start",
          paddingBottom: "32px",
          paddingTop: "24px",
          gridGap: "12px",
        }}
        className="trait-top-background"
      >
        <BabyPeekV2HeaderIconShare>
          <BabyPeekV2HeaderIconGrid>
            <img
              src={EyeIcon}
              alt="EyeIcon"
              style={{ justifySelf: "center" }}
            />
            <div>Eye Color</div>
          </BabyPeekV2HeaderIconGrid>
          {setShareModal && !modalPreview && (
            <BabyPeekV2HeaderIconShareTitle onClick={() => setShareModal(true)}>
              {" "}
              <FontAwesomeIcon icon={faArrowUpFromBracket} /> Share
              <ShareUnderline />
            </BabyPeekV2HeaderIconShareTitle>
          )}
        </BabyPeekV2HeaderIconShare>

        <BabyPeekV2HeaderTitleWrapper
          deviceType={deviceType}
          windowWidth={windowWidth}
        >
          <BabyPeekV2HeaderTitleWrapperIcon>
            <BabyPeekV2HeaderTitleTop>
              {publicTrait
                ? "The baby is most likely to have"
                : "Your baby is most likely to have"}
            </BabyPeekV2HeaderTitleTop>
            <FontAwesomeIcon
              icon={faCircleInfo}
              onClick={() =>
                document.getElementById("eyeFAQ")?.scrollIntoView()
              }
            />
          </BabyPeekV2HeaderTitleWrapperIcon>
          <BabyPeekV2HeaderTitleBottom>
            {`${eyeTraits[0].name.toLowerCase()} eyes`}
          </BabyPeekV2HeaderTitleBottom>
        </BabyPeekV2HeaderTitleWrapper>
      </div>
      <BabyPeekTraitWrapper deviceType={deviceType}>
        <TraitGradientMobile
          sortedTraitArray={checkOverHundred(eyeTraits)}
          showGif={modalPreview || showAnimation}
        />
        {!modalPreview && (
          <>
            {" "}
            <hr />
            <div
              style={{
                maxWidth: `${windowWidth - 2}px`,
                padding: "25px",
                paddingTop: "0px",
              }}
            >
              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "How it works",
                    content: HowItWorks,
                    key: "howItWorksEye",
                    defaultExpanded: true,
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "Other factors",
                    content: OtherFactors,
                    key: "otherFactorsEye",
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "About BabyPeek",
                    content: AboutBabyPeek,
                    key: "aboutBabyPeekEye",
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "FAQ",
                    content: EyeColorFAQ,
                    key: "eyeColorFAQ",
                  },
                ]}
              />
            </div>{" "}
          </>
        )}
        {!modalPreview && <BabyPeekSingleFeedback onlyFeedback />}
      </BabyPeekTraitWrapper>
      {setCurrentTrait && !modalPreview && (
        <StickyWrapper style={{ zIndex: 10 }}>
          {showTraitMenu && TraitMenu(setCurrentTrait, "EyeColor")}
          <BabyPeekTraitNavigationWrapper
            $width={windowWidth}
            deviceType={deviceType}
          >
            <BabyPeekTraitNavigationButtonWrapper
              onClick={() => setCurrentTrait("Cilantro")}
            >
              <TraitNavigationLeft />
            </BabyPeekTraitNavigationButtonWrapper>
            {!hideTraitMenu && (
              <BabyPeekButton
                variant="gray"
                content={<>{showTraitMenu ? " Hide Traits" : "View Traits"}</>}
                onClick={async () => {
                  setShowTraitMenu(!showTraitMenu);
                }}
              />
            )}
            <BabyPeekTraitNavigationButtonWrapper
              onClick={() => setCurrentTrait("Freckle")}
            >
              <TraitNavigationRight />
            </BabyPeekTraitNavigationButtonWrapper>
          </BabyPeekTraitNavigationWrapper>
        </StickyWrapper>
      )}
    </>
  );
};
