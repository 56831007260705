import { useRecoilValue } from "recoil";
import { useLocation, Redirect, Route } from "react-router-dom";
import { getUserAuth } from "../recoil/selectors";
import {
  VERIFY_EMAIL_SEND_AGAIN_URL,
  VERIFY_EMAIL_URL,
  LOGIN_PAGE_URL,
} from "../utils/urlConstants";

export const ProtectedRoute = ({
  component: Component,
  ...rest
}: {
  // We actually want to allow any here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
  [key: string]: unknown;
}) => {
  const { pathname } = useLocation();
  const auth = useRecoilValue(getUserAuth);

  // If the user is not authenticated, save the current pathname to redirect to after login
  // unless the user is going to the verify email pages
  if (
    !auth &&
    !pathname.startsWith(VERIFY_EMAIL_URL) &&
    !pathname.startsWith(VERIFY_EMAIL_SEND_AGAIN_URL)
  ) {
    sessionStorage.setItem(
      "current-pathname",
      `${pathname}${window.location.search}`,
    );
    return <Route {...rest} render={() => <Redirect to={LOGIN_PAGE_URL} />} />;
  }

  // If the user is authenticated but their email is not verified, redirect
  // them to the verify email page
  if (
    auth &&
    !auth.email_verified &&
    !pathname.startsWith(VERIFY_EMAIL_SEND_AGAIN_URL)
  ) {
    return (
      <Route {...rest} render={() => <Redirect to={VERIFY_EMAIL_URL} />} />
    );
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
