import styled from "styled-components/macro";
import { MenuItem, Select, Tooltip } from "@mui/material";
import { StyledTypography } from "../../components/shared/Typography/Typography.styles";
import { DeviceType } from "../../utils/hooks/useDeviceHook";
import { StyledButton } from "../../components/shared/Button/Button.styles";

export const LandingPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LandingPageRequisitionSelectWrapper = styled(Select)`
  border-radius: 10px !important;
  background: #fff;
  box-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.04);
  margin-top: 10px;
  width: 100%;

  .MuiInputBase-input {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: var(--Secondary-Grey-Medium, #5d5a5a);
  }
`;

export const LandingPageRequisitionSelectWrapperItem = styled(MenuItem)`
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  color: var(--Secondary-Grey-Medium, #5d5a5a) !important;

  &.Mui-selected {
    background-color: #eff8f5 !important;
  }
`;

export const LandingPageMultipleRequisitionTitleWrapper = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #929499;
  margin-left: 12px;
  margin-bottom: -10px;
  margin-top: 15px;
`;

const getWarningHeight = (deviceType: DeviceType) => {
  if (deviceType === "desktop") {
    return "96pxpx";
  }
  if (deviceType === "tablet") {
    return "110px";
  }
  return "250px";
};

export const LandingPageWarningWrapper = styled.div<{ deviceType: DeviceType }>`
  display: grid;
  grid-template-columns: auto auto 20%;
  align-items: center;
  justify-content: center;
  padding: 20px 24px;
  gap: 16px;
  width: 95%;
  height: ${({ deviceType }) => getWarningHeight(deviceType)};
  justify-self: center;
  margin-top: 15px;
  color: ${({ theme }) => theme.redDark};
  background: ${({ theme }) => theme.neutral100};
  border-radius: 24px;
`;

export const LandingPageBillIconGrid = styled.div`
  display: flex;
  grid-template-columns: 15px auto;
  align-items: center;
  grid-gap: 10px;
  justify-self: center;

  .info-icon {
    width: 30px;
    height: 30px;
    margin-right: 20px;

    path {
      fill: ${({ theme }) => theme.blueDark};
    }
  }
`;

export const LandingPageBillingMessageWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: ${({ deviceType }) => (deviceType === "desktop" ? "flex" : "grid")};
  grid-template-rows: ${({ deviceType }) =>
    deviceType !== "desktop" ? "auto auto" : undefined};
  justify-content: ${({ deviceType }) =>
    deviceType === "desktop" ? "space-between" : "center"};
  padding: 20px 24px;
  gap: 16px;
  width: 95%;
  align-items: center;
  justify-self: center;
  margin-top: 15px;
  color: ${({ theme }) => theme.blueDark};
  background: ${({ theme }) => theme.blueSubtle};
  border-radius: 24px;
  margin-bottom: 15px;
`;

export const LandingPagePatientSurveyWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: ${({ deviceType }) =>
    deviceType !== "desktop" ? "auto auto" : "auto auto auto"};
  padding: 24px 28px;
  gap: 16px;
  width: 95%;
  align-items: center;
  justify-self: center;
  color: ${({ theme }) => theme.blueDark};
  background: ${({ theme }) => theme.blueSubtle};
  border-radius: 24px;
  margin-bottom: 15px;

  .body {
    color: #5d5a5a;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width: 1100px;
  }
`;

export const LandingPageWarningMobileTextWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 15px;
`;

export const LandingPageWarningWrapperMobile = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: auto auto;
  align-items: center;
  justify-content: center;
  padding: 20px 24px;
  gap: 16px;
  width: 95%;
  height: ${({ deviceType }) => getWarningHeight(deviceType)};
  justify-self: center;
  color: ${({ theme }) => theme.redDark};
  background: ${({ theme }) => theme.neutral100};
  border-radius: 24px;

  svg {
    margin-top: 10px;
  }
`;

export const LandingPageWarningText = styled.div`
  font-size: 18px;
`;

export const LandingPageBillingTextWrapper = styled.div`
  color: ${({ theme }) => theme.blueDark};
`;

export const LandingPageBillingTextTop = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
`;

export const LandingPageBillingTextBottom = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  ${StyledButton} {
    width: 310px;
    height: 48px;
  }
`;

export const LandingPageBillingViewBill = styled.div`
  width: 310px;
  height: 48px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.redMedium};
  color: ${({ theme }) => theme.white};
  align-items: center;
  justify-content: center;
  font-weight: 700;
  display: grid;
  cursor: pointer;
  font-size: 18px;
  justify-self: center;
`;

export const LandingPageDesktopWrapperPadding = styled.div`
  margin: 40px 120px 40px 120px;

  ${LandingPageWarningWrapper} {
    margin-bottom: 25px;
  }
`;

export const LandingPageDesktopWrapper = styled.div`
  display: grid;
  grid-template-columns: 800px 330px;
  grid-column-gap: 24px;
`;

export const LandingPageTitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding-top: 0.85em;
`;

export const LandingPageDesktopLeftWrapper = styled.div<{
  requisitionCount: number;
  showBabyPeek: boolean;
  babyPeekAvailable: boolean;
}>`
  display: grid;
  grid-template-rows: ${({
    requisitionCount,
    showBabyPeek,
    babyPeekAvailable,
  }) =>
    requisitionCount
      ? `40px ${requisitionCount > 1 ? `auto auto` : `auto`}${
          showBabyPeek || babyPeekAvailable ? " auto" : ""
        } 490px auto;`
      : "490px auto"};
  grid-column-gap: 24px;
`;

export const LandingPageAccordionWrapper = styled.div`
  padding-top: 10px;
`;

export const LandingPageAccordionLinkWrapper = styled.div`
  padding: 15px 0px 16px 20px;
  border-top: 1px solid ${({ theme }) => theme.neutralBackground};
`;

export const LandingPageDesktopRightWrapper = styled.div`
  display: grid;
  grid-template-rows: 40px auto;
  grid-column-gap: 24px;
  display: inline-block;

  ${StyledTypography} {
    align-self: flex-end;
  }
`;

export const LandingGeneralContainer = styled.div<{ padding: string }>`
  margin-top: 20px;
  padding: ${({ padding }) => padding};
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${({ theme }) => theme.boxShadow};
  max-width: 802px;
  border-radius: 24px;
`;

export const LandingPageTabletMobileWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-columns: ${({ deviceType }) =>
    deviceType === "tablet" ? "568px" : "360px"};
  grid-column-gap: 24px;
  padding: ${({ deviceType }) =>
    deviceType === "tablet" ? "60px 18px 80px 18px" : "24px 0px 75px 0px"};
`;

export const LandingPageTabletMobileRowWrapper = styled.div<{
  deviceType: DeviceType;
  requisitionCount: number;
  showBabyPeek: boolean;
  babyPeekAvailable: boolean;
}>`
  display: ${({ deviceType }) => (deviceType === "mobile" ? "" : "grid")};
  grid-template-rows: ${({
    deviceType,
    requisitionCount,
    showBabyPeek,
    babyPeekAvailable,
  }) =>
    deviceType === "tablet"
      ? `40px repeat(${requisitionCount}, auto)${showBabyPeek ? " 500px" : ""}${
          babyPeekAvailable ? " 500px" : ""
        } 355px auto auto`
      : `40px repeat(${requisitionCount}, auto)${showBabyPeek ? " auto" : ""}${
          babyPeekAvailable ? " auto" : ""
        } 355px auto auto`};
  ${LandingPageTitleWrapper} {
    margin-top: -25px;
  }
`;

export const LandingFAQAnswerWrapper = styled.div`
  p {
    display: inline;
  }
`;

export const ProcessingToolTip = styled(Tooltip)`
  && {
    color: ${({ theme }) => theme.redMedium};
  }
`;

export const BabyPeekToolTip = styled(Tooltip)`
  && {
    color: #2d3748;
  }
  display: flex;
  justify-content: space-between;
  padding: 24px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  border-radius: 24px;
  .MuiTooltip-tooltip {
    background-color: white;
    box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);
    color: black;
  }
`;

export const BabyPeekAvailable = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--Grey-100, #edf2f7);
  background: var(--Primary-Beige, #fff6d2);
  /* Drop shadow */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  img {
    width: 90px;
  }
`;

export const LandingPretestQuestionWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  justify-content: center;
  align-items: center;

  ${LandingGeneralContainer} {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
  }
`;

export const LandingPretestModal = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  padding: 32px 32px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  outline: none;
  border-radius: 20px;
  background: var(--Basic-White, #fff);
  font-size: 20px;
  font-style: normal;
  line-height: 28px; /* 140% */
  text-align: center;
  span {
    font-weight: 700;
  }
  p {
    margin: 0px;
  }

  svg {
    justify-self: center;
  }
`;

export const LandingPretestConsentSpinner = styled.div`
  display: flex;
  justify-content: end;
  height: 18px;
  img {
    width: 40px !important;
    margin-top: -20px;
  }
`;

export const LandingPretestConsentTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  justify-self: start;
  color: #064682;
  margin-bottom: 6px;
`;

export const LandingPretestConsentText = styled.div`
  font-size: 18px;
  color: #064682;
`;

export const LandingConsentCheckboxGrid = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: min-content max-content;
  align-items: center;

  color: var(--Primary-Blue-Primary-Blue-Dark, #064682);
  font-size: 16px;
  font-style: normal;
  font-weight: 727;
  line-height: 24px;
  margin-left: -10px;
`;

export const LandingPretestConsentGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  color: var(--Primary-Blue-Primary-Blue-Dark, #064682);
  padding: 30px 30px;
  grid-gap: 5px;
  place-items: center;
  justify-content: center;
  align-items: center;
  background: #eef5fa;
  border-radius: 24px;
  margin-bottom: 12px;
  ${StyledButton} {
    max-width: 120px;
    height: 44px;
  }
  svg {
    width: 20px;
    align-self: flex-start;
  }
`;

export const LandingPretestConsentGridContent = styled.div`
  display: grid;
  grid-template-rows: auto auto auto auto;
`;

export const LandingTypeformWrapper = styled.div`
  height: 500px;
  width: 100%;
  .typeform-widget {
    height: 100%;
  }
`;
