import { ReactElement, useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { UpfrontPaymentMultiple } from "../components/UpfrontPayment/UpfrontPaymentMultiple";
import {
  HELP_PAGE_URL,
  EMAIL_REPORT_URL_PREFIX,
  LANDING_PAGE_URL,
  LOGIN_PAGE_URL,
  PROFILE_URL,
  REGISTER_URL,
  VERIFY_EMAIL_URL,
  VERIFY_EMAIL_SEND_AGAIN_URL,
  VERIFY_EMAIL_CONFIRMED_URL,
  RESET_PASSWORD_URL,
  RESET_PASSWORD_SENT_URL,
  ANEUPLOIDY_PAGE_URL,
  CARRIER_PAGE_URL,
  CONTACT_US_URL,
  SCHEDULING_URL,
  EXTERNAL_FORWARD_FETAL_SEX,
  PRETEST_REGISTER_URL,
  BILLING_URL,
  BABYPEEK_URL,
  TEST_INFO_URL,
  PUBLIC_BABYPEEK_URL,
  PUBLIC_BABYPEEK_TRAIL_URL,
  BABYPEEK_URL_FAQ,
  TERMS_OF_USE_URL,
  BABYPEEK_URL_FEEDBACK,
  PUBLIC_TRAIT_BABYPEEK_URL,
  BILLING_MULTIPLE_URL,
  VIDEO_PAGE_URL,
} from "../utils/urlConstants";
import Help from "../pages/Help";
import Landing from "../pages/Landing";
import CreateAccountPage from "../pages/CreateAccount";
import VerifyEmailPage from "../pages/VerifyEmail";
import VerifyEmailSendAgain from "../pages/VerifyEmailSendAgain";
import VerifyEmailConfirmedPage from "../pages/VerifyEmailConfirmed";
import LoginPage from "../pages/Login";
import TermsOfServicePage from "../pages/TermsOfService";
import ResetPasswordPage from "../pages/ResetPassword";
import ResetPasswordSentPage from "../pages/ResetPasswordSent";
import AneuploidyPage from "../pages/Aneuploidy";
import CarrierPage from "../pages/Carrier";
import ProfilePage from "../pages/Profile";
import ContactUsPage from "../pages/ContactUs";
import SchedulingPage from "../pages/Scheduling";
import ExternalForwardFetalSex from "../components/ExternalForwardFetalSex/ExternalForwardFetalSex";
import { Header } from "../components/Header/Header";
import { Footer } from "../components/Footer/Footer";
import { patientState } from "../recoil/atoms";
import CreateAccountPagePreTest from "../pages/CreateAccount/CreateAccountPreTest";
import { UpfrontPayment } from "../components/UpfrontPayment/Index";
import { BabyPeek } from "../components/BabyPeek/BabyPeek";
import { BabyPeekPublic } from "../components/BabyPeek/BabyPeekPublic";
import { ValidationTesting } from "../components/ValidationTesting/ValidationTesting";

import { BabyPeekPublicTrait } from "../components/BabyPeek/BabyPeekPublicTrait";
import { BabyPeekFAQ } from "../components/BabyPeek/BabyPeekFAQ";
import { BabyPeekSingleFeedback } from "../components/BabyPeek/BabyPeekSingleFeedback";
import { BabyPeekSingleTrait } from "../components/BabyPeek/BabyPeekSingleTrait";
import VideoPage from "../pages/Video/VideoPage";
import { ProtectedRoute } from "./ProtectedRoute";

const AppContainer = (): ReactElement => {
  const history = useHistory();
  const [pathname, setPathname] = useState(history.location.pathname);
  const [patientInfo] = useRecoilState(patientState);
  const emailPrefix = pathname.startsWith(EMAIL_REPORT_URL_PREFIX);
  const [showHeaderAndFooter, setShowHeaderAndFooter] = useState(false);

  const setPathnameCheck = () => {
    setPathname(history.location.pathname);
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
      setPathnameCheck();
    });
    return () => {
      unlisten();
    };
  }, []);

  useEffect(() => {
    setShowHeaderAndFooter(
      Boolean(
        pathname !== LOGIN_PAGE_URL &&
          pathname !== EXTERNAL_FORWARD_FETAL_SEX &&
          !pathname.startsWith(REGISTER_URL) &&
          !pathname.startsWith(VERIFY_EMAIL_URL) &&
          !emailPrefix &&
          patientInfo?.auth0,
      ),
    );
  }, [pathname, patientInfo]);

  return (
    <>
      {showHeaderAndFooter && <Header />}
      <Switch>
        <Route
          exact
          path={LOGIN_PAGE_URL}
          render={() => <LoginPage setPathname={setPathnameCheck} />}
        />
        <Route
          exact
          path={TERMS_OF_USE_URL}
          render={() => <TermsOfServicePage />}
        />
        <Route exact path={VERIFY_EMAIL_URL} component={VerifyEmailPage} />
        <Route
          exact
          path={`${REGISTER_URL}`}
          render={() => <CreateAccountPage setPathname={setPathnameCheck} />}
        />
        <Route
          exact
          path={`${PRETEST_REGISTER_URL}/:limsId?`}
          render={() => (
            <CreateAccountPagePreTest setPathname={setPathnameCheck} />
          )}
        />
        <Route
          exact
          path={`${REGISTER_URL}/:smsCode`}
          render={() => <CreateAccountPage setPathname={setPathnameCheck} />}
        />
        <Route exact path={VERIFY_EMAIL_URL} component={VerifyEmailPage} />
        <Route
          exact
          path={`${VERIFY_EMAIL_CONFIRMED_URL}/:verifyJwt`}
          component={VerifyEmailConfirmedPage}
        />
        <Route
          exact
          path={VERIFY_EMAIL_SEND_AGAIN_URL}
          component={VerifyEmailSendAgain}
        />
        <Route exact path={RESET_PASSWORD_URL} component={ResetPasswordPage} />
        <Route
          exact
          path={RESET_PASSWORD_SENT_URL}
          component={ResetPasswordSentPage}
        />
        <Route
          exact
          path={`${EXTERNAL_FORWARD_FETAL_SEX}/:base64PatientName/:base64FetalSex`}
          component={ExternalForwardFetalSex}
        />
        <Route exact path={HELP_PAGE_URL} component={Help} />
        <ProtectedRoute exact path={LANDING_PAGE_URL} component={Landing} />
        <ProtectedRoute exact path={PROFILE_URL} component={ProfilePage} />
        <ProtectedRoute exact path={CONTACT_US_URL} component={ContactUsPage} />
        <Route exact path={SCHEDULING_URL} component={SchedulingPage} />
        <ProtectedRoute exact path={VIDEO_PAGE_URL} component={VideoPage} />
        <ProtectedRoute
          exact
          path={`${ANEUPLOIDY_PAGE_URL}/:requisitionID`}
          component={AneuploidyPage}
        />
        <ProtectedRoute
          exact
          path={`${CARRIER_PAGE_URL}/:requisitionID`}
          component={CarrierPage}
        />
        <Route
          exact
          path={`${BILLING_URL}/:billingPK/:adminID?`}
          component={UpfrontPayment}
        />
        <ProtectedRoute
          exact
          path={BILLING_MULTIPLE_URL}
          component={UpfrontPaymentMultiple}
        />
        <Route
          exact
          path={`${BABYPEEK_URL}/:babypeekPK?/:pageIndex?`}
          component={BabyPeek}
        />

        <Route
          exact
          path={`${PUBLIC_BABYPEEK_URL}/:babypeekPK?/:pageIndex?`}
          component={BabyPeekPublic}
        />
        <Route
          exact
          path={`${PUBLIC_BABYPEEK_TRAIL_URL}/:traitPK?/`}
          component={BabyPeekPublicTrait}
        />
        <Route
          exact
          path={`${PUBLIC_TRAIT_BABYPEEK_URL}/:babypeekPK?/:trait`}
          component={BabyPeekSingleTrait}
        />
        <Route exact path={`${BABYPEEK_URL_FAQ}`} component={BabyPeekFAQ} />
        <Route
          exact
          path={`${BABYPEEK_URL_FEEDBACK}/:babypeekPK?`}
          component={BabyPeekSingleFeedback}
        />
        <Route
          exact
          path={`${TEST_INFO_URL}/:reqPK?`}
          component={ValidationTesting}
        />

        <Redirect from="*" to={LANDING_PAGE_URL} />
      </Switch>
      {showHeaderAndFooter && <Footer />}
    </>
  );
};
export default AppContainer;
