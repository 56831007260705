import { IFileWithMeta } from "react-dropzone-uploader";
import { BASE_URL } from "../../../utils/requestUtils";
import { PortalBillStatusType, PortalBillType } from "../types";
import { toBase64 } from "../../../utils/helpers";

export const showUpfrontPayment = (
  portal_bill_status: PortalBillStatusType,
): boolean =>
  // if the bill has any of these statuses it should not be shown to the patient
  Boolean(
    portal_bill_status !== "not_available" &&
      portal_bill_status !== "reviewable" &&
      portal_bill_status !== "external" &&
      portal_bill_status !== "external_billed_through_collectly" &&
      portal_bill_status !== "unusual_balance",
  );

export const getCentsToDollars = (
  cents?: number,
  hideCents?: boolean,
): string =>
  cents !== undefined
    ? (cents / 100).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: hideCents ? 0 : undefined,
        maximumFractionDigits: hideCents ? 0 : undefined,
      })
    : "";

const getUpfrontQueryParam = (queryRouteValue?: string) => {
  if (queryRouteValue === "q") {
    return "?utm_source=paper_statement";
  }
  if (queryRouteValue) {
    return "?is_billing_admin=true";
  }
  return "";
};

// does not need token
export const GetUpfrontPayment = async (
  upfront_pk: string,
  queryRouteValue?: string,
): Promise<Response> =>
  fetch(
    `${BASE_URL}/api/v1/u_payment/${upfront_pk}/${getUpfrontQueryParam(
      queryRouteValue,
    )}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

export const PostUpfrontPaymentSuccess = async (
  upfront_pk: string,
  client_secret: string,
  email: string,
  is_saved: boolean,
  queryRouteValue?: string,
  payment_method_id?: string,
): Promise<Response> =>
  fetch(
    `${BASE_URL}/api/v1/u_payment/${upfront_pk}/submit_one_time_payment/${getUpfrontQueryParam(
      queryRouteValue,
    )}`,
    {
      method: "POST",
      body: JSON.stringify({
        client_secret,
        email,
        is_saved,
        payment_method_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

export const PostOA100PaymentSuccess = async (
  upfront_pk: string,
  client_secret: string,
  email: string,
  payment_method_id?: string,
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/u_payment/${upfront_pk}/record_oa_100_payment/`, {
    method: "POST",
    body: JSON.stringify({
      client_secret,
      email,
      is_saved: false,
      payment_method_id,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

export const GetUpfrontPaymentReceipt = async (
  upfront_pk: string,
  email?: string,
  queryRouteValue?: string,
): Promise<Response> =>
  fetch(
    `${BASE_URL}/api/v1/u_payment/${upfront_pk}/receipt/${getUpfrontQueryParam(
      queryRouteValue,
    )}`,
    {
      method: "POST",
      body: JSON.stringify({
        email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

export const GetOA100Receipt = async (
  upfront_pk: string,
  email?: string,
  queryRouteValue?: string,
): Promise<Response> =>
  fetch(
    `${BASE_URL}/api/v1/u_payment/${upfront_pk}/oa_100_receipt/${getUpfrontQueryParam(
      queryRouteValue,
    )}`,
    {
      method: "POST",
      body: JSON.stringify({
        email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

export const GetUpfrontPaymentFinancialAssistance = async (
  upfront_pk: string,
  financial: UpfrontPaymenFinancialAssistance,
  financialStatementFile: IFileWithMeta | null,
  queryRouteValue?: string,
): Promise<Response> => {
  let requestBody = { ...financial };
  if (financialStatementFile) {
    const base64FinancialStatementFile = await toBase64(
      financialStatementFile.file,
    );
    requestBody = {
      ...financial,
      financial_statement_file: base64FinancialStatementFile,
    };
  }
  return fetch(
    `${BASE_URL}/api/v1/u_payment/${upfront_pk}/financial_assistance_application/${getUpfrontQueryParam(
      queryRouteValue,
    )}`,
    {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

export const GetFinancialAssistanceIncomeTiers = async (
  upfront_pk: string,
): Promise<Response> =>
  fetch(
    `${BASE_URL}/api/v1/u_payment/${upfront_pk}/financial_assistance_income_tiers/`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

export const GetFinancialAssistanceLocalities = async (
  upfront_pk: string,
): Promise<Response> =>
  fetch(
    `${BASE_URL}/api/v1/u_payment/${upfront_pk}/financial_assistance_localities/`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

export const GetUpfrontPaymentPaperStatementUrls = async (
  token: string,
  upfront_pk: string,
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/payment/${upfront_pk}/paper_statements/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

export const GetPreviewUpfrontPaymentPlan = async (
  upfront_pk: string,
  queryRouteValue?: string,
): Promise<Response> =>
  fetch(
    `${BASE_URL}/api/v1/u_payment/${upfront_pk}/preview_payment_plan/${getUpfrontQueryParam(
      queryRouteValue,
    )}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

export const GetSavedCardsUpfront = async (
  upfront_pk: string,
  access_token: string,
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/payment/${upfront_pk}/payment_methods/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });

export const PayWithSavedCardUpfront = async (
  upfront_pk: string,
  payment_method_id: string,
  email: string,
  payment_type: "pay_in_full" | "payment_plan",
  access_token: string,
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/payment/${upfront_pk}/submit_saved_payment/`, {
    method: "POST",
    body: JSON.stringify({ payment_method_id, email, payment_type }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });

export const RemoveUpfrontPayment = async (
  upfront_pk: string,
  payment_method_id?: string,
  access_token?: string,
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/payment/${upfront_pk}/remove_payment_method`, {
    method: "DELETE",
    body: JSON.stringify({ payment_method_id }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });

export const PostUpfrontPaymentPlanSuccess = async (
  upfront_pk: string,
  payment_method_id: string,
  email: string,
  is_saved: boolean,
  queryRouteValue?: string,
): Promise<Response> =>
  fetch(
    `${BASE_URL}/api/v1/u_payment/${upfront_pk}/submit_payment_plan/${getUpfrontQueryParam(
      queryRouteValue,
    )}`,
    {
      method: "POST",
      body: JSON.stringify({ payment_method_id, email, is_saved }),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

export const PostUpfrontPaymentMedicaidHMODismiss = async (
  upfront_pk: string,
  queryRouteValue?: string,
): Promise<Response> =>
  fetch(
    `${BASE_URL}/api/v1/u_payment/${upfront_pk}/dismiss_medicaid_or_hmo_prompt/${getUpfrontQueryParam(
      queryRouteValue,
    )}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

export const GetUpfrontPaymentPlan = async (
  upfront_pk: string,
  queryRouteValue?: string,
): Promise<Response> =>
  fetch(
    `${BASE_URL}/api/v1/u_payment/${upfront_pk}/get_payment_plan/${getUpfrontQueryParam(
      queryRouteValue,
    )}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

export const SubmitOA100Document = async (
  file_data: string,
  bill_pk: string,
): Promise<{ error: boolean }> => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/v1/u_payment/${bill_pk}/upload_oa100_document/`,
      {
        method: "POST",
        body: JSON.stringify({ file_data }),
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (response?.ok) {
      return { error: false };
    }
  } catch (e) {
    return { error: true };
  }
  return { error: true };
};

export const UploadSecondaryInsurance = async (
  file_data: string,
  bill_pk: string,
  insurance_type?: "primary" | "secondary" | "medicaid_or_hmo",
  queryRouteValue?: string,
): Promise<{ fileName: string; valid: boolean }> => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/v1/u_payment/${bill_pk}/upload_insurance_card/${getUpfrontQueryParam(
        queryRouteValue,
      )}`,
      {
        method: "POST",
        body: JSON.stringify({ file_data, insurance_type }),
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (response?.ok) {
      const responseData = await response.json();
      return { fileName: responseData.file_name as string, valid: true };
    }
    if (response.status === 400) {
      const responseData = await response.json();
      return { fileName: responseData.error as string, valid: false };
    }
    return { fileName: "", valid: false };
  } catch (e) {
    return { fileName: "network error", valid: false };
  }
};

export const SubmitNewUpfrontInsurance = async (
  s3_urls: string[],
  bill_pk: string,
  insurance_type: "primary" | "secondary" | "medicaid_or_hmo",
  queryRouteValue?: string,
): Promise<Response> =>
  fetch(
    `${BASE_URL}/api/v1/u_payment/${bill_pk}/submit_insurance_cards/${getUpfrontQueryParam(
      queryRouteValue,
    )}`,
    {
      method: "POST",
      body: JSON.stringify({ s3_urls, insurance_type }),
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

export interface UpfrontPaymentPlan {
  epoch: number;
  amount: number;
  status: "succeeded" | "failed" | "processing" | "pending" | "paid" | "draft";
  receipt_url: string;
  pay_now: boolean;
  card_brand: string;

  last_four_digits: string;
}

export interface UpfrontPaymentType {
  is_final: boolean;
  paid_amount: number;
  total_patient_responsibility_amount: number;
  oa100_balance: number;
  date_of_service: string;
  provider_name: string;
  is_qualified_for_financial_assistance: boolean;
  has_submitted_financial_assistance: boolean;
  login_url: string;
  signup_url: string;
  is_registered_for_portal: boolean;
  client_secret: string;
  oa_100_client_secret: string;
  oa_100_payment_intent: string;
  pk: string;
  req_identifier: string;
  is_payment_plan_enabled: boolean;
  portal_bill_status: PortalBillStatusType;
  oa_100_billing_status: PortalBillStatusType;
  bto_discount: number;
  total_insurance_allowed_amount: number;
  total_insurance_paid_amount: number;
  start_of_payment_amount?: number;
  insurance_name: string;
  is_cash_pay: boolean;
  show_medicaid_or_hmo_prompt: boolean;
  accept_insurance: boolean;
  babypeek_pk?: string;
  show_financial_assistance: boolean;
  insurance_adjustment_wording: string;
  bill_type: PortalBillType;
  due_date: string;
}

export interface PaperStatementUrls {
  paper_statements: {
    url: string;
    date: string;
  }[];
}

export interface UpfrontPaymentReceiptType {
  date_of_service: string;
  provider_name: string;
  amount_paid: number;
  payment_date: string;
  payment_intent_id: string;
  last_four_digits: string;
  card_brand: string;
  receipt_url: string;
}

interface UpfrontPaymenFinancialAssistance {
  has_medicaid: boolean;
  medicaid_insurance_card_urls: string[];
  household_size: number;
  income: number;
  extenuating_circumstances: string[];
  financial_statement_file?: string;
  locality: string | null;
}

export interface UpfrontPaymenFinancialAssistanceResponse {
  bill: UpfrontPaymentType;
  is_qualified: boolean;
}
