import styled from "styled-components/macro";
import { DeviceType } from "../../utils/hooks/useDeviceHook";

export const SchedulingAppointmentContainer = styled.div<{
  deviceType: DeviceType;
}>`
  overflow: scroll;
  height: 680px;

  iframe {
    max-height: ${({ deviceType }) =>
      deviceType === "desktop" ? "3700px" : "2800px"} !important;
  }
`;
