import styled, { DefaultTheme } from "styled-components/macro";
import { Grid } from "@mui/material";
import { DeviceType } from "../../utils/hooks/useDeviceHook";
import { StyledButton } from "../shared/Button/Button.styles";
import { StyledTextField } from "../shared/TextField/TextField.styles";
import { StyledTypography } from "../shared/Typography/Typography.styles";
import { TextField } from "../shared/TextField/TextField";
import {
  FieldContainer,
  FieldTitle,
} from "../shared/TextField/FormField.styles";

export const ProfileMainWrapper = styled.div`
  display: grid;
  grid-row-gap: 30px;
  padding-top: 12px;
  padding-bottom: 40px;
`;

export const ProfileInsuranceWrapper = styled.div`
  display: grid;
  grid-template-rows: 60px repeat(2, auto);
  grid-row-gap: 30px;
  padding-top: 12px;
  padding-bottom: 40px;
`;

export const ProfileFieldModalGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 255px));
  grid-column-gap: 15px;
  grid-row-gap: 30px;
`;

export const ProfileFieldGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(333px, 333px));
  grid-column-gap: 15px;
  grid-row-gap: 30px;
`;

export const ProfileFieldRowTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.neutral600};

  .edit-button {
    display: inline;
    text-transform: capitalize;
    justify-self: flex-end;
    margin-left: 15px;
  }
`;

export const ProfilePendingFailureInsuranceMessage = styled.div<{
  failure: boolean;
}>`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme, failure }) => (failure ? theme.redDark : theme.blueDark)};
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
    margin-top: -2px;
    min-width: 20px;
  }

  path {
    fill: ${({ theme, failure }) => (failure ? theme.redDark : theme.blueDark)};
  }
`;

export const ProfileNotifcationTitle = styled.div`
  color: var(--neutral-neutral-8, #000);
  /* Body */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const ProfileNotifcationDescription = styled.div`
  color: var(--neutral-neutral-5, #666);
  /* Mini */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
`;

export const ProfileNotifcationDescriptionRow = styled.div`
  display: grid;
  grid-template-rows: auto auto;
`;

export const ProfileNotifcationColumns = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  cursor: pointer;
  gap: 12px;
  align-items: center;
`;

export const ProfileFieldRowMissingAddress = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.redDark};
`;

export const ProfileFieldRow = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fill, 25px);
  grid-row-gap: 5px;
  margin-bottom: 12px;
  margin-bottom: 12px;

  .edit-button {
    cursor: pointer;
  }
`;

export const ProfileResetEmailWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: 20px 40px auto;
  grid-row-gap: 40px;
  padding: 20px
    ${({ deviceType }) => (deviceType === "mobile" ? "0px" : "20px")};
  background-color: ${({ theme }) => theme.white};
  place-items: center;

  ${StyledTypography} {
    place-self: flex-start;
    padding-left: 10px;
  }

  ${StyledTextField} {
    width: ${({ deviceType }) => getProfileResetTextFieldWidth(deviceType)};
  }
`;

const getProfileResetTextFieldWidth = (deviceType?: DeviceType) => {
  if (deviceType === "mobile") {
    return "330px";
  }
  if (deviceType === "tablet") {
    return "420px";
  }
  return "450px";
};

export const ProfileResetPasswordWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: 20px 40px auto auto;
  grid-row-gap: 40px;
  padding: 20px
    ${({ deviceType }) => (deviceType === "mobile" ? "0px" : "20px")};
  background-color: ${({ theme }) => theme.white};
  place-items: center;

  ${StyledTypography} {
    place-self: flex-start;
    padding-left: 10px;
  }

  ${StyledTextField} {
    width: ${({ deviceType }) => getProfileResetTextFieldWidth(deviceType)};
  }
`;

const getProfileResetButtonPadding = (deviceType?: DeviceType) => {
  if (deviceType === "mobile") {
    return "30px 10px";
  }
  if (deviceType === "tablet") {
    return "30px 10px";
  }
  return "40px 200px";
};

const getProfileResetButtonWidth = (deviceType?: DeviceType) => {
  if (deviceType === "mobile") {
    return "156px";
  }
  if (deviceType === "tablet") {
    return "202px";
  }
  return "212px";
};

export const ProfileResetButtonWrapper = styled.div<{ deviceType: DeviceType }>`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
  padding: ${({ deviceType }) => getProfileResetButtonPadding(deviceType)};
  border-top: 1px solid ${({ theme }) => theme.neutral100};
  ${StyledButton} {
    width: ${({ deviceType }) => getProfileResetButtonWidth(deviceType)};
    max-height: 48px;
  }
`;

const getDateColor = (
  theme: DefaultTheme,
  error?: string,
  noDateValue?: boolean,
) => {
  if (error) return theme.redDark;
  if (noDateValue) return theme.neutral500;
  return theme.black;
};

export const PatientInformationTextField = styled(TextField)<{
  $noDateValue?: boolean;
  $showHelpertext?: boolean;
}>`
  color: ${({ theme }) => theme.greyLight};
  width: 100%;
  font-family: light;

  .dateclass::-webkit-datetime-edit {
    color: ${({ theme, error, $noDateValue }) =>
      getDateColor(theme, error, $noDateValue)};
  }

  .dateclass {
    border: none;
    box-sizing: border-box;
    outline: 0;
    padding: 0.75rem 0px;
    position: relative;
    width: 100%;
  }

  .MuiFormHelperText-root {
    display: ${({ $showHelpertext }) => ($showHelpertext ? undefined : "none")};
    color: ${({ theme }) => theme.blueDark} !important;
  }

  .MuiFormHelperText-root.Mui-error {
    display: ${({ $showHelpertext }) => ($showHelpertext ? undefined : "none")};
    color: ${({ $showHelpertext, theme }) =>
      $showHelpertext ? theme.redDark : undefined} !important;
  }
`;

export const PatientInformationWrapper = styled.div`
  padding: 24px 0px 0px 10px;
`;

export const PatientInformationStateGrid = styled(Grid)`
  ${FieldContainer} {
    width: 100%;
  }
  .MuiInput-input {
    padding: 0px 4px 4px 0px !important;
  }
  .MuiAutocomplete-inputRoot {
    margin-top: -7px;
  }
  ${FieldTitle} {
    margin-top: -5px;
    margin-bottom: 5px;
    font-size: 14px !important;
  }
`;

export const PatientInformationStreetGrid = styled(Grid)<{
  $addPadding: boolean;
}>`
  ${FieldContainer} {
    width: 100%;
  }
  ${FieldTitle} {
    margin-top: -5px;
    margin-bottom: 5px;
    font-size: 14px;
  }

  .MuiAutocomplete-input {
    margin-top: -9px;
  }
  .MuiInput-input {
    padding: ${({ $addPadding }) =>
      $addPadding ? "0px 4px 4px 0px" : undefined} !important;
  }

  svg {
    display: none;
  }
`;

export const ProfileNotificationRow = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fill, auto);
  grid-row-gap: 5px;
  margin-bottom: 12px;
`;
