import * as React from "react";

export const GreenFullCheckIcon = (): React.ReactElement => (
  <svg
    width="35"
    height="32"
    viewBox="0 0 35 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 32C11.75 32 6.5 29 3.625 24C0.75 19.0625 0.75 13 3.625 8C6.5 3.0625 11.75 0 17.5 0C23.1875 0 28.4375 3.0625 31.3125 8C34.1875 13 34.1875 19.0625 31.3125 24C28.4375 29 23.1875 32 17.5 32ZM24.5625 13.0625H24.5C25.125 12.5 25.125 11.5625 24.5 10.9375C23.9375 10.375 23 10.375 22.4375 10.9375L15.5 17.9375L12.5625 15C11.9375 14.375 11 14.375 10.4375 15C9.8125 15.5625 9.8125 16.5 10.4375 17.0625L14.4375 21.0625C15 21.6875 15.9375 21.6875 16.5625 21.0625L24.5625 13.0625Z"
      fill="#008777"
    />
  </svg>
);
