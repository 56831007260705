import React, { useEffect, useState } from "react";
import { Modal, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import { PatientReviewStarRatingOptions } from "./types";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { GetTypographyContent } from "../shared/Typography/Typography";
import { DetailedResultsPatientReviewModalWrapper } from "./DetailedResults.styles";
import PatientReviewStar from "./DetailedResultsIcons/PatientReviewStar";
import { StyledButton } from "../shared/Button/Button.styles";
import { getUserAuth } from "../../recoil/selectors";
import {
  CreatePatientReviewRequest,
  UpdatePatientReviewRequest,
} from "./Services/DetailedResultsService";
import { LANDING_PAGE_URL } from "../../utils/urlConstants";
import { SendActionType } from "../shared/Services/SharedServices";
import {
  PatientReview1ModalSeen,
  PatientReview2StarsSelected,
  PatientReview3Submitted,
  PatientReview4RedirectedToGoogleReview,
  UiActionType,
} from "../../recoil/types";

export const DetailedResultsPatientReviewModal: React.FC<{
  requisitionID: string;
  showPatientReviewModal: boolean;
  setShowPatientReviewModal: (show: boolean) => void;
}> = ({ requisitionID, showPatientReviewModal, setShowPatientReviewModal }) => {
  const { deviceType } = useDeviceHook();
  const auth = useRecoilValue(getUserAuth);
  const [numStars, setNumStars] = useState(0);
  const [hoverNumStars, setHoverNumStars] = useState(0);
  const [patientReviewId, setPatientReviewId] = useState("");
  const [requestPatientReview, setRequestPatientReview] = useState(true);
  const [patientFeedbackComments, setPatientFeedbackComments] = useState("");
  const history = useHistory();

  const sendUserAction = async (patientReviewActionType: UiActionType) => {
    await SendActionType(
      patientReviewActionType,
      requisitionID,
      auth?.access_token,
    );
  };

  useEffect(() => {
    sendUserAction(PatientReview1ModalSeen);
  }, []);

  const onClickLeaveGoogleReview = () => {
    sendUserAction(PatientReview4RedirectedToGoogleReview);
    window.open(
      `https://g.page/r/CZNFy7AxKO-1EB0/review`, // <--- BillionToOne Google Review
      "_blank",
    );
    setShowPatientReviewModal(false);
  };

  const crupdatePatientReview = async (
    numStarsSelected: PatientReviewStarRatingOptions,
    feedbackComments = "",
  ) => {
    setNumStars(numStarsSelected);

    let response: Response;
    if (patientReviewId) {
      response = await UpdatePatientReviewRequest(
        patientReviewId,
        {
          num_stars: numStarsSelected,
          ...(feedbackComments && {
            patient_feedback_comments: feedbackComments,
          }),
        },
        auth?.access_token,
      );
    } else {
      response = await CreatePatientReviewRequest(
        {
          num_stars: numStarsSelected,
          ...(patientFeedbackComments && {
            patient_feedback_comments: patientFeedbackComments,
          }),
        },
        auth?.access_token,
      );
    }
    if (response?.ok) {
      const patientReview = await response.json();
      setPatientReviewId(patientReview.id);
    } else {
      history.push(LANDING_PAGE_URL);
    }
  };

  return (
    <>
      <Modal
        open={showPatientReviewModal}
        onClose={() => setShowPatientReviewModal(false)}
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <DetailedResultsPatientReviewModalWrapper deviceType={deviceType}>
          {requestPatientReview ? (
            <>
              <div style={{ display: "flex" }}>
                <div>
                  <div style={{ paddingBottom: "0.5rem" }}>
                    {GetTypographyContent({
                      content: "How would you rate your experience?",
                      size: "h3",
                      textColor: "greyMedium",
                      isBold: true,
                    })}
                  </div>
                  {GetTypographyContent({
                    content:
                      "Please rate your overall satisfaction with our service.",
                    size: "large",
                    textColor: "greyMedium",
                  })}
                </div>
                <CloseIcon
                  onClick={() => setShowPatientReviewModal(false)}
                  htmlColor="#DADADA"
                  style={{
                    alignSelf: "flex-start",
                    cursor: "pointer",
                  }}
                />
              </div>
              <div style={{ display: "inline-block", whiteSpace: "nowrap" }}>
                {[...Array(5)].map((_, index) => {
                  const currentStarNumber = (index +
                    1) as PatientReviewStarRatingOptions;
                  return (
                    <PatientReviewStar
                      currentColor={
                        numStars >= currentStarNumber ||
                        hoverNumStars >= currentStarNumber
                          ? "#F3B213"
                          : "#DADADA"
                      }
                      onClick={() => {
                        crupdatePatientReview(currentStarNumber);
                        sendUserAction(PatientReview2StarsSelected);
                      }}
                      onMouseEnter={() => setHoverNumStars(currentStarNumber)}
                      onMouseLeave={() => setHoverNumStars(numStars)}
                    />
                  );
                })}
              </div>
              <TextField
                multiline
                rows={4}
                placeholder="Type your suggestions here..."
                value={patientFeedbackComments}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPatientFeedbackComments(event.target.value)
                }
                // Disables the label
                // https://stackoverflow.com/a/73278187
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
              />
              <StyledButton
                size="small"
                $backgroundColor={numStars > 0 ? "redMedium" : "greyLight"}
                $textColor="white"
                variant="outlined"
                disabled={numStars === 0}
                onClick={() => {
                  crupdatePatientReview(
                    numStars as PatientReviewStarRatingOptions,
                    patientFeedbackComments,
                  );
                  sendUserAction(PatientReview3Submitted);
                  setRequestPatientReview(false);
                }}
              >
                SUBMIT
              </StyledButton>
            </>
          ) : (
            <>
              <div style={{ display: "flex" }}>
                <div>
                  <div style={{ paddingBottom: "0.5rem" }}>
                    {GetTypographyContent({
                      content: "Thank you for your feedback!",
                      size: "h3",
                      textColor: "greyMedium",
                      isBold: true,
                    })}
                  </div>
                  {GetTypographyContent({
                    content:
                      "If you could take a moment to leave a Google review, it would be immensely appreciated. Your shared experiences are a valuable guide for future moms.",
                    size: "large",
                    textColor: "greyMedium",
                  })}
                </div>
                <CloseIcon
                  onClick={() => setShowPatientReviewModal(false)}
                  htmlColor="#DADADA"
                  style={{
                    alignSelf: "flex-start",
                    cursor: "pointer",
                  }}
                />
              </div>
              <StyledButton
                size="small"
                $backgroundColor="redMedium"
                $textColor="white"
                variant="outlined"
                onClick={onClickLeaveGoogleReview}
              >
                CONTINUE
              </StyledButton>
            </>
          )}
        </DetailedResultsPatientReviewModalWrapper>
      </Modal>
    </>
  );
};
