import * as React from "react";

export const StarIconEmpty = (): React.ReactElement => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.83594 8.01172C8.62109 8.44141 8.23438 8.69922 7.80469 8.78516L1.91797 9.64453L6.17188 13.8984C6.51562 14.1992 6.64453 14.6719 6.55859 15.1016L5.57031 21.1172L10.8125 18.2812C11.2422 18.0664 11.7148 18.0664 12.1445 18.2812L17.3867 21.1172L16.3984 15.1016C16.3125 14.6719 16.4414 14.1992 16.7852 13.8984L21.0391 9.64453L15.1523 8.78516C14.6797 8.69922 14.293 8.44141 14.1211 8.01172L11.457 2.59766L8.83594 8.01172ZM17.3438 22.6641H17.3867L11.5 19.4844L5.61328 22.6641C5.26953 22.8359 4.83984 22.793 4.49609 22.5781C4.19531 22.3203 4.02344 21.9336 4.10938 21.5469L5.22656 14.8867L0.457031 10.1602C0.15625 9.85938 0.0703125 9.47266 0.199219 9.08594C0.328125 8.74219 0.628906 8.44141 1.01562 8.39844L7.58984 7.41016L10.5547 1.35156C10.7266 1.00781 11.0703 0.75 11.457 0.75C11.8867 0.75 12.2305 1.00781 12.4023 1.35156L15.3672 7.41016L21.9414 8.39844C22.3281 8.44141 22.6289 8.74219 22.7578 9.08594C22.8867 9.47266 22.8008 9.85938 22.5 10.1602L17.7305 14.8867L18.8477 21.5469C18.9336 21.9336 18.7617 22.3203 18.4609 22.5781C18.1172 22.793 17.7305 22.8359 17.3438 22.6641Z"
      fill="#718096"
    />
  </svg>
);
