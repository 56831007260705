import React from "react";

import { useWindowWidth } from "@react-hook/window-size";
import { faArrowUpFromBracket } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BabyPeekTraitWrapper,
  BabyPeekV2HeaderTitleWrapper,
  BabyPeekV2HeaderTitleTop,
  BabyPeekV2HeaderTitleBottom,
  BabyPeekV2HeaderIconGrid,
  BabyPeekV2SectionText,
  BabyPeekV2SectionTitle,
  BabyPeekV2Section,
  BabyPeekV2HeaderIconShare,
  BabyPeekV2HeaderIconShareTitle,
} from "../Shared/BabyPeekTrait.styles";
import SweetSaltyBackground from "./SweetSaltyBackground.png";
import SaltySweetIcon from "./SaltySweetIcon.png";

import {
  BabyPeekTraitResponse,
  Trait,
  convertPercentage,
} from "../../Services/BabyPeekService";
import { useDeviceHook } from "../../../../utils/hooks/useDeviceHook";
import { TraitGradientMobile } from "../Shared/TraitGradientMobile";
import { TraitNavigationLeft } from "../Shared/TraitNavigationLeft";

import {
  BabyPeekMobileButtonSticky,
  BabyPeekTraitNavigationWrapper,
  BabyPeekTraitNavigationButtonWrapper,
  BabyPeekDesktopButtonSticky,
} from "../../BabyPeekStripe.styles";
import { BabyPeekButton } from "../../BabyPeekButton";
import { TraitMenu } from "../Shared/TraitMenu";
import { BabyPeekTraitAccordion } from "../Shared/BabyPeekTraitAccordion";
import { SaltySweetGene } from "./SaltySweetGene";
import { SaltySweetHowItWorks } from "./SaltySweetHowItworks";
import { SaltySweetOtherFactors } from "./SaltySweetOtherFactors";
import { TraitNavigationRight } from "../Shared/TraitNavigationRight";
import { ShareUnderline } from "../../BabyPeekIcons/ShareUnderline";
import sweetLineGif from "../../AnimatedGifs/line-sweet.gif";
import saltyLineGif from "../../AnimatedGifs/line-salty.gif";
import { BabyPeekSingleFeedback } from "../../BabyPeekSingleFeedback";

const HowItWorks = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>How do we taste?</BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      Human taste receptors can sense 5 flavor groups: sweet, bitter, salty,
      sour, and savory.
      <br />
      Our genes determine which taste receptors we have, but also help determine
      how our brains react to different flavors.
      <br />
      {`How we react to food is influenced by our `}{" "}
      <span>{`brain's "reward system." `}</span>{" "}
      {`Things
      that activate the reward system feel good! If a person's reward system is
      activated by sweet flavors, they'll likely opt for sweet snacks over
      salty. If activated by salty flavors, salty snacks are usually a favorite. `}
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        {" "}
        <SaltySweetHowItWorks />{" "}
      </div>
      <br />
    </BabyPeekV2SectionText>
    <br />
    <BabyPeekV2SectionTitle>
      {`How did we determine your baby's likelihood to prefer sweet vs. salty flavors? `}
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      We looked at one genetic change in a gene called{" "}
      <span className="italic">FGF21</span>. This gene helps produce a hormone
      that has many different jobs, including how our brain responds to sugar.
      While this is an area of active research, it may be that this genetic
      change in FGF21 affects how much of this hormone is produced when we eat
      sugar, affecting how likely we are to prefer a sweet snack vs. a salty
      snack.
      <br />
      <BabyPeekV2SectionTitle>The gene we peeked at</BabyPeekV2SectionTitle>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {" "}
        <SaltySweetGene />{" "}
      </div>
      <br />
    </BabyPeekV2SectionText>
  </BabyPeekV2Section>
);

const OtherFactors = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>
      What else can affect our taste preferences?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      A person’s traits are often determined by a combination of genetic and
      environmental factors. While many of the genetic variations that influence
      these traits are well-studied, there are some genetic factors that are
      still unknown to scientists.
      <br />
      Other factors that can affect our perception of these flavors include:
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}
      >
        {" "}
        <SaltySweetOtherFactors />{" "}
      </div>
      <br />
    </BabyPeekV2SectionText>
    <br />
  </BabyPeekV2Section>
);

const AboutBabyPeek = (
  <BabyPeekV2SectionText>
    BabyPeek results are:
    <ul>
      <li>
        A <span style={{ fontWeight: 700 }}>predictor</span> of what traits your
        baby will have. These results do not mean that your baby will have these
        traits with absolute certainty and a baby may have different traits than
        what is predicted.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT medical test results.</span> These
        results should not be used to make medical decisions about your
        pregnancy. The genetic changes mentioned in these results do not affect
        a baby’s health.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT paternity testing.</span> These
        results should not be used to determine paternity. Given the complex
        genetics of these traits, a baby may be born with different traits from
        the biological mother or father.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>
          NOT managed by your healthcare provider.
        </span>{" "}
        These results are unlocked by the patient directly. Please do not
        contact your healthcare provider regarding your BabyPeek results.
      </li>
    </ul>
    If you have questions about BabyPeek results, please contact BillionToOne
    directly at
    <a href={`tel:${"(650) 460-2551"}`} target="_blank" rel="noreferrer">
      {` (650) 460-2551`}
    </a>
    .
    <br />
  </BabyPeekV2SectionText>
);

export const SweetSaltyTrait: React.FC<{
  setCurrentTrait?: (trait: Trait | undefined) => void;
  traitData: BabyPeekTraitResponse;
  hideTraitMenu: boolean;
  publicTrait?: boolean;
  setShareModal?: (share: boolean) => void;
  modalPreview?: boolean;
  showAnimation?: boolean;
}> = ({
  setCurrentTrait,
  traitData,
  setShareModal,
  hideTraitMenu,
  publicTrait,
  modalPreview = false,
  showAnimation = false,
}) => {
  const { deviceType } = useDeviceHook();
  const windowWidth = useWindowWidth();
  const [showTraitMenu, setShowTraitMenu] = React.useState(false);
  const StickyWrapper =
    deviceType !== "desktop"
      ? BabyPeekMobileButtonSticky
      : BabyPeekDesktopButtonSticky;
  const sweetSaltyTraits = [
    {
      color: "#FF7676",
      gradient: "#FF7676",
      name: "Prefer Sweet",
      percentage: convertPercentage(traitData.salty_sweet_prob),
      textColor: "#000000",
      gif: sweetLineGif,
    },
    {
      color: "#FF9D00",
      gradient: "#FF9D00",
      name: "Prefer Salty",
      percentage: 100 - convertPercentage(traitData.salty_sweet_prob),
      textColor: "#000000",
      gif: saltyLineGif,
    },
  ].sort((a, b) => b.percentage - a.percentage);

  return (
    <>
      {" "}
      <div
        style={{
          backgroundImage: `url(${SweetSaltyBackground})`,
          height: "315px",
          backgroundSize: "cover",
          padding: "20px",
          display: "grid",
          justifyContent: deviceType !== "mobile" ? "center" : "flex-start",
          paddingBottom: "32px",
          paddingTop: "24px",
          gridGap: "12px",
        }}
        className="trait-top-background"
      >
        <BabyPeekV2HeaderIconShare>
          <BabyPeekV2HeaderIconGrid>
            <img
              src={SaltySweetIcon}
              alt="SaltySweetIcon"
              style={{ justifySelf: "center" }}
            />
            <div>Sweet vs. Salty</div>
          </BabyPeekV2HeaderIconGrid>
          {setShareModal && !modalPreview && (
            <BabyPeekV2HeaderIconShareTitle onClick={() => setShareModal(true)}>
              {" "}
              <FontAwesomeIcon icon={faArrowUpFromBracket} /> Share
              <ShareUnderline />
            </BabyPeekV2HeaderIconShareTitle>
          )}
        </BabyPeekV2HeaderIconShare>

        <BabyPeekV2HeaderTitleWrapper
          deviceType={deviceType}
          windowWidth={windowWidth}
        >
          <BabyPeekV2HeaderTitleTop>
            {publicTrait
              ? "The baby is likely to prefer"
              : "Your baby is likely to prefer"}
          </BabyPeekV2HeaderTitleTop>
          <BabyPeekV2HeaderTitleBottom>
            {` ${sweetSaltyTraits[0].name
              .split(" ")[1]
              .toLowerCase()} over ${sweetSaltyTraits[1].name
              .split(" ")[1]
              .toLowerCase()} snacks`}
          </BabyPeekV2HeaderTitleBottom>
        </BabyPeekV2HeaderTitleWrapper>
      </div>
      <BabyPeekTraitWrapper deviceType={deviceType}>
        <TraitGradientMobile
          showGif={modalPreview || showAnimation}
          sortedTraitArray={sweetSaltyTraits}
        />
        {!modalPreview && (
          <>
            {" "}
            <hr />
            <div
              style={{
                maxWidth: `${windowWidth - 2}px`,
                padding: "25px",
                paddingTop: "0px",
              }}
            >
              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "How it works",
                    content: HowItWorks,
                    key: "howItWorksEye",
                    defaultExpanded: true,
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "Other factors",
                    content: OtherFactors,
                    key: "otherFactorsEye",
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "About BabyPeek",
                    content: AboutBabyPeek,
                    key: "aboutBabyPeekEye",
                  },
                ]}
              />
            </div>{" "}
          </>
        )}
        {!modalPreview && <BabyPeekSingleFeedback onlyFeedback />}
      </BabyPeekTraitWrapper>
      {setCurrentTrait && !modalPreview && (
        <StickyWrapper style={{ zIndex: 10 }}>
          {showTraitMenu && TraitMenu(setCurrentTrait, "SweetSalty")}
          <BabyPeekTraitNavigationWrapper
            $width={windowWidth}
            deviceType={deviceType}
          >
            <BabyPeekTraitNavigationButtonWrapper
              onClick={() => setCurrentTrait("Sweet")}
            >
              <TraitNavigationLeft />
            </BabyPeekTraitNavigationButtonWrapper>{" "}
            {!hideTraitMenu && (
              <BabyPeekButton
                variant="gray"
                content={<>{showTraitMenu ? " Hide Traits" : "View Traits"}</>}
                onClick={async () => {
                  setShowTraitMenu(!showTraitMenu);
                }}
              />
            )}
            <BabyPeekTraitNavigationButtonWrapper
              onClick={() => setCurrentTrait("BabyTeeth")}
            >
              <TraitNavigationRight />
            </BabyPeekTraitNavigationButtonWrapper>{" "}
          </BabyPeekTraitNavigationWrapper>
        </StickyWrapper>
      )}
    </>
  );
};
