import { ContactUsEmailSerializer } from "../../../recoil/types";
import { BASE_URL } from "../../../utils/requestUtils";

const ContactUsRequest = async (
  contactUsInfo: ContactUsEmailSerializer,
  access_token?: string,
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/contact_us`, {
    body: JSON.stringify(contactUsInfo),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });

export default ContactUsRequest;
