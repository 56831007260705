import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import FAQTitle from "../../components/FAQ/FAQSectionTitle";
import { Accordions } from "../../components/shared/Accordion/Accordions";
import { Card } from "../../components/shared/Cards/Card";
import VideoEmbed from "../../components/shared/Video/VideoEmbed";
import {
  HelpPageComponentGrid,
  HelpPageComponentWrapper,
  HelpPageWrapper,
  HelpGeneralContainer,
  HelpPageResources,
} from "./Help.styles";
import { Resources } from "../../components/Resources/Resources";
import { GetTypographyContent } from "../../components/shared/Typography/Typography";
import { GetInTouch } from "./GetInTouch";
import { helpAccordions, helpLargeCard } from "./HelpData";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { aneuploidyHelpAccordions } from "./AneuploidyHelpData";
import { carrierHelpAccordions } from "./CarrierHelpData";
import { billingHelpAccordion } from "./BillingHelpData";
import BillingVideoEmbed from "../../components/shared/Video/BillingVideoEmbed";
import { UpfrontPaymentHelpWrapper } from "../../components/UpfrontPayment/UpfrontPayment.styles";
import { VideoFeedBackTitleText } from "../../components/shared/Video/VideoEmbed.styles";

import { getFirstRequisitionID } from "../../recoil/selectors";

const Help = (): React.ReactElement => {
  const { hash } = useLocation();
  useEffect(() => {
    const anchor = hash.slice(1);
    if (anchor) {
      const anchorEl = document.getElementById(anchor);
      if (anchorEl) {
        anchorEl.scrollIntoView();
        anchorEl.click();
      }
    }
  }, []);

  const { deviceType } = useDeviceHook();
  const history = useHistory();
  const reqID = useRecoilValue(getFirstRequisitionID) ?? "";

  return (
    <HelpPageWrapper data-testid="HelpPageWrapper">
      <HelpPageComponentWrapper deviceType={deviceType}>
        <HelpPageComponentGrid deviceType={deviceType}>
          {GetTypographyContent({
            content: "Help & FAQs",
            size: "h2",
            padding: `${deviceType === "mobile" ? "24px 0 0 20px" : ""}`,
            isBold: true,
          })}
          <HelpGeneralContainer padding="16px">
            <VideoEmbed overlayText="Want to know more about UNITY Screen™?" />
          </HelpGeneralContainer>
          <HelpGeneralContainer padding="0px" paddingBottom="10px">
            <FAQTitle title="Aneuploidy Screen FAQ:" />
            <Accordions
              data={aneuploidyHelpAccordions(reqID)}
              isLinked={false}
              size="large"
            />
          </HelpGeneralContainer>
          <HelpGeneralContainer padding="0px" paddingBottom="10px">
            <FAQTitle title="Carrier Screen FAQ:" />
            <Accordions
              data={carrierHelpAccordions(reqID)}
              isLinked={false}
              size="large"
            />
          </HelpGeneralContainer>
          <HelpGeneralContainer padding="0px" paddingBottom="10px">
            <FAQTitle title="Billing FAQ:" />
            <UpfrontPaymentHelpWrapper>
              <VideoFeedBackTitleText style={{ paddingLeft: "16px" }}>
                Billing and Insurance Process Overview
              </VideoFeedBackTitleText>

              <BillingVideoEmbed
                reqID={reqID}
                videoLocation="billing-faq-section"
              />
            </UpfrontPaymentHelpWrapper>
            <Accordions
              data={billingHelpAccordion}
              isLinked={false}
              size="large"
            />
          </HelpGeneralContainer>
          <HelpGeneralContainer padding="0px" paddingBottom="10px">
            <FAQTitle />
            <Accordions data={helpAccordions} isLinked={false} size="large" />
          </HelpGeneralContainer>
          {deviceType === "mobile" && (
            <HelpGeneralContainer padding="16px">
              <Card
                {...helpLargeCard}
                buttons={[
                  {
                    buttonAction: () => history.push("./scheduling"),
                    buttonText: "Book a Consultation",
                  },
                ]}
              />
            </HelpGeneralContainer>
          )}
          {deviceType !== "mobile" && (
            <HelpGeneralContainer padding="0px">
              <Card
                {...helpLargeCard}
                buttons={[
                  {
                    buttonAction: () => history.push("./scheduling"),
                    buttonText: "Book a Consultation",
                  },
                ]}
              />
            </HelpGeneralContainer>
          )}
          <HelpPageResources deviceType={deviceType}>
            <HelpGeneralContainer padding="24px 20px 30px 16px">
              <Resources />
            </HelpGeneralContainer>
            <HelpGeneralContainer padding="16px">
              <GetInTouch />
            </HelpGeneralContainer>
          </HelpPageResources>
        </HelpPageComponentGrid>
      </HelpPageComponentWrapper>
    </HelpPageWrapper>
  );
};

export default Help;
