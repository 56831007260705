import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import "./index.css";
import { RecoilRoot } from "recoil";
import { datadogRum } from "@datadog/browser-rum";
import { App } from "./App";
import { materialTheme, themePalette } from "./theme";

datadogRum.init({
  applicationId: "3591831c-700f-4f1f-9ce9-39e30c23fa5d",
  clientToken: "pub8f0f7e778f2e51017cdbfae8930f5f83",
  site: "datadoghq.com",
  service: "prenatal-patient-portal",
  env: import.meta.env.VITE_ENV,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  defaultPrivacyLevel: "mask",
  allowedTracingUrls: [
    "https://api-staging.unityscreen.com",
    "https://api.unityscreen.com",
    "https://bto-patient-portal.herokuapp.com",
    "https://bto-patient-portal-staging.herokuapp.com",
  ],
  traceSampleRate: 20,
  enableExperimentalFeatures: ["feature_flags"],
});

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <MuiThemeProvider theme={materialTheme}>
        <ThemeProvider theme={themePalette}>
          <App />
        </ThemeProvider>
      </MuiThemeProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root"),
);
