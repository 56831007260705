import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useRecoilValue } from "recoil";
import {
  ProfileInsuranceInsurancePadding,
  ProfileInsuranceTextField,
  ProfileInsuranceTextFieldLabel,
  PatientBillingBox,
  PatientBillingDateWrapper,
  PolicyOwnerRelationshipText,
  RadioFormControl,
  StyledFormLabel,
} from "./ProfileInsuranceModal.styles";
import { GetTypographyContent } from "../shared/Typography/Typography";
import {
  getRelationshipToInsured,
  getInsuredParameter,
  PatientInsuranceInfo,
} from "./helpers";

import { WarningRounded } from "../../assets/iconComponents/WarningRounded";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import FormField from "../shared/TextField/FormField";
import { PatientInformationStateGrid } from "./Profile.styles";
import { getUserAuth } from "../../recoil/selectors";
import { GetInsuranceList } from "./Services/UpdateProfileInfoService";

interface InsuranceCarrier {
  Label: string;
  Value: string;
}

const ProfileInsuranceDetailsEntry: React.FC<{
  patientInsuranceInfo: PatientInsuranceInfo;
  setPatientInsuranceInfo: React.Dispatch<
    React.SetStateAction<PatientInsuranceInfo>
  >;
  isError: boolean;
  financialAssistance?: boolean;
}> = ({
  patientInsuranceInfo,
  setPatientInsuranceInfo,
  isError,
  financialAssistance,
}) => {
  const { deviceType } = useDeviceHook();
  const auth = useRecoilValue(getUserAuth);

  const [insuranceList, setInsuranceList] = useState<string[]>([]);

  useEffect(() => {
    const getMissingInsuranceList = async () => {
      if (auth) {
        try {
          const insuranceListResponse = await GetInsuranceList(
            auth.access_token,
          );
          const newInsuranceList = await insuranceListResponse.json();
          setInsuranceList(
            (newInsuranceList.records as InsuranceCarrier[]).map(
              (record) => record.Value,
            ) || [],
          );
        } catch (e) {
          //
        }
      }
    };
    getMissingInsuranceList();
  }, [auth]);

  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          value="Entry"
          control={<Radio />}
          label={GetTypographyContent({
            content: "Enter details below",
            size: "medium",
            textColor: "greyMedium",
          })}
        />
      </Grid>
      <ProfileInsuranceInsurancePadding
        $show={patientInsuranceInfo.billToInsuranceType === "Entry"}
      >
        <Grid container spacing={3}>
          <PatientInformationStateGrid item xs={12} sm={12} md={12}>
            <FormField
              fieldTitle="Insurance Company Name*"
              autoCompletePlaceholder="Insurance Company Name"
              isError={Boolean(
                patientInsuranceInfo.insuranceCompanyName && isError,
              )}
              fieldType="autocomplete"
              newRequisition
              autoCompleteDropdownStyle
              autoCompleteOptions={insuranceList}
              fieldValue={patientInsuranceInfo.insuranceCompanyName}
              onFieldChange={(val) =>
                setPatientInsuranceInfo({
                  ...patientInsuranceInfo,
                  insuranceCompanyName: val as string,
                })
              }
              edit
            />
          </PatientInformationStateGrid>
          <Grid item xs={12} sm={12} md={12}>
            <ProfileInsuranceTextField
              fullWidth
              focused
              placeholder="Group ID #"
              error={isError && !patientInsuranceInfo.groupId ? "Error" : ""}
              InputLabelProps={{ className: "insurance-label" }}
              label={
                <ProfileInsuranceTextFieldLabel>
                  Group ID #*
                  {isError && !patientInsuranceInfo.groupId && (
                    <WarningRounded />
                  )}
                </ProfileInsuranceTextFieldLabel>
              }
              variant="standard"
              value={patientInsuranceInfo.groupId}
              onChange={(e) =>
                setPatientInsuranceInfo({
                  ...patientInsuranceInfo,
                  groupId: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <ProfileInsuranceTextField
              fullWidth
              focused
              placeholder="Member ID #"
              error={isError && !patientInsuranceInfo.memberId ? "Error" : ""}
              label={
                <ProfileInsuranceTextFieldLabel>
                  Member ID #*
                  {isError && !patientInsuranceInfo.memberId && (
                    <WarningRounded />
                  )}
                </ProfileInsuranceTextFieldLabel>
              }
              variant="standard"
              value={patientInsuranceInfo.memberId}
              onChange={(e) =>
                setPatientInsuranceInfo({
                  ...patientInsuranceInfo,
                  memberId: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <StyledFormLabel
              style={{ lineHeight: 2.5, textTransform: "none" }}
              isError={
                isError &&
                !patientInsuranceInfo.relationshipToPolicyOwnerDependent &&
                !patientInsuranceInfo.relationshipToPolicyOwnerSelf &&
                !patientInsuranceInfo.relationshipToPolicyOwnerOther
              }
            >
              <PolicyOwnerRelationshipText>
                {GetTypographyContent({
                  content: "Relationship to Policy Owner*",
                  size: "small",
                  textColor: "blueDark",
                })}
              </PolicyOwnerRelationshipText>
            </StyledFormLabel>
            <PatientBillingBox>
              <RadioFormControl
                $hideBorder
                error={
                  isError &&
                  !patientInsuranceInfo.relationshipToPolicyOwnerDependent &&
                  !patientInsuranceInfo.relationshipToPolicyOwnerSelf &&
                  !patientInsuranceInfo.relationshipToPolicyOwnerOther
                }
              >
                <RadioGroup
                  value={getRelationshipToInsured(
                    patientInsuranceInfo.relationshipToPolicyOwnerSelf,
                    patientInsuranceInfo.relationshipToPolicyOwnerDependent,
                    patientInsuranceInfo.relationshipToPolicyOwnerOther,
                  )}
                  onChange={(e) =>
                    setPatientInsuranceInfo({
                      ...patientInsuranceInfo,
                      relationshipToPolicyOwnerSelf: false,
                      relationshipToPolicyOwnerDependent: false,
                      relationshipToPolicyOwnerOther: false,
                      [getInsuredParameter(
                        e.target.value as "Self" | "Dependent" | "Other",
                      )]: true,
                    })
                  }
                  row={deviceType !== "mobile" && !financialAssistance}
                >
                  <Grid container spacing={2} />
                  <Grid item xs={3}>
                    <FormControlLabel
                      value="Self"
                      control={<Radio />}
                      label={GetTypographyContent({
                        content: "Self",
                        size: "medium",
                        textColor: "greyMedium",
                      })}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value="Dependent"
                      control={<Radio />}
                      label={GetTypographyContent({
                        content: "Dependent",
                        size: "medium",
                        textColor: "greyMedium",
                      })}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      value="Other"
                      control={<Radio />}
                      label={GetTypographyContent({
                        content: "Other",
                        size: "medium",
                        textColor: "greyMedium",
                      })}
                    />
                  </Grid>
                </RadioGroup>
              </RadioFormControl>
            </PatientBillingBox>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <ProfileInsuranceTextField
              fullWidth
              focused
              placeholder="Policy Owner Name"
              error={
                isError && !patientInsuranceInfo.policyOwnerName ? "Error" : ""
              }
              label={
                <ProfileInsuranceTextFieldLabel>
                  {" "}
                  Policy Owner Name*{" "}
                  {isError && !patientInsuranceInfo.policyOwnerName && (
                    <WarningRounded />
                  )}
                </ProfileInsuranceTextFieldLabel>
              }
              variant="standard"
              value={patientInsuranceInfo.policyOwnerName}
              onChange={(e) =>
                setPatientInsuranceInfo({
                  ...patientInsuranceInfo,
                  policyOwnerName: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <PatientBillingDateWrapper>
              <ProfileInsuranceTextField
                fullWidth
                focused
                type="date"
                $noDateValue={!patientInsuranceInfo.policyOwnerDob}
                placeholder="MM-DD-YYYY"
                inputProps={{
                  className: "placeholderclass",
                }}
                error={
                  isError && !patientInsuranceInfo.policyOwnerDob ? "Error" : ""
                }
                label={
                  <ProfileInsuranceTextFieldLabel>
                    Policy Owner DOB*
                    {isError && !patientInsuranceInfo.policyOwnerDob && (
                      <WarningRounded />
                    )}
                  </ProfileInsuranceTextFieldLabel>
                }
                variant="standard"
                value={patientInsuranceInfo.policyOwnerDob}
                onChange={(e) =>
                  setPatientInsuranceInfo({
                    ...patientInsuranceInfo,
                    policyOwnerDob: e.target.value,
                  })
                }
              />
            </PatientBillingDateWrapper>
          </Grid>
        </Grid>
      </ProfileInsuranceInsurancePadding>
    </>
  );
};
export default ProfileInsuranceDetailsEntry;
