import styled from "styled-components/macro";
import { TextField } from "@mui/material";
import { DeviceType } from "../../utils/hooks/useDeviceHook";

const formFieldWidth = (deviceType: string) => {
  if (deviceType === "desktop") return "447px";
  if (deviceType === "tablet") return "421px";
  return "327px";
};

export const ResetPasswordFormGrid = styled.div`
  display: grid;
  grid-template-rows: 40px;
  grid-row-gap: 40px;
  padding-top: 12px;
  padding-bottom: 40px;
  place-items: center;
`;

export const ResetPasswordFormImage = styled.img<{ width: string }>`
  width: ${({ width }) => width};
  margin: auto;
`;

export const ResetPasswordFormVector = styled.img`
  position: absolute;
  left: 28.4%;
`;

export const ResetPasswordInput = styled(TextField)`
  width: 100%;
  color: ${({ theme }) => theme.greyMedium};
  background-color: #fff;
`;

export const FormFieldInput = styled.div<{
  deviceType: DeviceType;
}>`
  width: ${({ deviceType }) => formFieldWidth(deviceType)};
`;

export const ResetPasswordButtonContainer = styled.div`
  display: inline-grid;
  justify-content: flex-start;
`;

export const ResetPasswordDesktopContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: white;
`;

export const ResetPasswordMobileContainer = styled.div`
  background-color: white;
`;

export const ResetPasswordFormDesktopColorbackground = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100vh;
  background: ${({ theme }) => theme.gradient};
`;

export const Spinner = styled.div`
  text-align: center;
  margin-top: 40%;
`;

export const ResetPasswordCenterContainerPaddedTop = styled.div`
  max-width: 480px;
  display: grid;
  align-content: center;
  height: 400px;
  margin: auto;
  place-items: center;
  background-color: ${({ theme }) => theme.gradient};
  height: 100vh;
  text-align: center;
`;
