import * as React from "react";

export const TeethCTA = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="noun-teeth-1302546 1" clipPath="url(#clip0_1830_3708)">
      <g id="Group">
        <path
          id="Vector"
          d="M8.50316 15.8668C9.7823 15.8668 10.2027 14.3786 10.573 13.0654C10.9753 11.6371 11.2947 10.7013 12.0293 10.7013C12.7629 10.7013 13.0832 11.6381 13.4855 13.0654C13.8559 14.3785 14.2763 15.8668 15.5544 15.8668C18.2578 15.8668 18.377 8.19369 18.382 7.36924C19.1326 4.88397 19.1677 3.22949 18.488 2.31273C18.0777 1.75826 17.4131 1.47695 16.5123 1.47695C15.6516 1.47695 14.6676 1.72612 13.8009 1.94627C13.1122 2.12137 12.4607 2.28659 12.0282 2.28659C11.6038 2.28659 10.9523 2.11937 10.2616 1.94225C9.37594 1.71521 8.36987 1.45801 7.48717 1.45801C6.57328 1.45801 5.89969 1.74319 5.48532 2.30571C4.80067 3.23551 4.84472 4.90809 5.62037 7.41713C5.63344 8.19795 5.82764 15.8667 8.50308 15.8667L8.50316 15.8668ZM6.131 2.78029C6.38921 2.42892 6.83255 2.25885 7.48722 2.25885C8.26891 2.25885 9.22281 2.50299 10.0645 2.71831C10.8361 2.91553 11.5038 3.0866 12.0293 3.0866C12.5618 3.0866 13.2594 2.90949 14 2.72232C14.8207 2.51421 15.7524 2.27797 16.5142 2.27797C17.1558 2.27797 17.5911 2.44519 17.8465 2.78937C18.3619 3.48591 18.2788 4.9673 17.6003 7.19216C17.5883 7.23019 17.5822 7.26924 17.5822 7.30928C17.5822 10.5611 16.8787 15.0661 15.5554 15.0661C14.9299 15.0661 14.6347 14.1872 14.2563 12.8471C13.8669 11.4659 13.4256 9.89953 12.0293 9.89953C10.633 9.89953 10.1916 11.4649 9.80125 12.8471C9.4229 14.1872 9.12865 15.0661 8.50216 15.0661C7.11595 15.0661 6.42023 10.1228 6.42023 7.3539C6.42023 7.31284 6.4132 7.2728 6.4013 7.23476C5.70172 4.98575 5.60958 3.48746 6.13102 2.77964L6.131 2.78029Z"
          fill="black"
        />
        <path
          id="Vector_2"
          d="M19.8466 2.65292C19.9497 2.65292 20.0529 2.61287 20.1308 2.53479L21.8413 0.812281C21.9964 0.655108 21.9954 0.401926 21.8393 0.246748C21.6821 0.0905827 21.4279 0.0915865 21.2727 0.248759L19.5623 1.97127C19.4071 2.12844 19.4081 2.38162 19.5643 2.5368C19.6424 2.61388 19.7454 2.65292 19.8466 2.65292Z"
          fill="black"
        />
        <path
          id="Vector_3"
          d="M20.7112 4.69681C20.7112 4.91799 20.8913 5.09711 21.1115 5.09711H23.5698C23.791 5.09711 23.9701 4.91799 23.9701 4.69681C23.9701 4.47563 23.79 4.29651 23.5698 4.29651H21.1115C20.8913 4.29651 20.7112 4.47563 20.7112 4.69681Z"
          fill="black"
        />
        <path
          id="Vector_4"
          d="M21.2759 9.1207C21.354 9.19677 21.455 9.23481 21.5552 9.23481C21.6593 9.23481 21.7633 9.19476 21.8414 9.11366C21.9956 8.95548 21.9926 8.7023 21.8344 8.54813L20.1008 6.8556C19.9416 6.70044 19.6894 6.70346 19.5353 6.86264C19.3811 7.02082 19.3841 7.274 19.5423 7.42817L21.2759 9.1207Z"
          fill="black"
        />
        <path
          id="Vector_5"
          d="M3.86925 6.8635L2.18076 8.60313C2.02761 8.76231 2.03063 9.01549 2.18981 9.16965C2.26689 9.24471 2.36792 9.28275 2.46813 9.28275C2.57218 9.28275 2.67724 9.2417 2.75532 9.1616L4.44485 7.42198C4.598 7.2628 4.59498 7.00961 4.4358 6.85546C4.27862 6.7013 4.02341 6.70432 3.86925 6.8635Z"
          fill="black"
        />
        <path
          id="Vector_6"
          d="M0.408113 5.0932H2.86336C3.08454 5.0932 3.26366 4.91408 3.26366 4.6929C3.26366 4.47173 3.08354 4.2926 2.86336 4.2926H0.408113C0.186935 4.2926 0.00781262 4.47173 0.00781262 4.6929C0.00764503 4.91408 0.186768 5.0932 0.408113 5.0932Z"
          fill="black"
        />
        <path
          id="Vector_7"
          d="M3.85509 2.5169C3.93317 2.59499 4.03622 2.63403 4.13826 2.63403C4.24031 2.63403 4.34353 2.59499 4.42144 2.5169C4.57761 2.36074 4.57761 2.10755 4.42144 1.95137L2.71099 0.239927C2.55482 0.0837614 2.30063 0.0837614 2.14447 0.239927C1.9883 0.396092 1.9883 0.649278 2.14447 0.80546L3.85509 2.5169Z"
          fill="black"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1830_3708">
        <rect
          width="24"
          height="15.7962"
          fill="white"
          transform="translate(0 0.101929)"
        />
      </clipPath>
    </defs>
  </svg>
);
