import * as React from "react";

export const TraitNavigationLeft = (): React.ReactElement => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill="#E2E8F0" />
    <path
      d="M19.2812 23.3125L25.2812 17.3125C25.6562 16.9062 26.3125 16.9062 26.6875 17.3125C27.0938 17.6875 27.0938 18.3438 26.6875 18.7188L21.4062 24L26.6875 29.3125C27.0938 29.6875 27.0938 30.3438 26.6875 30.7188C26.3125 31.125 25.6562 31.125 25.2812 30.7188L19.2812 24.7188C18.875 24.3438 18.875 23.6875 19.2812 23.3125Z"
      fill="#171923"
    />
  </svg>
);
