import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import moment from "moment";
import { CallMade, Close } from "@mui/icons-material";
import {
  UpfrontPaymentReceiptsDownloadRow,
  UpfrontPaymentReceiptsDownloadTitle,
  UpfrontPaymentReceiptsDownloadTitleWrapper,
  UpfrontPaymentReceiptsDownloadWrapper,
} from "./UpfrontPayment.styles";
import {
  GetUpfrontPaymentPlan,
  UpfrontPaymentPlan,
  UpfrontPaymentType,
  getCentsToDollars,
} from "./Services/UpfrontPaymentService";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";

export const UpfrontPaymentReceiptDownload: React.FC<{
  receiptDownloadOpen: boolean;
  setReceiptDownloadOpen: React.Dispatch<React.SetStateAction<boolean>>;
  upfrontBill: UpfrontPaymentType;
}> = ({ receiptDownloadOpen, setReceiptDownloadOpen, upfrontBill }) => {
  const { billingPK, adminID } = useParams<{
    billingPK: string;
    adminID?: string;
  }>();
  const { deviceType } = useDeviceHook();

  const [paymentPlan, setPaymentPlan] = useState<UpfrontPaymentPlan[]>();

  useEffect(() => {
    const getUpfrontPaymentPlanDetails = async () => {
      if (upfrontBill.is_payment_plan_enabled) {
        const paymentPlanResponse = await GetUpfrontPaymentPlan(
          billingPK,
          adminID,
        );
        const paymentPlanJson = await paymentPlanResponse.json();
        setPaymentPlan(paymentPlanJson);
      }
    };
    getUpfrontPaymentPlanDetails();
  }, []);

  return (
    <Modal
      open={receiptDownloadOpen}
      onClose={() => setReceiptDownloadOpen(false)}
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <>
        <UpfrontPaymentReceiptsDownloadWrapper
          deviceType={deviceType}
          minHeight={200}
        >
          <UpfrontPaymentReceiptsDownloadTitleWrapper>
            <UpfrontPaymentReceiptsDownloadTitle>
              Select Receipt to View
            </UpfrontPaymentReceiptsDownloadTitle>
            {
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <UpfrontPaymentReceiptsDownloadTitle
                onClick={() => setReceiptDownloadOpen(false)}
                role="button"
              >
                <Close />
              </UpfrontPaymentReceiptsDownloadTitle>
            }
          </UpfrontPaymentReceiptsDownloadTitleWrapper>

          {paymentPlan
            ?.filter((payment) => payment.status === "paid")
            .map((payment) => (
              <a href={payment.receipt_url} target="_blank" rel="noreferrer">
                <UpfrontPaymentReceiptsDownloadRow>
                  <CallMade />
                  <div>
                    {moment(new Date(payment.epoch * 1000)).format(
                      "MMMM Do, YYYY",
                    )}
                  </div>
                  <div>{getCentsToDollars(payment.amount)}</div>
                </UpfrontPaymentReceiptsDownloadRow>
              </a>
            ))}
        </UpfrontPaymentReceiptsDownloadWrapper>
      </>
    </Modal>
  );
};
