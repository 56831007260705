import React from "react";

import {
  AccordionSummary,
  AccordionDetails,
  AccordionProps as MuiAccordionProps,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import {
  BabyPeekAccordionAnswer,
  BabyPeekAccordionQuestion,
  BabyPeekGameAccordionTitle,
  BabyPeekGameAccordionTitleGrid,
  BabyPeekGamesAccordionText,
  BabyPeekStyledAccordion,
} from "./BabyPeekAccordion.styles";

import { BabyPeekDescriptionSectionText } from "./BabyPeekTrait.styles";
import { BabyPeekGameszList } from "./BabyPeekIcons/BabyPeekGamesList";
import { BabyPeekGamesGuessTheLittleOne } from "./BabyPeekIcons/BabyPeekGamesGuessLittleOne";
import { BabyPeekButton } from "./BabyPeekButton";
import { BabyPeekGamesWriting } from "./BabyPeekIcons/BabyPeekGamesWriting";
import { BabyPeekGamesGifts } from "./BabyPeekIcons/BabyPeekGamesGIft";
import { BabyPeekGamesPeekABoo } from "./BabyPeekIcons/BabyPeekGamesPeekABook";
import { BabyPeekGamesTraitReveal } from "./BabyPeekIcons/BabyPeekGamesTraitReveal";
import { BabyPeekDownloadGames } from "./Services/BabyPeekService";

export const babyPeekFAQAccordion: BabyPeekAccordionProps[] = [
  {
    title: "Is BabyPeek available to everyone?",
    content: (
      <>
        <BabyPeekDescriptionSectionText>
          BabyPeek is available for the vast majority of parents who have had
          UNITY testing. However, BabyPeek is not available in the following
          circumstances:
          <ul>
            <li>Twin pregnancy</li>
            <li>Pregnancy conceived with egg donor / Surrogate</li>
            <li>
              {`UNITY Aneuploidy test reslited in "No Call" reslit or "Redraw
              Request"`}
            </li>
            <li>Other rare circumstances</li>
          </ul>
        </BabyPeekDescriptionSectionText>
        <BabyPeekDescriptionSectionText style={{ paddingTop: "24px" }}>
          If you have questions regarding BabyPeek or UNITY, please call
          BillionToOne at{" "}
          <a href={`tel:${"(650) 460-2551"}`} target="_blank" rel="noreferrer">
            {` (650) 460-2551`}
          </a>
          .
        </BabyPeekDescriptionSectionText>
      </>
    ),
    key: "available",
  },
  {
    title: "Does BabyPeek tell me anything more about the health of my baby?",
    content: (
      <>
        <BabyPeekDescriptionSectionText>
          No, BabyPeek is not a medical test. The UNITY Screen™ ordered by your
          provider contains medical information, but BabyPeek is a separate
          report that is designed to be optional and fun. BabyPeek cannot
          predict anything about the health of your baby.
        </BabyPeekDescriptionSectionText>
      </>
    ),
    key: "health",
  },
  {
    title: "Will BabyPeek predict how my baby will look with 100% certainty?",
    content: (
      <>
        <BabyPeekDescriptionSectionText>
          No, BabyPeek can provide information on how likely your baby is to
          have certain traits, but it cannot predict these traits with 100%
          certainty.{" "}
          <span style={{ fontWeight: 700 }}>
            {" "}
            Think of BabyPeek like a weather report – it can tell you what is
            most likely, but cannot predict the future.{" "}
          </span>{" "}
          It is possible your baby could have traits that are different than
          those predicted.
        </BabyPeekDescriptionSectionText>
      </>
    ),
    key: "predict",
  },
  {
    title: "Can BabyPeek be used as a paternity test?",
    content: (
      <>
        <BabyPeekDescriptionSectionText>
          No, BabyPeek cannot be used to determine paternity. BabyPeek results
          cannot predict traits with 100% certainty, and genetics are just one
          piece of the puzzle in determining what traits a baby will have. The
          genetics of some traits are complicated – it’s possible that a baby
          may have a trait or feature that neither you or the father of the baby
          have!
        </BabyPeekDescriptionSectionText>
      </>
    ),
    key: "paternity",
  },
  {
    title: "Who can I contact with more questions?",
    content: (
      <>
        <BabyPeekDescriptionSectionText>
          Please do not contact your healthcare provider with questions about
          BabyPeek, as it is not a medical test. If you have questions regarding
          BabyPeek, please call BillionToOne at{" "}
          <a href={`tel:${"(650) 460-2551"}`} target="_blank" rel="noreferrer">
            {` (650) 460-2551`}
          </a>
          .
        </BabyPeekDescriptionSectionText>
      </>
    ),
    key: "contact",
  },
  {
    title: "Did my healthcare provider order or recommend BabyPeek?",
    content: (
      <>
        <BabyPeekDescriptionSectionText>
          No, your healthcare provider did not order BabyPeek. Your doctor
          ordered a medical test called the UNITY Screen™. We (BillionToOne)
          offer BabyPeek as an optional, fun report if you are interested in
          learning more about what traits your baby may have.
        </BabyPeekDescriptionSectionText>
      </>
    ),
    key: "provider",
  },
  {
    title: `What if I'm not happy with my results?`,
    content: (
      <>
        <BabyPeekDescriptionSectionText>
          {`Don't worry, BabyPeek has a 4.9/5 star rating from parents just like you! BabyPeek is non-refundable after the traits are revealed, but you can always contact us about your results at hello@babypeek.com. `}
        </BabyPeekDescriptionSectionText>
      </>
    ),
    key: "results",
  },
];

export const babyPeekGameAccordion = (
  babypeekPK: string,
  auth?: string,
): BabyPeekAccordionProps[] => [
  {
    title: (
      <BabyPeekGameAccordionTitleGrid>
        <BabyPeekGameszList />{" "}
        <BabyPeekGameAccordionTitle>
          Guess The Little One
        </BabyPeekGameAccordionTitle>
      </BabyPeekGameAccordionTitleGrid>
    ),
    content: (
      <>
        <BabyPeekGamesAccordionText>
          <div>
            {`Can your friends and family guess the baby’s traits? This game allows them to `}{" "}
            <span>{`predict your future baby's traits in a fun and interactive way. `}</span>{" "}
          </div>
          <br />
          <BabyPeekGamesGuessTheLittleOne />
          <BabyPeekButton
            variant="filled"
            content={<>Download Preview</>}
            onClick={async () => {
              const downloadLinkResponse = await BabyPeekDownloadGames(
                babypeekPK,
                "GuessTheLittleOne",
                "Preview",
                auth,
              );
              if (downloadLinkResponse) {
                const link = await downloadLinkResponse.json();
                const tempAnchor = document.createElement("a");
                tempAnchor.download = "TraitsRevealAnswer.pdf";
                const downloadUrl = link.s3_url;
                if (downloadUrl) {
                  tempAnchor.href = downloadUrl;
                  tempAnchor.click();
                }
                tempAnchor.remove();
              }
            }}
            style={{
              justifySelf: "center",
              marginTop: "-90px",
            }}
          />
          <br />
        </BabyPeekGamesAccordionText>
      </>
    ),
    key: "guessLittleOne",
  },
  {
    title: (
      <BabyPeekGameAccordionTitleGrid>
        <BabyPeekGamesWriting />{" "}
        <BabyPeekGameAccordionTitle>Peek-a-Boo</BabyPeekGameAccordionTitle>
      </BabyPeekGameAccordionTitleGrid>
    ),
    content: (
      <>
        <BabyPeekGamesAccordionText>
          <div>
            {" "}
            {`Rank and guess the likelihood of the baby’s unique traits! `}{" "}
          </div>
          <br />
          <BabyPeekGamesPeekABoo />
          <BabyPeekButton
            variant="filled"
            content={<>Download Preview</>}
            onClick={async () => {
              const downloadLinkResponse = await BabyPeekDownloadGames(
                babypeekPK,
                "PeekABoo",
                "Preview",
                auth,
              );
              if (downloadLinkResponse) {
                const link = await downloadLinkResponse.json();
                const tempAnchor = document.createElement("a");
                tempAnchor.download = "TraitsRevealAnswer.pdf";
                const downloadUrl = link.s3_url;
                if (downloadUrl) {
                  tempAnchor.href = downloadUrl;
                  tempAnchor.click();
                }
                tempAnchor.remove();
              }
            }}
            style={{
              justifySelf: "center",
              marginTop: "-90px",
            }}
          />
          <br />
        </BabyPeekGamesAccordionText>
      </>
    ),
    key: "peekABoo",
  },
  {
    title: (
      <BabyPeekGameAccordionTitleGrid>
        <BabyPeekGamesGifts />{" "}
        <BabyPeekGameAccordionTitle>Traits Reveal</BabyPeekGameAccordionTitle>
      </BabyPeekGameAccordionTitleGrid>
    ),
    content: (
      <>
        <BabyPeekGamesAccordionText>
          <div>{`Print out your baby’s trait reveal cards and share the excitement with your friends and family! `}</div>
          <br />
          <BabyPeekGamesTraitReveal />
          <BabyPeekButton
            variant="filled"
            content={<>Download Preview</>}
            onClick={async () => {
              const downloadLinkResponse = await BabyPeekDownloadGames(
                babypeekPK,
                "TraitsReveal",
                "Preview",
                auth,
              );
              if (downloadLinkResponse) {
                const link = await downloadLinkResponse.json();
                const tempAnchor = document.createElement("a");
                tempAnchor.download = "TraitsRevealAnswer.pdf";
                const downloadUrl = link.s3_url;
                if (downloadUrl) {
                  tempAnchor.href = downloadUrl;
                  tempAnchor.click();
                }
                tempAnchor.remove();
              }
            }}
            style={{
              justifySelf: "center",
              marginTop: "-90px",
            }}
          />
          <br />
        </BabyPeekGamesAccordionText>
      </>
    ),
    key: "babypeekGamesTraitReveal",
  },
];

export interface BabyPeekAccordionProps
  extends Omit<MuiAccordionProps, "children" | "content" | "title"> {
  title: React.ReactNode;
  content: React.ReactNode;
  key: string;
}

export const BabyPeekAccordions: React.FC<{
  data: BabyPeekAccordionProps[];
}> = ({ data }) => {
  const [expanded, setExpanded] = React.useState<string | false>("");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const defaultAccordions = data.map((accordionSections) => (
    <BabyPeekStyledAccordion
      expanded={expanded === accordionSections.key}
      onChange={handleChange(accordionSections.key)}
      key={accordionSections.key}
    >
      <AccordionSummary
        expandIcon={
          expanded === accordionSections.key ? (
            <FontAwesomeIcon
              icon={faChevronUp}
              style={{ color: "#2D3748", height: "20px" }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{ color: "#2D3748", height: "20px" }}
            />
          )
        }
        aria-controls={`${accordionSections.key}-panel-content`}
        id={`${accordionSections.key}-help`}
      >
        <BabyPeekAccordionQuestion>
          {accordionSections.title}
        </BabyPeekAccordionQuestion>
      </AccordionSummary>
      <AccordionDetails>
        <BabyPeekAccordionAnswer>
          {accordionSections.content}
        </BabyPeekAccordionAnswer>
      </AccordionDetails>
    </BabyPeekStyledAccordion>
  ));

  return <>{defaultAccordions}</>;
};
