import { FC } from "react";
import { StepperIconBlank } from "../../../assets/iconComponents/StepperIconBlank";
import { StepperIconFilled } from "../../../assets/iconComponents/StepperIconFilled";
import { StepperIconProgress } from "../../../assets/iconComponents/StepperIconProgress";

export const getStepIconComponent = (index: number, activeStep: number): FC => {
  if (index < activeStep) {
    return StepperIconFilled;
  }
  if (index === activeStep) {
    return StepperIconProgress;
  }
  return StepperIconBlank;
};
