import * as React from "react";
import { themePalette } from "../../theme";

export const NotOrderedIcon = ({
  color = themePalette.greyLight,
  ...props
}: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 0.375C5.57967 0.375 0.375 5.57967 0.375 12C0.375 18.4203 5.57967 23.625 12 23.625C18.4203 23.625 23.625 18.4203 23.625 12C23.625 5.57967 18.4203 0.375 12 0.375ZM18.6291 5.37089C22.0592 8.80097 22.2408 14.1506 19.3774 17.7864L6.21356 4.62258C9.8505 1.75828 15.1999 1.94166 18.6291 5.37089ZM5.37089 18.6291C1.94077 15.199 1.75917 9.84937 4.62258 6.21356L17.7864 19.3774C14.1495 22.2417 8.80008 22.0583 5.37089 18.6291Z"
      fill={color}
    />
  </svg>
);
