import React from "react";
import { useRecoilValue } from "recoil";
import {
  ExternalForwardFetalSexHeaderContainer,
  ExternalForwardFetalSexHeaderIconsLinksContainer,
  ExternalForwardFetalSexHeaderIconsContainer,
  ExternalForwardFetalSexHeaderIcon,
  ExternalRevealGrid,
  ExternalRevealContentWrapper,
  ExternalForwardFetalSexInfoWrapper,
} from "./ExternalForwardFetalSex.styles";
import unityLogo from "../../assets/logos/unity.svg";
import byIcon from "../../assets/logos/by.svg";
import poweredByBTOLogo from "../../assets/logos/billionToOne.svg";
import { ExternalFetalSexReveal } from "./ExternalFetalSexReveal";
import { GetTypographyContent } from "../shared/Typography/Typography";
import { getUserAuth } from "../../recoil/selectors";

const ExternalForwardFetalSex = (): React.ReactElement => {
  const auth = useRecoilValue(getUserAuth);
  return (
    <>
      {!auth && (
        <ExternalForwardFetalSexHeaderContainer>
          <ExternalForwardFetalSexHeaderIconsLinksContainer>
            <ExternalForwardFetalSexHeaderIconsContainer>
              <ExternalForwardFetalSexHeaderIcon
                src={unityLogo}
                width="156px"
              />
              <ExternalForwardFetalSexHeaderIcon src={byIcon} width="15px" />
              <ExternalForwardFetalSexHeaderIcon
                src={poweredByBTOLogo}
                width="112px"
              />
            </ExternalForwardFetalSexHeaderIconsContainer>
          </ExternalForwardFetalSexHeaderIconsLinksContainer>
        </ExternalForwardFetalSexHeaderContainer>
      )}
      <ExternalForwardFetalSexInfoWrapper>
        <ExternalRevealGrid>
          {GetTypographyContent({
            content: `The results show...`,
            size: "h2",
            textColor: "greyMedium",
            padding: "0px 0px 0px 10px",
          })}
          <ExternalRevealContentWrapper>
            <ExternalFetalSexReveal />
          </ExternalRevealContentWrapper>
        </ExternalRevealGrid>
      </ExternalForwardFetalSexInfoWrapper>
    </>
  );
};
export default ExternalForwardFetalSex;
