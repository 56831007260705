import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { IFileWithMeta } from "react-dropzone-uploader";
import {
  UpfrontPaymentFinancialSummary,
  UpfrontPaymentFinancialSummaryRow,
  UpfrontPaymentFinancialSummaryRowHeader,
  UpfrontPaymentFinancialSummaryRowText,
  UpfrontPaymentFinancialSummaryTitle,
  UpfrontPaymentFinancialSummaryAttestation,
} from "../UpfrontPayment.styles";
import { GetTypographyContent } from "../../shared/Typography/Typography";

interface ConfirmationProps {
  medicaidInfo: {
    hasMedicaid?: "Yes" | "No";
    frontCardFileName?: string;
    backCardFileName?: string;
  };
  householdInfo?: {
    size?: number;
    income?: number;
    incomeDisplay?: string;
  };
  optionalQuestions?: {
    additionalCoverage?: boolean;
    locality?: string;
    extenuatingCircumstances?: string[];
  };
  rulesAccepted?: boolean;
  setRulesAccepted?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFAFile?: IFileWithMeta | null;
}

export const StepConfirmation: React.FC<ConfirmationProps> = ({
  medicaidInfo,
  householdInfo,
  optionalQuestions,
  rulesAccepted,
  setRulesAccepted,
  selectedFAFile,
}) => (
  <div style={{ marginLeft: "20px", marginRight: "20px" }}>
    <UpfrontPaymentFinancialSummary>
      <UpfrontPaymentFinancialSummaryTitle>
        {`Information I've Provided`}
      </UpfrontPaymentFinancialSummaryTitle>

      <UpfrontPaymentFinancialSummaryRow>
        <UpfrontPaymentFinancialSummaryRowHeader>
          Do I Have Medicaid?
        </UpfrontPaymentFinancialSummaryRowHeader>
        <UpfrontPaymentFinancialSummaryRowText>
          {medicaidInfo.hasMedicaid || "Not Provided"}
        </UpfrontPaymentFinancialSummaryRowText>
      </UpfrontPaymentFinancialSummaryRow>

      {medicaidInfo.hasMedicaid === "Yes" ? (
        <>
          <UpfrontPaymentFinancialSummaryRow>
            <UpfrontPaymentFinancialSummaryRowHeader>
              Front of My Medicaid Card
            </UpfrontPaymentFinancialSummaryRowHeader>
            <UpfrontPaymentFinancialSummaryRowText>
              {medicaidInfo.frontCardFileName || "Not Uploaded"}
            </UpfrontPaymentFinancialSummaryRowText>
          </UpfrontPaymentFinancialSummaryRow>
          <UpfrontPaymentFinancialSummaryRow>
            <UpfrontPaymentFinancialSummaryRowHeader>
              Back of My Medicaid Card
            </UpfrontPaymentFinancialSummaryRowHeader>
            <UpfrontPaymentFinancialSummaryRowText>
              {medicaidInfo.backCardFileName || "Not Uploaded"}
            </UpfrontPaymentFinancialSummaryRowText>
          </UpfrontPaymentFinancialSummaryRow>
        </>
      ) : (
        <>
          <UpfrontPaymentFinancialSummaryRow>
            <UpfrontPaymentFinancialSummaryRowHeader>
              Household Size (Including Current Pregnancy)
            </UpfrontPaymentFinancialSummaryRowHeader>
            <UpfrontPaymentFinancialSummaryRowText>
              {householdInfo?.size || "Not Provided"}
            </UpfrontPaymentFinancialSummaryRowText>
          </UpfrontPaymentFinancialSummaryRow>
          <UpfrontPaymentFinancialSummaryRow>
            <UpfrontPaymentFinancialSummaryRowHeader>
              Annual Total Household Income Before Taxes
            </UpfrontPaymentFinancialSummaryRowHeader>
            <UpfrontPaymentFinancialSummaryRowText>
              {householdInfo?.income
                ? householdInfo.incomeDisplay
                : "Not Provided"}
            </UpfrontPaymentFinancialSummaryRowText>
          </UpfrontPaymentFinancialSummaryRow>
          {Boolean(optionalQuestions?.extenuatingCircumstances?.length) && (
            <UpfrontPaymentFinancialSummaryRow>
              <UpfrontPaymentFinancialSummaryRowHeader>
                Extenuating Circumstances
              </UpfrontPaymentFinancialSummaryRowHeader>
              <UpfrontPaymentFinancialSummaryRowText
                style={{ textWrap: "wrap" }}
              >
                {optionalQuestions?.extenuatingCircumstances?.join(", ")}
              </UpfrontPaymentFinancialSummaryRowText>
            </UpfrontPaymentFinancialSummaryRow>
          )}
          {optionalQuestions?.locality && (
            <UpfrontPaymentFinancialSummaryRow>
              <UpfrontPaymentFinancialSummaryRowHeader>
                Locality
              </UpfrontPaymentFinancialSummaryRowHeader>
              <UpfrontPaymentFinancialSummaryRowText>
                {optionalQuestions.locality}
              </UpfrontPaymentFinancialSummaryRowText>
            </UpfrontPaymentFinancialSummaryRow>
          )}
          {selectedFAFile && (
            <UpfrontPaymentFinancialSummaryRow>
              <UpfrontPaymentFinancialSummaryRowHeader>
                Financial Assistance Document
              </UpfrontPaymentFinancialSummaryRowHeader>
              <UpfrontPaymentFinancialSummaryRowText>
                {selectedFAFile.meta.name}
              </UpfrontPaymentFinancialSummaryRowText>
            </UpfrontPaymentFinancialSummaryRow>
          )}
        </>
      )}
    </UpfrontPaymentFinancialSummary>
    {medicaidInfo.hasMedicaid === "No" && setRulesAccepted && (
      <div>
        <UpfrontPaymentFinancialSummaryAttestation>
          I hereby certify that the information provided is true and accurate. I
          also certify that I do not carry any U.S. federal and state-funded
          health insurance (e.g., Medicare, Medicaid, Tricare,
          MedicareAdvantage). I understand and agree that BillionToOne reserves
          the right, at any time and without notice, to modify the application
          form, to modify or terminate this program, to audit my information or
          to request additional information.
        </UpfrontPaymentFinancialSummaryAttestation>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => setRulesAccepted(e.target.checked)}
              checked={rulesAccepted}
              data-testid="terms-accepted-checkbox"
              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
            />
          }
          label={GetTypographyContent({
            content: "I accept",
            size: "medium",
            textColor: "greyMedium",
          })}
        />
      </div>
    )}
  </div>
);
