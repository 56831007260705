import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useHistory, useParams } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import {
  DetailedReportTop,
  CarrierGeneralContainer,
  CarrierDesktopLeftWrapper,
  CarrierDesktopRightWrapper,
  CarrierDesktopWrapper,
  CarrierTitleWrapper,
  CarrierWrapper,
  CarrierTabletMobileWrapper,
  CarrierBackToResults,
} from "./Carrier.styles";
import { GetTypographyContent } from "../../components/shared/Typography/Typography";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { DetailedResultsHeader } from "../../components/DetailedResults/DetailedResultsHeader";
import { DetailedResultsTable } from "../../components/DetailedResults/DetailedResultsTable";
import { DetailedResultsViewReport } from "../../components/DetailedResults/DetailedResultsViewReport";
import { DetailedResultsExpert } from "../../components/DetailedResults/DetailedResultsExpert";
import { DetailedResultsFAQ } from "../../components/DetailedResults/DetailedResultsFAQ";
import {
  getAllRequisitions,
  getSpecificFetalSexCs,
  getSpecificUnityLowRisk,
  getSpecificUnityReportID,
  getSpecificUnityStatus,
  getUserAuth,
} from "../../recoil/selectors";
import { LANDING_PAGE_URL } from "../../utils/urlConstants";
import { BackIcon } from "../../assets/iconComponents/BackIcon";
import { FullReportRequest } from "../../components/DetailedResults/Services/DetailedResultsService";
import { DetailedResultsFetalSexModal } from "../../components/DetailedResults/DetailedResultsFetalSexModal";
import { SendActionType } from "../../components/shared/Services/SharedServices";
import CarrierVideoEmbed from "../../components/shared/Video/CarrierVideoEmbed";
import TestResultsMultipleReqHeader from "../../components/TestResults/TestResultsMultipleReqHeader";

const Landing = (): React.ReactElement => {
  const { deviceType } = useDeviceHook();
  const { requisitionID } = useParams<{ requisitionID: string }>();
  const requisitions = useRecoilValue(getAllRequisitions);
  const isLowRisk = useRecoilValue(getSpecificUnityLowRisk(requisitionID));
  const status = useRecoilValue(getSpecificUnityStatus(requisitionID));
  const history = useHistory();
  const reportID = useRecoilValue(getSpecificUnityReportID(requisitionID));
  const auth = useRecoilValue(getUserAuth);
  const fetalSex = useRecoilValue(getSpecificFetalSexCs(requisitionID));
  const [showFetalSexModal, setShowFetalSexModal] = useState<
    "" | "report" | "reveal"
  >("");

  useEffect(() => {
    const sendUIAction = async () => {
      try {
        await SendActionType(
          "ViewCarrierPage",
          requisitionID,
          auth?.access_token,
        );
      } catch (e) {
        return undefined;
      }
    };
    sendUIAction();
  }, []);

  const getFullCarrierReport = async () => {
    if (reportID) {
      try {
        const reportData = await FullReportRequest(
          reportID,
          "carrier",
          auth?.access_token,
        );
        if (reportData?.ok) {
          const url = await reportData.json();
          return url.pdf_url as string;
        }
        return undefined;
      } catch (e) {
        return undefined;
      }
    }
  };

  const ModalComponent = (
    <Modal
      open={Boolean(showFetalSexModal)}
      onClose={() => setShowFetalSexModal("")}
      sx={{
        top: "33%",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <DetailedResultsFetalSexModal
          downloadReport={getFullCarrierReport}
          showModal={setShowFetalSexModal}
          showFetalSexModal={showFetalSexModal}
          showFetalSexAction={() => {}}
        />
      </Box>
    </Modal>
  );

  useEffect(() => {
    if (auth && (!status || !(isLowRisk !== undefined))) {
      history.push(LANDING_PAGE_URL);
    }
  }, [status, isLowRisk, auth]);

  const statusValid =
    status && status !== "Processing" && status !== "Not Ordered";

  if (deviceType === "desktop") {
    return (
      <>
        {ModalComponent}
        <CarrierWrapper>
          <CarrierDesktopWrapper>
            <CarrierDesktopLeftWrapper
              multipleReqs={Boolean(requisitions && requisitions.length > 1)}
            >
              <CarrierBackToResults
                onClick={() => history.push(LANDING_PAGE_URL)}
              >
                <BackIcon />
                {GetTypographyContent({
                  content: "Back to tests",
                  size: "medium",
                  textColor: "redMedium",
                })}
              </CarrierBackToResults>
              <CarrierTitleWrapper>
                {GetTypographyContent({
                  content: "Carrier Screen results",
                  size: "h2",
                  textColor: "greyMedium",
                  isBold: true,
                  padding: "0 0 0 10px",
                })}
              </CarrierTitleWrapper>
              <TestResultsMultipleReqHeader />
              <CarrierGeneralContainer style={{ height: "fit-content" }}>
                <DetailedReportTop>
                  <DetailedResultsHeader
                    type="carrier"
                    requisitionID={requisitionID}
                  />
                  {statusValid && isLowRisk && (
                    <DetailedResultsTable
                      requisitionID={requisitionID}
                      isAneuploidy={false}
                    />
                  )}
                  {statusValid && (
                    <DetailedResultsViewReport
                      fetalSexWarning={Boolean(fetalSex?.value)} // some fragile x carrier reports can report fetal_sex
                      setShowFetalSexModal={setShowFetalSexModal}
                      reportDownload={getFullCarrierReport}
                    />
                  )}
                </DetailedReportTop>
              </CarrierGeneralContainer>
              {isLowRisk && (
                <CarrierGeneralContainer
                  style={{ padding: "30px 15px 30px 16px" }}
                  id="carrierInfoVideo"
                >
                  <CarrierVideoEmbed
                    type="negativeCarrier"
                    reqID={requisitionID}
                    videoLocation="carrier_page"
                  />
                </CarrierGeneralContainer>
              )}
            </CarrierDesktopLeftWrapper>
            <CarrierDesktopRightWrapper>
              <div />
              <div />
              <CarrierGeneralContainer>
                <DetailedResultsExpert />
              </CarrierGeneralContainer>
              <CarrierGeneralContainer>
                <DetailedResultsFAQ />
              </CarrierGeneralContainer>
            </CarrierDesktopRightWrapper>
          </CarrierDesktopWrapper>
        </CarrierWrapper>
      </>
    );
  }
  return (
    <>
      {ModalComponent}
      <CarrierWrapper>
        <CarrierTabletMobileWrapper deviceType={deviceType}>
          <CarrierBackToResults onClick={() => history.push(LANDING_PAGE_URL)}>
            <BackIcon />
            {GetTypographyContent({
              content: "Back to tests",
              size: "medium",
              textColor: "redMedium",
            })}
          </CarrierBackToResults>
          <CarrierTitleWrapper>
            {GetTypographyContent({
              content: "Carrier Screen results",
              size: "h2",
              textColor: "greyMedium",
              isBold: true,
              padding: "0 0 0 10px",
            })}
          </CarrierTitleWrapper>
          <TestResultsMultipleReqHeader />
          <CarrierGeneralContainer style={{ height: "fit-content" }}>
            <DetailedReportTop>
              <DetailedResultsHeader
                type="carrier"
                requisitionID={requisitionID}
              />
              {statusValid && isLowRisk && (
                <DetailedResultsTable
                  requisitionID={requisitionID}
                  isAneuploidy={false}
                />
              )}
              {statusValid && (
                <DetailedResultsViewReport
                  fetalSexWarning={Boolean(fetalSex?.value)} // some fragile x carrier reports can report fetal_sex
                  setShowFetalSexModal={setShowFetalSexModal}
                  reportDownload={getFullCarrierReport}
                />
              )}
            </DetailedReportTop>
          </CarrierGeneralContainer>
          {isLowRisk && (
            <CarrierGeneralContainer
              style={{ padding: "30px 15px 30px 16px" }}
              id="carrierInfoVideo"
            >
              <CarrierVideoEmbed
                type="negativeCarrier"
                reqID={requisitionID}
                videoLocation="carrier_page"
              />
            </CarrierGeneralContainer>
          )}
          <CarrierGeneralContainer>
            <DetailedResultsExpert />
          </CarrierGeneralContainer>
          <CarrierGeneralContainer>
            <DetailedResultsFAQ />
          </CarrierGeneralContainer>
        </CarrierTabletMobileWrapper>
      </CarrierWrapper>
    </>
  );
};

export default Landing;
