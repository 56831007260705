import styled from "styled-components/macro";
import { StyledTypography } from "../../components/shared/Typography/Typography.styles";
import { DeviceType } from "../../utils/hooks/useDeviceHook";

export const AneuploidyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AneuploidyBackToResults = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  max-width: 120px;
  margin-bottom: 20px;
  cursor: pointer;
`;

export const ForwardFetalSexWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.white};
`;

export const AneuploidyDesktopWrapper = styled.div`
  display: grid;
  grid-template-columns: 800px 330px;
  grid-column-gap: 24px;
  padding: 70px 142px 142px 142px;
`;

export const AneuploidyTitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const DetailedReportTop = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
`;

export const AneuploidyDesktopLeftWrapper = styled.div`
  display: grid;
  grid-template-rows: 40px 40px auto auto auto;
  grid-column-gap: 24px;
`;

export const AneuploidyDesktopRightWrapper = styled.div<{ babyPeek?: boolean }>`
  display: grid;
  grid-template-rows: ${({ babyPeek }) =>
    babyPeek ? "40px 40px 400px 415px 200px" : "40px 40px 415px 200px"};
  grid-column-gap: 24px;
  margin-top: 33px;

  ${StyledTypography} {
    align-self: flex-end;
  }
`;

export const AneuploidyGeneralContainer = styled.div<{
  padding: string;
}>`
  margin-top: 20px;
  padding: ${({ padding }) => padding};
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${({ theme }) => theme.boxShadow};
  border-radius: 20px;
  max-width: 802px;
`;

export const AneuploidyTabletMobileWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-columns: ${({ deviceType }) =>
    deviceType === "tablet" ? "570px" : "360px"};
  grid-column-gap: 24px;
  padding: ${({ deviceType }) =>
    deviceType === "tablet" ? "60px 18px 112px 18px" : "40px 0px 72px 0px"};
`;
