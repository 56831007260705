import * as React from "react";
import { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  UpfrontPaymentAnswerWrapper,
  UpfrontPaymentComponentContentWrapper,
  UpfrontPaymentHelpGeneralContainer,
  UpfrontPaymentHelpWrapper,
  UpfrontPaymentInfoBillContent,
  UpfrontPaymentMainText,
  UpfrontPaymentPageWrapper,
  UpfrontPaymentSolidLine,
  UpfrontPaymentTitle,
} from "./UpfrontPayment.styles";
import { UpfrontPaymentMain } from "./UpfrontPaymentMain";
import { GetTypographyContent } from "../shared/Typography/Typography";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { UpfrontPaymentReceipt } from "./UpfrontPaymentReceipt";
import { UpfrontPaymentReceiptView } from "./UpfrontPaymentReceiptEmail";
import { UpfrontPaymentBillingSummary } from "./UpfrontPaymentBillingSummary";
import { UpfrontPaymentInsurance } from "./UpfrontPaymentInsurance";
import { FinancialAssistanceModal } from "./FinancialAssistance/FinancialAssistanceModal";
import { UpfrontPaymentStripe } from "./UpfrontPaymentStripe";
import { BillingSupportNumber, StepperIndex } from "./types";
import {
  UpfrontPaymentType,
  GetUpfrontPayment,
  UpfrontPaymentPlan,
  getCentsToDollars,
  GetPreviewUpfrontPaymentPlan,
  GetSavedCardsUpfront,
  showUpfrontPayment,
} from "./Services/UpfrontPaymentService";

import { Accordions } from "../shared/Accordion/Accordions";

import { getAllRequisitions, getUserAuth } from "../../recoil/selectors";
import { BlankHeader } from "../Header/BlankHeader";
import { UpfrontPaymentPlanBillingSummary } from "./UpfrontPaymentPlanBillingSummary";
import { patientState } from "../../recoil/atoms";
import {
  BILLING_MULTIPLE_URL,
  LANDING_PAGE_URL,
} from "../../utils/urlConstants";
import { upfrontPaymentAccordionData } from "./UpfrontPaymentFAQ";
import { ChevronLeft } from "../../assets/iconComponents/ChevronLeft";
import { BabyPeekPaymentPlanSteps } from "../BabyPeek/BabyPeekPaymentPlanSteps";

import {
  BabyPeekPaymentDropdownGridPaymentWrapper,
  BabyPeekPaymentPlanGrid,
  BabyPeekStripeIconPointGridText,
  BabyPeekPaymentPlanScheduleWrapper,
  BabyPeekPaymentSummaryTitle,
} from "../BabyPeek/BabyPeekStripe.styles";
import LightDarkDescription from "../BabyPeek/Traits/LightOrDarkHair/LightDarkDescription.png";
import {
  GetBabyPeekPaymentPlan,
  SavedCardType,
} from "../BabyPeek/Services/BabyPeekService";
import { VideoFeedBackTitleText } from "../shared/Video/VideoEmbed.styles";

export const UpfrontPayment: React.FC = () => {
  const { deviceType } = useDeviceHook();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(StepperIndex.Main);
  const [paymentModalOpen, setPaymentModalOpen] = useState<boolean>(false);
  const [stripeModalOpen, setStripeModalOpen] = useState<boolean>(false);
  const [patientReqState, setPatientReqState] = useRecoilState(patientState);
  const auth = useRecoilValue(getUserAuth);
  const [upfrontBill, setUpfrontBill] = useState<UpfrontPaymentType>();
  const [paymentSubmitted, setPaymentSubmitted] = useState(false);
  const { billingPK, adminID } = useParams<{
    billingPK: string;
    adminID?: string;
  }>();
  const [paymentPlanSteps, setPaymentPlanSteps] =
    useState<UpfrontPaymentPlan[]>();
  const [paymentPlanPreviewSteps, setPaymentPlanPreviewSteps] =
    useState<UpfrontPaymentPlan[]>();
  const [savedPayments, setSavedPayments] = useState<SavedCardType[]>();
  const [useSavedPayment, setUsedSavePayment] = useState<string>();
  const [useNewCard, setUseNewCard] = useState(false);
  const requisitions = useRecoilValue(getAllRequisitions);
  const billingReqs = requisitions?.filter((req) =>
    showUpfrontPayment(req.portal_bill_status),
  );

  useEffect(() => {
    const getSavedCards = async () => {
      if (auth) {
        const savedCardsStepsResponse = await GetSavedCardsUpfront(
          billingPK,
          auth?.access_token,
        );
        if (savedCardsStepsResponse?.ok) {
          const savedCardsStepsResponseStepsJson =
            await savedCardsStepsResponse.json();
          setSavedPayments(savedCardsStepsResponseStepsJson.payment_methods);
        }
      }
    };

    getSavedCards();
  }, []);

  const setStripeAndRefresh = async (open: boolean) => {
    if (billingPK) {
      const upfrontResponse = await GetUpfrontPayment(billingPK, adminID);
      if (upfrontResponse?.ok) {
        const upfrontJson =
          (await upfrontResponse.json()) as UpfrontPaymentType;
        if (upfrontJson?.portal_bill_status === "reviewable") {
          history.push(LANDING_PAGE_URL);
        }
        setUpfrontBill(upfrontJson);
      }
    }
    setStripeModalOpen(open);
  };

  useEffect(() => {
    const getUpfrontBill = async () => {
      if (billingPK) {
        const upfrontResponse = await GetUpfrontPayment(billingPK, adminID);
        if (upfrontResponse?.ok) {
          const upfrontJson =
            (await upfrontResponse.json()) as UpfrontPaymentType;
          if (upfrontJson?.portal_bill_status === "reviewable") {
            history.push(LANDING_PAGE_URL);
          }
          if (
            patientReqState &&
            !patientReqState?.requisitions.filter(
              (req) => req.req_identifier === upfrontJson?.req_identifier,
            ).length
          ) {
            history.push(LANDING_PAGE_URL);
          }
          setUpfrontBill(upfrontJson);
          if (
            upfrontJson?.is_payment_plan_enabled ||
            upfrontJson?.portal_bill_status === "paid_in_full" ||
            upfrontJson?.portal_bill_status === "refunded"
          ) {
            setPaymentSubmitted(true);
          }
        } else {
          history.push(LANDING_PAGE_URL);
        }
      } else {
        history.push(LANDING_PAGE_URL);
      }
    };
    getUpfrontBill();
  }, []);

  useEffect(() => {
    if (
      upfrontBill?.portal_bill_status === "paid_in_full" ||
      upfrontBill?.portal_bill_status === "refunded" ||
      upfrontBill?.is_payment_plan_enabled
    ) {
      setPaymentSubmitted(true);
    }
  }, [upfrontBill]);

  useEffect(() => {
    const filteredSavedPayments = savedPayments?.filter(
      (saved) => saved.is_chargeable,
    );
    if (filteredSavedPayments?.length && !useNewCard && !useSavedPayment) {
      setUsedSavePayment(filteredSavedPayments[0].payment_method_id);
    } else if (filteredSavedPayments || !auth) {
      setUseNewCard(true);
    }
  }, [savedPayments]);

  useEffect(() => {
    const getPaymentPlanSteps = async () => {
      if (upfrontBill?.babypeek_pk) {
        const paymentPlanStepsResponse = await GetBabyPeekPaymentPlan(
          upfrontBill?.babypeek_pk,
          auth?.access_token,
        );
        const paymentPlanStepsJson = await paymentPlanStepsResponse.json();
        setPaymentPlanSteps(paymentPlanStepsJson);
      }
    };

    getPaymentPlanSteps();
  }, [upfrontBill]);

  useEffect(() => {
    const getUpfrontPaymentPlanDetails = async () => {
      if (upfrontBill?.portal_bill_status === "outstanding") {
        const paymentPlanResponse = await GetPreviewUpfrontPaymentPlan(
          billingPK,
          adminID,
        );
        const paymentPlanJson = await paymentPlanResponse.json();
        setPaymentPlanPreviewSteps(paymentPlanJson);
      }
    };

    getUpfrontPaymentPlanDetails();
  }, [upfrontBill]);

  useEffect(() => {
    if (
      upfrontBill?.portal_bill_status === "paid_in_full" ||
      upfrontBill?.portal_bill_status === "refunded" ||
      upfrontBill?.is_payment_plan_enabled
    ) {
      const billingReq = patientReqState?.requisitions.find(
        (req) => req.bill_pk === upfrontBill?.pk,
      );
      if (billingReq && patientReqState) {
        setPatientReqState({
          ...patientReqState,
          requisitions: patientReqState.requisitions.map((req) => {
            if (req.bill_pk === billingReq.bill_pk) {
              return {
                ...req,
                portal_bill_status:
                  upfrontBill?.portal_bill_status ?? "paid_in_full",
              };
            }
            return req;
          }),
        });
      }
    }
  }, [upfrontBill]);

  const stripeUpfrontPromise =
    import.meta.env.VITE_UPFRONT_STRIPE_PK &&
    loadStripe(import.meta.env.VITE_UPFRONT_STRIPE_PK);

  const getCharge = () => {
    if (
      StepperIndex.PaymentPlan === activeStep &&
      paymentPlanPreviewSteps?.length
    ) {
      return paymentPlanPreviewSteps[0].amount;
    }
    return upfrontBill?.total_patient_responsibility_amount || 10000;
  };

  const options = React.useMemo(
    () => ({
      mode: "payment" as "payment" | "setup" | "subscription" | undefined,
      paymentMethodCreation: "manual" as "manual" | undefined,
      currency: "usd",
      amount: getCharge(),
      appearance: {
        /* ... */
      },
    }),
    [upfrontBill, paymentPlanPreviewSteps, activeStep],
  );

  function getStepContent(step: number) {
    window.scrollTo(0, 0);
    switch (step) {
      case StepperIndex.Main:
        return (
          <>
            {upfrontBill && (
              <UpfrontPaymentMain
                setStep={setActiveStep}
                setPaymentOpen={setPaymentModalOpen}
                setStripeOpen={setStripeAndRefresh}
                paymentSubmitted={paymentSubmitted}
                upfrontBill={upfrontBill}
                setUpfrontBill={setUpfrontBill}
                setHomeScreen={() => setActiveStep(StepperIndex.Main)}
                savedPayments={savedPayments}
                setUseNewCard={setUseNewCard}
                setUsedSavePayment={setUsedSavePayment}
                useNewCard={useNewCard}
                useSavedPayment={useSavedPayment}
                setSavedPayments={setSavedPayments}
              />
            )}
          </>
        );
      case StepperIndex.Receipt:
        return (
          <>
            {upfrontBill && (
              <>
                <UpfrontPaymentReceipt
                  isPaymentPlan={
                    StepperIndex.PaymentPlan === activeStep ||
                    upfrontBill.is_payment_plan_enabled
                  }
                  paymentAmount={
                    upfrontBill.start_of_payment_amount ||
                    upfrontBill.total_patient_responsibility_amount
                  }
                  setActiveStep={setActiveStep}
                />
                <UpfrontPaymentReceiptView />{" "}
              </>
            )}
          </>
        );
      case StepperIndex.ItemizedBill:
        return (
          <>
            {upfrontBill && (
              <UpfrontPaymentBillingSummary
                setPaymentOpen={setPaymentModalOpen}
                setStripeOpen={setStripeAndRefresh}
                upfrontBill={upfrontBill}
                setStep={setActiveStep}
              />
            )}
          </>
        );
      case StepperIndex.Insurance:
        return (
          <>
            {" "}
            {upfrontBill && (
              <UpfrontPaymentInsurance
                setUpfrontBill={setUpfrontBill}
                upfrontBill={upfrontBill}
                setHomeScreen={() => setActiveStep(StepperIndex.Main)}
              />
            )}
          </>
        );
      case StepperIndex.PaymentPlan:
        return (
          <>
            {upfrontBill && (
              <UpfrontPaymentPlanBillingSummary
                setPaymentOpen={setPaymentModalOpen}
                setStripeOpen={setStripeAndRefresh}
                setStep={setActiveStep}
                upfrontBill={upfrontBill}
                savedPayments={savedPayments}
                setUseNewCard={setUseNewCard}
                setUsedSavePayment={setUsedSavePayment}
                useNewCard={useNewCard}
                useSavedPayment={useSavedPayment}
                setSavedPayments={setSavedPayments}
              />
            )}
          </>
        );
      default:
        return "unknown step";
    }
  }

  const multiReqSupport =
    activeStep === StepperIndex.Main &&
    billingReqs &&
    billingReqs.length &&
    billingReqs.length > 1;

  return (
    <>
      {upfrontBill && stripeUpfrontPromise ? (
        <Elements stripe={stripeUpfrontPromise} options={options}>
          {!auth?.access_token && (
            <BlankHeader link="https://unityscreen.com/" />
          )}
          <UpfrontPaymentPageWrapper
            deviceType={deviceType}
            style={{ pointerEvents: stripeModalOpen ? "none" : undefined }}
          >
            <UpfrontPaymentComponentContentWrapper>
              <FinancialAssistanceModal
                financialAssistanceModalOpen={paymentModalOpen}
                setFinancialAssistanceModalOpen={setPaymentModalOpen}
                setUpfrontBill={setUpfrontBill}
                setStripeModalOpen={setStripeModalOpen}
              />
              {upfrontBill && (
                <UpfrontPaymentStripe
                  setUpfrontBill={setUpfrontBill}
                  setStripeModalOpen={setStripeModalOpen}
                  stripeModalOpen={stripeModalOpen}
                  clientSecret={upfrontBill?.client_secret}
                  paymentAmount={
                    upfrontBill.start_of_payment_amount ||
                    upfrontBill.total_patient_responsibility_amount
                  }
                  setPaymentSubmitted={setPaymentSubmitted}
                  paymentSubmitted={paymentSubmitted}
                  currentStep={activeStep}
                  setActiveStep={setActiveStep}
                  useSavedPayment={useSavedPayment}
                  upfrontPayment={upfrontBill}
                  useNewCard={useNewCard}
                />
              )}
              <UpfrontPaymentTitle
                pointer={activeStep !== StepperIndex.Main}
                onClick={() => {
                  if (multiReqSupport) {
                    return history.push(BILLING_MULTIPLE_URL);
                  }

                  return activeStep === StepperIndex.Main
                    ? undefined
                    : setActiveStep(StepperIndex.Main);
                }}
              >
                {(activeStep !== StepperIndex.Main || multiReqSupport) && (
                  <ChevronLeft />
                )}{" "}
                {multiReqSupport ? `View all bills` : `Billing`}
              </UpfrontPaymentTitle>
              {getStepContent(activeStep)}
              {paymentPlanSteps && paymentPlanSteps.length > 0 && (
                <BabyPeekPaymentDropdownGridPaymentWrapper
                  deviceType={deviceType}
                  style={{
                    marginTop: "24px",
                    width: deviceType !== "mobile" ? "532px" : "auto",
                    justifySelf: deviceType !== "mobile" ? "center" : "",
                  }}
                >
                  <BabyPeekPaymentPlanGrid>
                    <img
                      src={LightDarkDescription}
                      style={{ maxWidth: "200px" }}
                      alt="LightDarkDescription"
                    />
                    <BabyPeekPaymentSummaryTitle>
                      BabyPeek
                    </BabyPeekPaymentSummaryTitle>

                    <BabyPeekPaymentPlanScheduleWrapper>
                      <BabyPeekStripeIconPointGridText
                        style={{ fontWeight: 600 }}
                      >
                        Payment Schedule
                      </BabyPeekStripeIconPointGridText>
                      {paymentPlanSteps && (
                        <BabyPeekPaymentPlanSteps steps={paymentPlanSteps} />
                      )}
                      <UpfrontPaymentSolidLine />
                      <UpfrontPaymentInfoBillContent
                        style={{ marginTop: "10px" }}
                      >
                        <UpfrontPaymentMainText>
                          No interest
                        </UpfrontPaymentMainText>
                        <UpfrontPaymentMainText>
                          {`Total: ${getCentsToDollars(
                            paymentPlanSteps?.reduce(
                              (acc, curr) => acc + curr.amount,
                              0,
                            ),
                          )}`}
                        </UpfrontPaymentMainText>
                      </UpfrontPaymentInfoBillContent>
                    </BabyPeekPaymentPlanScheduleWrapper>
                  </BabyPeekPaymentPlanGrid>
                </BabyPeekPaymentDropdownGridPaymentWrapper>
              )}
            </UpfrontPaymentComponentContentWrapper>

            {/* <UpfrontPaymentHelpWrapper>
              <VideoFeedBackTitleText>
                Billing and Insurance Process Overview
              </VideoFeedBackTitleText>

              <BillingVideoEmbed
                reqID={upfrontBill.req_identifier}
                videoLocation="billing_page"
              />
            </UpfrontPaymentHelpWrapper> */}
            <UpfrontPaymentHelpWrapper>
              <VideoFeedBackTitleText>Have a question?</VideoFeedBackTitleText>
              <UpfrontPaymentHelpGeneralContainer
                padding="0px"
                paddingBottom="10px"
              >
                <Accordions
                  data={upfrontPaymentAccordionData}
                  isLinked={false}
                  size="large"
                />
              </UpfrontPaymentHelpGeneralContainer>
            </UpfrontPaymentHelpWrapper>
            <UpfrontPaymentAnswerWrapper deviceType={deviceType}>
              {GetTypographyContent({
                content:
                  "If you have any billing questions, please contact us at ",
                size: "medium",
              })}
              {GetTypographyContent({
                content: BillingSupportNumber,
                size: "medium",
                isPhone: true,
                textColor: "greenDark",
              })}
              {GetTypographyContent({
                content: " or email ",
                size: "medium",
              })}
              {GetTypographyContent({
                content: "billingsupport@billiontoone.com",
                size: "medium",
                isEmail: true,
                textColor: "greenDark",
              })}
            </UpfrontPaymentAnswerWrapper>
          </UpfrontPaymentPageWrapper>
        </Elements>
      ) : (
        <></>
      )}
    </>
  );
};
