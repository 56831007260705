import React from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getUserAuth } from "../../recoil/selectors";
import {
  CreateAccountDesktopContainer,
  CreateAccountFormDesktopColorbackground,
  CreateAccountFormImage,
  CreateAccountFormVector,
  CreateAccountMobileContainer,
} from "../CreateAccount/CreateAccountForm.styles";
import unityRound from "../../assets/logos/unityRound.svg";
import vector from "../../assets/icons/vector.svg";
import { VerifyEmailSendAgainSection } from "./VerifyEmailSectionSendAgainSection";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { LOGIN_PAGE_URL } from "../../utils/urlConstants";
import Loading from "../shared/Loading/Loading";

export const VerifyEmailSendAgain = (): React.ReactElement => {
  const { isDesktop } = useDeviceHook();
  const history = useHistory();
  const auth = useRecoilValue(getUserAuth);

  if (!auth) {
    history.push(LOGIN_PAGE_URL);
    return <Loading />;
  }

  return (
    <div data-testid="verify-email-send-again">
      {isDesktop && (
        <CreateAccountDesktopContainer>
          <CreateAccountFormDesktopColorbackground>
            <CreateAccountFormVector src={vector} />
            <CreateAccountFormImage src={unityRound} width="321px" />
          </CreateAccountFormDesktopColorbackground>
          <VerifyEmailSendAgainSection />
        </CreateAccountDesktopContainer>
      )}
      {!isDesktop && (
        <CreateAccountMobileContainer>
          <VerifyEmailSendAgainSection />
        </CreateAccountMobileContainer>
      )}
    </div>
  );
};
