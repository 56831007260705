import * as React from "react";

export const InfoIcon = (): React.ReactElement => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="info-icon"
  >
    <path
      d="M9.42076 5.06667C9.34609 5.51467 9.05809 5.77067 8.41809 5.77067C7.77809 5.77067 7.54343 5.49333 7.54343 5.12C7.54343 5.04533 7.55409 4.94933 7.59676 4.69333C7.67143 4.24533 7.95942 3.98933 8.59943 3.98933C9.23943 3.98933 9.47409 4.26667 9.47409 4.64C9.47409 4.71467 9.46342 4.81067 9.42076 5.06667Z"
      fill="#737478"
    />
    <path
      d="M10.0288 10.9013C9.31409 11.712 8.71676 12.128 7.98076 12.128C7.10609 12.128 6.65809 11.6267 6.65809 10.848C6.65809 10.6133 6.67943 10.432 6.76476 9.99467L7.19143 7.712H5.52743L5.73009 6.496H8.97276L8.15143 10.8693L8.33276 10.9227L9.82609 9.39733L10.6794 10.1547L10.0288 10.9013Z"
      fill="#737478"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14.6667 8C14.6667 11.6819 11.6819 14.6667 8 14.6667C4.3181 14.6667 1.33333 11.6819 1.33333 8C1.33333 4.3181 4.3181 1.33333 8 1.33333C11.6819 1.33333 14.6667 4.3181 14.6667 8Z"
      fill="#737478"
    />
  </svg>
);
