import React from "react";
import ReactPlayer from "react-player/vimeo";
import { useRecoilValue } from "recoil";
import billingVideo from "../../../assets/images/billingInsuranceVideo.png";
import { useDeviceHook } from "../../../utils/hooks/useDeviceHook";
import { getUserAuth } from "../../../recoil/selectors";
import { SendActionType } from "../Services/SharedServices";
import VideoFeedbackWidget from "./VideoFeedback";

const BillingVideoEmbed: React.FC<{
  videoLocation:
    | "billing_page"
    | "billing-faq-section"
    | "dashboard-faq-section";
  reqID: string;
}> = ({ videoLocation, reqID }) => {
  const [showFeedback, setShowFeedback] = React.useState(false);
  const auth = useRecoilValue(getUserAuth);
  const { deviceType } = useDeviceHook();

  return (
    <>
      <ReactPlayer
        playing
        url="https://player.vimeo.com/video/960669684?playsinline=0"
        id="billing-video-player"
        light={billingVideo}
        height={`${deviceType === "mobile" ? "328px" : "295px"}`}
        width={`${deviceType === "mobile" ? "100%" : "528px"}`}
        style={{ justifySelf: "center", marginBottom: "20px" }}
        controls
        vimeoConfig={{ iframeParams: { fullscreen: 1 } }}
        data-testid="billing-video-player"
        data-vimeo-playsinline
        data-vimeo-autoplay
        playsinline={false}
        onPlay={async () => {
          await SendActionType(
            "BillingVideoClicked",
            reqID,
            auth?.access_token,
            {
              videoLocation,
            },
          );
          setShowFeedback(true);
        }}
      />
      {showFeedback && (
        <VideoFeedbackWidget
          videoType="billing"
          showFeedback={showFeedback}
          setShowFeedback={setShowFeedback}
          reqID={reqID}
          videoLocation={videoLocation}
        />
      )}
    </>
  );
};

export default BillingVideoEmbed;
