import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import {
  VideoPageWrapper,
  VideoTitle,
  VideoDescription,
  VideoPageBackTo,
} from "./VideoPage.styles";
import { BackIcon } from "../../assets/iconComponents/BackIcon";
import { GetTypographyContent } from "../../components/shared/Typography/Typography";
import {
  ANEUPLOIDY_PAGE_URL,
  CARRIER_PAGE_URL,
  LANDING_PAGE_URL,
} from "../../utils/urlConstants";
import carrierNegativeImage from "../../assets/images/carrierNegative.png";
import lowRiskAneuploidyVideo from "../../assets/images/lowRiskAneuploidyVideo.png";
import highRisk22qVideo from "../../assets/images/highRisk22qVideo.png";
import lowRisk22qVideo from "../../assets/images/lowRisk22qVideo.png";
import fetalAntigenNiptDetected from "../../assets/images/fetalAntigenNiptDetected.png";
import fetalAntigenNiptNotDetected from "../../assets/images/fetalAntigenNiptNotDetected.png";
import aneuploidyMonosomyX from "../../assets/images/aneuploidyMonosomyX.png";
import rhdDetectedVideo from "../../assets/images/rhdDetectedVideo.png";
import rhdNotDetectedVideo from "../../assets/images/rhdNotDetectedVideo.png";
import aneuploidyZygosity from "../../assets/images/aneuploidyZygosity.png";
import carrierFetalRisk from "../../assets/images/carrierFetalRisk.png";
import unityAneuploidyInfoVideo from "../../assets/images/unityAneuploidyInfoVideo.png";
import billingInsuranceVideo from "../../assets/images/billingInsuranceVideo.png";
import fetalRhdVideo from "../../assets/images/fetalRhdVideo.png";
import VideoLibraryCard from "./VideoLibraryCard";
import { getUserAuth } from "../../recoil/selectors";
import { TrackAnalyticsEvent } from "../../services/Analytics";

const VideoPage = (): React.ReactElement => {
  const { deviceType } = useDeviceHook();
  const auth = useRecoilValue(getUserAuth);
  const history = useHistory();
  const location = useLocation();
  const from = location.state?.from;

  useEffect(() => {
    if (auth?.access_token) {
      TrackAnalyticsEvent(
        "VideoLibraryPageView",
        auth?.access_token,
        auth?.user_id,
      );
    }
  }, []);

  const getFromText = (): string => {
    if (!from) return "";
    if (from.startsWith(LANDING_PAGE_URL)) return "to tests";
    if (from.startsWith(ANEUPLOIDY_PAGE_URL))
      return "to Aneuploidy Screen results";
    if (from.startsWith(CARRIER_PAGE_URL)) return "to Carrier Screen results";
    return "";
  };

  type Video = {
    name: string;
    url: string;
    image: string;
  };

  const videoList: Video[] = [
    {
      name: "CarrierNegative",
      url: "https://player.vimeo.com/video/1057943467?playsinline=0",
      image: carrierNegativeImage,
    },
    {
      name: "LowRiskAneuploidy",
      url: "https://player.vimeo.com/video/1035771695?playsinline=0",
      image: lowRiskAneuploidyVideo,
    },
    {
      name: "HighRisk22Q",
      url: "https://player.vimeo.com/video/1035771784?playsinline=0",
      image: highRisk22qVideo,
    },
    {
      name: "LowRisk22Q",
      url: "https://player.vimeo.com/video/1035771746?playsinline=0",
      image: lowRisk22qVideo,
    },
    {
      name: "FetalAntigenNIPTDetected",
      url: "https://player.vimeo.com/video/1035771652?playsinline=0",
      image: fetalAntigenNiptDetected,
    },
    {
      name: "FetalAntigenNIPTNotDetected",
      url: "https://player.vimeo.com/video/1035771669?playsinline=0",
      image: fetalAntigenNiptNotDetected,
    },
    {
      name: "AneuploidyMonosomyX",
      url: "https://player.vimeo.com/video/1035771767?playsinline=0",
      image: aneuploidyMonosomyX,
    },
    {
      name: "FetalRhdDetected",
      url: "https://player.vimeo.com/video/1035771802?playsinline=0",
      image: rhdDetectedVideo,
    },
    {
      name: "FetalRhdNotDetected",
      url: "https://player.vimeo.com/video/1035771730?playsinline=0",
      image: rhdNotDetectedVideo,
    },
    {
      name: "AneuploidyZygosity",
      url: "https://player.vimeo.com/video/1035771715?playsinline=0",
      image: aneuploidyZygosity,
    },
    {
      name: "CarrierFetalRisk",
      url: "https://player.vimeo.com/video/960668319?playsinline=0",
      image: carrierFetalRisk,
    },
    {
      name: "AnueploidyInfo",
      url: "https://player.vimeo.com/video/960668298?playsinline=0",
      image: unityAneuploidyInfoVideo,
    },
    {
      name: "BillingAndInsuranceProcess",
      url: "https://player.vimeo.com/video/1035775307?playsinline=0",
      image: billingInsuranceVideo,
    },
    {
      name: "FetalRhd",
      url: "https://player.vimeo.com/video/960668334?playsinline=0",
      image: fetalRhdVideo,
    },
  ];

  return (
    <VideoPageWrapper deviceType={deviceType}>
      <VideoPageBackTo onClick={() => history.push(from || LANDING_PAGE_URL)}>
        <BackIcon />
        {GetTypographyContent({
          content: `Back ${getFromText()}`,
          size: "medium",
          textColor: "redMedium",
        })}
      </VideoPageBackTo>
      <VideoTitle>Video Library</VideoTitle>
      <VideoDescription>
        These videos provide general explanations for possible UNITY results and
        common questions. Please refer to your report for details specific to
        your results.
      </VideoDescription>
      {videoList.map((video) => (
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "15px",
          }}
          data-testid={`video-library-card-${video.name}`}
        >
          <VideoLibraryCard
            key={video.name}
            name={video.name}
            url={video.url}
            image={video.image}
          />
        </div>
      ))}
    </VideoPageWrapper>
  );
};

export default VideoPage;
