import * as React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  UpfrontPaymentMultipleContainer,
  UpfrontPaymentFieldRow,
  UpfrontPaymentFieldRowTitle,
  UpfrontPaymentInfoRow,
  UpfrontPaymentMultipleContent,
  UpfrontPaymentFinancialAssistance,
  UpfrontPaymentAnswerWrapper,
  UpfrontPaymentMultipleGrid,
  UpfrontPaymentTitle,
  UpfrontPaymentTitleWrapper,
} from "./UpfrontPayment.styles";
import { GetTypographyContent } from "../shared/Typography/Typography";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { BillingSupportNumber } from "./types";
import {
  UpfrontPaymentType,
  GetUpfrontPayment,
  getCentsToDollars,
} from "./Services/UpfrontPaymentService";

import { getAllRequisitions } from "../../recoil/selectors";
import { BILLING_URL } from "../../utils/urlConstants";
import { getStatusDetails } from "./UpfrontPaymentMain";

export const UpfrontPaymentMultiple: React.FC = () => {
  const { deviceType } = useDeviceHook();
  const history = useHistory();
  const requisitions = useRecoilValue(getAllRequisitions);
  const [upfrontBills, setUpfrontBills] = useState<UpfrontPaymentType[]>([]);

  useEffect(() => {
    const getUpfrontBill = async () => {
      const allUpfrontBillsPromise = await Promise.all(
        requisitions?.map(async (req) => {
          const upfrontResponse = await GetUpfrontPayment(req.bill_pk);
          if (upfrontResponse?.ok) {
            const upfrontJson =
              (await upfrontResponse.json()) as UpfrontPaymentType;
            return upfrontJson;
          }
        }) || [],
      );
      const allUpfrontBills = allUpfrontBillsPromise.filter((bill) =>
        Boolean(bill),
      ) as UpfrontPaymentType[];
      setUpfrontBills(allUpfrontBills);
    };
    getUpfrontBill();
  }, []);

  return (
    <div style={{ display: "grid" }}>
      <UpfrontPaymentTitle
        style={{
          marginTop: deviceType === "mobile" ? "53px" : "47px",
          marginBottom: "0px",
          justifySelf: deviceType !== "mobile" ? "center" : "start",
          width: deviceType !== "mobile" ? "610px" : "325px",
        }}
      >
        <UpfrontPaymentTitleWrapper>
          {GetTypographyContent({
            content: "Your Bills",
            size: "h2",
            textColor: "greyMedium",
            isBold: true,
            padding: "0",
          })}
        </UpfrontPaymentTitleWrapper>
      </UpfrontPaymentTitle>
      {upfrontBills &&
        upfrontBills.map((upfrontBill) => {
          const isPastDue =
            upfrontBill.due_date && new Date(upfrontBill.due_date) < new Date();
          const isPaid =
            upfrontBill.portal_bill_status === "paid_in_full" ||
            upfrontBill.portal_bill_status === "refunded";
          return (
            <UpfrontPaymentMultipleContainer
              deviceType={deviceType}
              key={upfrontBill.pk}
            >
              <UpfrontPaymentMultipleGrid>
                <UpfrontPaymentMultipleContent>
                  {`Bill for Requisition ${upfrontBill?.req_identifier}`}{" "}
                </UpfrontPaymentMultipleContent>
              </UpfrontPaymentMultipleGrid>
              <>
                <UpfrontPaymentInfoRow adjustable>
                  <>
                    {upfrontBill.portal_bill_status !== "paid_in_full" &&
                      upfrontBill.portal_bill_status !== "refunded" && (
                        <UpfrontPaymentFieldRow>
                          <UpfrontPaymentFieldRowTitle>
                            Amount Owed{" "}
                          </UpfrontPaymentFieldRowTitle>
                          {GetTypographyContent({
                            content: getCentsToDollars(
                              upfrontBill?.total_patient_responsibility_amount,
                            ),
                            size: "medium",
                            textColor: "black",
                          })}
                        </UpfrontPaymentFieldRow>
                      )}
                    <UpfrontPaymentFieldRow>
                      <UpfrontPaymentFieldRowTitle>
                        Payment Status{" "}
                      </UpfrontPaymentFieldRowTitle>
                      {getStatusDetails(upfrontBill, false, Boolean(isPastDue))}
                    </UpfrontPaymentFieldRow>
                  </>
                </UpfrontPaymentInfoRow>
              </>
              <>
                <UpfrontPaymentInfoRow adjustable>
                  <>
                    <UpfrontPaymentFieldRow>
                      <UpfrontPaymentFieldRowTitle>
                        Date of Service{" "}
                      </UpfrontPaymentFieldRowTitle>

                      {GetTypographyContent({
                        content: upfrontBill?.date_of_service,
                        size: "medium",
                        textColor: "black",
                      })}
                    </UpfrontPaymentFieldRow>
                  </>
                </UpfrontPaymentInfoRow>
              </>
              <UpfrontPaymentFinancialAssistance
                onClick={() => {
                  history.push(`${BILLING_URL}/${upfrontBill?.pk}`);
                }}
              >
                View Details
              </UpfrontPaymentFinancialAssistance>
            </UpfrontPaymentMultipleContainer>
          );
        })}
      <UpfrontPaymentAnswerWrapper
        deviceType={deviceType}
        style={{ justifySelf: "center" }}
      >
        {GetTypographyContent({
          content: "If you have any billing questions, please contact us at ",
          size: "medium",
        })}
        {GetTypographyContent({
          content: BillingSupportNumber,
          size: "medium",
          isPhone: true,
          textColor: "greenDark",
        })}
        {GetTypographyContent({
          content: " or email ",
          size: "medium",
        })}
        {GetTypographyContent({
          content: "billingsupport@billiontoone.com",
          size: "medium",
          isEmail: true,
          textColor: "greenDark",
        })}
      </UpfrontPaymentAnswerWrapper>
    </div>
  );
};
