import * as React from "react";

export const PaymentPendingCircle = (): React.ReactElement => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7" cy="7" r="6.5" fill="white" stroke="#B6B2B2" />
  </svg>
);
