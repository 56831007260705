import * as React from "react";
import { themePalette } from "../../theme";

export const ProcessingIcon = ({
  color = themePalette.greyLight,
  ...props
}: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 18.4219 5.57812 23.625 12 23.625C18.4219 23.625 23.625 18.4219 23.625 12C23.625 5.57812 18.4219 0.375 12 0.375ZM12 21.375C6.82031 21.375 2.625 17.1797 2.625 12C2.625 6.82031 6.82031 2.625 12 2.625C17.1797 2.625 21.375 6.82031 21.375 12C21.375 17.1797 17.1797 21.375 12 21.375ZM14.8969 16.4813L10.9172 13.5891C10.7719 13.4812 10.6875 13.3125 10.6875 13.1344V5.4375C10.6875 5.12813 10.9406 4.875 11.25 4.875H12.75C13.0594 4.875 13.3125 5.12813 13.3125 5.4375V12.0797L16.4437 14.3578C16.6969 14.5406 16.7484 14.8922 16.5656 15.1453L15.6844 16.3594C15.5016 16.6078 15.15 16.6641 14.8969 16.4813Z"
      fill={color}
    />
  </svg>
);
