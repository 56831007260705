import React from "react";

import styled from "styled-components";
import {
  StyledInputWrapper,
  StyledInputInputTitle,
  StyledInputOptional,
  StyledSelectComponent,
  StyledInputRowWrapper,
} from "./UI-Library.styles";

interface StyledSelectProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  fieldTitle: string;
  error?: string;
  optional?: boolean;
  minWidth?: string;
}

export const StyledUISelect: React.FC<StyledSelectProps> = ({
  fieldTitle,
  optional,
  error,
  minWidth,
  ref,
  ...props
}) => (
  <StyledInputRowWrapper style={{ minWidth: minWidth || "auto" }}>
    <StyledInputWrapper>
      <StyledInputInputTitle>
        {`${fieldTitle} `}{" "}
        {optional && <StyledInputOptional>(optional)</StyledInputOptional>}
      </StyledInputInputTitle>
      <StyledSelectComponent
        {...props}
        error={error}
        style={{ width: "100%", fontSize: "16px" }}
      />
    </StyledInputWrapper>
  </StyledInputRowWrapper>
);

export const RequiredAsterisk = styled.span`
  color: red;
  margin-left: 0.25rem;
  font-size: 1rem;
  vertical-align: top;
`;

export const StyledUISelectRequired: React.FC<StyledSelectProps> = ({
  fieldTitle,
  error,
  ref,
  ...props
}) => (
  <StyledInputRowWrapper>
    <StyledInputWrapper>
      <StyledInputInputTitle>
        {`${fieldTitle} `} <RequiredAsterisk>*</RequiredAsterisk>
      </StyledInputInputTitle>
      <StyledSelectComponent
        {...props}
        error={error}
        style={{ width: "100%", fontSize: "16px" }}
      />
    </StyledInputWrapper>
  </StyledInputRowWrapper>
);
