import React from "react";

import { useWindowWidth } from "@react-hook/window-size";
import { faArrowUpFromBracket } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BabyPeekTraitWrapper,
  BabyPeekV2HeaderTitleWrapper,
  BabyPeekV2HeaderTitleTop,
  BabyPeekV2HeaderTitleBottom,
  BabyPeekV2HeaderIconGrid,
  BabyPeekV2SectionText,
  BabyPeekV2SectionTitle,
  BabyPeekV2Section,
  BabyPeekV2SectionTextLink,
  BabyPeekV2HeaderIconShare,
  BabyPeekV2HeaderIconShareTitle,
} from "../Shared/BabyPeekTrait.styles";
import LightOrDarkHairBackground from "./LightHairDarkHairBackground.png";
import LightDarkIcon from "./LightDarkIcon.png";

import {
  BabyPeekTraitResponse,
  Trait,
  convertPercentage,
} from "../../Services/BabyPeekService";
import { useDeviceHook } from "../../../../utils/hooks/useDeviceHook";
import { TraitGradientMobile } from "../Shared/TraitGradientMobile";
import { TraitNavigationLeft } from "../Shared/TraitNavigationLeft";
import { TraitNavigationRight } from "../Shared/TraitNavigationRight";
import {
  BabyPeekMobileButtonSticky,
  BabyPeekTraitNavigationWrapper,
  BabyPeekTraitNavigationButtonWrapper,
  BabyPeekDesktopButtonSticky,
} from "../../BabyPeekStripe.styles";
import { BabyPeekButton } from "../../BabyPeekButton";
import { TraitMenu } from "../Shared/TraitMenu";
import { BabyPeekTraitAccordion } from "../Shared/BabyPeekTraitAccordion";
import { LightOrDarkHairGene } from "./LightOrDarkHairGene";
import { LightOrDarkHairHowItWorks } from "./LightOrDarkHairHowItWorks";
import { LightOrDarkHairOtherFactors } from "./LightOrDarkHairOtherFactors";
import { ShareUnderline } from "../../BabyPeekIcons/ShareUnderline";
import blackLineGif from "../../AnimatedGifs/line-black.gif";
import blondeLineGif from "../../AnimatedGifs/line-blonde.gif";
import brownLineGif from "../../AnimatedGifs/line-brown.gif";
import darkBlondeGif from "../../AnimatedGifs/line-dark-blonde.gif";
import darkBrownGif from "../../AnimatedGifs/line-dark-brown.gif";
import { BabyPeekSingleFeedback } from "../../BabyPeekSingleFeedback";

const HowItWorks = (setCurrentTrait?: (trait: Trait | undefined) => void) => (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>
      How does hair get its color?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      A person’s hair color is determined by a naturally occurring pigment
      called melanin. There are two types of melanin: <span>eumelanin</span> and{" "}
      <span>pheomelanin</span>.
      <br />
      Eumelanin is dark in color and pheomelanin is reddish-yellow. The more
      eumelanin, the darker the hair. Pheomelanin impacts how red hair
      appears—see the{" "}
      <BabyPeekV2SectionTextLink
        onClick={() => setCurrentTrait && setCurrentTrait("RedHair")}
      >
        Red Hair trait
      </BabyPeekV2SectionTextLink>{" "}
      for more!
      <br />
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        {" "}
        <LightOrDarkHairHowItWorks />{" "}
      </div>
      <br />
    </BabyPeekV2SectionText>
    <br />
    <BabyPeekV2SectionTitle>
      {`How did we determine your baby's hair color? `}
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      We looked at 20+ genetic variations that determine how much eumelanin the
      body produces, which affects what shade the hair will be on a scale from
      blonde (low eumelanin) to black (high eumelanin).
      <br />
      <BabyPeekV2SectionTitle>The genes we peeked at</BabyPeekV2SectionTitle>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {" "}
        <LightOrDarkHairGene />{" "}
      </div>
      <br />
    </BabyPeekV2SectionText>
  </BabyPeekV2Section>
);
const DidYouKnow = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>
      Why does hair often turn grey or white with age?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      Just as we grow tired with age, so do the cells in our scalp! Melanocytes
      often produce less melanin over time, and may even start to disappear. If
      hair has no melanin at all, then it has no color at all—and appears bright
      white! Grey hair is the result of some melanin still being produced, but
      less than before.
      <br />
    </BabyPeekV2SectionText>
    <br />
    <BabyPeekV2SectionTitle>
      Why do different hair colors exist?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      The very first humans likely had dark hair. Lighter hair and red hair
      evolved later as people migrated to places with cooler temperatures and
      less sunlight. Sunlight helps people produce vitamin D—early humans with
      lighter hair absorbed more of the limited sunlight, helping them make more
      vitamin D.
      <br />
      As humans migrated all over the world, the genetic variations that led to
      light and dark hair mixed—that’s why there are hundreds of different
      variations that affect hair color today!
      <br />
    </BabyPeekV2SectionText>
  </BabyPeekV2Section>
);

const OtherFactors = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>
      What else can affect hair color?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      A person’s traits are often determined by a combination of genetic and
      environmental factors. While many of the genetic variations that influence
      these traits are well-studied, there are some genetic factors that are
      still unknown to scientists.
      <br />
      Other factors that can affect hair color include:
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}
      >
        {" "}
        <LightOrDarkHairOtherFactors />{" "}
      </div>
      <br />
    </BabyPeekV2SectionText>
    <br />
  </BabyPeekV2Section>
);

const AboutBabyPeek = (
  <BabyPeekV2SectionText>
    BabyPeek results are:
    <ul>
      <li>
        A <span style={{ fontWeight: 700 }}>predictor</span> of what traits your
        baby will have. These results do not mean that your baby will have these
        traits with absolute certainty and a baby may have different traits than
        what is predicted.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT medical test results.</span> These
        results should not be used to make medical decisions about your
        pregnancy. The genetic changes mentioned in these results do not affect
        a baby’s health.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT paternity testing.</span> These
        results should not be used to determine paternity. Given the complex
        genetics of these traits, a baby may be born with different traits from
        the biological mother or father.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>
          NOT managed by your healthcare provider.
        </span>{" "}
        These results are unlocked by the patient directly. Please do not
        contact your healthcare provider regarding your BabyPeek results.
      </li>
    </ul>
    If you have questions about BabyPeek results, please contact BillionToOne
    directly at
    <a href={`tel:${"(650) 460-2551"}`} target="_blank" rel="noreferrer">
      {` (650) 460-2551`}
    </a>
    .
    <br />
  </BabyPeekV2SectionText>
);

export const LightOrDarkHairTrait: React.FC<{
  setCurrentTrait?: (trait: Trait | undefined) => void;
  traitData: BabyPeekTraitResponse;
  setShareModal?: (share: boolean) => void;
  publicTrait?: boolean;
  hideTraitMenu: boolean;
  modalPreview?: boolean;
  showAnimation?: boolean;
}> = ({
  setCurrentTrait,
  traitData,
  setShareModal,
  hideTraitMenu,
  publicTrait,
  modalPreview = false,
  showAnimation = false,
}) => {
  const { deviceType } = useDeviceHook();
  const windowWidth = useWindowWidth();
  const [showTraitMenu, setShowTraitMenu] = React.useState(false);
  const StickyWrapper =
    deviceType !== "desktop"
      ? BabyPeekMobileButtonSticky
      : BabyPeekDesktopButtonSticky;
  const colorTraits = [
    {
      color: "#7C4504",
      gradient: "#98663C",
      name: "Dark brown",
      percentage: convertPercentage(traitData.dark_brown_hair_prob),
      gif: darkBrownGif,
    },
    {
      color: "black",
      gradient: "#1D232C",
      name: "Black",
      percentage: convertPercentage(traitData.black_hair_prob),
      gif: blackLineGif,
    },
    {
      color: "#B76709",
      gradient: "#DA8724",
      name: "Brown",
      percentage: convertPercentage(traitData.brown_hair_prob),
      gif: brownLineGif,
    },
    {
      color: "#FA9600",
      gradient: "#F9D35D",
      name: "Dark blonde",
      percentage: convertPercentage(traitData.dark_blonde_hair_prob),
      textColor: "black",
      gif: darkBlondeGif,
    },
    {
      color: "#FFC555",
      gradient: "linear-gradient(90deg, #FFFADE 0%, #FFC93D 100%)",
      name: "Blonde",
      percentage: convertPercentage(traitData.blonde_hair_prob),
      textColor: "black",
      gif: blondeLineGif,
    },
  ].sort((a, b) => b.percentage - a.percentage);

  const checkOverHundred = (colorTraitsArray: typeof colorTraits) => {
    if (
      colorTraitsArray[0].percentage +
        colorTraitsArray[1].percentage +
        colorTraitsArray[2].percentage +
        colorTraitsArray[3].percentage +
        colorTraitsArray[4].percentage >
      100
    ) {
      const newColorTraitsArray = [...colorTraitsArray];
      newColorTraitsArray[4] = {
        ...colorTraitsArray[4],
        percentage: Math.max(
          100 -
            (colorTraitsArray[0].percentage +
              colorTraitsArray[1].percentage +
              colorTraitsArray[2].percentage +
              colorTraitsArray[3].percentage),
          0,
        ),
      };
      return newColorTraitsArray;
    }
    return colorTraitsArray;
  };

  return (
    <>
      {" "}
      <div
        style={{
          backgroundImage: `url(${LightOrDarkHairBackground})`,
          height: "260px",
          backgroundSize: "cover",
          padding: "20px",
          display: "grid",
          justifyContent: deviceType !== "mobile" ? "center" : "flex-start",
          paddingBottom: "32px",
          paddingTop: "24px",
          gridGap: "12px",
        }}
        className="trait-top-background"
      >
        <BabyPeekV2HeaderIconShare>
          <BabyPeekV2HeaderIconGrid>
            <img
              src={LightDarkIcon}
              alt="LightDarkIcon"
              style={{ justifySelf: "center" }}
            />
            <div>Light or Dark Hair</div>
          </BabyPeekV2HeaderIconGrid>
          {setShareModal && !modalPreview && (
            <BabyPeekV2HeaderIconShareTitle onClick={() => setShareModal(true)}>
              {" "}
              <FontAwesomeIcon icon={faArrowUpFromBracket} /> Share
              <ShareUnderline />
            </BabyPeekV2HeaderIconShareTitle>
          )}
        </BabyPeekV2HeaderIconShare>

        <BabyPeekV2HeaderTitleWrapper
          deviceType={deviceType}
          windowWidth={windowWidth}
        >
          <BabyPeekV2HeaderTitleTop>
            {publicTrait
              ? "The baby is most likely to have"
              : "Your baby is most likely to have"}
          </BabyPeekV2HeaderTitleTop>
          <BabyPeekV2HeaderTitleBottom>
            {`${colorTraits[0].name.toLowerCase()} hair`}
          </BabyPeekV2HeaderTitleBottom>
        </BabyPeekV2HeaderTitleWrapper>
      </div>
      <BabyPeekTraitWrapper deviceType={deviceType}>
        <TraitGradientMobile
          showGif={modalPreview || showAnimation}
          sortedTraitArray={checkOverHundred(colorTraits)}
        />
        {!modalPreview && (
          <>
            {" "}
            <hr />
            <div
              style={{
                maxWidth: `${windowWidth - 2}px`,
                padding: "25px",
                paddingTop: "0px",
              }}
            >
              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "How it works",
                    content: HowItWorks(setCurrentTrait),
                    key: "howItWorksLDH",
                    defaultExpanded: true,
                  },
                ]}
              />
              <br />
              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "Did you know?",
                    content: DidYouKnow,
                    key: "otherFactorsLDH",
                  },
                ]}
              />
              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "Other factors",
                    content: OtherFactors,
                    key: "otherFactorsLDH",
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "About BabyPeek",
                    content: AboutBabyPeek,
                    key: "aboutBabyPeekEye",
                  },
                ]}
              />
            </div>{" "}
          </>
        )}
        {!modalPreview && <BabyPeekSingleFeedback onlyFeedback />}
      </BabyPeekTraitWrapper>
      {setCurrentTrait && !modalPreview && (
        <StickyWrapper style={{ zIndex: 10 }}>
          {showTraitMenu && TraitMenu(setCurrentTrait, "LightOrDarkHair")}
          <BabyPeekTraitNavigationWrapper
            $width={windowWidth}
            deviceType={deviceType}
          >
            <BabyPeekTraitNavigationButtonWrapper
              onClick={() => setCurrentTrait("HairThickness")}
            >
              <TraitNavigationLeft />
            </BabyPeekTraitNavigationButtonWrapper>{" "}
            {!hideTraitMenu && (
              <BabyPeekButton
                variant="gray"
                content={<>{showTraitMenu ? " Hide Traits" : "View Traits"}</>}
                onClick={async () => {
                  setShowTraitMenu(!showTraitMenu);
                }}
              />
            )}
            <BabyPeekTraitNavigationButtonWrapper
              onClick={() => setCurrentTrait("RedHair")}
            >
              <TraitNavigationRight />
            </BabyPeekTraitNavigationButtonWrapper>
          </BabyPeekTraitNavigationWrapper>
        </StickyWrapper>
      )}
    </>
  );
};
