import React from "react";
import { ANEUPLOIDY_PAGE_URL } from "../../utils/urlConstants";
import { DisplayWithIcon } from "../shared/Typography/types";
import { GetTypographyContent } from "../shared/Typography/Typography";
import {
  TestResultIconAndTypography,
  TestResultsIcon,
} from "./TestResults.styles";

export const TestResultsFetalSexInfo = (
  displayWithIcon: DisplayWithIcon,
  requisitionID: string,
  useLink?: boolean,
): React.ReactElement => (
  <>
    {GetTypographyContent({
      content: "Fetal Sex",
      size: "large",
      textColor: "greyMedium",
    })}
    <TestResultIconAndTypography>
      <TestResultsIcon marginLeft={displayWithIcon.display === "See Report"}>
        {displayWithIcon.icon}
      </TestResultsIcon>
      <>
        {GetTypographyContent({
          content: displayWithIcon.display,
          size: "medium",
          textColor: "greyMedium",
          link: useLink
            ? `${ANEUPLOIDY_PAGE_URL}/${requisitionID}#fetal_sex_section`
            : "",
        })}
      </>
    </TestResultIconAndTypography>
  </>
);
