import React, { useEffect, useRef, useState } from "react";
import Dropzone, { IFileWithMeta, StatusValue } from "react-dropzone-uploader";
import { useParams } from "react-router-dom";
import { FileUploaderButton } from "./DocumentUploader.styles";
import { themePalette } from "../../theme";
import { SpinnerLoading } from "../shared/Spinner/SpinnerLoading";
import { SubmitOA100Document } from "./Services/UpfrontPaymentService";
import { toBase64 } from "../../utils/helpers";
import { BillingSupportNumber } from "./types";
import { GenericFileUploadInputContent } from "./DocumentUploader";

export const CheckEOBFileUploader: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<IFileWithMeta | null>(null);
  const [fileSubmitted, setFileSubmitted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { billingPK } = useParams<{ billingPK: string }>();
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
      setSelectedFile(null);
      setFileSubmitted(false);
      setLoading(false);
      setError(null);
    };
  }, []);

  const handleFileChange = (recentFile: IFileWithMeta, status: StatusValue) => {
    // Prevent state updates if component is unmounted
    // Sometimes react-dropzone-uploader will call the onChangeStatus function after
    // the component is unmounted
    if (!isMounted.current) return;

    setError(null);
    if (status === "done") {
      setSelectedFile(recentFile);
    } else if (status === "removed") {
      setSelectedFile(null);
    } else if (status === "error_file_size") {
      setError("File size is too large.  Please upload a file less than 2MB.");
    } else {
      setSelectedFile(null);
    }
  };

  const submitFile: () => void = async () => {
    if (selectedFile) {
      setError(null);
      setLoading(true);
      const base64File = await toBase64(selectedFile.file);
      const response = await SubmitOA100Document(base64File, billingPK);
      setLoading(false);
      const isError = response.error;
      if (isError) {
        setError(
          `There was an error submitting the file.  If you need assistance contact support at: ${BillingSupportNumber}`,
        );
        return;
      }
      setFileSubmitted(true);
    } else {
      setError("Select a file to upload prior to submitting.");
    }
  };

  return (
    <>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {fileSubmitted && (
        <p style={{ color: "green" }}>
          File submitted successfully. Your document will be reviewed within 72
          hours and your balance will be updated accordingly. A member of our
          team will contact you directly if additional information is needed.
        </p>
      )}
      {!fileSubmitted && (
        <>
          <Dropzone
            accept=".png, .jpg, .jpeg, .pdf"
            maxFiles={1}
            multiple={false}
            maxSizeBytes={2000000}
            onChangeStatus={handleFileChange}
            submitButtonDisabled
            styles={{
              dropzone: {
                border: `2px dotted ${themePalette.greyLight}`,
                overflow: "hidden",
                background: themePalette.neutral50,
              },
            }}
            inputContent={GenericFileUploadInputContent()}
            data-testid="dropzone"
          />
          <br />
          {loading && <SpinnerLoading />}
          {selectedFile && !loading && (
            <FileUploaderButton onClick={() => submitFile()}>
              Submit File
            </FileUploaderButton>
          )}
        </>
      )}
    </>
  );
};
