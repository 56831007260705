import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  LandingGeneralContainer,
  ProfileContactWrapper,
  ProfilePageComponentGrid,
  ProfilePageComponentWrapper,
  ProfilePageWrapper,
  ProfileWarningMobileTextWrapper,
  ProfileWarningMobileWrapper,
  ProfileWarningText,
  ProfileWarningWrapper,
} from "./Profile.styles";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { Profile } from "../../components/Profile/Profile";
import { ProfileInsurance } from "../../components/Profile/ProfileInsurance";
import { GetTypographyContent } from "../../components/shared/Typography/Typography";
import { ProfileResetEmail } from "../../components/Profile/ProfileResetEmail";
import { ProfileResetPassword } from "../../components/Profile/ProfileResetPassword";
import { getUserAuth } from "../../recoil/selectors";
import { patientProfileState } from "../../recoil/atoms";
import { GetPhiRequest } from "../../components/Profile/Services/UpdateProfileInfoService";
import { UserProfileAuthClientResponse } from "../../recoil/types";
import Loading from "../../components/shared/Loading/Loading";
import { ProfileInsuranceModal } from "../../components/Profile/ProfileInsuranceUploadModal";
import { WarningRounded } from "../../assets/iconComponents/WarningRounded";
import { Button } from "../../components/shared/Button/Button";
import {
  getMissingProfileInfoMessage,
  isAddressOrInsuranceRequired,
} from "../../components/Profile/helpers";
import { ErrorPopup } from "../../components/shared/ErrorPopup/ErrorPopup";
import { ProfileNotifications } from "../../components/Profile/ProfileNotifications";

const ProfilePage = (): React.ReactElement => {
  const [resetPassword, setResetPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState(false);
  const [infoSubmitted, setInfoSubmitted] = useState("");
  const { deviceType } = useDeviceHook();
  const [patientInfo, setPatientInfo] = useRecoilState(patientProfileState);
  const [insuranceModalOpen, setInsuranceModalOpen] = useState(
    isAddressOrInsuranceRequired(patientInfo),
  );
  const authInfo = useRecoilValue(getUserAuth);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (infoSubmitted) {
      timeout = setTimeout(() => setInfoSubmitted(""), 10000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [infoSubmitted]);

  useEffect(() => {
    const getProfileInfo = async () => {
      const response = await GetPhiRequest(authInfo?.access_token);
      if (response?.ok) {
        const profile =
          (await response.json()) as UserProfileAuthClientResponse;
        setPatientInfo(profile);
      }
    };
    if (!patientInfo && authInfo) {
      getProfileInfo();
    }
  }, [patientInfo, authInfo]);

  return (
    <ProfilePageWrapper>
      {!patientInfo && authInfo && <Loading />}
      {patientInfo && (
        <ProfilePageComponentWrapper deviceType={deviceType}>
          {infoSubmitted && (
            <ErrorPopup
              message="Info submmited successfully!"
              hideMessage={setInfoSubmitted}
            />
          )}
          {isAddressOrInsuranceRequired(patientInfo) &&
            (deviceType === "mobile" ? (
              <ProfileWarningMobileWrapper deviceType={deviceType}>
                <ProfileWarningMobileTextWrapper>
                  <WarningRounded />
                  <ProfileWarningText>
                    {getMissingProfileInfoMessage(
                      patientInfo?.phi.ins_status === "Required",
                      patientInfo?.phi.address_status === "Required",
                      patientInfo?.phi.prebilling_status === "Required",
                    )}
                  </ProfileWarningText>
                </ProfileWarningMobileTextWrapper>
                <Button
                  size="medium"
                  onClick={() => setInsuranceModalOpen(true)}
                  label="UPDATE"
                />
              </ProfileWarningMobileWrapper>
            ) : (
              <ProfileWarningWrapper deviceType={deviceType}>
                <WarningRounded />
                <ProfileWarningText>
                  {getMissingProfileInfoMessage(
                    patientInfo?.phi.ins_status === "Required",
                    patientInfo?.phi.address_status === "Required",
                    patientInfo?.phi.prebilling_status === "Required",
                  )}
                </ProfileWarningText>
                <Button
                  size="medium"
                  onClick={() => setInsuranceModalOpen(true)}
                  label="UPDATE"
                />
              </ProfileWarningWrapper>
            ))}
          <ProfilePageComponentGrid deviceType={deviceType}>
            {resetEmail && !resetPassword && (
              <ProfileResetEmail setResetEmail={setResetEmail} />
            )}
            {!resetEmail && resetPassword && (
              <ProfileResetPassword setResetPassword={setResetPassword} />
            )}
            {!resetEmail && !resetPassword && (
              <>
                <LandingGeneralContainer>
                  <Profile
                    setResetEmail={setResetEmail}
                    setResetPassword={setResetPassword}
                  />
                </LandingGeneralContainer>
                <div>
                  <ProfileInsuranceModal
                    profileModalInsuranceOpen={insuranceModalOpen}
                    setProfileModalInsuranceOpen={setInsuranceModalOpen}
                    setInfoSubmitted={setInfoSubmitted}
                  />
                  {patientInfo.phi.ins_status !== "None" && (
                    <LandingGeneralContainer>
                      <ProfileInsurance />
                    </LandingGeneralContainer>
                  )}
                </div>
              </>
            )}
            <ProfileContactWrapper>
              {GetTypographyContent({
                content:
                  "If any of your information is incorrect, please contact us at ",
                size: "medium",
              })}
              {GetTypographyContent({
                content: "+1(650) 460-2551",
                size: "medium",
                isPhone: true,
              })}
              {GetTypographyContent({
                content: " or email us at ",
                size: "medium",
                link: "./client/scheduling",
              })}
              {GetTypographyContent({
                content: "support@unityscreen.com",
                size: "medium",
                isEmail: true,
              })}
            </ProfileContactWrapper>
            {!resetEmail && !resetPassword && (
              <LandingGeneralContainer style={{ marginTop: "80px" }}>
                <ProfileNotifications />
              </LandingGeneralContainer>
            )}
          </ProfilePageComponentGrid>
        </ProfilePageComponentWrapper>
      )}
    </ProfilePageWrapper>
  );
};

export default ProfilePage;
