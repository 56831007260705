import React from "react";
import { ButtonProps as MuiButtonProps } from "@mui/material";
import { ButtonSize, StyledButton } from "./Button.styles";
import { ColorPalette } from "../../../theme";

interface ButtonProps extends Omit<MuiButtonProps, "children"> {
  label: string;
  onClick: () => void;
  size: ButtonSize;
  backgroundColor?: keyof ColorPalette;
  textColor?: keyof ColorPalette;
  reverse?: boolean;
}

export const Button = (props: ButtonProps): React.ReactElement => (
  <StyledButton
    {...props}
    disabled={props.disabled}
    size={props.size}
    onClick={props.onClick}
    $backgroundColor={props.backgroundColor}
    $textColor={props.textColor}
    $reverse={props.reverse}
  >
    {props.label}
  </StyledButton>
);
