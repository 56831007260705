import styled from "styled-components/macro";

export const ErrorPopupWrapper = styled.div<{
  deviceType: string;
  onTop?: boolean;
  isSticky?: boolean;
}>`
  ${({ onTop, isSticky }) => {
    if (isSticky) {
      return "position: sticky;";
    }
    if (onTop) {
      return "position: absolute;";
    }
    return "";
  }}
  left: 20px;
  top: 20px;
  width: inherit;
  right: 20px;
  background-color: ${({ theme }) => theme.greyDark};
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  border-radius: 4px;
  justify-content: space-between;
  flex-direction: ${({ deviceType }) =>
    deviceType === "mobile" ? "column" : "row"};
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ErrorPopupRetry = styled.div`
  display: flex;
  justify-content: flex-end;
`;
