import * as React from "react";

export const TasteCTA = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group">
      <path
        id="Vector"
        d="M16.073 13.9343C14.8935 13.9343 14.0316 13.7656 13.9414 13.7473C13.836 13.7258 13.7446 13.6617 13.6883 13.5702C13.5611 13.3623 10.6016 8.4363 13.2284 4.68692C15.8078 1.00507 22.7677 0.042488 23.0627 0.00323926C23.2625 -0.0224894 23.4488 0.107564 23.4916 0.30388C23.5556 0.594474 25.0272 7.46474 22.448 11.146C20.8141 13.478 18.0139 13.9345 16.073 13.9345L16.073 13.9343ZM14.2637 13.0176C15.1923 13.1633 19.7143 13.6978 21.8139 10.7018C23.881 7.75113 23.0672 2.27462 22.8048 0.827385C21.3552 1.07516 15.93 2.18025 13.8629 5.13098C11.7602 8.13261 13.809 12.1948 14.2642 13.0177L14.2637 13.0176Z"
        fill="black"
      />
      <path
        id="Vector_2"
        d="M6.10577 15.7438C5.35576 15.7438 4.57507 15.5778 3.83246 15.1293C1.09479 13.4756 0.0818598 8.61084 0.0402652 8.40468C0.000262215 8.20766 0.117806 8.01258 0.310946 7.95636C0.513077 7.89715 5.29057 6.53001 8.02805 8.18351C10.8364 9.87987 10.3269 13.9459 10.3038 14.1182C10.2897 14.2246 10.2317 14.3203 10.1443 14.3825C10.04 14.4564 8.19298 15.7441 6.10576 15.7441L6.10577 15.7438ZM0.882082 8.60683C1.16457 9.73588 2.18352 13.2287 4.23248 14.4662C6.31121 15.7214 8.91841 14.2439 9.55271 13.8445C9.61121 13.0969 9.70549 10.1013 7.62745 8.84588C5.57478 7.60563 2.01217 8.33078 0.882082 8.60675V8.60683Z"
        fill="black"
      />
      <path
        id="Vector_3"
        d="M11.5348 24C11.5279 24 11.5212 23.9998 11.5144 23.9994C11.301 23.9883 11.1369 23.8061 11.148 23.5927C11.464 17.5159 13.1222 12.9243 16.2178 9.55657C16.3631 9.39884 16.6077 9.38915 16.7647 9.5333C16.9222 9.67798 16.9324 9.92294 16.7879 10.0801C13.8194 13.3098 12.2274 17.7426 11.9215 23.6327C11.9106 23.8394 11.7399 23.9997 11.5351 23.9997L11.5348 24Z"
        fill="black"
      />
      <path
        id="Vector_4"
        d="M12.6608 17.1026C12.5512 17.1026 12.4423 17.0562 12.3656 16.9662C10.8432 15.1754 9.23445 13.8606 7.44733 12.9462C7.25701 12.8489 7.18176 12.6157 7.27921 12.4252C7.37631 12.2349 7.61016 12.1597 7.80014 12.2571C9.67938 13.2186 11.3657 14.5949 12.9552 16.4647C13.0939 16.6275 13.074 16.8719 12.9112 17.0103C12.8384 17.0723 12.7492 17.1026 12.6608 17.1026Z"
        fill="black"
      />
    </g>
  </svg>
);
