import styled from "styled-components/macro";
import { TextField as MuiTextField } from "@mui/material";

export const StyledTextField = styled(MuiTextField)`
  && {
    .Mui-focused {
      color: ${({ theme, error }) =>
        error ? theme.redDark : theme.greenMedium};
    }
    min-width: 120px;
    color: ${({ theme }) => theme.black};
    font-family: inherit;

    .MuiInputLabel-root {
      font-family: inherit;
      line-height: inherit;
    }
  }
`;
