import * as React from "react";

export const CilantroHowItWorks = (): React.ReactElement => (
  <svg
    width="181"
    height="150"
    viewBox="0 0 181 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1168_40471)">
      <path
        d="M98.0813 0.376953C90.9945 0.377313 84.8671 4.47636 81.9379 10.4336C80.1297 9.4921 78.046 9.00589 75.8375 9.13787C70.0274 9.48455 65.2925 14.1673 64.8904 19.9738C64.6499 23.4515 65.9337 26.6295 68.1375 28.9149C65.3378 29.8992 63.3275 32.5591 63.3275 35.695C63.3275 37.856 64.2852 39.7894 65.7938 41.1078L65.7902 41.111L84.9034 60.3965C86.4279 61.9346 86.5962 64.4207 85.1717 66.052C83.544 67.916 80.6911 67.9203 79.0541 66.1243L74.5648 61.1999C73.4874 59.8225 71.654 59.145 69.8584 59.6262C69.1966 59.8035 68.6126 60.1232 68.1224 60.5385L68.1196 60.5382C68.2476 59.2935 68.0689 57.9877 67.4683 56.7229C66.0995 53.8408 62.9294 52.1739 59.7841 52.7091C57.1412 53.1589 55.1416 54.9531 54.2659 57.2202C51.3296 55.3628 47.5812 54.8111 43.9795 56.1245C38.7134 58.0445 35.5268 63.5644 36.497 69.0851C37.1606 72.8605 39.5125 75.831 42.5892 77.445C41.0111 79.4021 40.4724 82.1476 41.543 84.7387C42.6686 87.4636 45.4263 89.233 48.3734 89.1488C49.9321 89.1042 51.2853 88.5835 52.3919 87.7822C52.2768 88.4159 52.2912 89.0826 52.4692 89.7461C52.4725 89.7576 52.4754 89.7692 52.4786 89.7807C52.8994 91.303 54.2663 92.3714 55.8397 92.511C60.7324 92.9447 68.0829 94.2904 75.8455 98.5509C89.8544 106.24 96.053 118.4 98.0813 122.909L105.848 59.2773L98.0813 0.376953Z"
        fill="#9BC933"
      />
      <path
        d="M98.0812 0.376953C105.168 0.377313 111.295 4.47636 114.225 10.4336C116.033 9.4921 118.116 9.00589 120.325 9.13787C126.135 9.48455 130.87 14.1673 131.272 19.9738C131.513 23.4515 130.229 26.6295 128.025 28.9149C130.825 29.8992 132.835 32.5591 132.835 35.695C132.835 37.856 131.877 39.7894 130.369 41.1078L130.372 41.111L111.259 60.3965C109.735 61.9346 109.566 64.4207 110.991 66.052C112.618 67.916 115.471 67.9203 117.108 66.1243L121.598 61.1999H121.598C122.675 59.8225 124.508 59.145 126.304 59.6262C126.966 59.8035 127.55 60.1232 128.04 60.5385L128.043 60.5382C127.915 59.2935 128.094 57.9877 128.694 56.7229C130.063 53.8408 133.233 52.1739 136.378 52.7091C139.021 53.1589 141.021 54.9531 141.897 57.2202C144.833 55.3628 148.581 54.8111 152.183 56.1245C157.449 58.0445 160.636 63.5644 159.665 69.0851C159.002 72.8605 156.65 75.831 153.573 77.445C155.151 79.4021 155.69 82.1476 154.619 84.7387C153.494 87.4636 150.736 89.233 147.789 89.1488C146.23 89.1042 144.877 88.5835 143.771 87.7822C143.886 88.4159 143.871 89.0826 143.693 89.7461C143.69 89.7576 143.687 89.7692 143.684 89.7807C143.263 91.303 141.896 92.3714 140.323 92.511C135.43 92.9447 128.08 94.2904 120.317 98.5509C106.308 106.24 100.109 118.4 98.0812 122.909V0.376953Z"
        fill="#83B22D"
      />
      <path
        d="M98.0815 149.537L99.6653 66.225L98.0815 11.6686H95.3843V29.0645L80.6139 20.1483L77.8261 24.7666L95.3843 35.3656V49.9809L84.0031 41.0837L80.6808 45.3338L95.3843 56.8279V89.6857L65.5678 73.1878L64.2613 63.9496L58.9197 64.7052L59.6569 69.9173L48.9396 63.9874L46.3284 68.7075L57.3995 74.8334L51.5232 77.9154L54.0287 82.6924L63.0511 77.9604L95.3843 95.8508V149.537H98.0815Z"
        fill="#A9D939"
      />
      <path
        d="M98.0812 149.537V11.6686H100.778V29.0645L115.549 20.1483L118.337 24.7666L100.778 35.3656V49.9809L112.16 41.0837L115.482 45.3338L100.778 56.8279V89.6857L130.595 73.1878L131.901 63.9496L137.243 64.7052L136.506 69.9173L147.223 63.9874L149.834 68.7075L138.763 74.8334L144.639 77.9154L142.134 82.6924L133.112 77.9604L100.778 95.8508V149.537H98.0812Z"
        fill="#9BC933"
      />
      <circle cx="72.8261" cy="77.1577" r="5.57425" fill="#E2E8F0" />
      <circle cx="85.5929" cy="87.4071" r="4.67517" fill="#E2E8F0" />
      <circle cx="74.2646" cy="96.218" r="11.3283" fill="#E2E8F0" />
      <path
        d="M80.4502 91.7945C79.0798 91.7945 77.9687 90.6835 77.9687 89.3131C77.9687 88.8563 77.5984 88.486 77.1416 88.486C76.6848 88.486 76.3144 88.8563 76.3144 89.3131C76.3144 90.6835 75.2034 91.7945 73.833 91.7945C73.3762 91.7945 73.0059 92.1649 73.0059 92.6217C73.0059 93.0785 73.3762 93.4488 73.833 93.4488C75.2034 93.4488 76.3144 94.5599 76.3144 95.9303C76.3144 96.3871 76.6848 96.7574 77.1416 96.7574C77.5984 96.7574 77.9687 96.3871 77.9687 95.9303C77.9687 94.5599 79.0798 93.4488 80.4502 93.4488C80.907 93.4488 81.2773 93.0785 81.2773 92.6217C81.2773 92.1649 80.907 91.7945 80.4502 91.7945ZM77.1416 93.4743C76.9005 93.15 76.6133 92.8628 76.289 92.6217C76.6133 92.3806 76.9005 92.0934 77.1416 91.7691C77.3827 92.0934 77.6699 92.3806 77.9942 92.6217C77.6699 92.8628 77.3827 93.15 77.1416 93.4743Z"
        fill="#F7FAFC"
      />
      <path
        d="M83.4403 100.288C83.2838 100.476 79.5361 104.907 74.4734 105.136C71.5928 105.194 68.8284 103.996 66.9023 101.853L68.3996 100.355C69.9127 102.066 72.0922 103.037 74.3755 103.018C78.5122 102.831 81.7788 98.9682 81.8084 98.9254L83.4403 100.288Z"
        fill="#F7FAFC"
      />
      <rect
        x="0.953125"
        y="114.874"
        width="86.2275"
        height="27.04"
        rx="13.52"
        fill="white"
      />
      <rect
        x="0.953125"
        y="114.874"
        width="86.2275"
        height="27.04"
        rx="13.52"
        stroke="#E2E8F0"
        strokeWidth="3"
      />
      <path
        d="M16.7426 135.334C15.7516 135.334 14.8636 135.173 14.0785 134.851C13.2935 134.529 12.6629 134.053 12.1867 133.423C11.7234 132.792 11.4789 132.033 11.4531 131.145H14.9665C15.018 131.647 15.1918 132.033 15.4878 132.303C15.7838 132.56 16.1699 132.689 16.646 132.689C17.1351 132.689 17.5212 132.58 17.8043 132.361C18.0874 132.129 18.229 131.814 18.229 131.415C18.229 131.08 18.1132 130.804 17.8815 130.585C17.6627 130.366 17.386 130.186 17.0514 130.044C16.7297 129.903 16.2664 129.742 15.6615 129.562C14.7864 129.291 14.0721 129.021 13.5187 128.751C12.9653 128.481 12.4891 128.082 12.0902 127.554C11.6912 127.026 11.4917 126.338 11.4917 125.488C11.4917 124.227 11.9486 123.243 12.8624 122.535C13.7761 121.814 14.9665 121.454 16.4337 121.454C17.9266 121.454 19.1299 121.814 20.0436 122.535C20.9574 123.243 21.4464 124.234 21.5108 125.508H17.9394C17.9137 125.07 17.7528 124.729 17.4568 124.485C17.1608 124.227 16.7812 124.098 16.3179 124.098C15.9189 124.098 15.5972 124.208 15.3526 124.427C15.1081 124.633 14.9858 124.935 14.9858 125.334C14.9858 125.772 15.1918 126.113 15.6036 126.357C16.0154 126.602 16.6589 126.865 17.534 127.149C18.4092 127.445 19.117 127.728 19.6575 127.998C20.2109 128.268 20.6871 128.661 21.0861 129.176C21.485 129.69 21.6845 130.353 21.6845 131.164C21.6845 131.936 21.485 132.637 21.0861 133.268C20.7 133.899 20.1337 134.401 19.3873 134.774C18.6408 135.147 17.7593 135.334 16.7426 135.334Z"
        fill="#A0AEC0"
      />
      <path
        d="M29.6535 135.334C28.3794 135.334 27.2083 135.038 26.1401 134.446C25.0848 133.854 24.2418 133.03 23.6112 131.975C22.9934 130.907 22.6846 129.71 22.6846 128.384C22.6846 127.058 22.9934 125.868 23.6112 124.813C24.2418 123.757 25.0848 122.934 26.1401 122.342C27.2083 121.75 28.3794 121.454 29.6535 121.454C30.9276 121.454 32.0923 121.75 33.1476 122.342C34.2158 122.934 35.0523 123.757 35.6572 124.813C36.2749 125.868 36.5838 127.058 36.5838 128.384C36.5838 129.71 36.2749 130.907 35.6572 131.975C35.0395 133.03 34.2029 133.854 33.1476 134.446C32.0923 135.038 30.9276 135.334 29.6535 135.334ZM29.6535 132.322C30.7345 132.322 31.5968 131.962 32.2403 131.241C32.8967 130.52 33.2248 129.568 33.2248 128.384C33.2248 127.187 32.8967 126.235 32.2403 125.527C31.5968 124.806 30.7345 124.446 29.6535 124.446C28.5596 124.446 27.6844 124.8 27.0281 125.508C26.3846 126.216 26.0629 127.174 26.0629 128.384C26.0629 129.581 26.3846 130.54 27.0281 131.26C27.6844 131.968 28.5596 132.322 29.6535 132.322Z"
        fill="#A0AEC0"
      />
      <path
        d="M46.9078 132.776H41.85L41.0392 135.17H37.5837L42.4871 121.618H46.3094L51.2127 135.17H47.7186L46.9078 132.776ZM46.0584 130.228L44.3789 125.266L42.7187 130.228H46.0584Z"
        fill="#A0AEC0"
      />
      <path
        d="M62.5407 125.981C62.5407 126.766 62.3605 127.486 62.0002 128.143C61.6398 128.786 61.0864 129.307 60.34 129.706C59.5935 130.105 58.6669 130.305 57.5601 130.305H55.5138V135.17H52.2128V121.618H57.5601C58.6412 121.618 59.5549 121.804 60.3014 122.178C61.0478 122.551 61.6076 123.066 61.9808 123.722C62.3541 124.378 62.5407 125.131 62.5407 125.981ZM57.3092 127.679C57.9398 127.679 58.4095 127.531 58.7184 127.235C59.0273 126.939 59.1817 126.521 59.1817 125.981C59.1817 125.44 59.0273 125.022 58.7184 124.726C58.4095 124.43 57.9398 124.282 57.3092 124.282H55.5138V127.679H57.3092Z"
        fill="#A0AEC0"
      />
      <path
        d="M76.6806 121.374L71.8207 130.774V135.414H68.4007V130.774L63.5406 121.374H67.4207L70.1407 127.254L72.8407 121.374H76.6806Z"
        fill="#A0AEC0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1168_40471">
        <rect width="181" height="150" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
