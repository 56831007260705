import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { BrowserRouter } from "react-router-dom";
import styled from "styled-components/macro";
import { datadogRum } from "@datadog/browser-rum";
import AppContainer from "./AppContainer";
import "./App.css";
import {
  LOGIN_PAGE_URL,
  VERIFY_EMAIL_CONFIRMED_URL,
  VERIFY_EMAIL_SEND_AGAIN_URL,
} from "./utils/urlConstants";
import Loading from "./components/shared/Loading/Loading";
import { RefreshPatientInfo } from "./components/CreateAccount/Services/CreateAccountService";
import { patientState } from "./recoil/atoms";
import { getCookieAccessToken } from "./utils/requestUtils";

const Container = styled.div`
  position: relative;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  background: ${({ theme }) => theme.neutral50};
`;

export const App = (): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [patientInfo, setPatientInfo] = useRecoilState(patientState);
  const { pathname } = window.location;

  useEffect(() => {
    if (patientInfo?.auth0.user_id) {
      datadogRum.setUser({ id: patientInfo.auth0.user_id });
    }
  }, [patientInfo]);

  useEffect(() => {
    if (
      !pathname.startsWith(VERIFY_EMAIL_CONFIRMED_URL) &&
      !pathname.startsWith(VERIFY_EMAIL_SEND_AGAIN_URL) &&
      !pathname.startsWith(LOGIN_PAGE_URL)
    ) {
      const authToken = getCookieAccessToken();
      (async () => {
        if (authToken) {
          setIsLoading(true);
          await RefreshPatientInfo(authToken, setPatientInfo);
        }
        setIsLoading(false);
      })();
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container className="App">
      <BrowserRouter>
        <AppContainer />
      </BrowserRouter>
    </Container>
  );
};
