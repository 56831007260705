import React from "react";

import {
  AccordionSummary,
  AccordionDetails,
  AccordionProps as MuiAccordionProps,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronRight, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import {
  BabyPeekAccordionAnswer,
  BabyPeekAccordionQuestion,
  BabyPeekStyledAccordion,
} from "./BabyPeekAccordion.styles";

interface BabyPeekAccordionProps
  extends Omit<MuiAccordionProps, "children" | "content"> {
  title: string;
  content: React.ReactNode;
  key: string;
}

export const BabyPeekTraitAccordion: React.FC<{
  data: BabyPeekAccordionProps[];
}> = ({ data }) => {
  const [expanded, setExpanded] = React.useState<string | boolean>(
    data.length === 1 && data[0].defaultExpanded ? data[0].key : false,
  );

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const defaultAccordions = data.map((accordionSections) => (
    <BabyPeekStyledAccordion
      expanded={expanded === accordionSections.key}
      onChange={handleChange(accordionSections.key)}
      key={accordionSections.key}
      defaultExpanded={accordionSections.defaultExpanded}
    >
      <AccordionSummary
        expandIcon={
          expanded === accordionSections.key ? (
            <FontAwesomeIcon
              icon={faChevronUp}
              style={{ color: "#2D3748", height: "20px" }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{ color: "#2D3748", height: "20px" }}
            />
          )
        }
        aria-controls={`${accordionSections.key}-panel-content`}
        id={`${accordionSections.key}-help`}
      >
        <BabyPeekAccordionQuestion>
          {accordionSections.title}
        </BabyPeekAccordionQuestion>
      </AccordionSummary>
      <AccordionDetails>
        <BabyPeekAccordionAnswer>
          {accordionSections.content}
        </BabyPeekAccordionAnswer>
      </AccordionDetails>
    </BabyPeekStyledAccordion>
  ));

  return <>{defaultAccordions}</>;
};
