import * as React from "react";

export const DownloadButton = (): React.ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9996 15.35C11.8496 15.35 11.7079 15.325 11.5746 15.275C11.4413 15.225 11.3163 15.1416 11.1996 15.025L7.52461 11.35C7.30794 11.1333 7.19961 10.8666 7.19961 10.55C7.19961 10.2333 7.30794 9.96662 7.52461 9.74995C7.72461 9.54995 7.98694 9.44595 8.31161 9.43795C8.63694 9.42928 8.90794 9.52495 9.12461 9.72495L10.8496 11.475V4.57495C10.8496 4.25828 10.9623 3.99162 11.1876 3.77495C11.4123 3.55828 11.6829 3.44995 11.9996 3.44995C12.3163 3.44995 12.5869 3.55828 12.8116 3.77495C13.0369 3.99162 13.1496 4.25828 13.1496 4.57495V11.475L14.8746 9.72495C15.0913 9.52495 15.3623 9.42928 15.6876 9.43795C16.0123 9.44595 16.2746 9.54995 16.4746 9.74995C16.6913 9.96662 16.7996 10.2333 16.7996 10.55C16.7996 10.8666 16.6913 11.1333 16.4746 11.35L12.7996 15.025C12.6829 15.1416 12.5579 15.225 12.4246 15.275C12.2913 15.325 12.1496 15.35 11.9996 15.35ZM5.87461 20.375C5.24128 20.375 4.70394 20.154 4.26261 19.712C3.82061 19.2706 3.59961 18.7333 3.59961 18.1V15.875C3.59961 15.5583 3.71228 15.2916 3.93761 15.075C4.16228 14.8583 4.43294 14.75 4.74961 14.75C5.06628 14.75 5.33294 14.8583 5.54961 15.075C5.76628 15.2916 5.87461 15.5583 5.87461 15.875V18.1H18.0996V15.875C18.0996 15.5583 18.2123 15.2916 18.4376 15.075C18.6623 14.8583 18.9329 14.75 19.2496 14.75C19.5663 14.75 19.8329 14.8583 20.0496 15.075C20.2663 15.2916 20.3746 15.5583 20.3746 15.875V18.1C20.3746 18.7333 20.1536 19.2706 19.7116 19.712C19.2703 20.154 18.7329 20.375 18.0996 20.375H5.87461Z"
      fill="#231F20"
    />
    <path
      d="M11.9996 15.35C11.8496 15.35 11.7079 15.325 11.5746 15.275C11.4413 15.225 11.3163 15.1416 11.1996 15.025L7.52461 11.35C7.30794 11.1333 7.19961 10.8666 7.19961 10.55C7.19961 10.2333 7.30794 9.96662 7.52461 9.74995C7.72461 9.54995 7.98694 9.44595 8.31161 9.43795C8.63694 9.42928 8.90794 9.52495 9.12461 9.72495L10.8496 11.475V4.57495C10.8496 4.25828 10.9623 3.99162 11.1876 3.77495C11.4123 3.55828 11.6829 3.44995 11.9996 3.44995C12.3163 3.44995 12.5869 3.55828 12.8116 3.77495C13.0369 3.99162 13.1496 4.25828 13.1496 4.57495V11.475L14.8746 9.72495C15.0913 9.52495 15.3623 9.42928 15.6876 9.43795C16.0123 9.44595 16.2746 9.54995 16.4746 9.74995C16.6913 9.96662 16.7996 10.2333 16.7996 10.55C16.7996 10.8666 16.6913 11.1333 16.4746 11.35L12.7996 15.025C12.6829 15.1416 12.5579 15.225 12.4246 15.275C12.2913 15.325 12.1496 15.35 11.9996 15.35ZM5.87461 20.375C5.24128 20.375 4.70394 20.154 4.26261 19.712C3.82061 19.2706 3.59961 18.7333 3.59961 18.1V15.875C3.59961 15.5583 3.71228 15.2916 3.93761 15.075C4.16228 14.8583 4.43294 14.75 4.74961 14.75C5.06628 14.75 5.33294 14.8583 5.54961 15.075C5.76628 15.2916 5.87461 15.5583 5.87461 15.875V18.1H18.0996V15.875C18.0996 15.5583 18.2123 15.2916 18.4376 15.075C18.6623 14.8583 18.9329 14.75 19.2496 14.75C19.5663 14.75 19.8329 14.8583 20.0496 15.075C20.2663 15.2916 20.3746 15.5583 20.3746 15.875V18.1C20.3746 18.7333 20.1536 19.2706 19.7116 19.712C19.2703 20.154 18.7329 20.375 18.0996 20.375H5.87461Z"
      fill="white"
      fillOpacity="0.3"
    />
  </svg>
);
