import React, { useEffect, useState } from "react";

import {
  BabyPeekTraitPercetangeGridLabelMobile,
  BabyPeekTraitPercetangeGridMobile,
  BabyPeekTraitPercetangeGridMobileHeader,
} from "./BabyPeekTrait.styles";
import { BabyPeekPercantageBar } from "../../BabyPeekPercentageBar";

const getPercentage = (percentage: number) => {
  if (percentage === 0.99) return "<1";
  if (percentage === 99.1) return ">99";
  return percentage;
};

export const TraitGradientMobile: React.FC<{
  sortedTraitArray: {
    color: string;
    gradient: string;
    name: string;
    percentage: number;
    gif?: string;
    textColor?: string;
  }[];
  showGif: boolean;
}> = ({ sortedTraitArray, showGif }) => {
  const [showingGifs, setShowingGifs] = useState(showGif ?? false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (showingGifs) {
        setShowingGifs(false);
      }
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      {" "}
      <div style={{ padding: `25px` }}>
        {Boolean(sortedTraitArray.length) && (
          <BabyPeekTraitPercetangeGridMobile>
            {sortedTraitArray.map((trait, index) => (
              <div key={trait.name}>
                <BabyPeekTraitPercetangeGridMobileHeader
                  style={{ marginBottom: showingGifs ? "-5px" : undefined }}
                >
                  <BabyPeekTraitPercetangeGridLabelMobile
                    $selected={index === 0}
                  >
                    {showingGifs && trait.gif
                      ? `${trait.name}`
                      : `${getPercentage(trait.percentage)}% ${trait.name}`}
                  </BabyPeekTraitPercetangeGridLabelMobile>
                </BabyPeekTraitPercetangeGridMobileHeader>
                {showingGifs && trait.gif ? (
                  <img
                    src={trait.gif}
                    alt="loading..."
                    style={{
                      verticalAlign: "bottom",
                      height: "8px",
                      width: "350px",
                    }}
                  />
                ) : (
                  <BabyPeekPercantageBar
                    values={[
                      {
                        percetange: trait.percentage,
                        color: trait.gradient,
                      },
                    ]}
                    selected={index === 0}
                  />
                )}
              </div>
            ))}
          </BabyPeekTraitPercetangeGridMobile>
        )}
      </div>
    </>
  );
};
