import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { RequisitionModelSerializer } from "recoil/types";
import { getAllRequisitions } from "../../recoil/selectors";

interface UseBabyPeekResult {
  babypeekPK: string;
  babyPeekStatus: string;
  selectedRequisition: RequisitionModelSerializer | null;
  isMostRecentRequisition: boolean;
}

const useBabyPeek = (): UseBabyPeekResult => {
  const { babypeekPK } = useParams<{ babypeekPK: string }>();
  const requisitions = useRecoilValue(getAllRequisitions);

  const selectedRequisitionIndex =
    requisitions?.findIndex(
      (requisition) => requisition.baby_peek_pk === babypeekPK,
    ) ?? -1;

  if (selectedRequisitionIndex === -1 || !requisitions) {
    return {
      babypeekPK,
      babyPeekStatus: "hidden",
      selectedRequisition: null,
      isMostRecentRequisition: false,
    };
  }

  const selectedRequisition = requisitions[selectedRequisitionIndex];
  const babyPeekStatus = selectedRequisition.reveal_status;
  const isMostRecentRequisition = selectedRequisitionIndex === 0;

  return {
    babypeekPK,
    babyPeekStatus,
    selectedRequisition,
    isMostRecentRequisition,
  };
};

export default useBabyPeek;
