import React from "react";
import { useRecoilValue } from "recoil";
import {
  ProfileFieldGrid,
  ProfileFieldRow,
  ProfileFieldRowTitle,
  ProfileInsuranceWrapper,
  ProfilePendingFailureInsuranceMessage,
} from "./Profile.styles";
import { GetTypographyContent } from "../shared/Typography/Typography";
import { getUserProfile } from "../../recoil/selectors";
import { dateFormatter } from "../../utils/helpers";
import { WarningRounded } from "../../assets/iconComponents/WarningRounded";

export const ProfileInsurance = (): React.ReactElement => {
  const profileInfo = useRecoilValue(getUserProfile);
  return (
    <>
      {profileInfo && (
        <>
          <ProfileInsuranceWrapper>
            {GetTypographyContent({
              content: "Insurance Information",
              size: "h3",
              textColor: "greyMedium",
              isBold: true,
            })}
            {profileInfo?.ins_status === "Pending" ||
            profileInfo?.prebilling_status === "Pending" ||
            profileInfo?.ins_status === "Failure" ? (
              <ProfilePendingFailureInsuranceMessage
                failure={profileInfo?.ins_status === "Failure"}
              >
                <WarningRounded />
                {profileInfo?.ins_status === "Failure"
                  ? `We cannot verify your insurance information. To prevent unexpected billing and delays in processing your claim, please contact our billing team directly at (650) 517-7743 to resolve this.`
                  : `Your insurance information is under review. Please check back
                later for the updated information.`}
              </ProfilePendingFailureInsuranceMessage>
            ) : (
              <ProfileFieldGrid>
                <ProfileFieldRow>
                  <ProfileFieldRowTitle>
                    Insurance Provider
                  </ProfileFieldRowTitle>
                  <ProfileFieldRow>
                    {GetTypographyContent({
                      content: profileInfo?.ins_provider || "",
                      size: "medium",
                      textColor: "black",
                    })}
                  </ProfileFieldRow>
                </ProfileFieldRow>
                <ProfileFieldRow>
                  <ProfileFieldRowTitle>Member ID</ProfileFieldRowTitle>

                  {GetTypographyContent({
                    content: profileInfo?.ins_number || "",
                    size: "medium",
                    textColor: "black",
                  })}
                </ProfileFieldRow>
                <ProfileFieldRow>
                  <ProfileFieldRowTitle>
                    Relationship To Policy Owner
                  </ProfileFieldRowTitle>
                  <ProfileFieldRow>
                    {GetTypographyContent({
                      content: profileInfo?.relationship_to_insured || "",
                      size: "medium",
                      textColor: "black",
                    })}
                  </ProfileFieldRow>
                </ProfileFieldRow>
                <ProfileFieldRow>
                  <ProfileFieldRowTitle>Policy Owner Name</ProfileFieldRowTitle>

                  {GetTypographyContent({
                    content: profileInfo?.subscriber_name || "",
                    size: "medium",
                    textColor: "black",
                  })}
                </ProfileFieldRow>
                <ProfileFieldRow>
                  <ProfileFieldRowTitle>Group ID</ProfileFieldRowTitle>
                  <ProfileFieldRow>
                    {GetTypographyContent({
                      content: profileInfo?.ins_group || "",
                      size: "medium",
                      textColor: "black",
                    })}
                  </ProfileFieldRow>
                </ProfileFieldRow>
                <ProfileFieldRow>
                  <ProfileFieldRowTitle>
                    Policy Owner Date Of Birth
                  </ProfileFieldRowTitle>
                  {GetTypographyContent({
                    content: profileInfo?.subscriber_date_of_birth
                      ? dateFormatter(profileInfo?.subscriber_date_of_birth)
                      : "",
                    size: "medium",
                    textColor: "black",
                  })}
                </ProfileFieldRow>
              </ProfileFieldGrid>
            )}
          </ProfileInsuranceWrapper>
        </>
      )}
    </>
  );
};
