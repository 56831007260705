import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  ProfileFieldGrid,
  ProfileNotificationRow,
  ProfileMainWrapper,
  ProfileNotifcationColumns,
  ProfileNotifcationDescription,
  ProfileNotifcationDescriptionRow,
  ProfileNotifcationTitle,
} from "./Profile.styles";
import { GetTypographyContent } from "../shared/Typography/Typography";
import { getUserAuth } from "../../recoil/selectors";
import { CustomSwitch } from "../shared/CustomSwitch";
import { UpdateNotifications } from "./Services/UpdateProfileInfoService";
import { patientProfileState } from "../../recoil/atoms";

export const ProfileNotifications: React.FC = () => {
  const authInfo = useRecoilValue(getUserAuth);
  const [profileInfo, setProfileInfo] = useRecoilState(patientProfileState);
  const [notificationSettings, setNotificationSettings] = useState({
    is_marketing_subscribed: profileInfo?.phi.is_marketing_subscribed ?? false,

    is_new_report_subscribed:
      profileInfo?.phi.is_new_report_subscribed ?? false,
  });

  useEffect(() => {
    const updateNotifcationSettings = async () => {
      const response = await UpdateNotifications(
        notificationSettings.is_marketing_subscribed,
        notificationSettings.is_new_report_subscribed,
        authInfo?.access_token,
      );
      if (response?.ok) {
        const responseJson = await response.json();
        setProfileInfo(responseJson);
      }
    };
    if (
      JSON.stringify({
        is_marketing_subscribed: profileInfo?.phi.is_marketing_subscribed,
        is_new_report_subscribed: profileInfo?.phi.is_new_report_subscribed,
      }) !== JSON.stringify(notificationSettings)
    ) {
      updateNotifcationSettings();
    }
  }, [notificationSettings]);

  return (
    <>
      {profileInfo && authInfo && (
        <ProfileMainWrapper>
          <div>
            {GetTypographyContent({
              content: "Notifications",
              size: "h3",
              textColor: "greyMedium",
              isBold: true,
            })}
            <ProfileNotifcationDescription>
              Changes may take 48 hours to come into effect.
            </ProfileNotifcationDescription>
          </div>
          <ProfileFieldGrid>
            <ProfileNotificationRow>
              <ProfileNotifcationColumns
                onClick={() => {
                  setNotificationSettings({
                    ...notificationSettings,
                    is_new_report_subscribed:
                      !notificationSettings.is_new_report_subscribed,
                  });
                }}
              >
                <CustomSwitch
                  clicked={notificationSettings.is_new_report_subscribed}
                />
                <ProfileNotifcationDescriptionRow>
                  <ProfileNotifcationTitle>New Report</ProfileNotifcationTitle>
                  <ProfileNotifcationDescription>
                    Examples: Welcome to Patient Portal, your results are ready,
                    and account profile
                  </ProfileNotifcationDescription>
                </ProfileNotifcationDescriptionRow>
              </ProfileNotifcationColumns>
            </ProfileNotificationRow>

            <ProfileNotificationRow>
              <ProfileNotifcationColumns
                onClick={() => {
                  setNotificationSettings({
                    ...notificationSettings,
                    is_marketing_subscribed:
                      !notificationSettings.is_marketing_subscribed,
                  });
                }}
              >
                <CustomSwitch
                  clicked={notificationSettings.is_marketing_subscribed}
                />
                <ProfileNotifcationDescriptionRow>
                  <ProfileNotifcationTitle>Marketing</ProfileNotifcationTitle>
                  <ProfileNotifcationDescription>
                    Examples: BabyPeek is available, BabyPeek results, and
                    non-medical information about UNITY.
                  </ProfileNotifcationDescription>
                </ProfileNotifcationDescriptionRow>
              </ProfileNotifcationColumns>
            </ProfileNotificationRow>
          </ProfileFieldGrid>
        </ProfileMainWrapper>
      )}
    </>
  );
};
