import React, { useEffect, useState } from "react";
import { Stepper, Step, StepLabel, RadioGroup } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  UpfrontPaymentFinancialQuestionsWrapper,
  UpfrontPaymentFinancialStepperWrapper,
  UpfrontPaymentMedicaidWarningWrapper,
} from "../UpfrontPayment.styles";
import {
  GetUpfrontPaymentFinancialAssistance,
  UpfrontPaymenFinancialAssistanceResponse,
  UpfrontPaymentType,
} from "../Services/UpfrontPaymentService";
import {
  BillingRadioControlDropdown,
  ProfileInsuranceModalButtons,
  ProfileInsuranceModalTitle,
} from "../../Profile/ProfileInsuranceModal.styles";
import { Button } from "../../shared/Button/Button";
import { useDeviceHook } from "../../../utils/hooks/useDeviceHook";
import ProfileInsuranceUpload from "../../Profile/ProfileInsuranceUpload";
import { PatientInsuranceInfo } from "../../Profile/helpers";
import { StepConfirmation } from "./StepConfirmation";
import { getStepIconComponent } from "./utils";
import { SpinnerLoading } from "../../shared/Spinner/SpinnerLoading";
import { BillingSupportNumber } from "../types";

interface MedicaidFlowProps {
  setMedicaidStatus: React.Dispatch<React.SetStateAction<"Yes" | "No" | null>>;
  setUpfrontBill: React.Dispatch<
    React.SetStateAction<UpfrontPaymentType | undefined>
  >;
  setFinancialAidResponse: React.Dispatch<
    React.SetStateAction<UpfrontPaymenFinancialAssistanceResponse | undefined>
  >;
}

export const MedicaidFlow: React.FC<MedicaidFlowProps> = ({
  setMedicaidStatus,
  setUpfrontBill,
  setFinancialAidResponse,
}) => {
  const { deviceType } = useDeviceHook();
  const { billingPK, adminID } = useParams<{
    billingPK: string;
    adminID?: string;
  }>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isError, setCheckError] = useState(false);
  const steps = ["Upload Documents", "Confirmation"];
  const [patientInsuranceInfo, setPatientInsuranceInfo] =
    React.useState<PatientInsuranceInfo>({
      billToInsuranceType: "Upload",
      insuranceCompanyName: "",
      groupId: "",
      memberId: "",
      policyOwnerName: "",
      policyOwnerDob: "",
      relationshipToPolicyOwnerSelf: true,
      relationshipToPolicyOwnerDependent: false,
      relationshipToPolicyOwnerOther: false,
      insuranceFileUrls: {},
      checkError: false,
    });

  useEffect(() => {
    setCheckError(false);
  }, [patientInsuranceInfo]);

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => {
    if (activeStep === 0) {
      setMedicaidStatus(null);
      setActiveStep(0);
      return;
    }
    setActiveStep((prev) => prev - 1);
  };
  const onSubmit = async () => {
    setLoading(true);
    const financialAssistanceResponse =
      await GetUpfrontPaymentFinancialAssistance(
        billingPK,
        {
          has_medicaid: true,
          household_size: 0,
          income: 0,
          medicaid_insurance_card_urls: Object.keys(
            patientInsuranceInfo.insuranceFileUrls,
          ).map(
            (file) => patientInsuranceInfo.insuranceFileUrls[file].fileName,
          ),
          extenuating_circumstances: [],
          locality: null,
        },
        null,
        adminID,
      );
    setLoading(false);
    if (financialAssistanceResponse?.ok) {
      const financialAssistanceJson =
        (await financialAssistanceResponse.json()) as UpfrontPaymenFinancialAssistanceResponse;
      if (financialAssistanceJson) {
        setFinancialAidResponse(financialAssistanceJson);
        setUpfrontBill(financialAssistanceJson.bill);
      }
    } else {
      setError(
        `Error occured submitting application, please try again later.  If the issue persists, please contact support at ${BillingSupportNumber}.`,
      );
    }
  };

  const blockNext = () => {
    switch (activeStep) {
      case 0:
        return Object.keys(patientInsuranceInfo.insuranceFileUrls).length < 2;
      default:
        return false;
    }
  };

  const renderStepContent = () => {
    if (loading) {
      return <SpinnerLoading />;
    }
    if (error) {
      return <div>{error}</div>;
    }
    switch (activeStep) {
      case 0:
        return (
          <div style={{ width: "90%" }}>
            <BillingRadioControlDropdown $hideBorder>
              <RadioGroup
                value={patientInsuranceInfo.billToInsuranceType}
                onChange={(e) =>
                  setPatientInsuranceInfo({
                    ...patientInsuranceInfo,
                    billToInsuranceType: e.target.value as "Entry" | "Upload",
                  })
                }
              >
                <UpfrontPaymentMedicaidWarningWrapper className="profile-modal-title profile-insurance-title">
                  With Medicaid, you will have $0 out-of-pocket responsibility
                  for the test.
                </UpfrontPaymentMedicaidWarningWrapper>

                <ProfileInsuranceUpload
                  patientInsuranceInfo={patientInsuranceInfo}
                  setPatientInsuranceInfo={setPatientInsuranceInfo}
                  isError={isError}
                  upfrontBilling="medicaid_or_hmo"
                />
              </RadioGroup>
            </BillingRadioControlDropdown>
          </div>
        );
      case 1:
        return (
          <StepConfirmation
            medicaidInfo={{
              hasMedicaid: "Yes",
              frontCardFileName: Object.keys(
                patientInsuranceInfo.insuranceFileUrls,
              )
                .filter(
                  (fileName) =>
                    patientInsuranceInfo.insuranceFileUrls[fileName]?.side ===
                    "front",
                )
                .join(", "),
              backCardFileName: Object.keys(
                patientInsuranceInfo.insuranceFileUrls,
              )
                .filter(
                  (fileName) =>
                    patientInsuranceInfo.insuranceFileUrls[fileName]?.side ===
                    "back",
                )
                .join(", "),
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <UpfrontPaymentFinancialQuestionsWrapper>
        {renderStepContent()}
        <UpfrontPaymentFinancialStepperWrapper marginTop={0}>
          <Stepper>
            {steps.map((value, index) => (
              <Step key={value}>
                <StepLabel
                  StepIconComponent={getStepIconComponent(index, activeStep)}
                />
              </Step>
            ))}
          </Stepper>
        </UpfrontPaymentFinancialStepperWrapper>
      </UpfrontPaymentFinancialQuestionsWrapper>
      <ProfileInsuranceModalTitle deviceType={deviceType}>
        <ProfileInsuranceModalButtons deviceType={deviceType}>
          <Button
            className="upfront-cancel-button"
            label="Back"
            onClick={handleBack}
            size="medium"
            reverse
            textColor="greyMedium"
            backgroundColor="greyMedium"
          />
          <Button
            className="upfront-submit-button"
            label={activeStep === steps.length - 1 ? "Submit" : "Next"}
            data-testid="submit-button"
            disabled={blockNext()}
            onClick={activeStep === steps.length - 1 ? onSubmit : handleNext}
            size="medium"
            backgroundColor="greenDark"
          />
        </ProfileInsuranceModalButtons>
      </ProfileInsuranceModalTitle>
    </>
  );
};
