import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useWindowWidth } from "@react-hook/window-size";
import { useRecoilValue } from "recoil";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";

import {
  BabyPeekIntroWrapper,
  BabyPeekIntroBodyWrapper,
  BabyPeekIntroTitle,
  BabyPeekIntroDescription,
  BabyPeekIntroBottomMobileGrid,
  BabyPeekIntroInfoBarWrapper,
  BabyPeekIntroInfoBarBigMobile,
  BabyPeekIntroInfoBarDescriptionMobile,
  BabyPeekIntroCTAWrapper,
  BabyPeekCTATitle,
  BabyPeekFAQTitle,
  BabyPeekIntroFAQAccordionWrapper,
  BabyPeekMobileFAQ,
  BabyPeekDisclaimer,
  BabyPeekDisclaimerGrid,
  BabyPeekDisclaimerText,
  BabyPeekDisclaimerTitle,
  BabyPeekIntroPaymentMobileButtonWrapper,
  BabyPeekIntroMobileDescriptionWrapper,
  BabyPeekMobileHeaderImageWrapper,
  BabyPeekIntroMarqueeWrapper,
  BabyPeekRatingsWrapper,
} from "./BabyPeekIntro.styles";

import { BabyPeekButton } from "./BabyPeekButton";
import { TraitMarquee } from "./TraitMarquee";

import {
  BabyPeekAccordions,
  babyPeekFAQAccordion,
  babyPeekGameAccordion,
} from "./BabyPeekAccordion";
import { DetailedResultsBabyPeekText } from "../DetailedResults/DetailedResults.styles";
import { EyeCTA } from "./BabyPeekIcons/EyeCTA";
import { TasteCTA } from "./BabyPeekIcons/TasteCTA";
import { TeethCTA } from "./BabyPeekIcons/TeethCTA";
import { WaveCTA } from "./BabyPeekIcons/WaveCTA";
import {
  BabyPeekPaymentPromotionGradient,
  BabyPeekPaymentPromotionGradientContent,
  BabyPeekPaymentGradientContentSpecialGrid,
  BabyPeekPaymentGradientTitle,
  BabyPeekPaymentSummaryItemGridRow,
  BabyPeekPaymentSummaryTitleTrait,
  BabyPeekPaymentSummaryDescription,
  BabyPeekPaymentGradientTitleMobile,
  BabyPeekPaymentGradientTitleStrikeThrough,
  BabyPeekPaymentPromotionalPriceGrid,
  BabyPeekMobileButtonSticky,
  BabyPeekPreTestMessage,
} from "./BabyPeekStripe.styles";
import {
  BabyPeekButtonNames,
  BabyPeekTraitResponse,
  getUnlockButtonText,
} from "./Services/BabyPeekService";
import { getCentsToDollars } from "../UpfrontPayment/Services/UpfrontPaymentService";
import useScrollPosition from "../../utils/hooks/useScrollHeight";
import { BabyPeekIcon } from "./BabyPeekIcons/BabyPeekIcon";
import { BABYPEEK_URL } from "../../utils/urlConstants";
import BabyPeekVideoEmbed from "../shared/Video/BabyPeekVideoEmbed";
import { TraitSummaryList } from "./BabyPeekIcons/TraitSummaryList";
import { FutureFoodie } from "./BabyPeekIcons/FutureFoodie";
import { TechnologyGene } from "./BabyPeekIcons/TechnologyGene";
import { BabyPeekVerticalQuotes } from "./BabyPeekQuotes";
import { BabyPeekRatings } from "./BabyPeekIcons/BabyPeekRatings";
import { BabyPeekRatingsStar } from "./BabyPeekIcons/BabyPeekRatingsStar";
import { BabyPeekGamesExplosions } from "./BabyPeekIcons/BabyPeekGamesExplosions";
import { BabyPeekGamesExclusive } from "./BabyPeekIcons/BabyPeekGamesExclusive";
import { getUserAuth } from "../../recoil/selectors";
import BabyPeekMultipleReqHeader from "./BabyPeekMultipleReqHeader";

export const BabyPeekIntroMobile: React.FC<{
  babyPeekReport: BabyPeekTraitResponse;
  babyPeekPKVal?: string;
  setLastButtonClick?: React.Dispatch<
    React.SetStateAction<BabyPeekButtonNames | undefined>
  >;
}> = ({ babyPeekReport, babyPeekPKVal, setLastButtonClick }) => {
  const { deviceType } = useDeviceHook();
  const windowWidth = useWindowWidth();
  const history = useHistory();
  const scrollPosition = useScrollPosition();
  const auth = useRecoilValue(getUserAuth);
  const [registryLinkCopied, setRegistryLinkCopied] = useState(false);
  let { babypeekPK } = useParams<{
    babypeekPK: string;
  }>();
  babypeekPK = babypeekPK ?? babyPeekPKVal;

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (registryLinkCopied) {
      timeout = setTimeout(() => {
        setRegistryLinkCopied(false);
      }, 4000);
    }
    return () => clearTimeout(timeout);
  }, [registryLinkCopied]);

  return (
    <>
      <div
        style={{
          display: "grid",
          background:
            "linear-gradient(180deg, #FFF6CF 0%, rgba(255, 253, 244, 0.60) 5%, #FFFDF4 100%, #FFFDF4 100%), #FFF",
        }}
      >
        <BabyPeekIntroWrapper>
          <BabyPeekMultipleReqHeader />
          <BabyPeekMobileHeaderImageWrapper
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "40px",
              margin: "40px 0px",
              width: windowWidth,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: windowWidth,
              }}
            >
              <div>
                <BabyPeekIcon />
              </div>
              <BabyPeekRatingsWrapper
                onClick={() => {
                  const anchorEl = document.getElementById("babypeekRatings");
                  if (anchorEl) {
                    anchorEl.scrollIntoView();
                  }
                }}
              >
                <BabyPeekRatings />
              </BabyPeekRatingsWrapper>
            </div>
          </BabyPeekMobileHeaderImageWrapper>
          <div
            style={{
              display: "grid",
              gridTemplateRows: "auto auto auto",
              gap: "16px",
            }}
          >
            <BabyPeekIntroBottomMobileGrid
              style={{
                padding: windowWidth < 400 ? "0px 12px" : "0px 32px",
              }}
            >
              <BabyPeekIntroTitle
                deviceType={deviceType}
                style={{
                  textAlign: "center",
                }}
              >
                Discover your baby’s traits before birth
              </BabyPeekIntroTitle>
              <BabyPeekIntroDescription
                deviceType={deviceType}
                style={{
                  textAlign: "center",
                }}
              >
                {babyPeekReport.experiment_details.landing_page === "a"
                  ? `Explore beyond the results of your UNITY Screen™ and learn more about your baby’s unique traits – all from your blood sample you already provided.`
                  : `Explore beyond the results of your UNITY Screen™ and discover your baby’s unique traits—all from the blood sample you already provided.`}
              </BabyPeekIntroDescription>
            </BabyPeekIntroBottomMobileGrid>
            <BabyPeekIntroMobileDescriptionWrapper
              style={{ display: "grid", gridGap: "12px" }}
            >
              <>
                {babyPeekReport?.reveal_status === "pending" && (
                  <>
                    <>
                      <BabyPeekButton
                        variant="filled"
                        id="vwo-unlock-babypeek"
                        style={{
                          width: "300px",
                          justifySelf: "center",
                          marginTop: "0px",
                        }}
                        content={<>Unlock BabyPeek</>}
                        onClick={() => {
                          if (setLastButtonClick) {
                            setLastButtonClick("IntroMainUnlock");
                            history.push(
                              `${BABYPEEK_URL}/${babypeekPK}/checkout`,
                            );
                          }
                        }}
                      />
                    </>
                  </>
                )}
              </>
            </BabyPeekIntroMobileDescriptionWrapper>
          </div>
        </BabyPeekIntroWrapper>
        <BabyPeekIntroMarqueeWrapper>
          <TraitMarquee mobile />
        </BabyPeekIntroMarqueeWrapper>
        <BabyPeekIntroBodyWrapper>
          {babyPeekReport.unlock_type === "discount_payment" && (
            <BabyPeekPaymentPromotionGradient deviceType={deviceType}>
              <BabyPeekPaymentPromotionGradientContent>
                <BabyPeekPaymentGradientContentSpecialGrid>
                  <BabyPeekPaymentGradientTitle>
                    <BabyPeekIntroInfoBarBigMobile>
                      BabyPeek Reports
                    </BabyPeekIntroInfoBarBigMobile>
                    <BabyPeekPaymentPromotionalPriceGrid>
                      <DetailedResultsBabyPeekText>
                        {getCentsToDollars(
                          babyPeekReport.bill_details.balance,
                          false,
                        )}
                      </DetailedResultsBabyPeekText>
                      <BabyPeekPaymentGradientTitleStrikeThrough>
                        {getCentsToDollars(24900, true)}
                      </BabyPeekPaymentGradientTitleStrikeThrough>
                    </BabyPeekPaymentPromotionalPriceGrid>
                  </BabyPeekPaymentGradientTitle>

                  <BabyPeekPaymentGradientTitleMobile>
                    <BabyPeekPaymentSummaryItemGridRow>
                      <BabyPeekPaymentSummaryTitleTrait
                        deviceType={deviceType}
                        style={{ display: "flex" }}
                      >
                        <div>
                          <WaveCTA />
                        </div>{" "}
                        Hair
                      </BabyPeekPaymentSummaryTitleTrait>
                      <BabyPeekPaymentSummaryDescription
                        deviceType={deviceType}
                      >
                        &bull; Light or dark hair <br />
                        &bull; Red hair <br />
                        &bull; Hair thickness <br />
                        &bull; Hair texture <br />
                      </BabyPeekPaymentSummaryDescription>
                    </BabyPeekPaymentSummaryItemGridRow>

                    <BabyPeekPaymentSummaryItemGridRow>
                      <BabyPeekPaymentSummaryTitleTrait
                        deviceType={deviceType}
                        style={{ display: "flex" }}
                      >
                        <div>
                          <EyeCTA />
                        </div>
                        Eye Color
                      </BabyPeekPaymentSummaryTitleTrait>
                      <BabyPeekPaymentSummaryDescription
                        deviceType={deviceType}
                      >
                        Eye color
                      </BabyPeekPaymentSummaryDescription>
                    </BabyPeekPaymentSummaryItemGridRow>
                  </BabyPeekPaymentGradientTitleMobile>
                  <BabyPeekPaymentGradientTitleMobile>
                    <BabyPeekPaymentSummaryItemGridRow>
                      <BabyPeekPaymentSummaryTitleTrait
                        style={{ display: "flex" }}
                        deviceType={deviceType}
                      >
                        <div>
                          <TeethCTA />
                        </div>{" "}
                        Other traits
                      </BabyPeekPaymentSummaryTitleTrait>
                      <BabyPeekPaymentSummaryDescription
                        deviceType={deviceType}
                      >
                        &bull; Teeth <br />
                        &bull; Freckles <br />
                        &bull; Asparagus odor detection <br />
                      </BabyPeekPaymentSummaryDescription>
                    </BabyPeekPaymentSummaryItemGridRow>

                    <BabyPeekPaymentSummaryItemGridRow>
                      <BabyPeekPaymentSummaryTitleTrait
                        style={{ display: "flex" }}
                        deviceType={deviceType}
                      >
                        <div>
                          <TasteCTA />
                        </div>
                        Taste
                      </BabyPeekPaymentSummaryTitleTrait>
                      <BabyPeekPaymentSummaryDescription
                        deviceType={deviceType}
                      >
                        &bull; Cilantro <br />
                        &bull; Bitter taste <br />
                        &bull; Sweet <br />
                        &bull; Sweet vs Salty <br />
                      </BabyPeekPaymentSummaryDescription>
                    </BabyPeekPaymentSummaryItemGridRow>
                  </BabyPeekPaymentGradientTitleMobile>
                  <BabyPeekIntroPaymentMobileButtonWrapper>
                    <>
                      <BabyPeekButton
                        id="vwo-unlock-babypeek"
                        variant="outline"
                        content={<>{getUnlockButtonText()}</>}
                        onClick={() => {
                          if (setLastButtonClick) {
                            setLastButtonClick("PromoUnlock");
                          }

                          history.push(
                            `${BABYPEEK_URL}/${babypeekPK}/checkout`,
                          );
                        }}
                      />
                    </>
                  </BabyPeekIntroPaymentMobileButtonWrapper>
                </BabyPeekPaymentGradientContentSpecialGrid>
              </BabyPeekPaymentPromotionGradientContent>
            </BabyPeekPaymentPromotionGradient>
          )}
          <BabyPeekIntroInfoBarWrapper
            deviceType={deviceType}
            windowWidth={windowWidth}
          >
            <div>
              <BabyPeekIntroInfoBarBigMobile>
                Eye Color
              </BabyPeekIntroInfoBarBigMobile>
              <BabyPeekIntroInfoBarBigMobile>
                Hair Color
              </BabyPeekIntroInfoBarBigMobile>
              <BabyPeekIntroInfoBarBigMobile style={{ marginBottom: "24px" }}>
                Hair Texture
              </BabyPeekIntroInfoBarBigMobile>
              <BabyPeekIntroInfoBarDescriptionMobile>
                {`BabyPeek uses advanced technology to analyze well-known genetic factors that determine which traits a baby will most likely have. Much like a weather report, results are calculated as probabilities. `}
              </BabyPeekIntroInfoBarDescriptionMobile>
            </div>
            <div style={{ display: "grid", marginBottom: "-32px" }}>
              <TraitSummaryList />
            </div>
          </BabyPeekIntroInfoBarWrapper>
          <BabyPeekIntroInfoBarWrapper
            deviceType={deviceType}
            windowWidth={windowWidth}
          >
            <div>
              <BabyPeekIntroInfoBarBigMobile style={{ marginBottom: "24px" }}>
                Future Foodie
              </BabyPeekIntroInfoBarBigMobile>

              <BabyPeekIntroInfoBarDescriptionMobile>
                Sweet or salty? Sensitive to bitter tastes? Begin imagining the
                fun of introducing your baby to their future favorite foods and
                flavors.
              </BabyPeekIntroInfoBarDescriptionMobile>
              <div style={{ display: "grid", marginTop: "10px" }}>
                <FutureFoodie />
              </div>
            </div>
          </BabyPeekIntroInfoBarWrapper>
          <BabyPeekIntroInfoBarWrapper
            deviceType={deviceType}
            windowWidth={windowWidth}
          >
            <div>
              <div style={{ marginTop: "-32px", marginBottom: "-195px" }}>
                <BabyPeekGamesExplosions />
              </div>
              <BabyPeekGamesExclusive />
              <BabyPeekIntroInfoBarBigMobile style={{ marginBottom: "24px" }}>
                BabyPeek Games
              </BabyPeekIntroInfoBarBigMobile>
              <BabyPeekIntroInfoBarDescriptionMobile>
                Unlock printable games and have fun discovering your baby’s
                traits with your friends and family!
              </BabyPeekIntroInfoBarDescriptionMobile>
              <div style={{ display: "grid", marginTop: "10px" }}>
                <BabyPeekAccordions
                  data={babyPeekGameAccordion(babypeekPK, auth?.access_token)}
                />
              </div>
            </div>
          </BabyPeekIntroInfoBarWrapper>
          <BabyPeekIntroInfoBarWrapper
            style={{ position: "relative", justifyContent: "center" }}
            deviceType={deviceType}
            id="babypeekRatings"
          >
            <BabyPeekRatingsStar />

            <div style={{ display: "grid", justifyItems: "center" }}>
              <BabyPeekVideoEmbed babypeekPK={babypeekPK} />
              <BabyPeekPreTestMessage>
                Four celebrated sisters discover baby traits together with
                BabyPeek.
              </BabyPeekPreTestMessage>
            </div>
            <BabyPeekVerticalQuotes />
          </BabyPeekIntroInfoBarWrapper>

          <BabyPeekIntroCTAWrapper deviceType={deviceType}>
            <BabyPeekCTATitle deviceType={deviceType}>
              Powered by Cutting Edge DNA Sequencing Technology
            </BabyPeekCTATitle>
            <TechnologyGene />
            <BabyPeekIntroInfoBarDescriptionMobile>
              {`Thanks to our pioneering genetic testing technology, we can also explore your baby’s genetic traits hidden in the cell-free DNA fragments in your blood. Explore your baby’s unique characteristics today by ordering BabyPeek. `}
            </BabyPeekIntroInfoBarDescriptionMobile>
          </BabyPeekIntroCTAWrapper>
          <BabyPeekIntroFAQAccordionWrapper deviceType={deviceType}>
            <BabyPeekFAQTitle deviceType={deviceType}>
              Frequently Asked Questions
            </BabyPeekFAQTitle>
            <BabyPeekMobileFAQ>
              <BabyPeekAccordions data={babyPeekFAQAccordion} />
            </BabyPeekMobileFAQ>
          </BabyPeekIntroFAQAccordionWrapper>
          <BabyPeekDisclaimer
            style={{
              display: "grid",
              backgroundColor: "#fff",
              backgroundSize: "100% 100%",
            }}
          >
            <BabyPeekDisclaimerTitle> Disclaimer</BabyPeekDisclaimerTitle>
            <BabyPeekDisclaimerGrid deviceType={deviceType}>
              <ul>
                <li>
                  <BabyPeekDisclaimerText>
                    BabyPeek does not provided information about the health of
                    the baby and is only for fun.
                  </BabyPeekDisclaimerText>
                </li>
                <li>
                  <BabyPeekDisclaimerText>
                    {`The results from BabyPeek are not guaranteed and will tell you
                what traits the baby is most likely to have. Your baby's actual
                traits may vary from what is predicted.`}
                  </BabyPeekDisclaimerText>
                </li>
              </ul>

              <ul>
                <li>
                  <BabyPeekDisclaimerText>
                    Your doctor did not order this additional test and should
                    not be contacted for questions about the results.{" "}
                  </BabyPeekDisclaimerText>
                </li>
                <li>
                  <BabyPeekDisclaimerText>
                    You instead should contact BillionToOne laboratory directly
                    with questions.
                  </BabyPeekDisclaimerText>
                </li>
              </ul>
            </BabyPeekDisclaimerGrid>
          </BabyPeekDisclaimer>
        </BabyPeekIntroBodyWrapper>

        {scrollPosition > 400 && (
          <BabyPeekMobileButtonSticky>
            <>
              <BabyPeekButton
                id="vwo-unlock-babypeek"
                variant="filled"
                content={<>{getUnlockButtonText()}</>}
                onClick={() => {
                  if (setLastButtonClick) {
                    setLastButtonClick("IntroMainUnlock");
                  }

                  history.push(`${BABYPEEK_URL}/${babypeekPK}/checkout`);
                }}
                className="unlock-button"
              />
            </>
          </BabyPeekMobileButtonSticky>
        )}
      </div>
    </>
  );
};
