import React from "react";
import { RedrawIcon } from "../../assets/iconComponents/Redraw";
import { GetTypographyContent } from "../shared/Typography/Typography";
import { TestResultsRedrawWrapper } from "./TestResults.styles";

export const TestResultsId = (
  testId: string,
  isRedraw: boolean,
): React.ReactElement => (
  <>
    {GetTypographyContent({
      content: "Test ID",
      size: "medium",
      textColor: "greyMedium",
      isBold: true,
      isCaps: true,
    })}
    <TestResultsRedrawWrapper>
      {GetTypographyContent({
        content: testId,
        size: "medium",
        textColor: "greyMedium",
      })}
      {isRedraw && <RedrawIcon />}
    </TestResultsRedrawWrapper>
  </>
);
