import React from "react";
import { ForwardIcon } from "../../assets/iconComponents/Forward";
import { StyledButton } from "../shared/Button/Button.styles";
import { GetTypographyContent } from "../shared/Typography/Typography";
import {
  DetailedResultsFetalSexButton,
  DetailedResultsFetalSexButtonWrappers,
  DetailedResultsButtonTextWrapper,
} from "./DetailedResults.styles";
import { themePalette } from "../../theme";
import { HideIcon } from "../../assets/iconComponents/HideIcon";

export const DetailedResultsFetalSexHideButtons: React.FC<{
  setShowFetalSex: (show: boolean) => void;
  setForwardFetalSex: (forward: boolean) => void;
}> = ({ setShowFetalSex, setForwardFetalSex }) => (
  <DetailedResultsFetalSexButtonWrappers hide>
    <StyledButton
      size="small"
      $backgroundColor="redMedium"
      $textColor="white"
      onClick={() => setShowFetalSex(false)}
    >
      <DetailedResultsFetalSexButton>
        <div>
          <HideIcon color={themePalette.white} />
        </div>
        <div data-testid="yes-reveal-fetal-sex-button">
          <DetailedResultsButtonTextWrapper>
            {GetTypographyContent({
              content: "HIDE FETAL SEX",
              size: "medium",
              textColor: "white",
              isCaps: true,
              padding: "0 0 0 3px",
            })}
          </DetailedResultsButtonTextWrapper>
        </div>
      </DetailedResultsFetalSexButton>
    </StyledButton>
    <StyledButton
      $reverse
      size="small"
      $backgroundColor="white"
      $textColor="redMedium"
      $borderColor="redMedium"
      onClick={() => setForwardFetalSex(true)}
    >
      <DetailedResultsFetalSexButton>
        <div>
          <ForwardIcon />
        </div>
        <DetailedResultsButtonTextWrapper>
          {GetTypographyContent({
            content: "FORWARD FETAL SEX",
            size: "medium",
            textColor: "redMedium",
            isCaps: true,
            padding: "0 0 0 3px",
          })}
        </DetailedResultsButtonTextWrapper>
      </DetailedResultsFetalSexButton>
    </StyledButton>
  </DetailedResultsFetalSexButtonWrappers>
);
