import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { GetTypographyContent } from "../shared/Typography/Typography";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import {
  CreateAccountButtonContainer,
  CreateAccountCenterContainerPaddedTop,
  CreateAccountFormStyled,
  FormFieldInput,
  Spinner,
  VerifyEmailSendAgainInput,
} from "../CreateAccount/CreateAccountForm.styles";
import { Button } from "../shared/Button/Button";
import { patientState } from "../../recoil/atoms";
import { UpdateEmailOnlyRequest } from "../CreateAccount/Services/CreateAccountService";
import { VERIFY_EMAIL_URL } from "../../utils/urlConstants";
import loading from "../../assets/icons/loading.svg";
import { validateEmail } from "../../utils/hooks/helpers";

export const VerifyEmailSendAgainSection = (): React.ReactElement => {
  const [patientInfo, setPatientInfo] = useRecoilState(patientState);
  const [email, setEmail] = useState(patientInfo?.auth0.email);
  const [emailError, setEmailError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const { isDesktop, deviceType } = useDeviceHook();

  const submit = async () => {
    if (!email) {
      setEmailError("Required");
      return;
    }
    if (!validateEmail(email)) {
      setEmailError("Invalid email");
      return;
    }
    setIsSubmitting(true);
    const response = await UpdateEmailOnlyRequest(
      email,
      patientInfo?.auth0.access_token as string,
    );
    if (response && response?.ok && patientInfo) {
      setPatientInfo({
        ...patientInfo,
        auth0: { ...patientInfo.auth0, email },
      });
      history.push(VERIFY_EMAIL_URL);
    } else {
      setErrorMessage(
        "We are unable to send you a verification email to that address. Please try again or use another email.",
      );
    }
    setIsSubmitting(false);
  };
  return (
    <CreateAccountCenterContainerPaddedTop isDesktop={isDesktop}>
      {GetTypographyContent({
        content: "Didn't receive the email?",
        size: "h3",
        isBold: true,
        textColor: "neutral700",
        padding: "0 0 24px 0",
      })}
      {GetTypographyContent({
        content:
          "We sent the verify email link to the address below. Please check it's correct or enter a new one.",
        size: "medium",
        textColor: "neutral700",
        padding: "0 0 24px 0",
      })}
      {GetTypographyContent({
        content: errorMessage,
        size: "medium",
        textColor: "redDark",
      })}
      <CreateAccountFormStyled deviceType={deviceType}>
        <FormFieldInput deviceType={deviceType}>
          <VerifyEmailSendAgainInput
            label="Email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder={email}
            value={email}
            error={emailError}
          />
        </FormFieldInput>
        <CreateAccountButtonContainer>
          {isSubmitting && (
            <Spinner>
              <img src={loading} alt="Loading" />
            </Spinner>
          )}
          {!isSubmitting && (
            <Button
              type="submit"
              label="Send Again"
              size="medium"
              onClick={submit}
            />
          )}
        </CreateAccountButtonContainer>
      </CreateAccountFormStyled>
    </CreateAccountCenterContainerPaddedTop>
  );
};
