import styled from "styled-components/macro";
import { DeviceType } from "../../utils/hooks/useDeviceHook";

export const ContactUsPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getContactUsWidth = (deviceType: DeviceType) => {
  if (deviceType === "desktop") {
    return "800px 330px";
  }
  if (deviceType === "tablet") {
    return "570px";
  }
  return "360px";
};

const getContactUsPadding = (deviceType: DeviceType) => {
  if (deviceType === "desktop") {
    return "90px 142px 142px 142px";
  }
  if (deviceType === "tablet") {
    return "60px 18px 80px 18px";
  }
  return "40px 0px 92px 0px";
};

export const ContactUsComponentWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-columns: ${({ deviceType }) => getContactUsWidth(deviceType)};
  padding: ${({ deviceType }) => getContactUsPadding(deviceType)};
  grid-column-gap: ${({ deviceType }) =>
    deviceType === "desktop" ? "24px" : undefined};
`;

export const ContactUsComponentContentWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: ${({ deviceType }) =>
    deviceType === "desktop" ? "40px auto" : "40px auto auto"};
  grid-column-gap: ${({ deviceType }) =>
    deviceType === "desktop" ? "24px" : undefined};
`;

export const ContactUsPageDesktopRightWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: ${({ deviceType }) =>
    deviceType === "desktop" ? "40px 268px 420px" : "70px 268px 420px"};

  .contact-us-connect {
    line-height: 60px !important;
  }
`;

export const ContactUsGeneralContainer = styled.div<{ padding: string }>`
  margin-top: 20px;
  padding: ${({ padding }) => padding};
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${({ theme }) => theme.boxShadow};
  max-width: 802px;
  border-radius: 24px;
`;
