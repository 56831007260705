import styled from "styled-components/macro";
import { Typography as MuiTypography } from "@mui/material";
import { Link as ReactLink } from "react-router-dom";
import { ColorPalette, ThemePalette } from "../../../theme";

export type TypographySize =
  | "label"
  | "xsmall"
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "h3"
  | "h2"
  | "h1";

type FontFamily = "light" | "bold" | "caps" | "label";

interface TypographyStyle {
  fontSize: string;
  lineHeight: string;
  fontFamily: FontFamily;
  fontWeight: number;
}

export const getTypographySize = (
  size: TypographySize,
  isBold: boolean,
  isCaps: boolean,
): TypographyStyle => {
  let fw = 400;
  let ff: FontFamily = isBold ? "bold" : "light";
  if (isBold) {
    ff = "bold";
    fw = 700;
  }
  switch (size) {
    case "label":
      return {
        fontSize: "18px",
        lineHeight: "20px",
        fontFamily: "label",
        fontWeight: fw,
      };
    case "xsmall":
      return {
        fontSize: "12px",
        lineHeight: "18px",
        fontFamily: ff,
        fontWeight: fw,
      };
    case "small":
      return {
        fontSize: "14px",
        lineHeight: "20px",
        fontFamily: isCaps ? "caps" : ff,
        fontWeight: isCaps ? 900 : fw,
      };
    case "medium":
      return {
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: isCaps ? "caps" : ff,
        fontWeight: isCaps ? 900 : fw,
      };
    case "large":
      return {
        fontSize: "18px",
        lineHeight: "28px",
        fontFamily: ff,
        fontWeight: fw,
      };
    case "xlarge":
      return {
        fontSize: "20px",
        lineHeight: "28px",
        fontFamily: ff,
        fontWeight: fw,
      };
    case "h3":
      return {
        fontSize: "24px",
        lineHeight: "30px",
        fontFamily: ff,
        fontWeight: fw,
      };
    case "h2":
      return {
        fontSize: "28px",
        lineHeight: "34px",
        fontFamily: ff,
        fontWeight: fw,
      };
    case "h1":
      return {
        fontSize: "32px",
        lineHeight: "40px",
        fontFamily: ff,
        fontWeight: fw,
      };
    default:
      return {
        fontSize: "12px",
        lineHeight: "18px",
        fontFamily: "light",
        fontWeight: fw,
      };
  }
};

export const getFontFeatureSettings = (size: TypographySize): string => {
  switch (size) {
    case "large":
      return "'ss02' on, 'calt' off";
    case "xlarge":
    case "h1":
      return "'case' on";
    default:
      return "";
  }
};

export const StyledTypography = styled(MuiTypography)<{
  size: TypographySize;
  $isBold: boolean;
  $isCaps: boolean;
  $isItalic: boolean;
  $textColor?: keyof ColorPalette;
  padding?: string;
  $externalLink?: boolean;
}>`
  && {
    color: ${({ theme, $textColor }) =>
      $textColor ? (theme as ThemePalette)[$textColor] : theme.greyMedium};
    font-size: ${({ size, $isBold, $isCaps }) =>
      getTypographySize(size, $isBold, $isCaps).fontSize};
    line-height: ${({ size, $isBold, $isCaps }) =>
      getTypographySize(size, $isBold, $isCaps).lineHeight};
    font-family: ${({ size, $isBold, $isCaps }) =>
      getTypographySize(size, $isBold, $isCaps).fontFamily};
    font-weight: ${({ size, $isBold, $isCaps }) =>
      getTypographySize(size, $isBold, $isCaps).fontWeight};
    text-transform: ${({ $isCaps }) => ($isCaps ? "uppercase" : "")};
    font-feature-settings: ${({ size }) => getFontFeatureSettings(size)};
    letter-spacing: normal;
    font-style: ${({ $isItalic }) => ($isItalic ? "italic" : "")};
    margin: 0;
    padding: ${({ padding }) => padding || "0"};
    &:hover {
      color: ${({ theme, onClick, $externalLink }) =>
        onClick || $externalLink ? theme.greenDark : undefined};
      cursor: ${({ onClick, $externalLink }) =>
        onClick || $externalLink ? "pointer" : "undefined"};
    }
  }
`;

export const TypographyLink = styled(ReactLink)<{
  $textColor?: keyof ColorPalette;
}>`
  && {
    color: ${({ theme, $textColor }) =>
      $textColor ? (theme as ThemePalette)[$textColor] : "inherit"} !important;
    width: fit-content !important;
  }
  p {
    &:hover {
      color: ${({ theme }) => theme.greenDark} !important;
    }
  }
`;
