import React from "react";
import {
  CONTACT_US_URL,
  HELP_PAGE_URL,
  PROFILE_URL,
  SCHEDULING_URL,
} from "../../utils/urlConstants";
import { GetTypographyContent } from "../shared/Typography/Typography";

export const FooterLinkInfo = (): React.ReactElement => (
  <>
    {GetTypographyContent({
      content: "About Us",
      size: "medium",
      link: "https://billiontoone.com/about",
      externalLink: true,
    })}
    {GetTypographyContent({
      content: "Help & FAQ",
      size: "medium",
      link: HELP_PAGE_URL,
    })}
    {GetTypographyContent({
      content: "Contact Us",
      size: "medium",
      link: CONTACT_US_URL,
    })}
    {GetTypographyContent({
      content: "Profile",
      size: "medium",
      link: PROFILE_URL,
    })}
    {GetTypographyContent({
      content: "Book a Consultation",
      size: "medium",
      link: SCHEDULING_URL,
    })}
  </>
);
