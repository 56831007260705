import React from "react";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { GetTypographyContent } from "../shared/Typography/Typography";
import {
  ResourcesInfoContainer,
  ResourcesSingleFrameContainter,
  ResourcesTitleContainter,
} from "./Resources.styles";
import { ResourcesData } from "./ResourcesData";

export const Resources = (): React.ReactElement => {
  const { deviceType } = useDeviceHook();

  return (
    <ResourcesSingleFrameContainter>
      <ResourcesTitleContainter>
        {GetTypographyContent({
          content: "Resources",
          isBold: true,
          isCaps: true,
          size: "large",
          textColor: "greyMedium",
        })}
      </ResourcesTitleContainter>
      <ResourcesInfoContainer deviceType={deviceType}>
        {ResourcesData()}
      </ResourcesInfoContainer>
    </ResourcesSingleFrameContainter>
  );
};
