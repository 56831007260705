import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { Modal } from "@mui/material";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import CloseIcon from "@mui/icons-material/Close";
import {
  UpfrontPaymentButtonRow,
  UpfrontPaymentCloseIcon,
  UpfrontPaymentDesktopContainer,
  UpfrontPaymentFinancialModalWrapper,
  UpfrontPaymentOptional,
  UpfrontPaymentSaveCreditCardGrid,
  UpfrontPaymentStripeInput,
  UpfrontPaymentStripeInputError,
  UpfrontPaymentStripeInputRow,
  UpfrontPaymentStripePaymentDisclosure,
} from "./UpfrontPayment.styles";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { Button } from "../shared/Button/Button";
import { StyledButton } from "../shared/Button/Button.styles";
import { UpfrontPaymentReceipt } from "./UpfrontPaymentReceipt";
import {
  PayWithSavedCardUpfront,
  PostUpfrontPaymentPlanSuccess,
  PostUpfrontPaymentSuccess,
  UpfrontPaymentType,
  getCentsToDollars,
} from "./Services/UpfrontPaymentService";
import { BillingSupportNumber, StepperIndex } from "./types";
import { getUserAuth } from "../../recoil/selectors";
import { validateEmail } from "../../utils/hooks/helpers";
import { SpinnerLoading } from "../shared/Spinner/SpinnerLoading";
import { ErrorPopup } from "../shared/ErrorPopup/ErrorPopup";
import {
  ProfileInsuranceModalTitle,
  ProfileModalTitleWrapper,
} from "../Profile/ProfileInsuranceModal.styles";

export const UpfrontPaymentStripe: React.FC<{
  stripeModalOpen: boolean;
  setStripeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  paymentSubmitted: boolean;
  setPaymentSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  setUpfrontBill: React.Dispatch<
    React.SetStateAction<UpfrontPaymentType | undefined>
  >;
  setActiveStep: React.Dispatch<React.SetStateAction<StepperIndex>>;
  currentStep: StepperIndex;
  clientSecret: string;
  paymentAmount: number;
  upfrontPayment: UpfrontPaymentType;
  useSavedPayment?: string;
  useNewCard: boolean;
}> = ({
  stripeModalOpen,
  setStripeModalOpen,
  clientSecret,
  paymentAmount,
  setPaymentSubmitted,
  paymentSubmitted,
  currentStep,
  setUpfrontBill,
  setActiveStep,
  useSavedPayment,
  upfrontPayment,
  useNewCard,
}) => {
  const { deviceType } = useDeviceHook();
  const auth = useRecoilValue(getUserAuth);
  const { billingPK, adminID } = useParams<{
    billingPK: string;
    adminID?: string;
  }>();

  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [stripeReady, setStripeReady] = useState(false);
  const [saveCard, setSaveCard] = useState(false);

  useEffect(() => {
    // Prompt confirmation when reload page is triggered
    window.onbeforeunload = () => "";

    // Unmount the window.onbeforeunload event
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    if (currentStep !== StepperIndex.PaymentPlan && !clientSecret) {
      setErrorMessage(
        `There is an error - please contact us at ${BillingSupportNumber} for more details.`,
      );
    }
  }, [clientSecret]);

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    if (loading) {
      return;
    }

    if (useSavedPayment && auth) {
      setLoading(true);
      const savedPaymentResponse = await PayWithSavedCardUpfront(
        billingPK,
        useSavedPayment,
        auth?.email || email,
        currentStep === StepperIndex.PaymentPlan
          ? "payment_plan"
          : "pay_in_full",
        auth?.access_token,
      );
      const responseJson = await savedPaymentResponse.json();
      setUpfrontBill(responseJson);
      setPaymentSubmitted(true);
      setLoading(false);

      return;
    }

    await elements.submit();
    setErrorMessage("");
    setLoading(true);

    if (currentStep === StepperIndex.PaymentPlan) {
      const paymentPlanStripeResponse = await stripe.createPaymentMethod({
        elements,
        params: {
          metadata: {
            bill_pk: billingPK,
          },
        },
      });

      if (paymentPlanStripeResponse?.paymentMethod?.id) {
        const paymentPlanSyncResponse = await PostUpfrontPaymentPlanSuccess(
          billingPK,
          paymentPlanStripeResponse.paymentMethod.id,
          auth ? auth.email : email,
          saveCard,
          adminID,
        );
        setLoading(false);
        if (paymentPlanSyncResponse?.ok) {
          const paymentPlanSyncJson = await paymentPlanSyncResponse.json();
          setPaymentSubmitted(true);
          setUpfrontBill(paymentPlanSyncJson.bill);
        } else if (paymentPlanSyncResponse.status === 400) {
          const paymentPlanSyncJson = await paymentPlanSyncResponse.json();
          setErrorMessage(paymentPlanSyncJson.error);
        }
      }
      setLoading(false);
    } else if (clientSecret) {
      const paymentPlanStripeResponse = await stripe.createPaymentMethod({
        elements,
        params: {
          metadata: {
            bill_pk: billingPK,
          },
        },
      });

      const oneTimePaymentStripeResponse = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: paymentPlanStripeResponse.paymentMethod?.id || "",
          setup_future_usage: "off_session",
        },
      );

      if (oneTimePaymentStripeResponse.error) {
        setLoading(false);
        setErrorMessage(oneTimePaymentStripeResponse.error.message);
      } else if (
        oneTimePaymentStripeResponse.paymentIntent.status === "succeeded"
      ) {
        const oneTimePaymentSyncResponse = await PostUpfrontPaymentSuccess(
          billingPK,
          clientSecret,
          auth ? auth.email : email,
          saveCard,
          adminID,
          paymentPlanStripeResponse.paymentMethod?.id,
        );
        setLoading(false);
        if (oneTimePaymentSyncResponse?.ok) {
          const oneTimePaymentSyncJson =
            await oneTimePaymentSyncResponse.json();
          setUpfrontBill(oneTimePaymentSyncJson);
          setPaymentSubmitted(true);
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    const sendPrePayment = async () => {
      if (
        useSavedPayment &&
        auth &&
        upfrontPayment.portal_bill_status === "outstanding" &&
        stripeModalOpen
      ) {
        setLoading(true);
        const savedPaymentResponse = await PayWithSavedCardUpfront(
          billingPK,
          useSavedPayment,
          auth?.email || email,
          currentStep === StepperIndex.PaymentPlan
            ? "payment_plan"
            : "pay_in_full",
          auth?.access_token,
        );
        if (savedPaymentResponse?.ok) {
          const responseJson = await savedPaymentResponse.json();
          setUpfrontBill(responseJson);
          setPaymentSubmitted(true);
          setLoading(false);
        }
      }
    };
    sendPrePayment();
  }, [stripeModalOpen]);

  return (
    <Modal
      open={stripeModalOpen}
      onClose={() => setStripeModalOpen(false)}
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        pointerEvents: loading ? "none" : undefined,
      }}
    >
      <UpfrontPaymentFinancialModalWrapper
        deviceType={deviceType}
        allowScroll
        receipt={false}
      >
        <ProfileInsuranceModalTitle deviceType={deviceType}>
          <ProfileModalTitleWrapper deviceType={deviceType}>
            <div>Pay Bill</div>
            <CloseIcon onClick={() => setStripeModalOpen(false)} />
          </ProfileModalTitleWrapper>
        </ProfileInsuranceModalTitle>
        <div style={{ margin: paymentSubmitted ? "0px" : "20px" }}>
          {paymentSubmitted ? (
            <>
              <UpfrontPaymentReceipt
                modal
                setModal={setStripeModalOpen}
                isPaymentPlan={currentStep === StepperIndex.PaymentPlan}
                setActiveStep={setActiveStep}
                paymentAmount={paymentAmount}
              />
            </>
          ) : (
            <>
              {errorMessage && (
                <ErrorPopup
                  message={errorMessage}
                  hideMessage={() => setErrorMessage("")}
                />
              )}
              {!useNewCard && <SpinnerLoading />}
              <form
                style={{
                  display: useNewCard ? "grid" : "none",
                }}
                onSubmit={handleSubmit}
              >
                {!auth && (
                  <UpfrontPaymentStripeInputRow>
                    <div>
                      {" "}
                      Email{" "}
                      <UpfrontPaymentOptional>
                        {` ${
                          currentStep === StepperIndex.PaymentPlan
                            ? " (required)"
                            : " (optional)"
                        }`}{" "}
                      </UpfrontPaymentOptional>
                    </div>
                    <UpfrontPaymentStripeInput
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {email && !validateEmail(email) && (
                      <UpfrontPaymentStripeInputError>
                        {" "}
                        Please enter a valid email
                      </UpfrontPaymentStripeInputError>
                    )}
                  </UpfrontPaymentStripeInputRow>
                )}
                {!stripeReady && <SpinnerLoading />}
                <PaymentElement
                  options={{
                    paymentMethodOrder: ["card"],
                    layout: {
                      type: "tabs",
                    },
                  }}
                  onReady={() => setStripeReady(true)}
                />
                {!useSavedPayment && stripeReady && (
                  <UpfrontPaymentSaveCreditCardGrid>
                    <input
                      type="checkbox"
                      onClick={() => {
                        setSaveCard(!saveCard);
                      }}
                      checked={saveCard}
                    />

                    <div>Save this card for faster checkout</div>
                  </UpfrontPaymentSaveCreditCardGrid>
                )}
                {currentStep === StepperIndex.PaymentPlan && (
                  <UpfrontPaymentStripePaymentDisclosure>
                    By scheduling payments, I agree to the payment plan, and my
                    first payment will be processed today.
                  </UpfrontPaymentStripePaymentDisclosure>
                )}
                {loading && useNewCard ? (
                  <SpinnerLoading />
                ) : (
                  <>
                    {" "}
                    <UpfrontPaymentButtonRow>
                      <StyledButton
                        type="submit"
                        size="medium"
                        $backgroundColor="greenDark"
                        disabled={
                          !stripe ||
                          (currentStep === StepperIndex.PaymentPlan &&
                            !auth &&
                            !email) ||
                          (currentStep !== StepperIndex.PaymentPlan &&
                            !clientSecret) ||
                          Boolean(email && !validateEmail(email))
                        }
                      >
                        {currentStep === StepperIndex.PaymentPlan
                          ? "Schedule Payments"
                          : `Pay ${getCentsToDollars(paymentAmount)}`}
                      </StyledButton>
                    </UpfrontPaymentButtonRow>
                    <UpfrontPaymentButtonRow bottomRow tertiary>
                      <Button
                        label="Cancel"
                        size="medium"
                        backgroundColor="greyMedium"
                        onClick={() => {
                          setErrorMessage("");
                          setStripeModalOpen(false);
                        }}
                        reverse
                      />
                    </UpfrontPaymentButtonRow>
                  </>
                )}
              </form>
            </>
          )}
        </div>
      </UpfrontPaymentFinancialModalWrapper>
    </Modal>
  );
};
