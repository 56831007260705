import * as React from "react";

export const ChatIcon = (): React.ReactElement => (
  <svg
    width="32"
    height="35"
    viewBox="0 0 32 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 18.9972C18 16.8174 16.1879 15.05 13.953 15.05H4.04698C1.81208 15.05 0 16.8174 0 18.9972V26.6852C0 28.6294 1.44966 30.2494 3.35235 30.5735V33.902C3.35235 34.1377 3.50336 34.3733 3.71477 34.4911C3.80537 34.5501 3.92617 34.55 4.01678 34.55C4.16779 34.55 4.28859 34.5206 4.4094 34.4322L6.43289 32.9889C8.63758 31.4277 11.2349 30.6029 13.953 30.6029C16.1879 30.6029 18 28.8355 18 26.6558V18.9972Z"
      fill="#03CBBA"
    />
    <path
      d="M31.3996 6.7C31.3996 3 28.3996 0 24.6996 0H8.29961C4.59961 0 1.59961 3 1.59961 6.7V19.75C1.59961 23.05 3.99961 25.8 7.14961 26.35V32C7.14961 32.4 7.39961 32.8 7.74961 33C7.89961 33.1 8.09961 33.1 8.24961 33.1C8.49961 33.1 8.69961 33.05 8.89961 32.9L12.2496 30.45C15.8996 27.8 20.1996 26.4 24.6996 26.4C28.3996 26.4 31.3996 23.4 31.3996 19.7V6.7ZM29.1996 19.75C29.1996 22.2 27.1996 24.2 24.7496 24.2C19.7996 24.2 15.0496 25.75 10.9996 28.65L9.39961 29.8V25.3C9.39961 24.7 8.89961 24.2 8.29961 24.2C5.84961 24.2 3.84961 22.2 3.84961 19.75V6.7C3.84961 4.25 5.84961 2.25 8.29961 2.25H24.7496C27.1996 2.25 29.1996 4.25 29.1996 6.7V19.75Z"
      fill="#5D5A5A"
    />
  </svg>
);
