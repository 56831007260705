import styled from "styled-components/macro";

export const VideoPageWrapper = styled.div<{ deviceType: string }>`
  margin: 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;

  ${({ deviceType }) =>
    deviceType === "desktop" &&
    `
  margin-left: 139px;
  max-width: 770px;
  `}
`;

export const VideoPageBackTo = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  cursor: pointer;
`;

export const VideoTitle = styled.div`
  align-self: stretch;
  color: #636467;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  word-wrap: break-word;
  padding-left: 16px;
  margin-top: 14px;
`;

export const VideoDescription = styled.div`
  align-self: stretch;
  color: #636467;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
  padding-left: 16px;
  margin-bottom: 15px;
`;
