import React, { useEffect, useState } from "react";
import {
  BreakBar,
  InfoBox,
  InfoList,
  UpfrontPaymentBigCost,
  UpfrontPaymentButtonRow,
  UpfrontPaymentDesktopContainer,
  UpfrontPaymentFieldRow,
  UpfrontPaymentFieldRowTitle,
} from "./UpfrontPayment.styles";
import {
  getCentsToDollars,
  UpfrontPaymentType,
} from "./Services/UpfrontPaymentService";
import { Button } from "../shared/Button/Button";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { OA100PaymentStripe } from "./OA100PaymentStripe";
import { GetTypographyContent } from "../shared/Typography/Typography";
import { PaidIcon } from "../../assets/iconComponents/PaidIcon";
import { OA100Receipt } from "./OA100Receipt";
import { CheckEOBFileUploader } from "./CheckEOBUploader";
import { OA100NoPaymentRequired } from "./OA100NoPaymentRequired";
import UpfrontPaymentPhoneLink from "./UpfrontPaymentPhoneLink";

export const UpfrontPaymentOA100: React.FC<{
  upfrontBill: UpfrontPaymentType;
  setUpfrontBill: React.Dispatch<
    React.SetStateAction<UpfrontPaymentType | undefined>
  >;
}> = ({ upfrontBill, setUpfrontBill }) => {
  const { deviceType } = useDeviceHook();
  const [showOA100Stripe, setShowOA100Stripe] = useState(false);
  const [paymentSubmitted, setPaymentSubmitted] = useState(false);

  useEffect(() => {
    if (upfrontBill?.oa_100_billing_status === "paid_in_full") {
      setPaymentSubmitted(true);
    }
  }, [upfrontBill]);

  const openOA100Stripe = () => {
    setShowOA100Stripe(true);
  };

  if (upfrontBill?.oa_100_billing_status === "no_oa_100_payment_required") {
    return <OA100NoPaymentRequired />;
  }

  return (
    <>
      <OA100PaymentStripe
        setUpfrontBill={setUpfrontBill}
        setStripeModalOpen={setShowOA100Stripe}
        stripeModalOpen={showOA100Stripe}
        clientSecret={upfrontBill?.oa_100_client_secret}
        paymentAmount={upfrontBill.oa100_balance}
        setPaymentSubmitted={setPaymentSubmitted}
        useNewCard
      />
      <UpfrontPaymentDesktopContainer deviceType={deviceType}>
        <UpfrontPaymentFieldRow adjustable>
          <UpfrontPaymentFieldRowTitle>
            Additional Responsibility
          </UpfrontPaymentFieldRowTitle>
          {paymentSubmitted && (
            <>
              <br />
              <UpfrontPaymentFieldRow>
                <UpfrontPaymentFieldRowTitle>
                  Payment Status
                </UpfrontPaymentFieldRowTitle>
                {GetTypographyContent({
                  content: <PaidIcon />,
                  size: "medium",
                  textColor: "black",
                })}
              </UpfrontPaymentFieldRow>
              <OA100Receipt />
            </>
          )}
          {!paymentSubmitted && (
            <>
              <UpfrontPaymentBigCost marginTop={8}>
                {getCentsToDollars(upfrontBill?.oa100_balance)}
              </UpfrontPaymentBigCost>
              <InfoBox>
                <InfoList>
                  <li>
                    Our records indicate that you have received or will receive
                    a check from your insurance company to cover this portion.
                  </li>
                  <br />
                  <li>
                    {`If you received a check for a different amount or have not yet
                received a check, please contact us at `}
                    <UpfrontPaymentPhoneLink />
                    {` for assistance.`}
                  </li>
                </InfoList>
              </InfoBox>
            </>
          )}
        </UpfrontPaymentFieldRow>
        {!paymentSubmitted && (
          <UpfrontPaymentButtonRow>
            <Button
              type="submit"
              label={`Pay ${getCentsToDollars(upfrontBill?.oa100_balance)}`}
              size="medium"
              onClick={() => openOA100Stripe()}
              backgroundColor="greenDark"
            />
          </UpfrontPaymentButtonRow>
        )}
        <BreakBar />
        <UpfrontPaymentFieldRowTitle>
          Upload EOB or Check
        </UpfrontPaymentFieldRowTitle>
        <CheckEOBFileUploader />
      </UpfrontPaymentDesktopContainer>
    </>
  );
};
