import React from "react";

import { useHistory, useParams } from "react-router-dom";

import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import {
  BabyPeekIntroWrapper,
  BabyPeekIntroBodyWrapper,
  BabyPeekIntroTitle,
  BabyPeekIntroDescription,
  BabyPeekIntroInfoBarWrapper,
  BabyPeekIntroInfoBarBig,
  BabyPeekIntroInfoBarDescription,
  BabyPeekIntroCTAWrapper,
  BabyPeekCTATitle,
  BabyPeekFAQTitle,
  BabyPeekIntroFAQAccordionWrapper,
  BabyPeekDisclaimer,
  BabyPeekDisclaimerTitle,
  BabyPeekDisclaimerGrid,
  BabyPeekDisclaimerText,
  BabyPeekIntroGrid,
  BabyPeekIntroInfoBarWrapperNew,
  BabyPeekIntroMarqueeWrapper,
  BabyPeekRatingsWrapper,
  BabyPeekIntroInfoBarBigMobile,
  BabyPeekIntroInfoBarDescriptionMobile,
  BabyPeekIntroMessageWrapper,
} from "./BabyPeekIntro.styles";

import Decorations from "./BabyPeekIcons/Decorations.png";

import { BabyPeekButton } from "./BabyPeekButton";
import {
  BabyPeekAccordions,
  babyPeekFAQAccordion,
  babyPeekGameAccordion,
} from "./BabyPeekAccordion";
import {
  BabyPeekPaymentGradientContentSpecialGrid,
  BabyPeekPaymentGradientTitle,
  BabyPeekPaymentGradientTitleStrikeThrough,
  BabyPeekPaymentPromotionGradient,
  BabyPeekPaymentPromotionGradientContent,
  BabyPeekPaymentPromotionalPriceGrid,
  BabyPeekPaymentSummaryDescription,
  BabyPeekPaymentSummaryItemGrid,
  BabyPeekPaymentSummaryItemGridRow,
  BabyPeekPaymentSummaryTitleTrait,
  BabyPeekPreTestMessage,
} from "./BabyPeekStripe.styles";
import { DetailedResultsBabyPeekText } from "../DetailedResults/DetailedResults.styles";
import { EyeCTA } from "./BabyPeekIcons/EyeCTA";
import { WaveCTA } from "./BabyPeekIcons/WaveCTA";
import { TasteCTA } from "./BabyPeekIcons/TasteCTA";
import { TeethCTA } from "./BabyPeekIcons/TeethCTA";
import {
  BabyPeekButtonNames,
  BabyPeekTraitResponse,
  getUnlockButtonText,
} from "./Services/BabyPeekService";
import { getCentsToDollars } from "../UpfrontPayment/Services/UpfrontPaymentService";
import { BabyPeekIcon } from "./BabyPeekIcons/BabyPeekIcon";
import { BABYPEEK_URL } from "../../utils/urlConstants";
import { TraitMarquee } from "./TraitMarquee";
import BabyPeekVideoEmbed from "../shared/Video/BabyPeekVideoEmbed";
import { TraitSummaryList } from "./BabyPeekIcons/TraitSummaryList";
import { TechnologyGene } from "./BabyPeekIcons/TechnologyGene";
import { FutureFoodie } from "./BabyPeekIcons/FutureFoodie";
import { BabyPeekRatings } from "./BabyPeekIcons/BabyPeekRatings";
import { BabyPeekRatingsStar } from "./BabyPeekIcons/BabyPeekRatingsStar";
import { BabyPeekGamesExclusive } from "./BabyPeekIcons/BabyPeekGamesExclusive";
import { BabyPeekGamesExplosions } from "./BabyPeekIcons/BabyPeekGamesExplosions";
import { BabyPeekChangeIcon } from "./BabyPeekIcons/BabyPeekChangeIcon";
import BabyPeekMultipleReqHeader from "./BabyPeekMultipleReqHeader";

export const BabyPeekIntro: React.FC<{
  babyPeekReport: BabyPeekTraitResponse;
  babyPeekPKVal?: string;
  setLastButtonClick?: React.Dispatch<
    React.SetStateAction<BabyPeekButtonNames | undefined>
  >;
}> = ({ babyPeekReport, babyPeekPKVal, setLastButtonClick }) => {
  const { deviceType } = useDeviceHook();
  const history = useHistory();

  let { babypeekPK } = useParams<{
    babypeekPK: string;
  }>();
  babypeekPK = babypeekPK ?? babyPeekPKVal;

  return (
    <>
      <div
        style={{
          display: "grid",
          background:
            "linear-gradient(180deg, #FFF6CF 0%, rgba(255, 253, 244, 0.60) 5%, #FFFDF4 100%, #FFFDF4 100%), #FFF",
        }}
      >
        <BabyPeekIntroWrapper>
          <BabyPeekMultipleReqHeader />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto",
              padding: "64px 64px",
              gap: "16px",
            }}
          >
            <BabyPeekIntroGrid>
              <div
                style={{
                  display: "grid",
                  alignItems: "center",
                  justifySelf: "center",
                }}
              >
                <BabyPeekIcon />
                <BabyPeekRatingsWrapper
                  onClick={() => {
                    const anchorEl = document.getElementById("babypeekRatings");
                    if (anchorEl) {
                      anchorEl.scrollIntoView();
                    }
                  }}
                >
                  <BabyPeekRatings />
                </BabyPeekRatingsWrapper>
              </div>
              <BabyPeekIntroTitle deviceType={deviceType}>
                Discover your baby’s traits before birth
              </BabyPeekIntroTitle>
              <BabyPeekIntroDescription deviceType={deviceType}>
                {babyPeekReport.experiment_details.landing_page === "a"
                  ? `Explore beyond the results of your UNITY Screen™ and learn more about your baby’s unique traits – all from your blood sample you already provided.`
                  : `Explore beyond the results of your UNITY Screen™ and discover your baby’s unique traits—all from the blood sample you already provided.`}
              </BabyPeekIntroDescription>
              <div style={{ display: "grid", gridGap: "12px" }}>
                <>
                  {babyPeekReport?.reveal_status === "pending" && (
                    <>
                      <BabyPeekButton
                        variant="filled"
                        id="vwo-unlock-babypeek"
                        style={{
                          width: "300px",
                          height: "44px",
                          justifySelf: "center",
                        }}
                        content={<>Unlock BabyPeek</>}
                        onClick={() => {
                          if (setLastButtonClick) {
                            setLastButtonClick("IntroMainUnlock");
                          }
                          history.push(
                            `${BABYPEEK_URL}/${babypeekPK}/checkout`,
                          );
                        }}
                      />
                    </>
                  )}
                </>
              </div>
            </BabyPeekIntroGrid>
          </div>
        </BabyPeekIntroWrapper>
        <BabyPeekIntroBodyWrapper>
          <BabyPeekIntroMarqueeWrapper style={{ padding: "0px 64px" }}>
            <TraitMarquee />
          </BabyPeekIntroMarqueeWrapper>

          <BabyPeekIntroInfoBarWrapper deviceType={deviceType}>
            <div>
              <BabyPeekIntroInfoBarBig>Eye Color</BabyPeekIntroInfoBarBig>
              <BabyPeekIntroInfoBarBig>Hair Color</BabyPeekIntroInfoBarBig>
              <BabyPeekIntroInfoBarBig style={{ marginBottom: "48px" }}>
                Hair texture
              </BabyPeekIntroInfoBarBig>
              <BabyPeekIntroInfoBarDescription>
                BabyPeek uses advanced technology to analyze well-known genetic
                factors that determine which traits a baby will most likely
                have. Much like a weather report, results are calculated as
                probabilities.
              </BabyPeekIntroInfoBarDescription>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "-32px",
              }}
            >
              <TraitSummaryList />
            </div>
          </BabyPeekIntroInfoBarWrapper>
          {babyPeekReport.unlock_type === "discount_payment" && (
            <BabyPeekPaymentPromotionGradient deviceType={deviceType}>
              <BabyPeekPaymentPromotionGradientContent>
                <BabyPeekPaymentGradientContentSpecialGrid>
                  <BabyPeekPaymentGradientTitle>
                    <BabyPeekIntroInfoBarBig>
                      BabyPeek Reports
                    </BabyPeekIntroInfoBarBig>
                    <BabyPeekPaymentPromotionalPriceGrid>
                      <DetailedResultsBabyPeekText>
                        {getCentsToDollars(
                          babyPeekReport.bill_details.balance,
                          false,
                        )}
                      </DetailedResultsBabyPeekText>
                      <BabyPeekPaymentGradientTitleStrikeThrough>
                        {getCentsToDollars(24900, true)}
                      </BabyPeekPaymentGradientTitleStrikeThrough>
                    </BabyPeekPaymentPromotionalPriceGrid>
                  </BabyPeekPaymentGradientTitle>

                  <BabyPeekPaymentGradientTitle>
                    <BabyPeekPaymentSummaryItemGrid>
                      <div>
                        <WaveCTA />
                      </div>
                      <BabyPeekPaymentSummaryItemGridRow>
                        <BabyPeekPaymentSummaryTitleTrait
                          deviceType={deviceType}
                        >
                          Hair
                        </BabyPeekPaymentSummaryTitleTrait>
                        <BabyPeekPaymentSummaryDescription
                          deviceType={deviceType}
                        >
                          Light or dark hair &bull; Red hair &bull; Hair
                          thickness &bull; Curly hair
                        </BabyPeekPaymentSummaryDescription>
                      </BabyPeekPaymentSummaryItemGridRow>
                    </BabyPeekPaymentSummaryItemGrid>
                    <BabyPeekPaymentSummaryItemGrid>
                      <div>
                        <EyeCTA />
                      </div>
                      <BabyPeekPaymentSummaryItemGridRow>
                        <BabyPeekPaymentSummaryTitleTrait
                          deviceType={deviceType}
                        >
                          Eye Color
                        </BabyPeekPaymentSummaryTitleTrait>
                        <BabyPeekPaymentSummaryDescription
                          deviceType={deviceType}
                        >
                          Eye color
                        </BabyPeekPaymentSummaryDescription>
                      </BabyPeekPaymentSummaryItemGridRow>
                    </BabyPeekPaymentSummaryItemGrid>
                  </BabyPeekPaymentGradientTitle>
                  <BabyPeekPaymentGradientTitle>
                    <BabyPeekPaymentSummaryItemGrid>
                      <div>
                        <TeethCTA />
                      </div>

                      <BabyPeekPaymentSummaryItemGridRow>
                        <BabyPeekPaymentSummaryTitleTrait
                          deviceType={deviceType}
                        >
                          Other traits
                        </BabyPeekPaymentSummaryTitleTrait>
                        <BabyPeekPaymentSummaryDescription
                          deviceType={deviceType}
                        >
                          Teeth &bull; Freckles &bull; Asparagus odor detection
                        </BabyPeekPaymentSummaryDescription>
                      </BabyPeekPaymentSummaryItemGridRow>
                    </BabyPeekPaymentSummaryItemGrid>
                    <BabyPeekPaymentSummaryItemGrid>
                      <div>
                        <TasteCTA />
                      </div>
                      <BabyPeekPaymentSummaryItemGridRow>
                        <BabyPeekPaymentSummaryTitleTrait
                          deviceType={deviceType}
                        >
                          Taste
                        </BabyPeekPaymentSummaryTitleTrait>
                        <BabyPeekPaymentSummaryDescription
                          deviceType={deviceType}
                        >
                          Cilantro &bull; Bitter taste &bull; Sweet &bull; Sweet
                          vs Salty
                        </BabyPeekPaymentSummaryDescription>
                      </BabyPeekPaymentSummaryItemGridRow>
                    </BabyPeekPaymentSummaryItemGrid>
                  </BabyPeekPaymentGradientTitle>
                </BabyPeekPaymentGradientContentSpecialGrid>
                {babyPeekReport?.reveal_status !== "prepaid" && (
                  <div style={{ display: "block" }}>
                    <BabyPeekButton
                      id="vwo-unlock-babypeek"
                      variant="outline"
                      content={<>{getUnlockButtonText()}</>}
                      onClick={() => {
                        if (setLastButtonClick) {
                          setLastButtonClick("PromoUnlock");
                        }
                        history.push(`${BABYPEEK_URL}/${babypeekPK}/checkout`);
                      }}
                    />
                  </div>
                )}
              </BabyPeekPaymentPromotionGradientContent>
            </BabyPeekPaymentPromotionGradient>
          )}
          <BabyPeekIntroInfoBarWrapper deviceType={deviceType}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FutureFoodie />
            </div>
            <div>
              <BabyPeekIntroInfoBarBig>Future Foodie</BabyPeekIntroInfoBarBig>

              <BabyPeekIntroInfoBarDescription>
                Sweet or salty? Sensitive to bitter tastes? Begin imagining the
                fun of introducing your baby to their future favorite foods and
                flavors.
              </BabyPeekIntroInfoBarDescription>
            </div>
          </BabyPeekIntroInfoBarWrapper>
          <BabyPeekIntroInfoBarWrapper deviceType="mobile">
            <div>
              <div style={{ marginTop: "-32px", marginBottom: "-195px" }}>
                <BabyPeekGamesExplosions />
              </div>
              <BabyPeekGamesExclusive />
              <BabyPeekIntroInfoBarBigMobile style={{ marginBottom: "24px" }}>
                BabyPeek Games
              </BabyPeekIntroInfoBarBigMobile>
              <BabyPeekIntroInfoBarDescriptionMobile>
                Unlock printable games and have fun discovering your baby’s
                traits with your friends and family!
              </BabyPeekIntroInfoBarDescriptionMobile>
              <div style={{ display: "grid", marginTop: "10px" }}>
                <BabyPeekAccordions
                  data={babyPeekGameAccordion(babypeekPK, "auth?.access_token")}
                />
              </div>
            </div>
          </BabyPeekIntroInfoBarWrapper>

          <BabyPeekIntroInfoBarWrapperNew
            style={{ position: "relative", justifyContent: "center" }}
            id="babypeekRatings"
          >
            <img
              src={Decorations}
              alt="Decorations"
              style={{ position: "absolute", top: "10px", right: "20px" }}
            />
            <img
              src={Decorations}
              alt="Decorations"
              style={{ position: "absolute", bottom: "10px", left: "20px" }}
            />
            <div style={{ display: "grid", justifyContent: "center" }}>
              <BabyPeekRatingsStar />
            </div>
            <div style={{ display: "grid", justifyItems: "center" }}>
              <BabyPeekVideoEmbed babypeekPK={babypeekPK} />
              <BabyPeekPreTestMessage>
                Four celebrated sisters discover baby traits together with
                BabyPeek.
              </BabyPeekPreTestMessage>
            </div>
          </BabyPeekIntroInfoBarWrapperNew>

          <BabyPeekIntroCTAWrapper deviceType={deviceType}>
            <BabyPeekCTATitle deviceType={deviceType}>
              Powered by Cutting Edge DNA Sequencing Technology
            </BabyPeekCTATitle>
            <TechnologyGene />
            <BabyPeekIntroInfoBarDescription>
              {`Thanks to our pioneering genetic testing technology, we can also explore your baby’s genetic traits hidden in the cell-free DNA fragments in your blood. Explore your baby’s unique characteristics today by ordering BabyPeek. `}
            </BabyPeekIntroInfoBarDescription>
          </BabyPeekIntroCTAWrapper>
          <BabyPeekIntroFAQAccordionWrapper deviceType={deviceType}>
            <BabyPeekFAQTitle deviceType={deviceType}>
              Frequently Asked Questions
            </BabyPeekFAQTitle>
            <BabyPeekAccordions data={babyPeekFAQAccordion} />
          </BabyPeekIntroFAQAccordionWrapper>
          <BabyPeekDisclaimer
            style={{
              display: "grid",
              backgroundColor: "#fff",
              backgroundSize: "100% 100%",
            }}
          >
            <BabyPeekDisclaimerTitle> Disclaimer</BabyPeekDisclaimerTitle>
            <BabyPeekDisclaimerGrid deviceType={deviceType}>
              <ul>
                <li>
                  <BabyPeekDisclaimerText>
                    BabyPeek does not provide information about the health of
                    the baby and is only for fun.
                  </BabyPeekDisclaimerText>
                </li>
                <li>
                  <BabyPeekDisclaimerText>
                    {` The results from BabyPeek are not guaranteed and only tell you what traits the baby is most likely to have. Your baby's actual traits may vary from what is predicted.`}
                  </BabyPeekDisclaimerText>
                </li>
              </ul>

              <ul>
                <li>
                  <BabyPeekDisclaimerText>
                    Your doctor did not order this additional test and should
                    not be contacted for questions about the results.{" "}
                  </BabyPeekDisclaimerText>
                </li>
                <li>
                  <BabyPeekDisclaimerText>
                    You should contact BillionToOne laboratory directly with
                    questions.
                  </BabyPeekDisclaimerText>
                </li>
              </ul>
            </BabyPeekDisclaimerGrid>
          </BabyPeekDisclaimer>
        </BabyPeekIntroBodyWrapper>
      </div>
    </>
  );
};
