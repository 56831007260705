import * as React from "react";

export const MaleFemale = (): React.ReactElement => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#F6FBFC" fillOpacity="0.24" />
    <path
      d="M17.5 25.3125H15.25V23.625C18.3789 23.3438 20.875 20.6719 20.875 17.4375C20.875 14.0273 18.0977 11.25 14.6875 11.25C11.2422 11.25 8.5 14.0273 8.5 17.4375C8.5 20.6719 10.9609 23.3438 14.125 23.625V25.3125H11.875C11.5586 25.3125 11.3125 25.5938 11.3125 25.875C11.3125 26.1914 11.5586 26.4375 11.875 26.4375H14.125V28.6875C14.125 29.0039 14.3711 29.25 14.6875 29.25C14.9688 29.25 15.25 29.0039 15.25 28.6875V26.4375H17.5C17.7812 26.4375 18.0625 26.1914 18.0625 25.875C18.0625 25.5938 17.7812 25.3125 17.5 25.3125ZM14.6875 22.5C11.875 22.5 9.625 20.25 9.625 17.4375C9.625 14.6602 11.875 12.375 14.6875 12.375C17.4648 12.375 19.75 14.6602 19.75 17.4375C19.75 20.25 17.4648 22.5 14.6875 22.5ZM30.9297 11.6016C30.8945 11.4609 30.7891 11.3555 30.6484 11.3203C30.5781 11.2852 30.5078 11.25 30.4375 11.25H25.9375C25.6211 11.25 25.375 11.5312 25.375 11.8125C25.375 12.1289 25.6211 12.375 25.9375 12.375H29.0664L26.5 14.9414C25.4453 14.0625 24.0391 13.5352 22.5273 13.5352C22.3516 13.5352 22.1406 13.5352 21.9297 13.5352C21.6133 13.5703 21.4023 13.8516 21.4375 14.168C21.4375 14.4844 21.7188 14.6953 22.0352 14.6602C22.2109 14.6602 22.3867 14.625 22.5625 14.625C25.3398 14.625 27.625 16.9102 27.625 19.6875C27.625 22.5 25.3398 24.75 22.5625 24.75C21.4375 24.75 20.3828 24.4336 19.5039 23.7656C19.2578 23.5547 18.9062 23.625 18.7305 23.8711C18.5195 24.1172 18.5898 24.4688 18.8359 24.6445C19.9258 25.4531 21.1914 25.875 22.5625 25.875C25.9727 25.875 28.75 23.0977 28.75 19.6875C28.75 18.2109 28.1875 16.8047 27.3086 15.75L29.875 13.1836V16.3125C29.875 16.6289 30.1211 16.875 30.4375 16.875C30.7188 16.875 31 16.6289 31 16.3125V11.8125C31 11.7422 30.9648 11.6719 30.9297 11.6016Z"
      fill="white"
    />
  </svg>
);
