import React, { useState, useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useHistory, useLocation } from "react-router-dom";
import { getSelectedRequisition, getUserAuth } from "../recoil/selectors";
import { patientProfileState, patientState } from "../recoil/atoms";
import {
  GetHeaderMenuLinks,
  GetLoginHeader,
} from "../components/Header/HeaderMenuLinks";
import {
  MobileMenuHr,
  MobileMenuLinkHrContainer,
  MenuContainer,
} from "./MobileMenu.styles";
import { LOGIN_PAGE_URL } from "../utils/urlConstants";
import { useDeviceHook } from "../utils/hooks/useDeviceHook";
import { showUpfrontPayment } from "../components/UpfrontPayment/Services/UpfrontPaymentService";

export const MobileMenu: React.FC<{ handleToggle: () => void }> = ({
  handleToggle,
}) => {
  const [activeHeader, setActiveHeader] = useState("");
  const [patientInfo, setPatientInfo] = useRecoilState(patientState);
  const [profileInfo, setProfileInfo] = useRecoilState(patientProfileState);
  const { pathname } = useLocation();
  const history = useHistory();
  const { deviceType } = useDeviceHook();
  const specificRequisition = useRecoilValue(getSelectedRequisition);

  useEffect(() => {
    setActiveHeader(pathname);
  }, []);

  const setPathnameCheck = () => {
    setActiveHeader(history.location.pathname);
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      setPathnameCheck();
    });
    return () => {
      unlisten();
    };
  }, []);

  const user = useRecoilValue(getUserAuth);

  const babyPeekReq = specificRequisition;
  const babyPeekStatus = specificRequisition?.reveal_status;

  const headerLinks = useMemo(
    () =>
      user?.email_verified
        ? GetHeaderMenuLinks(
            "white",
            "xlarge",
            activeHeader,
            setActiveHeader,
            profileInfo,
            setPatientInfo,
            setProfileInfo,
            deviceType,
            patientInfo?.requisitions?.filter((req) =>
              showUpfrontPayment(req.portal_bill_status),
            ),
            babyPeekReq && {
              status: babyPeekStatus,
              babyPeekPK: babyPeekReq.baby_peek_pk,
            },
            handleToggle,
          )
        : GetLoginHeader(
            "greyMedium",
            "medium",
            setPatientInfo,
            pathname === LOGIN_PAGE_URL,
            handleToggle,
          ),
    [
      pathname,
      user?.email_verified,
      profileInfo,
      babyPeekStatus,
      handleToggle,
      activeHeader,
    ],
  );

  const headerLinksWithHr = headerLinks
    .filter((header) => !header.hide)
    .map((headerLink) => (
      <MobileMenuLinkHrContainer key={headerLink.key}>
        {headerLink.header}
        <MobileMenuHr />
      </MobileMenuLinkHrContainer>
    ));

  return (
    <MenuContainer>
      <br />
      {headerLinksWithHr}
    </MenuContainer>
  );
};
