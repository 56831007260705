import * as React from "react";
import { themePalette } from "../../theme";

export const GreenCheckIcon = ({
  color = themePalette.greenMedium,
}: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3906 15.9375C10.875 16.4531 10.0781 16.4531 9.5625 15.9375L6.5625 12.9375C6.04688 12.4219 6.04688 11.625 6.5625 11.1094C7.07812 10.5938 7.875 10.5938 8.39062 11.1094L10.5 13.1719L15.5625 8.10938C16.0781 7.59375 16.875 7.59375 17.3906 8.10938C17.9062 8.625 17.9062 9.42188 17.3906 9.9375L11.3906 15.9375ZM24 12C24 18.6562 18.6094 24 12 24C5.34375 24 0 18.6562 0 12C0 5.39062 5.34375 0 12 0C18.6094 0 24 5.39062 24 12ZM12 2.25C6.60938 2.25 2.25 6.65625 2.25 12C2.25 17.3906 6.60938 21.75 12 21.75C17.3438 21.75 21.75 17.3906 21.75 12C21.75 6.65625 17.3438 2.25 12 2.25Z"
      fill={color}
    />
  </svg>
);
