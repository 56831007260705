import React, { useState } from "react";

import { useParams } from "react-router-dom";
import phone from "phone";
import { CreateAccountInput } from "../CreateAccount/CreateAccountForm.styles";
import { BASE_URL } from "../../utils/requestUtils";
import { validateEmail } from "../../utils/hooks/helpers";
import { SpinnerLoading } from "../shared/Spinner/SpinnerLoading";

export const ValidationTesting = (): React.ReactElement => {
  const [email, setEmail] = useState("");
  const [userPhone, setPhone] = useState("");
  const [data, setData] = useState<{
    dob: string;
    first_name: string;
    last_name: string;
    signup_url: string;
  }>();
  const { reqPK } = useParams<{ reqPK: string }>();
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
    event,
  ) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      setEmailError("Enter a valid email");
    } else if (!phone(userPhone).isValid) {
      setPhoneError("Enter a valid phone");
    } else {
      setLoading(true);
      const dataResponse = await fetch(`${BASE_URL}/api/v1/test_setup`, {
        method: "POST",
        body: JSON.stringify({
          email,
          phone_number: userPhone,
          requisition_pk: reqPK,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      setLoading(false);
      if (dataResponse?.ok) {
        const dataJson = await dataResponse.json();
        setData(dataJson);
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      {" "}
      {data ? (
        <div
          style={{
            display: "grid",
            gap: "20px",
          }}
        >
          <table cellPadding="10">
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>DOB</th>
            </tr>
            <tr>
              <td>{data.first_name}</td>
              <td>{data.last_name}</td>
              <td>{data.dob}</td>
            </tr>
          </table>

          <div
            style={{
              textAlign: "center",
            }}
          >
            <a href={data.signup_url} target="_blank" rel="noreferrer">
              {" "}
              Sign Up Link{" "}
            </a>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit} style={{ display: "grid", gap: " 20px" }}>
          <CreateAccountInput
            type="text"
            name="email"
            value={email}
            placeholder="Email"
            label="Email"
            size="small"
            onChange={(event) => {
              setEmail(event.target.value);
              setEmailError("");
            }}
            error={emailError}
          />
          <CreateAccountInput
            type="text"
            name="phone"
            value={userPhone}
            placeholder="Phone"
            label="Phone"
            size="small"
            onChange={(event) => {
              setPhone(event.target.value);
              setPhoneError("");
            }}
            error={phoneError}
          />
          {loading ? (
            <SpinnerLoading />
          ) : (
            <button type="submit">Submit </button>
          )}
        </form>
      )}
    </div>
  );
};
