import React, { useState } from "react";
import { useRecoilValue } from "recoil";

import { Formik, FormikErrors, FormikProps } from "formik";
import ReCAPTCHAV2 from "react-google-recaptcha";
import { Button } from "../shared/Button/Button";
import {
  ContactUsButtonContainer,
  ContactUsFormGrid,
  ContactUsInput,
} from "./ContactUs.styles";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { validateEmail } from "../../utils/hooks/helpers";
import ContactUsRequest from "./Services/ContactUsService";
import { getUserAuth } from "../../recoil/selectors";
import { ErrorPopup } from "../shared/ErrorPopup/ErrorPopup";
import { SpinnerLoading } from "../shared/Spinner/SpinnerLoading";
import { networkErrorMessage } from "../../utils/helpers";

interface ContactUsFormValues {
  firstName: string;
  lastName: string;
  phone?: string;
  email: string;
  message: string;
}

export const ContactUsContent = (): React.ReactElement => {
  const { deviceType } = useDeviceHook();
  const [captchaValid, setCaptchaValid] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const auth = useRecoilValue(getUserAuth);

  const getLoadingOrMessage = () => {
    if (isLoading) {
      return <SpinnerLoading />;
    }
    if (responseMessage) {
      return (
        <ErrorPopup
          message={responseMessage}
          hideMessage={setResponseMessage}
        />
      );
    }
  };

  return (
    <>
      <Formik<ContactUsFormValues>
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          message: "",
          phone: "",
        }}
        validate={(values) => {
          const errors: FormikErrors<ContactUsFormValues> = {};
          Object.keys(values)
            .filter(
              (value) =>
                value !== "phone" &&
                !values[value as keyof ContactUsFormValues],
            )
            .forEach((value) => {
              errors[value as keyof ContactUsFormValues] = "Required";
            });

          if (values.email && !validateEmail(values.email)) {
            errors.email = "Please enter a valid email";
          }
          return errors;
        }}
        onSubmit={async (values, { resetForm }) => {
          setIsLoading(true);
          try {
            const response = await ContactUsRequest(
              {
                first_name: values.firstName,
                last_name: values.lastName,
                phone: values.phone || "",
                email: values.email,
                message: values.message,
                customer_type: "patient",
              },
              auth?.access_token,
            );
            setIsLoading(false);
            if (response?.ok) {
              resetForm();
              setResponseMessage(
                "Thank you for your message. We will get back to you as soon as possible.",
              );
            } else {
              setResponseMessage("There was an error - please try again.");
            }
          } catch (e) {
            setIsLoading(false);
            setResponseMessage(networkErrorMessage);
          }
        }}
        validateOnChange={false}
      >
        {({
          values,
          errors,
          handleSubmit,
          setFieldValue,
          setFieldError,
        }: FormikProps<ContactUsFormValues>) => (
          <ContactUsFormGrid deviceType={deviceType}>
            <ContactUsInput
              type="text"
              name="firstName"
              value={values.firstName}
              onChange={(event) => {
                setFieldError("firstName", "");
                setFieldValue("firstName", event.target.value);
              }}
              placeholder="First name"
              label="First name"
              size="small"
              error={Boolean(errors.firstName)}
              helperText={errors.firstName}
            />
            <ContactUsInput
              type="text"
              name="lastName"
              value={values.lastName}
              onChange={(event) => {
                setFieldError("lastName", "");
                setFieldValue("lastName", event.target.value);
              }}
              label="Last name"
              placeholder="Last name"
              size="small"
              error={Boolean(errors.lastName)}
              helperText={errors.lastName}
            />
            <ContactUsInput
              type="email"
              name="email"
              value={values.email}
              onChange={(event) => {
                setFieldError("email", "");
                setFieldValue("email", event.target.value);
              }}
              label="Email"
              placeholder="Email"
              size="small"
              error={Boolean(errors.email)}
              helperText={errors.email}
            />
            <ContactUsInput
              type="text"
              name="phone"
              value={values.phone}
              onChange={(event) => setFieldValue("phone", event.target.value)}
              label="Phone (optional)"
              placeholder="Phone (optional)"
              size="small"
            />
            <ContactUsInput
              type="text"
              name="message"
              value={values.message}
              onChange={(event) => {
                setFieldError("message", "");
                setFieldValue("message", event.target.value);
              }}
              label="Your message"
              placeholder="Your message"
              size="small"
              multiline
              rows={5}
              error={Boolean(errors.message)}
              helperText={errors.message}
            />
            <div>
              <ReCAPTCHAV2
                sitekey="6LekybwdAAAAAFL-3IZhgAWBZD1FDwdvWzMr6vBr"
                onChange={(event) => setCaptchaValid(Boolean(event))}
              />
            </div>
            {getLoadingOrMessage() || (
              <ContactUsButtonContainer>
                <Button
                  type="submit"
                  label={isLoading ? "..." : "Send Inquiry"}
                  size="small"
                  onClick={handleSubmit}
                  disabled={isLoading || !captchaValid}
                />
              </ContactUsButtonContainer>
            )}
          </ContactUsFormGrid>
        )}
      </Formik>
    </>
  );
};
