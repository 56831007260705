import React from "react";
import { Info } from "@mui/icons-material";
import { useRecoilValue } from "recoil";
import {
  getAllRequisitions,
  getSelectedRequisition,
} from "../../recoil/selectors";
import {
  TestResultsTooltip,
  TestResultsTooltipPopper,
  TestResultsTooltipContent,
  TestResultsMultipleReqMessage,
} from "./TestResults.styles";
import { getReqDisplayDate } from "../../utils/dateUtils";

const TestResultsMultipleReqHeader: React.FC = () => {
  const requisitions = useRecoilValue(getAllRequisitions);
  const specificRequisition = useRecoilValue(getSelectedRequisition);

  const selectedRequisitionIndex = requisitions
    ? requisitions.findIndex(
        (requisition) =>
          requisition.req_identifier === specificRequisition?.req_identifier,
      )
    : -1;

  const displayDate = specificRequisition
    ? getReqDisplayDate(specificRequisition)
    : "";

  return (
    <>
      {requisitions && requisitions?.length > 1 && (
        <TestResultsMultipleReqMessage>
          <>{`for your ${
            selectedRequisitionIndex === 0
              ? "most recent test"
              : `test on ${displayDate}`
          }`}</>
          <TestResultsTooltip
            PopperComponent={TestResultsTooltipPopper}
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            arrow
            title={
              <TestResultsTooltipContent>
                To view test results from a different BillionToOne test,
                navigate to the homepage and select the desired test.
              </TestResultsTooltipContent>
            }
          >
            <div>
              <Info style={{ color: "#464547" }} />
            </div>
          </TestResultsTooltip>
        </TestResultsMultipleReqMessage>
      )}
    </>
  );
};

export default TestResultsMultipleReqHeader;
