import React from "react";
import { UpfrontPaymentFinancialQuestionsWrapper } from "../UpfrontPayment.styles";
import { StyledUISelectRequired } from "../../shared/UI-Library/StyledUISelect";
import { FinancialAssistanceIncomeTiers } from "../types";

type IncomeRange = { value: number; label: string };

export const getIncome = (
  householdSize: number,
  thresholds: Record<number, number[]>,
): IncomeRange[] => {
  // Default to household size 8 if not found;
  const tiers: number[] = thresholds[householdSize] || thresholds[8];
  if (!tiers) {
    return [];
  }
  const ranges: IncomeRange[] = tiers.map((upperBound, index) => {
    // since the values are in cents we add 100 to the lower bound so there is no overlap
    const lowerBound = index === 0 ? 0 : tiers[index - 1] + 100;
    // since the values are in cents we divide by 100 to get the dollar amount
    const displayLowerBound = lowerBound / 100;
    const displayUpperBound = upperBound / 100;
    const midpoint = Math.floor((lowerBound + upperBound) / 2);
    let label: string;

    if (index === 0) {
      label = `Less than $${displayUpperBound.toLocaleString()}`;
    } else {
      label = `Between $${displayLowerBound.toLocaleString()} and $${displayUpperBound.toLocaleString()}`;
    }

    return { value: midpoint, label };
  });

  // Tack on a final range for the last tier
  const lastLowerBound = tiers[tiers.length - 1];
  const displayLastLowerBound = lastLowerBound / 100;
  ranges.push({
    value: lastLowerBound,
    label: `More than $${displayLastLowerBound.toLocaleString()}`,
  });

  return ranges;
};

interface HouseholdInfoProps {
  householdInfo: {
    size?: number;
    income?: number;
    incomeDisplay?: string;
  };
  setHouseholdInfo: React.Dispatch<
    React.SetStateAction<{
      size?: number;
      income?: number;
      incomeDisplay?: string;
    }>
  >;
  incomeTiers: FinancialAssistanceIncomeTiers;
}

export const StepHouseholdInfo: React.FC<HouseholdInfoProps> = ({
  householdInfo,
  setHouseholdInfo,
  incomeTiers,
}) => {
  const handleSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setHouseholdInfo({ ...householdInfo, size: Number(event.target.value) });
  };

  const handleIncomeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.selectedOptions.length === 0) {
      return;
    }
    setHouseholdInfo({
      ...householdInfo,
      income: Number(event.target.value),
      incomeDisplay: event.target.selectedOptions[0].text,
    });
  };

  const householdSizes = Object.keys(incomeTiers);

  return (
    <UpfrontPaymentFinancialQuestionsWrapper>
      <StyledUISelectRequired
        fieldTitle="What is your household size? (Including current pregnancy)"
        value={householdInfo.size?.toString()}
        onChange={handleSizeChange}
        data-testid="household-size-select"
      >
        <option disabled selected>
          Select an option
        </option>
        {householdSizes.map((size, index) => (
          <option key={size} value={size}>
            {index === householdSizes.length - 1 ? `${size} or more` : size}
          </option>
        ))}
      </StyledUISelectRequired>

      {householdInfo.size && (
        <StyledUISelectRequired
          fieldTitle=" What’s your total household income before taxes?"
          value={householdInfo.income?.toString()}
          onChange={handleIncomeChange}
          data-testid="income-select"
        >
          <option disabled selected>
            Select an option
          </option>
          {getIncome(householdInfo.size, incomeTiers).map((opt) => (
            <option value={opt.value}>{opt.label}</option>
          ))}
        </StyledUISelectRequired>
      )}
    </UpfrontPaymentFinancialQuestionsWrapper>
  );
};
