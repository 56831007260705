import styled from "styled-components/macro";

export const BabyPeekIntroFAQAccordionWrapper = styled.div`
  border-radius: 24px;
  border: 1px solid var(--grey-ultra, #f7f7f7);
  background: #9bdff9;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.05);
  font-size: 12px;
  color: #064682;
  padding: 4px 12px 4px 12px;
`;
