import React, { useEffect, useRef, useState } from "react";
import Dropzone, { IFileWithMeta, StatusValue } from "react-dropzone-uploader";
import { themePalette } from "../../theme";
import { GenericFileUploadInputContent } from "./DocumentUploader";

export const FADocumentUploader: React.FC<{
  selectedFile: IFileWithMeta | null;
  setSelectedFile: React.Dispatch<React.SetStateAction<IFileWithMeta | null>>;
}> = ({ selectedFile, setSelectedFile }) => {
  const [error, setError] = useState<string | null>(null);
  const [initialFiles] = useState<File[]>(
    selectedFile ? [selectedFile.file] : [],
  );

  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
      setError(null);
    };
  }, []);

  const handleFileChange = (recentFile: IFileWithMeta, status: StatusValue) => {
    // Prevent state updates if component is unmounted
    // Sometimes react-dropzone-uploader will call the onChangeStatus function after
    // the component is unmounted
    if (!isMounted.current) return;

    if (status === "done") {
      setSelectedFile(recentFile);
      setError(null);
    } else if (status === "removed") {
      setSelectedFile(null);
    } else if (status === "error_file_size") {
      setError("File size is too large.  Please upload a file less than 10MB.");
      recentFile.remove();
    } else if (status === "preparing") {
      // Do nothing the Dropzone component is initializing
    } else {
      setSelectedFile(null);
      setError(null);
    }
  };

  return (
    <div data-testid="fa-uploader">
      {error && <p style={{ color: "red" }}>{error}</p>}
      <Dropzone
        accept=".png, .jpg, .jpeg, .pdf"
        maxFiles={1}
        multiple={false}
        maxSizeBytes={10000000}
        onChangeStatus={handleFileChange}
        submitButtonDisabled
        styles={{
          dropzone: {
            border: `2px dotted ${themePalette.greyLight}`,
            background: themePalette.neutral50,
            overflow: "visible",
          },
        }}
        inputContent={GenericFileUploadInputContent()}
        initialFiles={initialFiles}
      />
    </div>
  );
};
