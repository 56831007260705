import React from "react";
import ReactPlayer from "react-player/vimeo";
import { useRecoilValue } from "recoil";
import { VideoDiv } from "../../components/shared/Video/VideoEmbed.styles";
import { DeviceType, useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { getUserAuth } from "../../recoil/selectors";
import { TrackAnalyticsEvent } from "../../services/Analytics";
import { AnalyticsEventNames } from "../../utils/analyticsEvents";

const getWidth = (deviceType: DeviceType) => {
  if (deviceType === "mobile") return "336px";
  if (deviceType === "tablet") return "544px";
  if (deviceType === "desktop") return "760px";
};

const getHeight = (deviceType: DeviceType) => {
  if (deviceType === "mobile") return "328px";
  if (deviceType === "tablet") return "296px";
  if (deviceType === "desktop") return "442px";
};

const VideoLibraryCard: React.FC<{
  name: string;
  url: string;
  image: string;
}> = ({ name, url, image }) => {
  const { deviceType } = useDeviceHook();
  const auth = useRecoilValue(getUserAuth);

  return (
    <VideoDiv>
      <ReactPlayer
        playing
        url={url}
        light={image}
        height={getHeight(deviceType)}
        width={getWidth(deviceType)}
        controls
        data-testid="unity-video-player"
        data-vimeo-playsinline
        data-vimeo-autoplay
        onPlay={() => {
          if (auth?.access_token) {
            TrackAnalyticsEvent(
              `${AnalyticsEventNames.VideoLibraryCardVideoClicked}-${name}`,
              auth?.access_token,
              auth?.user_id,
            );
          }
        }}
      />
    </VideoDiv>
  );
};

export default VideoLibraryCard;
