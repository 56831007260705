import { PortalBillStatusType } from "../components/UpfrontPayment/types";

export interface CodeResultSerializer {
  value: string;
  abnormal_flag: "Abnormal" | "Normal";
  status?: "Final" | "Corrected" | "Processing" | "Not Ordered" | "Ordered";
}

export const NoValueProvided = "No value provided";
export interface FetalSexCodeResultSerializer {
  value:
    | "MALE"
    | "FEMALE"
    | "MALE & MALE"
    | "FEMALE & FEMALE"
    | "MALE & FEMALE"
    | "ONE OR BOTH MALES"
    | "NO RESULT"
    | "NO CALL"
    | "NOT REPORTED"
    | "NOT ORDERED"
    | "ORDERED"
    | "N/A"
    | ""
    | typeof NoValueProvided;
  abnormal_flag: "Abnormal" | "Normal";
  status?: "Final" | "Corrected" | "Processing" | "Not Ordered" | "Ordered";
}

export const PatientReview1ModalSeen = "PatientReview1_ModalSeen";
export const PatientReview2StarsSelected = "PatientReview2_StarsSelected";
export const PatientReview3Submitted = "PatientReview3_Submitted";
export const PatientReview4RedirectedToGoogleReview =
  "PatientReview4_RedirectedToGoogleReview";

export type UiActionType =
  | "ViewCarrierPage"
  | "ViewedDashboardVideo"
  | "ViewAneuploidyPage"
  | "ShowFetalSex"
  | "ViewDashboard"
  | "BookConsultation"
  | "PreviewPaymentPlan"
  | "BillingVideoClicked"
  | "BillingVideoFeedback"
  | "AneuploidyVideoClicked"
  | "AneuploidyVideoFeedback"
  | "CarrierVideoClicked"
  | "CarrierVideoFeedback"
  | "RhDVideoClicked"
  | "RhDVideoFeedback"
  | "LowRiskAneuploidyVideoClicked"
  | "Del22qVideoClicked"
  | "LowRiskAneuploidyVideoFeedback"
  | "Del22qVideoFeedback"
  | "NegativeCarrierVideoClicked"
  | "RhDDetectedVideoClicked"
  | "RhDNotDetectedVideoClicked"
  | "NegativeCarrierVideoFeedback"
  | "RhDDetectedFeedback"
  | "RhDNotDetectedFeedback"
  | typeof PatientReview1ModalSeen
  | typeof PatientReview2StarsSelected
  | typeof PatientReview3Submitted
  | typeof PatientReview4RedirectedToGoogleReview;

export interface ActionSerializer {
  action_type: UiActionType;
  requisition: string;
  meta_data?: { [field: string]: string };
}

interface GestationCodeResultSerializer {
  value:
    | "SINGLETON"
    | "TWIN"
    | "Processing"
    | "UNKNOWN"
    | typeof NoValueProvided;
  abnormal_flag: "Abnormal" | "Normal";
  status?: "Final" | "Corrected" | "Processing" | "Not Ordered" | "Ordered";
}

export interface UnityReportDataJsonSerializer {
  sma_carrier?: CodeResultSerializer;
  cftr_carrier?: CodeResultSerializer;
  bthal_carrier?: CodeResultSerializer;
  athal_carrier?: CodeResultSerializer;
  fmr1_carrier?: CodeResultSerializer;
  aspa_carrier?: CodeResultSerializer;
  dmd_carrier?: CodeResultSerializer;
  elp1_carrier?: CodeResultSerializer;
  acadm_carrier?: CodeResultSerializer;
  pah_carrier?: CodeResultSerializer;
  pmm2_carrier?: CodeResultSerializer;
  dhcr7_carrier?: CodeResultSerializer;
  hexa_carrier?: CodeResultSerializer;
  fetal_sex?: CodeResultSerializer; // some fragilex reports reveal fetal sex
}

export interface UnityReportDataSerializer {
  report_data: UnityReportDataJsonSerializer;
  version: "v1";
  id: string;
  status: "Final" | "Corrected" | "Processing" | "Not Ordered" | "Ordered";

  is_amended: boolean;
  is_ordered: boolean;
  summary: "Low Risk" | "Negative" | "See Report" | "Test Not Performed";
}

export interface AneuploidyReportDataJsonSerializer {
  gestation?: GestationCodeResultSerializer;
  trisomy_21?: CodeResultSerializer;
  trisomy_18?: CodeResultSerializer;
  trisomy_13?: CodeResultSerializer;
  monosomy_x?: CodeResultSerializer;
  sex_aneuploidy?: CodeResultSerializer;
  fetal_fraction?: CodeResultSerializer;
  fetal_rhd: CodeResultSerializer;
  fetal_rhd_fa:
    | "Not Ordered"
    | "Opted out"
    | "Processing"
    | "Detected"
    | "Not Detected"
    | "See Report"
    | "Test Not Performed";
  fetal_sex: FetalSexCodeResultSerializer;
  del22q: "Opted out" | "Processing" | "See Report" | "Low Risk";
}

export interface AneuploidyReportDataSerializer {
  report_data: AneuploidyReportDataJsonSerializer;
  version: "v1";
  id: string;
  status: "Final" | "Corrected" | "Processing" | "Not Ordered" | "Ordered";

  is_amended: boolean;
  is_ordered: boolean;
  summary: "Low Risk" | "Negative" | "See Report" | "Test Not Performed";
}

export type BabyPeekReveal =
  | "empty_traits"
  | "hidden"
  | "pretest"
  | "pending"
  | "dismissed"
  | "available"
  | "prepaid"
  | "never"
  | "removed_prepaid"
  | "removed";

export interface RequisitionModelSerializer {
  req_identifier: string;
  date_received: string;
  collection_date: string;
  show_abn_form: boolean;
  physician_name: string;
  redraw_parent: string; // req_identifier of the parent
  is_unity_included?: boolean;
  is_aneuploidy_included?: boolean;
  is_rhd_included?: boolean;
  is_fetal_sex_included?: boolean;
  is_fragile_x_included?: boolean;
  unity_reports?: UnityReportDataSerializer[];
  aneuploidy_reports?: AneuploidyReportDataSerializer[];
  portal_bill_status: PortalBillStatusType;
  bill_pk: string;
  baby_peek_pk: string;
  reveal_free_trait: boolean;
  reveal_status: BabyPeekReveal;
  fetal_antigen_panel?: string;
  gestational_age_from_sfdc?: number;
}

export interface RevealGenderEmailSerializer {
  req_identifier: string;
  to_email: string;
  to_name: string;
}

export interface ContactUsEmailSerializer {
  customer_type: "patient" | "provider";
  email: string;
  first_name: string;
  last_name: string;
  message: string;
  phone: string;
}

export interface UpdateUserEmailSerializer {
  new_email: string;
}

export interface UpdateUserPasswordSerializer {
  password: string;
  email?: string;
  new_password: string;
}

export interface Auth0UserSerializer {
  email: string;
  email_verified: boolean;
  user_id: string;
  last_login: string;
  last_password_reset: string;
  access_token: string;
}

export type PhiInsuranceStatus =
  | "Pending"
  | "Complete"
  | "Required"
  | "Failure"
  | "None";

interface ClientPhiSerializer {
  first_name: string;
  last_name: string;
  dob: string;
  estimated_due_date: string;
  phone_number: string;
  ins_provider: string;
  ins_group: string;
  ins_number: string;
  subscriber_name: string;
  subscriber_date_of_birth: string;
  relationship_to_insured: string;
  ins_status: PhiInsuranceStatus;
  is_marketing_subscribed: boolean;
  is_new_report_subscribed: boolean;
  address_status: PhiInsuranceStatus;
  prebilling_status: PhiInsuranceStatus;
  address: string;
  street_address_1: string;
  street_address_2: string;
  state: string;
  city: string;
  zip: string;
}

export enum ConsentStatus {
  NOT_APPLICABLE = "Not Applicable",
  COMPLETE = "Complete",
  INCOMPLETE = "Incomplete",
}

export interface ClientUserSerializer {
  id: string;
  consent_status: ConsentStatus;
  survey_status: ConsentStatus;
  dob: string;
  first_name: string;
  last_name: string;
  gender: string;
  date_joined: string;
  has_aneuploidy_consent: boolean;
  has_carrier_consent: boolean;
  phone_number: string;
  referral_code: string;
  zip: string;
}

export interface UserAuthClientResponse {
  requisitions: RequisitionModelSerializer[];
  auth0: Auth0UserSerializer;
  user?: ClientUserSerializer;
}

export interface UserProfileAuthClientResponse {
  phi: ClientPhiSerializer;
  user?: ClientUserSerializer;
}
