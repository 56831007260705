import styled from "styled-components/macro";

export const ContentDiv = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 60%;
  }
`;

export const StyledH2 = styled.h2`
  font-size: 1.5em;
  color: #1dace9;
  text-transform: uppercase;
`;
