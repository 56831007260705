import { SetterOrUpdater } from "recoil";
import {
  BASE_URL,
  setCookieAccessToken,
  removeCookieAccessToken,
} from "../../../utils/requestUtils";
import { GetNewPatientInfoFromToken } from "../../Login/Services/LoginService";
import { UserAuthClientResponse } from "../../../recoil/types";
import { networkErrorMessage } from "../../../utils/helpers";

export const auth0ResponseMessages: { [id: number]: string } = {
  201: "",
  400: "The combination of first name, last name, and date of birth does not match our records. Please review the inputted information and try again. If you continue to experience difficulties contact our customer care team at 650-460-2554.",
  404: "Unknown signup link. If you continue to experience difficulties contact our customer care team at 650-460-2554.",
  406: "The signup link has expired. If you continue to experience difficulties contact our customer care team at 650-460-2554.",
  409: "An error occurred during account creation. If you continue to experience difficulties contact our customer care team at 650-460-2554.",
  412: "Please verify your personal details and try again. If you continue to experience difficulties contact our customer care team at 650-460-2554.",
  500: "Unknown error during registration. If you continue to experience difficulties contact our customer care team at 650-460-2554.",
  501: networkErrorMessage,
};

interface CreateAccountRequestType {
  first_name: string;
  last_name: string;
  dob: string;
  email: string;
  password: string;
  sms_code: string;
  is_from_email: boolean;
  middle_name?: string;
  gender?: "Male" | "Female" | "Other";
  zip?: string;
  referral?: string;
}

export const CreateAccountRequest = async (
  accountInfo: CreateAccountRequestType,
): Promise<[number, UserAuthClientResponse | undefined]> => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/users/`, {
      body: JSON.stringify(accountInfo),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      return [
        response.status,
        (await response.json()) as UserAuthClientResponse,
      ];
    }
    return [response.status, undefined];
  } catch (e) {
    return [501, undefined];
  }
};

interface CreateAccountPreTestRequestType {
  first_name: string;
  last_name: string;
  dob: string;
  email: string;
  password: string;
  middle_name?: string;
  gender?: "Male" | "Female";
  zip?: string;
  referral?: string;
  lims_id?: string;
}

export const CreateAccountPreTestRequest = async (
  accountInfo: CreateAccountPreTestRequestType,
): Promise<[number, UserAuthClientResponse | undefined]> => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/users/`, {
      body: JSON.stringify(accountInfo),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      return [
        response.status,
        (await response.json()) as UserAuthClientResponse,
      ];
    }
    return [response.status, undefined];
  } catch (e) {
    return [501, undefined];
  }
};

export const UpdateEmailOnlyRequest = async (
  email: string,
  token: string,
): Promise<Response | undefined> => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/account/email`, {
      body: JSON.stringify({ new_email: email }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (e) {
    return undefined;
  }
};

export const RefreshPatientInfo = async (
  token: string,
  setter: SetterOrUpdater<UserAuthClientResponse | undefined>,
): Promise<void> => {
  try {
    const response = await GetNewPatientInfoFromToken(token);
    let newPatientInfo;
    if (response.ok) {
      newPatientInfo = await response.json();
    }
    setter(newPatientInfo);
    if (newPatientInfo) {
      setCookieAccessToken(newPatientInfo.auth0.access_token);
    } else {
      removeCookieAccessToken();
    }
  } catch (e) {
    return undefined;
  }
};

export const SendViewSignUpPageAction = async (
  smsCode: string,
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/external_actions/${smsCode}/load/`, {
    body: JSON.stringify({ action_type: "ViewSignUpPage", error: "" }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

export const SendSignUpErrorAction = async (
  smsCode: string,
  errorMessage: string,
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/external_actions/${smsCode}/load/`, {
    body: JSON.stringify({ action_type: "Error", error: errorMessage }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

interface PatientPhiVerifyRequestType {
  first_name: string;
  last_name: string;
  dob: string;
  email: string;
  sms_code: string;
}

export const PatientPhiVerifyRequest = async (
  patientPhiInfo: PatientPhiVerifyRequestType,
): Promise<Response | undefined> => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/patient_phi_verify`, {
      body: JSON.stringify(patientPhiInfo),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (e) {
    return undefined;
  }
};
