import React from "react";

const PatientReviewStar: React.FC<{
  currentColor: string;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}> = ({ currentColor, onClick, onMouseEnter, onMouseLeave }) => (
  <svg
    width={46}
    height={44}
    viewBox="0 0 46 44"
    fill="none"
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <path
      d="M21.3183 1.13576C22.0427 -0.38248 24.2039 -0.382483 24.9283 1.13576L30.2502 12.2886C30.5418 12.8996 31.1226 13.3217 31.7939 13.4102L44.0454 15.0251C45.7132 15.245 46.381 17.3003 45.161 18.4585L36.1985 26.9663C35.7075 27.4324 35.4856 28.1153 35.6089 28.781L37.8589 40.932C38.1652 42.5861 36.4168 43.8564 34.9383 43.0539L24.0773 37.1592C23.4823 36.8363 22.7643 36.8363 22.1693 37.1592L11.3083 43.0539C9.82977 43.8564 8.08139 42.5861 8.38768 40.932L10.6377 28.781C10.7609 28.1153 10.5391 27.4325 10.0481 26.9663L1.0856 18.4585C-0.134462 17.3003 0.533362 15.245 2.20117 15.0251L14.4527 13.4102C15.1239 13.3217 15.7048 12.8996 15.9964 12.2886L21.3183 1.13576Z"
      fill={currentColor}
    />
  </svg>
);

export default PatientReviewStar;
