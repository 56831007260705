import React from "react";
import {
  FinancialAssistanceTitle,
  UpfrontPaymentFinancialQuestionsWrapper,
  UpfrontPaymentInputRowWrapper,
  UpfrontPaymentInputTitle,
  UpfrontPaymentInputWrapper,
  UpfrontPaymentTextInputComponent,
} from "../UpfrontPayment.styles";
import { StyledUISelect } from "../../shared/UI-Library/StyledUISelect";
import StyledMUIMultiSelect from "../../shared/UI-Library/StyledMUISelect";
import { ExtenuatingCircumstances } from "../types";
import { StyledInputOptional } from "../../shared/UI-Library/UI-Library.styles";

interface OptionalQuestionsProps {
  localities: string[];
  optionalQuestions: {
    locality?: string;
    extenuatingCircumstances: string[];
    otherCircumstance?: string;
  };
  setOptionalQuestions: React.Dispatch<
    React.SetStateAction<{
      locality?: string;
      extenuatingCircumstances: string[];
      otherCircumstance?: string;
    }>
  >;
}

export const StepOptionalQuestions: React.FC<OptionalQuestionsProps> = ({
  localities,
  optionalQuestions,
  setOptionalQuestions,
}) => {
  const handleLocalityChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setOptionalQuestions({
      ...optionalQuestions,
      locality: event.target.value,
    });
  };

  const handleExtenuatingCircumstancesChange = (values: string[]) => {
    setOptionalQuestions({
      ...optionalQuestions,
      extenuatingCircumstances: values,
    });
  };

  const handleOtherCircumstanceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setOptionalQuestions({
      ...optionalQuestions,
      otherCircumstance: event.target.value,
    });
  };

  return (
    <UpfrontPaymentFinancialQuestionsWrapper>
      <FinancialAssistanceTitle>
        Additional Information{" "}
        <StyledInputOptional style={{ fontWeight: "bold" }}>
          (optional)
        </StyledInputOptional>
      </FinancialAssistanceTitle>
      <StyledUISelect
        fieldTitle="Are you located in the greater metropolitan area of any of these cities? We'll consider the high cost of living in your application."
        value={optionalQuestions.locality || ""}
        onChange={handleLocalityChange}
        data-testid="locality-select"
      >
        <option value="" disabled>
          Select an area
        </option>
        {localities
          .sort((a, b) => a.localeCompare(b))
          .map((locality) => (
            <option key={locality} value={locality}>
              {locality}
            </option>
          ))}
      </StyledUISelect>

      <StyledMUIMultiSelect
        fieldTitle="Do you have extenuating circumstances you would like us to consider
          when evaluating your application?"
        options={ExtenuatingCircumstances.map((circumstance) => ({
          label: circumstance,
          value: circumstance,
        }))}
        selectedValues={optionalQuestions.extenuatingCircumstances || []}
        customOnChange={handleExtenuatingCircumstancesChange}
        data-testid="extenuating-circumstances-select"
      />
      {optionalQuestions.extenuatingCircumstances.includes("Other") && (
        <UpfrontPaymentInputRowWrapper>
          <UpfrontPaymentInputWrapper>
            <UpfrontPaymentInputTitle>Other</UpfrontPaymentInputTitle>
            <UpfrontPaymentTextInputComponent
              value={optionalQuestions.otherCircumstance || undefined}
              onChange={handleOtherCircumstanceChange}
              disableUnderline
              data-testid="other-circumstance-input"
            />
          </UpfrontPaymentInputWrapper>
        </UpfrontPaymentInputRowWrapper>
      )}
    </UpfrontPaymentFinancialQuestionsWrapper>
  );
};
