import styled from "styled-components/macro";

export const FAQHeader = styled.div`
  font-size: 2.5em;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 33px;
  color: ${({ theme }) => theme.greyMedium};
  font-weight: bold;
  text-align: left;
`;
