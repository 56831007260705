import styled from "styled-components/macro";
import { DeviceType } from "../../utils/hooks/useDeviceHook";
import { StyledButton } from "../shared/Button/Button.styles";
import { TextField } from "../shared/TextField/TextField";
import { StyledTextField } from "../shared/TextField/TextField.styles";
import { StyledTypography } from "../shared/Typography/Typography.styles";

export const DetailedResultsHeaderContainer = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: 30px 50px;
  grid-gap: 15px;
  background-color: ${({ theme }) => theme.white};
  padding: ${({ deviceType }) =>
    deviceType === "desktop" ? "5px 0 30px 0" : "10px 10px 30px 0px"};
`;

export const DetailedResultIconRow = styled.div`
  display: grid;
  grid-template-columns: 32px auto;
  place-items: center;
  gap: 15px;

  svg {
    width: 32px;
    height: 32px;
  }

  .detailed-result-carrier {
    margin-top: 5px;
  }

  p {
    justify-self: start;
  }
`;

export const DetailedResultsTableContainer = styled.div`
  display: grid;
  grid-gap: 5px;
  background-color: ${({ theme }) => theme.white};
  padding-bottom: 30px;
`;

export const DetailedResultsTableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px 15%;
  background-color: ${({ theme }) => theme.white};
  padding: 10px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.neutral100};
  align-items: center;

  .fetal-risk-title {
    margin-left: -20px;
  }

  ${StyledTypography} {
    align-items: left;
  }

  p {
    display: inline;
  }
`;

export const DetailedResultsViewReportWarning = styled.div`
  display: grid;
  grid-template-columns: 35px auto;
  color: ${({ theme }) => theme.white};
  margin-left: 4px;
  margin-top: 10px;
  margin-bottom: 10px;

  svg {
    margin-top: 5px;
  }

  p {
    line-height: 35px !important;
  }
`;

export const DetailedResultsViewReportWrapper = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-rows: auto auto;
  ${StyledButton} {
    width: 240px;
  }
`;

export const DetailedResultsViewReportButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 35px auto;
  color: ${({ theme }) => theme.white};
  margin-left: -10px;
  margin-top: 5px;
  text-align: center;

  p {
    line-height: 28px !important;
  }

  svg {
    margin-top: 5px;
  }
`;

export const DetailedResultsRhDWrapper = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-rows: repeat(2, 40px) 20px;
`;

export const DetailedResultsRhDLearnMoreWrapper = styled.div`
  p {
    padding: 0px !important;
  }
`;

export const DetailedResultsTypographyWrapper = styled.div`
  p {
    display: inline;
  }
  cursor: pointer;
`;

export const DetailedResultRhDIconRow = styled.div`
  display: grid;
  grid-template-columns: 32px auto;
  align-items: center;
  justify-content: start;
  grid-column-gap: 10px;

  svg {
    width: 32px;
    height: 32px;
    margin-top: 5px;
  }

  .detailed-result-fetal {
    margin-top: 5px;
  }
`;

export const DetailedResultsFetalSexWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-row-gap: 20px;
`;

export const DetailedResulFetalSexIconRow = styled.div`
  display: grid;
  grid-template-columns: 32px 165px;
  max-width: 300px;
  place-items: center;

  svg {
    width: 32px;
    height: 32px;
    margin-top: 5px;
  }
`;

export const DetailedResultsButtonTextWrapper = styled.div`
  min-width: 250px;
`;

export const DetailedResultsFetalSexButton = styled.div`
  display: grid;
  grid-template-columns: 35px 240px;
  color: ${({ theme }) => theme.white};
  margin-left: -10px;
  margin-top: 6px;

  ${DetailedResultsButtonTextWrapper} {
    margin-left: -15px;
  }

  svg {
    margin-right: -30px;
    margin-left: 0px;
    width: 20px;
    height: 22px;
  }
`;

export const DetailedResultsFetalSexButtonWrappers = styled.div<{
  hide?: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(auto-fill, 275px);
  grid-gap: 22px;
  margin-top: ${({ hide }) => hide && "20px"};
`;

const getDetailedResultsExpertPadding = (deviceType?: DeviceType) => {
  if (deviceType === "mobile") {
    return "47px 73px 130px 80px";
  }
  if (deviceType === "tablet") {
    return "47px 45px 98px 80px";
  }
  return "44px 70px 142px 90px";
};

export const DetailedResultsExpertWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: ${({ deviceType }) =>
    getDetailedResultsExpertPadding(deviceType)};
  grid-row-gap: 10px;

  ${StyledButton} {
    height: 40px;
    width: 226px;
    border: 1px solid ${({ theme }) => theme.redMedium};
    margin-top: 15px;
    :hover {
      border: 1px solid ${({ theme }) => theme.redMedium};
      opacity: 0.8;
    }
  }
`;
export const DetailedResultsFAQWrapper = styled.div`
  display: grid;
  grid-template-rows: 43px 30px 35px;
  grid-row-gap: 10px;
`;

export const DetailedResultsFAQActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DetailedResultsFAQArrowWrapper = styled.div`
  cursor: pointer;
  align-items: center;
`;

export const DetailedResultsRevealTitle = styled.div`
  margin-top: -10px;
`;

export const DetailedResultsFetalSexTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DetailedResultsFetalSexRevealWrapper = styled.div`
  display: grid;
  grid-template-rows: 10px auto 120px;
  grid-row-gap: 25px;
  border: 1px solid ${({ theme }) => theme.neutralBackground};
  border-radius: 8px;
  padding: 0px 10px 40px 10px;
  z-index: 10;

  canvas {
    position: relative !important;
    z-index: 0 !important;
    margin-left: -10px;
    justify-self: center;
  }
`;

export const DetailedResultFetalSexRevealRow = styled.div<{ extend?: boolean }>`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0;
  place-items: center;
  z-index: 2 !important;

  .detailed-result-fetal {
    margin-top: 5px;
  }
`;

export const DetailedResultsFetalGrid = styled.div<{ isTwin: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, 245px);
  padding: 0;
  place-self: ${({ isTwin }) => (isTwin ? "" : "center")};
`;

const getDetailedResultModalWidth = (deviceType?: DeviceType) => {
  if (deviceType === "mobile") {
    return "300px";
  }
  if (deviceType === "tablet") {
    return "500px";
  }
  return "600px";
};

export const DetailedResultsFetalSexModalWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: 30px 30px 70px 50px;
  grid-gap: 20px;
  max-width: ${({ deviceType }) => getDetailedResultModalWidth(deviceType)};
  background-color: ${({ theme }) => theme.white};
  padding: ${({ deviceType }) =>
    deviceType === "desktop" ? "20px 40px" : "30px 50px 30px 30px"};
  border-radius: 10px;
`;

export const DetailedResultsPatientReviewModalWrapper = styled(
  DetailedResultsFetalSexModalWrapper,
)`
  grid-template-rows: auto;
  padding: 20px 16px;
`;

export const DetailedResultsFetalSexForwardInput = styled(TextField)`
  max-width: 450px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.greyLight};
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.greenMedium};
  padding: 14px;
  padding-top: 17px;
  border-radius: 5px;
  margin-bottom: 40px;
`;

const getForwardFetalSexPadding = (deviceType?: DeviceType) => {
  if (deviceType === "mobile") {
    return "60px 10px";
  }
  if (deviceType === "tablet") {
    return "60px 60px";
  }
  return "60px 120px";
};

const getForwardFetalSexMinWidth = (deviceType?: DeviceType) => {
  if (deviceType === "mobile") {
    return "320px";
  }
  if (deviceType === "tablet") {
    return "600px";
  }
  return "700px";
};

const getForwardFetalSexMaxWidth = (deviceType?: DeviceType) => {
  if (deviceType === "mobile") {
    return "320px";
  }
  if (deviceType === "tablet") {
    return "450px";
  }
  return "450px";
};

const getForwardFetalInputWidth = (deviceType?: DeviceType) => {
  if (deviceType === "mobile") {
    return "320px";
  }
  if (deviceType === "tablet") {
    return "430px";
  }
  return "460px";
};

const getFetalSexResetTextFieldWidth = (deviceType?: DeviceType) => {
  if (deviceType === "mobile") {
    return "330px";
  }
  if (deviceType === "tablet") {
    return "420px";
  }
  return "450px";
};

export const DetailedResultsFetalSexForwardWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: 45px 75px 40px 40px 40px 50px auto;
  grid-gap: 40px;
  max-width: ${({ deviceType }) => getForwardFetalSexMaxWidth(deviceType)};
  text-align: center;
  align-items: center;
  justify-content: center;
  place-items: center;
  padding: ${({ deviceType }) => getForwardFetalSexPadding(deviceType)};
  min-width: ${({ deviceType }) => getForwardFetalSexMinWidth(deviceType)};

  ${DetailedResultsFetalSexForwardInput} {
    width: ${({ deviceType }) => getForwardFetalInputWidth(deviceType)};
  }

  ${StyledTextField} {
    width: ${({ deviceType }) => getFetalSexResetTextFieldWidth(deviceType)};
  }
`;

export const ActionAndCancelButtonPair = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 15px;

  ${StyledButton} {
    height: 40px;
    :hover {
      opacity: 0.8;
    }
  }

  .cancel-action {
    border: 1px solid ${({ theme }) => theme.redMedium};
    :hover {
      border: 1px solid ${({ theme }) => theme.redMedium};
      opacity: 0.8;
    }
  }
`;

export const DetailedResultsForwardFetalSexTerms = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;

  .forward-fetal-checkbox {
    margin-top: -40px;
  }
`;

export const DetailedResultsFetalSexForwardConfirmWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: 40px 40px 40px;
  grid-gap: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${({ deviceType }) => getForwardFetalSexPadding(deviceType)};

  ${StyledButton} {
    border: 1px solid ${({ theme }) => theme.redMedium};
    width: 224px;
    :hover {
      border: 1px solid ${({ theme }) => theme.redMedium};
      opacity: 0.8;
    }
  }
`;

export const DetailedResultsFetalSexForwardConfirmButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DetailedResultsBabyPeekContent = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: var(--Padding-Left-with-Icon, 24px);

  border: 1px solid var(--grey-200, #e2e8f0);
  background: white;
  /* Hard shadow */
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 2px 16px 0px rgba(0, 0, 0, 0.05);
  padding: 24px 24px;
  gap: 12px;
  margin-top: 12px;

  .babypeek-gift-traits {
    margin-bottom: ${({ deviceType }) =>
      deviceType === "mobile" ? "-10px" : "5px"} !important;
    margin-top: 0px !important;
  }
`;

export const DetailedResultsBabyPeekText = styled.div`
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 115%;
  background-image: linear-gradient(
    46deg,
    #ffde27 0%,
    #fa709a 48.44%,
    #c670fa 84.13%,
    #3431ce 100%
  );
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
`;

export const DetailedResultsBabyPeekTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    height: 30px;
    margin-right: -15px;
  }
`;

export const DetailedResultsBabyPeekTextAneuploidy = styled.div`
  color: var(--grey-900, #171923);
  /* Heading/H3/Desktop */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
`;

export const DetailedResultsBabyPeekTextUpdate = styled.div`
  color: var(--Grey-700, #2d3748);

  font-family: Inter;

  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
