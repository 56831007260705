import { RevealGenderEmailSerializer } from "../../../recoil/types";
import { BASE_URL } from "../../../utils/requestUtils";
import { PatientReviewRequestPayload } from "../types";

const ForwardFetalSexEmailRequest = async (
  revealInfo: RevealGenderEmailSerializer,
  access_token?: string,
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/gender_reveal`, {
    body: JSON.stringify(revealInfo),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });

export default ForwardFetalSexEmailRequest;

export const FullReportRequest = async (
  reportID: string,
  type: "carrier" | "aneuploidy",
  access_token?: string,
): Promise<Response> =>
  fetch(
    `${BASE_URL}/api/v1/${
      type === "aneuploidy" ? "aneuploidy" : "unity"
    }reports/${reportID}/download/`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    },
  );

export const GetPatientReviewRequest = async (
  access_token?: string,
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/patient_review/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });

export const CreatePatientReviewRequest = async (
  createPatientReviewPayload: PatientReviewRequestPayload,
  access_token?: string,
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/patient_review/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify(createPatientReviewPayload),
  });

export const UpdatePatientReviewRequest = async (
  patientReviewID: string,
  updatePatientReviewPayload: PatientReviewRequestPayload,
  access_token?: string,
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/patient_review/${patientReviewID}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify(updatePatientReviewPayload),
  });
