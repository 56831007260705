import styled from "styled-components/macro";

import { DeviceType } from "../../utils/hooks/useDeviceHook";
import { BabyPeekButtonGradient } from "./BabyPeekIntro.styles";

export const BabyPeekAdvocacyModalConfettiWrapper = styled.div`
  border-radius: 24px;
  background: white;
  canvas {
    position: relative !important;
    z-index: 0 !important;
    width: 100%;
    justify-self: center;
  }
  overflow: hidden;
`;

export const BabyPeekAdvocacyModalWrapper = styled.div<{
  paymentPlan: boolean;
  babyPeekProcessing: boolean;
}>`
  padding: 48px 24px;
  padding-bottom: 16px;
  height: ${({ paymentPlan }) => (paymentPlan ? "auto" : "250px")};
  margin-top: ${({ babyPeekProcessing }) =>
    babyPeekProcessing ? "-400px" : "-260px"};
  justify-content: center;
  display: grid;
  z-index: 10;
  position: relative;
`;

export const BabyPeekAdvocacyModalText = styled.div`
  color: ${({ theme }) => theme.greyDark};
  text-align: center;
  /* Header Bold */
  font-size: 24px;
  font-family: Poppins;
  font-weight: 800;
  line-height: 32px;
  margin-bottom: 40px;
`;

export const BabyPeekProcessingStepperWrapper = styled.div`
  .MuiStepConnector-line {
    border-width: 2px;
  }

  .MuiStepConnector-vertical.Mui-active .MuiStepConnector-lineVertical {
    border-color: ${({ theme }) => theme.greenDark};
    border-top-width: 2px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const BabyPeekProcessingStepperLabel = styled.div`
  color: var(--Grey-600, #4a5568);
  font-feature-settings: "case" on;
  /* Text/Regular/Mobile */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;

export const BabyPeekAdvocacyModalButton = styled.div<{
  deviceType: DeviceType;
}>`
  display: flex;
  justify-content: center;

  ${BabyPeekButtonGradient} {
    width: ${({ deviceType }) => (deviceType === "mobile" ? "100%" : 415)};
  }
`;
