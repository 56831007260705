import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import { InfoOutlined } from "@mui/icons-material";
import { Modal, PopperProps } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { useWindowWidth } from "@react-hook/window-size";
import { getReqDisplayDate } from "../../utils/dateUtils";
import VideoEmbed from "../../components/shared/Video/VideoEmbed";
import { TestResults } from "../../components/TestResults/TestResults";
import {
  LandingGeneralContainer,
  LandingPageAccordionLinkWrapper,
  LandingPageAccordionWrapper,
  LandingPageDesktopLeftWrapper,
  LandingPageDesktopRightWrapper,
  LandingPageDesktopWrapper,
  LandingPageDesktopWrapperPadding,
  LandingPageTabletMobileRowWrapper,
  LandingPageTabletMobileWrapper,
  LandingPageTitleWrapper,
  LandingPageWarningMobileTextWrapper,
  LandingPageWarningText,
  LandingPageWarningWrapper,
  LandingPageWarningWrapperMobile,
  LandingPageWrapper,
  LandingTypeformWrapper,
  LandingPretestConsentTitle,
  BabyPeekToolTip,
  BabyPeekAvailable,
  LandingPageBillingViewBill,
  LandingPageRequisitionSelectWrapper,
  LandingPageRequisitionSelectWrapperItem,
  LandingPageMultipleRequisitionTitleWrapper,
  LandingPretestConsentSpinner,
  LandingPretestModal,
} from "./Landing.styles";
import { Card } from "../../components/shared/Cards/Card";
import { Accordions } from "../../components/shared/Accordion/Accordions";
import { GetTypographyContent } from "../../components/shared/Typography/Typography";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import {
  BABYPEEK_URL,
  BILLING_MULTIPLE_URL,
  BILLING_URL,
  HELP_PAGE_URL,
} from "../../utils/urlConstants";
import {
  getAllRequisitions,
  getSelectedRequisition,
  getUserAuth,
} from "../../recoil/selectors";
import { landingPageAccordions, landingPageCard } from "./LandingHelpData";
import { WarningRounded } from "../../assets/iconComponents/WarningRounded";
import { Button } from "../../components/shared/Button/Button";
import {
  getMissingProfileInfoMessage,
  isAddressOrInsuranceRequired,
} from "../../components/Profile/helpers";
import {
  babyPeekStatus,
  patientProfileState,
  selectedRequisitionIndex,
  showABNForm,
} from "../../recoil/atoms";
import { ProfileInsuranceModal } from "../../components/Profile/ProfileInsuranceUploadModal";
import { Typeform } from "../../components/Typeform/Typeform";
import { UpdateConsent } from "./Services/LandingService";
import {
  ConsentStatus,
  UserProfileAuthClientResponse,
} from "../../recoil/types";
import { GetPhiRequest } from "../../components/Profile/Services/UpdateProfileInfoService";
import { ProfileAlabamaWarning } from "../../components/Profile/ProfileAlabamaWarning";
import { BabyPeekOptIn } from "../../components/BabyPeek/BabyPeekOptIn";
import BabyPeekHeader from "../../components/BabyPeek/BabyPeekIcons/BabyPeekHeader.png";
import { BabyPeekButton } from "../../components/BabyPeek/BabyPeekButton";
import { DetailedResultsShareBabyPeek } from "../../components/DetailedResults/DetailedResultsShareBabyPeek";
import { SpinnerLoading } from "../../components/shared/Spinner/SpinnerLoading";
import { Banner } from "../../components/Banner/Banner";

const babyPeekUnavailableText =
  "We were unable to provide you with BabyPeek results. BabyPeek is not available for twin pregnancies, pregnancies conceived with a donor egg, surrogate pregnancies, or following a No Call or redraw request.";

const Landing = (): React.ReactElement => {
  const { deviceType } = useDeviceHook();
  const windowWidth = useWindowWidth();

  const requisitions = useRecoilValue(getAllRequisitions);
  const specificRequisition = useRecoilValue(getSelectedRequisition);
  const [selectedRequisition, setSelectedRequisitionIndex] = useRecoilState(
    selectedRequisitionIndex,
  );
  const history = useHistory();
  const auth = useRecoilValue(getUserAuth);
  const [patientInfo, setPatientInfo] = useRecoilState(patientProfileState);
  const [showABNFormPatient, setShowABNFormPatient] =
    useRecoilState(showABNForm);
  const [insuranceModalOpen, setInsuranceModalOpen] = useState(false);
  const [infoSubmitted, setInfoSubmitted] = useState("");
  const [submittingConsent, setSubmittingConsent] = useState(false);

  const [tooltip, setTooltip] = useState(false);
  const [showBabyPeekPopUp, setShowBabyPeekPopUp] = useState(false);
  const babyPeekStatusFlag = useRecoilValue(babyPeekStatus);
  const [showOutcomesBanner, setShowOutcomesBanner] = useState(false);
  const [showPatientConsent, setShowPatientConsent] = useState(false);
  const [consentRequestFailed, setConsentRequestFailed] = useState(false);

  const multipleBills = requisitions?.filter(
    (req) => req.portal_bill_status === "outstanding",
  ).length;

  useEffect(() => {
    const localPopUp = localStorage.getItem("babyPeekPopUpDismmised");
    const sessionPopUp = sessionStorage.getItem("babyPeekPopUpDismmised");

    if (localPopUp !== "true" && sessionPopUp !== "true") {
      setShowBabyPeekPopUp(true);
    }
  }, []);

  useEffect(() => {
    if (patientInfo?.user?.consent_status === ConsentStatus.INCOMPLETE) {
      setShowPatientConsent(true);
    }
    if (patientInfo?.user?.consent_status === ConsentStatus.COMPLETE) {
      const timeout = setTimeout(() => {
        setShowPatientConsent(false);
      }, 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [patientInfo?.user?.consent_status]);

  const dismissOutcomesBanner = () => {
    setShowOutcomesBanner(false);
    localStorage.setItem("seenOutcomesBanner", "true");
  };

  useEffect(() => {
    if (localStorage.getItem("seenOutcomesBanner") !== "true") {
      window.onbeforeunload = () => {
        localStorage.setItem("seenOutcomesBanner", "true");
      };
      setShowOutcomesBanner(true);
    }
  }, []);

  useEffect(() => {
    if (
      !showABNFormPatient.loaded &&
      !showABNFormPatient.show &&
      requisitions?.[selectedRequisition]?.show_abn_form
    ) {
      setShowABNFormPatient({ loaded: true, show: true });
    }
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (infoSubmitted) {
      timeout = setTimeout(() => setInfoSubmitted(""), 10000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [infoSubmitted]);

  useEffect(() => {
    const getProfileInfo = async () => {
      const response = await GetPhiRequest(auth?.access_token);
      if (response?.ok) {
        const profile =
          (await response.json()) as UserProfileAuthClientResponse;
        setPatientInfo(profile);
      }
    };
    if (!patientInfo && auth) {
      getProfileInfo();
    }
  }, [patientInfo, auth]);

  const ConsentFormAction = () => {
    if (patientInfo?.user?.consent_status === ConsentStatus.INCOMPLETE) {
      if (submittingConsent) {
        return (
          <LandingPretestConsentSpinner>
            {" "}
            <SpinnerLoading />
          </LandingPretestConsentSpinner>
        );
      }
      return (
        <LandingPretestConsentTitle
          style={{ justifySelf: "end", cursor: "pointer" }}
          onClick={async () => {
            setSubmittingConsent(!submittingConsent);
            if (patientInfo?.user) {
              const response = await UpdateConsent(
                {
                  consent_status: ConsentStatus.COMPLETE,
                  has_carrier_consent: true,
                  has_aneuploidy_consent: true,
                },
                auth?.access_token,
              );
              if (response.ok) {
                const updatedUserInfo = await response.json();
                setPatientInfo(
                  updatedUserInfo as UserProfileAuthClientResponse,
                );
              } else {
                setConsentRequestFailed(true);
              }
              setSubmittingConsent(false);
            }
          }}
        >
          I AGREE
        </LandingPretestConsentTitle>
      );
    }
    if (patientInfo?.user?.consent_status === ConsentStatus.COMPLETE) {
      return (
        <LandingPretestConsentSpinner>
          <FontAwesomeIcon
            icon={faCircleCheck}
            style={{
              color: "#60C66A",
              width: "30px",
              height: "30px",
            }}
          />
        </LandingPretestConsentSpinner>
      );
    }
  };

  const ConsentForm = (
    <>
      <Modal
        open={consentRequestFailed}
        onClose={() => setConsentRequestFailed(false)}
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          width: windowWidth,
        }}
        sx={{
          "& .MuiModal-backdrop": {
            width: "inherit",
          },
        }}
      >
        <LandingPretestModal>
          <svg
            width="41"
            height="37"
            viewBox="0 0 41 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.1328 35.8672L3.38281 29.1172C2.46875 28.2734 2.46875 26.7969 3.38281 25.9531C4.22656 25.0391 5.70312 25.0391 6.54688 25.9531L9.5 28.8359V20.75H2.75C1.48438 20.75 0.5 19.7656 0.5 18.5C0.5 17.3047 1.48438 16.25 2.75 16.25H9.5H14H27.5V8.23438L24.5469 11.1172C23.7031 12.0312 22.2266 12.0312 21.3828 11.1172C20.4688 10.2734 20.4688 8.79688 21.3828 7.95312L28.1328 1.20312C28.9766 0.289062 30.4531 0.289062 31.2969 1.20312L38.0469 7.95312C38.9609 8.79688 38.9609 10.2734 38.0469 11.1172C37.2031 12.0312 35.7266 12.0312 34.8828 11.1172L32 8.23438V16.25H38.75C39.9453 16.25 41 17.3047 41 18.5C41 19.7656 39.9453 20.75 38.75 20.75H14V28.8359L16.8828 25.9531C17.7266 25.0391 19.2031 25.0391 20.0469 25.9531C20.9609 26.7969 20.9609 28.2734 20.0469 29.1172L13.2969 35.8672C12.4531 36.7812 10.9766 36.7812 10.1328 35.8672ZM9.5 14V5C9.5 3.80469 10.4844 2.75 11.75 2.75C12.9453 2.75 14 3.80469 14 5V14H9.5ZM32 23V32C32 33.2656 30.9453 34.25 29.75 34.25C28.4844 34.25 27.5 33.2656 27.5 32V23H32Z"
              fill="#F0604B"
            />
          </svg>
          <span> Oops.</span>
          <p>This is taking longer than usual. Please try again later.</p>
          <LandingPageBillingViewBill
            onClick={() => setConsentRequestFailed(false)}
          >
            OK
          </LandingPageBillingViewBill>
        </LandingPretestModal>
      </Modal>
      {patientInfo?.user && showPatientConsent && (
        <Banner
          bannerType="information"
          bannerActionCTA={ConsentFormAction()}
          bannerText="I have been informed of and understand the details of the tests
                ordered herein for me by my healthcare provider, including the
                risks, benefits and alternatives, and consented to testing. I
                understand (1) the test results may inform me of a medical
                condition that may require follow-up and (2) a negative result
                does not rule out the possibility of such medical condition in
                the fetus, myself or my partner."
          bannerTitle="Patient Consent"
        />
      )}
      {patientInfo?.user?.survey_status === ConsentStatus.INCOMPLETE &&
        Boolean(requisitions?.length) && (
          <LandingTypeformWrapper>
            <Typeform
              typeformID="jvuge1VD"
              onSubmit={async () => {
                if (patientInfo?.user) {
                  const response = await UpdateConsent(
                    {
                      survey_status: ConsentStatus.COMPLETE,
                    },
                    auth?.access_token,
                  );
                  if (response?.ok) {
                    const updatedUserInfo = await response.json();
                    setPatientInfo(
                      updatedUserInfo as UserProfileAuthClientResponse,
                    );
                  }
                }
              }}
            />
          </LandingTypeformWrapper>
        )}
    </>
  );

  const RequisitionPicker = (
    <div>
      <LandingPageMultipleRequisitionTitleWrapper>
        Select test to display
      </LandingPageMultipleRequisitionTitleWrapper>
      <LandingPageRequisitionSelectWrapper
        value={selectedRequisition}
        onChange={(e) => setSelectedRequisitionIndex(e.target.value as number)}
        disableUnderline
        sx={{
          ".MuiOutlinedInput-notchedOutline": { borderStyle: "none" },
        }}
      >
        {requisitions?.map((req, index) => (
          <LandingPageRequisitionSelectWrapperItem
            value={index}
          >{`${getReqDisplayDate(req)} ${
            index === 0 ? "(Most recent)" : ""
          }`}</LandingPageRequisitionSelectWrapperItem>
        ))}
      </LandingPageRequisitionSelectWrapper>
    </div>
  );

  const getLandingContent = () => {
    if (deviceType === "desktop") {
      return (
        <>
          <LandingPageWrapper data-testid="LandingPageWrapper">
            <LandingPageDesktopWrapperPadding>
              {isAddressOrInsuranceRequired(patientInfo) && (
                <LandingPageWarningWrapper deviceType={deviceType}>
                  <WarningRounded />
                  <LandingPageWarningText>
                    {getMissingProfileInfoMessage(
                      patientInfo?.phi.ins_status === "Required",
                      patientInfo?.phi.address_status === "Required",
                      patientInfo?.phi.prebilling_status === "Required",
                    )}
                  </LandingPageWarningText>
                  <Button
                    size="medium"
                    onClick={() => setInsuranceModalOpen(true)}
                    label="UPDATE"
                  />
                </LandingPageWarningWrapper>
              )}
              {ConsentForm}

              {showOutcomesBanner && Boolean(requisitions?.length) && (
                <Banner
                  bannerType="information"
                  bannerActionCTA="OK"
                  bannerText="You will receive a link to complete a survey about your
                    baby’s health 4 weeks after your due date. Completing this
                    survey will help us continue to improve our products. Thank
                    you for your help!"
                  bannerTitle="Help Us Improve Prenatal Care!"
                  bannerActionOnClick={() => {
                    dismissOutcomesBanner();
                  }}
                />
              )}

              {Boolean(
                requisitions?.filter(
                  (req) => req.portal_bill_status === "outstanding",
                ).length,
              ) && (
                <Banner
                  bannerType="billing"
                  bannerActionCTA="View My Bill"
                  bannerText=""
                  bannerTitle="Your bill is ready"
                  bannerActionOnClick={() =>
                    history.push(
                      multipleBills && multipleBills > 1
                        ? `${BILLING_MULTIPLE_URL}`
                        : `${BILLING_URL}/${
                            requisitions?.filter(
                              (req) => req.portal_bill_status === "outstanding",
                            )[selectedRequisition].bill_pk
                          }`,
                    )
                  }
                />
              )}
              <LandingPageDesktopWrapper>
                <LandingPageDesktopLeftWrapper
                  requisitionCount={requisitions?.length || 0}
                  showBabyPeek={Boolean(
                    requisitions &&
                      requisitions[selectedRequisition]?.reveal_status ===
                        "pending",
                  )}
                  babyPeekAvailable={Boolean(
                    requisitions &&
                      (requisitions[selectedRequisition]?.reveal_status ===
                        "available" ||
                        requisitions[selectedRequisition]?.reveal_status ===
                          "removed"),
                  )}
                >
                  {requisitions && Boolean(requisitions?.length) && (
                    <>
                      <LandingPageTitleWrapper>
                        {GetTypographyContent({
                          content: "Your Tests",
                          size: "h2",
                          textColor: "greyMedium",
                          isBold: true,
                        })}
                      </LandingPageTitleWrapper>
                      {requisitions.length > 1 && RequisitionPicker}
                      {specificRequisition && (
                        <LandingGeneralContainer
                          padding="0px"
                          key={specificRequisition.req_identifier}
                        >
                          <TestResults
                            requisitionID={specificRequisition.req_identifier}
                            setInsuranceModalOpen={setInsuranceModalOpen}
                          />
                        </LandingGeneralContainer>
                      )}
                    </>
                  )}

                  {Boolean(
                    requisitions &&
                      (requisitions[selectedRequisition]?.reveal_status ===
                        "available" ||
                        requisitions[selectedRequisition]?.reveal_status ===
                          "removed"),
                  ) && (
                    <LandingGeneralContainer padding="0px">
                      {requisitions &&
                      requisitions[selectedRequisition]?.reveal_status ===
                        "removed" ? (
                        <BabyPeekToolTip
                          onClick={() => setTooltip(!tooltip)}
                          PopperProps={
                            {
                              sx: {
                                "& .MuiTooltip-tooltip": {
                                  backgroundColor: "white",
                                  boxShadow: "0px 10px 20px rgb(0 0 0 / 10%)",
                                  color: "black",
                                  fontSize: "16px",
                                  fontWeight: 400,
                                  lineHeight: "150%",
                                  textAlign: "center",
                                  border: "1px solid #cbd5e0",
                                },
                              },
                              open: tooltip,
                            } as Partial<PopperProps>
                          }
                          title={babyPeekUnavailableText}
                        >
                          <div>
                            BabyPeek is currently unavailable <InfoOutlined />
                          </div>
                        </BabyPeekToolTip>
                      ) : (
                        <BabyPeekAvailable>
                          <div>
                            <img src={BabyPeekHeader} alt="BabyPeekHeader" />
                            <div>BabyPeek</div>
                          </div>
                          <div>
                            <BabyPeekButton
                              variant="filled"
                              content={<>View Results</>}
                              onClick={() =>
                                history.push(
                                  `${BABYPEEK_URL}/${
                                    requisitions &&
                                    requisitions[selectedRequisition]
                                      ?.baby_peek_pk
                                  }/${
                                    babyPeekStatusFlag === "available"
                                      ? "summary"
                                      : "intro"
                                  }?loc=dashboard`,
                                )
                              }
                            />
                          </div>
                        </BabyPeekAvailable>
                      )}
                    </LandingGeneralContainer>
                  )}
                  {requisitions &&
                    requisitions[selectedRequisition]?.reveal_status ===
                      "pending" && (
                      <DetailedResultsShareBabyPeek
                        babyPeekPK={
                          requisitions[selectedRequisition]?.baby_peek_pk
                        }
                        page="Dashboard"
                      />
                    )}
                  <LandingGeneralContainer padding="20px">
                    <VideoEmbed
                      overlayText="Want to know more about UNITY Screen™?"
                      requisitionID={
                        (requisitions &&
                          requisitions[selectedRequisition]?.req_identifier) ||
                        ""
                      }
                    />
                  </LandingGeneralContainer>
                  <LandingGeneralContainer padding="0px">
                    <Card
                      {...landingPageCard(deviceType)}
                      buttons={[
                        {
                          buttonAction: () => history.push("scheduling"),
                          buttonText: "Book a Consultation",
                        },
                      ]}
                    />
                  </LandingGeneralContainer>
                </LandingPageDesktopLeftWrapper>
                <LandingPageDesktopRightWrapper>
                  <LandingPageTitleWrapper>
                    {GetTypographyContent({
                      content: "Have a question?",
                      size: "large",
                      textColor: "greyMedium",
                      isBold: true,
                    })}
                  </LandingPageTitleWrapper>
                  <LandingGeneralContainer padding="0px">
                    <LandingPageAccordionWrapper>
                      <Accordions
                        data={landingPageAccordions(
                          (requisitions &&
                            requisitions[selectedRequisition]
                              ?.req_identifier) ??
                            "",
                        )}
                        size="small"
                        isLinked
                      />
                      <LandingPageAccordionLinkWrapper>
                        {GetTypographyContent({
                          content: "See all FAQs",
                          size: "medium",
                          link: HELP_PAGE_URL,
                          textColor: "greenDark",
                        })}
                      </LandingPageAccordionLinkWrapper>
                    </LandingPageAccordionWrapper>
                  </LandingGeneralContainer>
                </LandingPageDesktopRightWrapper>
              </LandingPageDesktopWrapper>
            </LandingPageDesktopWrapperPadding>
          </LandingPageWrapper>
        </>
      );
    }
    return (
      <LandingPageWrapper data-testid="LandingPageWrapper">
        <LandingPageTabletMobileWrapper deviceType={deviceType}>
          {isAddressOrInsuranceRequired(patientInfo) && (
            <LandingPageWarningWrapperMobile deviceType={deviceType}>
              <LandingPageWarningMobileTextWrapper>
                <WarningRounded />
                <LandingPageWarningText>
                  {getMissingProfileInfoMessage(
                    patientInfo?.phi.ins_status === "Required",
                    patientInfo?.phi.address_status === "Required",
                    patientInfo?.phi.prebilling_status === "Required",
                  )}
                </LandingPageWarningText>
              </LandingPageWarningMobileTextWrapper>
              <Button
                size="medium"
                onClick={() => setInsuranceModalOpen(true)}
                label="UPDATE"
              />
            </LandingPageWarningWrapperMobile>
          )}
          {ConsentForm}
          {showOutcomesBanner && Boolean(requisitions?.length) && (
            <Banner
              bannerType="information"
              bannerActionCTA="OK"
              bannerText="You will receive a link to complete a survey about your
                    baby’s health 4 weeks after your due date. Completing this
                    survey will help us continue to improve our products. Thank
                    you for your help!"
              bannerTitle="Help Us Improve Prenatal Care!"
              bannerActionOnClick={() => {
                dismissOutcomesBanner();
              }}
            />
          )}
          {Boolean(
            requisitions?.filter(
              (req) => req.portal_bill_status === "outstanding",
            ).length,
          ) && (
            <Banner
              bannerType="billing"
              bannerActionCTA="View My Bill"
              bannerText=""
              bannerTitle="Your bill is ready"
              bannerActionOnClick={() =>
                history.push(
                  multipleBills && multipleBills > 1
                    ? `${BILLING_MULTIPLE_URL}`
                    : `${BILLING_URL}/${
                        requisitions?.filter(
                          (req) => req.portal_bill_status === "outstanding",
                        )[selectedRequisition].bill_pk
                      }`,
                )
              }
            />
          )}
          <LandingPageTabletMobileRowWrapper
            deviceType={deviceType}
            requisitionCount={requisitions?.length || 0}
            showBabyPeek={Boolean(
              requisitions &&
                requisitions[selectedRequisition]?.reveal_status === "pending",
            )}
            babyPeekAvailable={Boolean(
              requisitions &&
                (requisitions[selectedRequisition]?.reveal_status ===
                  "available" ||
                  requisitions[selectedRequisition]?.reveal_status ===
                    "removed"),
            )}
          >
            <LandingPageTitleWrapper>
              {GetTypographyContent({
                content: "Your Tests",
                size: "h2",
                textColor: "greyMedium",
                isBold: true,
                padding:
                  deviceType === "mobile" ? "40px 0 0 15px" : "40px 0 0 0",
              })}
            </LandingPageTitleWrapper>
            {requisitions && requisitions.length > 1 && RequisitionPicker}
            {specificRequisition && (
              <LandingGeneralContainer
                padding="0px"
                key={specificRequisition.req_identifier}
              >
                <TestResults
                  requisitionID={specificRequisition.req_identifier}
                  setInsuranceModalOpen={setInsuranceModalOpen}
                />
              </LandingGeneralContainer>
            )}

            {Boolean(
              requisitions &&
                requisitions[selectedRequisition]?.reveal_status ===
                  "available",
            ) && (
              <LandingGeneralContainer padding="0px">
                {requisitions &&
                requisitions[selectedRequisition]?.reveal_status ===
                  "removed" ? (
                  <BabyPeekToolTip
                    onClick={() => setTooltip(!tooltip)}
                    PopperProps={
                      {
                        sx: {
                          "& .MuiTooltip-tooltip": {
                            backgroundColor: "white",
                            boxShadow: "0px 10px 20px rgb(0 0 0 / 10%)",
                            color: "black",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "150%",
                            textAlign: "center",
                            border: "1px solid #cbd5e0",
                          },
                        },
                        open: tooltip,
                      } as Partial<PopperProps>
                    }
                    title={babyPeekUnavailableText}
                  >
                    <div>
                      BabyPeek is currently unavailable <InfoOutlined />
                    </div>
                  </BabyPeekToolTip>
                ) : (
                  <BabyPeekAvailable>
                    <div>
                      <img src={BabyPeekHeader} alt="BabyPeekHeader" />
                      <div>BabyPeek</div>
                    </div>
                    <div>
                      <BabyPeekButton
                        variant="filled"
                        content={<>View Results</>}
                        onClick={() =>
                          history.push(
                            `${BABYPEEK_URL}/${
                              requisitions &&
                              requisitions[selectedRequisition]?.baby_peek_pk
                            }/${
                              babyPeekStatusFlag === "available"
                                ? "summary"
                                : "intro"
                            }?loc=dashboard`,
                          )
                        }
                      />
                    </div>
                  </BabyPeekAvailable>
                )}
              </LandingGeneralContainer>
            )}
            {requisitions &&
              requisitions[selectedRequisition]?.reveal_status ===
                "pending" && (
                <DetailedResultsShareBabyPeek
                  babyPeekPK={requisitions[selectedRequisition]?.baby_peek_pk}
                  page="Dashboard"
                />
              )}
            <LandingGeneralContainer
              padding={deviceType === "mobile" ? "16px" : "20px"}
            >
              <VideoEmbed
                overlayText="Want to know more about UNITY Screen™?"
                requisitionID={
                  (requisitions &&
                    requisitions[selectedRequisition]?.req_identifier) ||
                  ""
                }
              />
            </LandingGeneralContainer>

            <LandingGeneralContainer
              padding={deviceType === "mobile" ? "16px" : "0px"}
            >
              <Card
                {...landingPageCard(deviceType)}
                buttons={[
                  {
                    buttonAction: () => history.push("scheduling"),
                    buttonText: "Book a Consultation",
                  },
                ]}
              />
            </LandingGeneralContainer>

            <LandingPageDesktopRightWrapper>
              <LandingPageTitleWrapper>
                {GetTypographyContent({
                  content: "Have a question?",
                  size: "xlarge",
                  textColor: "greyMedium",
                  isBold: true,
                  padding:
                    deviceType === "mobile" ? "40px 0 0 15px" : "40px 0 0 0",
                })}
              </LandingPageTitleWrapper>
              <LandingGeneralContainer padding="0px">
                <LandingPageAccordionWrapper>
                  <Accordions
                    data={landingPageAccordions(
                      (requisitions &&
                        requisitions[selectedRequisition]?.req_identifier) ??
                        "",
                    )}
                    isLinked={deviceType !== "mobile"}
                    size="small"
                  />
                  <LandingPageAccordionLinkWrapper>
                    {GetTypographyContent({
                      content: "See all FAQs",
                      size: "medium",
                      link: HELP_PAGE_URL,
                      textColor: "greenDark",
                    })}
                  </LandingPageAccordionLinkWrapper>
                </LandingPageAccordionWrapper>
              </LandingGeneralContainer>
            </LandingPageDesktopRightWrapper>
          </LandingPageTabletMobileRowWrapper>
        </LandingPageTabletMobileWrapper>
      </LandingPageWrapper>
    );
  };

  return (
    <>
      {!showBabyPeekPopUp && <BabyPeekOptIn />}
      {requisitions?.[selectedRequisition] && (
        <ProfileAlabamaWarning
          alabamaModalOpen={showABNFormPatient.show}
          setAlabamaModalOpen={(show: boolean) =>
            setShowABNFormPatient({ show, loaded: true })
          }
          requisition={requisitions?.[selectedRequisition]}
        />
      )}

      <ProfileInsuranceModal
        profileModalInsuranceOpen={insuranceModalOpen}
        setProfileModalInsuranceOpen={setInsuranceModalOpen}
        setInfoSubmitted={setInfoSubmitted}
      />

      {getLandingContent()}
    </>
  );
};

export default Landing;
