import React from "react";

import {
  BabyPeekShareOutlineButton,
  BabyPeekFilledButton,
  BabyPeekGrayButton,
} from "./BabyPeekTrait.styles";

interface BabyPeekButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "content"> {
  content: React.ReactElement;
  variant: "outline" | "filled" | "gray";
}

export const BabyPeekButton: React.FC<BabyPeekButtonProps> = ({
  content,
  variant,
  ...props
}): React.ReactElement => {
  const getButtonType = () => {
    if (variant === "filled") {
      return BabyPeekFilledButton;
    }
    if (variant === "outline") {
      return BabyPeekShareOutlineButton;
    }
    return BabyPeekGrayButton;
  };
  const ButtonType = getButtonType();
  return <ButtonType {...props}>{content}</ButtonType>;
};
