import styled from "styled-components/macro";

export const ExternalForwardFetalSexHeaderIcon = styled.img<{ width: string }>`
  width: ${({ width }) => width};
  margin: auto;
  height: inherit;
`;

export const ExternalForwardFetalSexHeaderIconsLinksContainer = styled.div`
  display: flex;
`;

export const ExternalForwardFetalSexHeaderContainer = styled.div`
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.11));
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  height: 89px;
  background: ${({ theme }) => theme.white};
`;

export const ExternalForwardFetalSexHeaderIconsContainer = styled.div`
  display: flex;
  grid-template-columns: min-content min-content min-content;
  grid-gap: 20px;
  padding-left: 50px;
`;

export const ExternalForwardFetalSexInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 75px;
  margin-bottom: 50px;
`;

export const ExternalFetalSexRevealWrapper = styled.div`
  display: grid;
  grid-template-rows: 10px auto 175px;
  grid-row-gap: 10px;
  border: 1px solid ${({ theme }) => theme.neutralBackground};
  border-radius: 8px;
  padding: 20px 20px 40px 20px;
  z-index: 10;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.neutral100};
  width: auto;
  border-radius: 8px;
  canvas {
    position: relative !important;
    z-index: 0 !important;
    margin-left: -10px;
    justify-self: center !important;
  }
`;

export const ExternalFetalSexRevealRow = styled.div<{ extend?: boolean }>`
  display: grid;
  grid-template-columns: 40px ${({ extend }) => (extend ? "250px" : "112px")};
  grid-gap: 10px;
  place-items: center;
  z-index: 2 !important;

  .detailed-result-fetal {
    margin-top: 5px;
  }
`;

export const ExternalFetalGrid = styled.div<{ isTwin: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, 245px);
  grid-gap: 15px;
  padding: 0px 0px 0px 18px;
  place-self: ${({ isTwin }) => (isTwin ? "" : "center")};
`;

export const ExternalRevealTitle = styled.div`
  margin-top: -10px;
`;

export const ExternalRevealContentWrapper = styled.div``;

export const ExternalRevealGrid = styled.div`
  display: grid;
  grid-template-rows: 40px auto;
  grid-row-gap: 20px;
`;
