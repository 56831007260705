import { Button } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoVideo } from "@fortawesome/pro-regular-svg-icons";
import { useRecoilValue } from "recoil";
import { getUserAuth } from "../../recoil/selectors";
import { TrackAnalyticsEvent } from "../../services/Analytics";
import { AnalyticsEventNames } from "../../utils/analyticsEvents";

const StyledButton = styled(Button)`
  gap: 8px;
  color: #f0604b;
  font-size: 16px;

  &:hover {
    background-color: rgba(240, 96, 75, 0.04);
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: #f0604b;
  font-size: 16px;
  font-family: "Font Awesome 6 Pro";
`;

const Text = styled.span`
  color: #f0604b;
  font-size: 14px;
  text-transform: none;
`;

const BrowseVideoButton: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const auth = useRecoilValue(getUserAuth);

  const handleClick = () => {
    if (auth?.access_token) {
      TrackAnalyticsEvent(
        AnalyticsEventNames.VideoLibraryButtonClicked,
        auth?.access_token,
        auth?.user_id,
        {
          source: location.pathname,
        },
      );
    }
    history.push("/client/videos", { from: location.pathname });
  };

  return (
    <div>
      <StyledButton
        onClick={handleClick}
        data-testid="browse-video-library-button"
        style={{ padding: "0px" }}
        disableRipple
      >
        <Icon icon={faPhotoVideo} />
        <Text>Browse video library</Text>
      </StyledButton>
    </div>
  );
};

export default BrowseVideoButton;
