import React from "react";

export const BabyPeekRadioButton: React.FC<{
  clicked: boolean;
  setClick: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ clicked, setClick }) => (
  <>
    {!clicked ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        onClick={() => setClick(!clicked)}
      >
        <rect width="24" height="24" fill="white" />
        <rect
          x="1"
          y="1"
          width="22"
          height="22"
          rx="11"
          stroke="#B2B1B1"
          strokeWidth="2"
        />
      </svg>
    ) : (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" fill="white" />
        <rect
          x="1"
          y="1"
          width="22"
          height="22"
          rx="11"
          fill="white"
          stroke="#2B3FF6"
          strokeWidth="2"
        />
        <circle cx="12" cy="12" r="6" fill="#2B3FF6" />
      </svg>
    )}
  </>
);
