import React from "react";
import ReactPlayer from "react-player/vimeo";
import { useRecoilValue } from "recoil";
import { BabyPeekVideoDiv } from "./VideoEmbed.styles";
import babyPeekVideo from "../../../assets/images/babyPeekVideo.png";
import { useDeviceHook } from "../../../utils/hooks/useDeviceHook";
import { PostBabyPeekAction } from "../../BabyPeek/Services/BabyPeekService";
import { getUserAuth } from "../../../recoil/selectors";

const BabyPeekVideoEmbed: React.FC<{ babypeekPK: string }> = ({
  babypeekPK,
}) => {
  const { deviceType } = useDeviceHook();
  const auth = useRecoilValue(getUserAuth);

  return (
    <BabyPeekVideoDiv deviceType={deviceType}>
      <ReactPlayer
        playing
        url="https://player.vimeo.com/video/874984079?h=54814c7b52#t=0m0s"
        light={babyPeekVideo}
        height={`${deviceType === "desktop" ? "100%" : "328px"}`}
        width="100%"
        controls
        data-testid="babypeek-video-player"
        data-vimeo-playsinline
        data-vimeo-autoplay
        playsinline
        onPlay={async () => {
          await PostBabyPeekAction(
            babypeekPK,
            "BabyPeekViewedDashboardVideo",
            deviceType,
            undefined,
            undefined,
            { is_logged_in: auth ? "true" : "false" },
          );
        }}
      />
    </BabyPeekVideoDiv>
  );
};

export default BabyPeekVideoEmbed;
