import React from "react";
import {
  UpfrontPaymentButtonRow,
  UpfrontPaymentFinancialReceipt,
  UpfrontPaymentFinancialReceiptTitle,
  UpfrontPaymentFinancialWarningRoundedWrapper,
} from "../UpfrontPayment.styles";
import { useDeviceHook } from "../../../utils/hooks/useDeviceHook";
import {
  getCentsToDollars,
  UpfrontPaymenFinancialAssistanceResponse,
} from "../Services/UpfrontPaymentService";
import { WarningRounded } from "../../../assets/iconComponents/WarningRounded";
import { BillingSupportNumber } from "../types";
import { Button } from "../../shared/Button/Button";
import { GreenFullCheckIcon } from "../../../assets/iconComponents/GreenFullCheckIcon";
import UpfrontPaymentPhoneLink from "../UpfrontPaymentPhoneLink";

interface StepQualificationProps {
  financialAidResponse: UpfrontPaymenFinancialAssistanceResponse;
  setStripeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setFinancialAssistanceModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  medicaidStatus: "Yes" | "No" | null;
}

const StepQualification: React.FC<StepQualificationProps> = ({
  financialAidResponse,
  setStripeModalOpen,
  setFinancialAssistanceModalOpen,
  medicaidStatus,
}) => {
  const { deviceType } = useDeviceHook();
  return (
    <UpfrontPaymentFinancialReceipt deviceType={deviceType}>
      {financialAidResponse?.is_qualified ? (
        <GreenFullCheckIcon />
      ) : (
        <UpfrontPaymentFinancialWarningRoundedWrapper>
          <WarningRounded />
        </UpfrontPaymentFinancialWarningRoundedWrapper>
      )}
      <UpfrontPaymentFinancialReceiptTitle>
        {financialAidResponse?.is_qualified
          ? `Your bill has been adjusted to ${getCentsToDollars(
              financialAidResponse?.bill.total_patient_responsibility_amount,
            )}`
          : `It doesn't look like your financial information qualifies for an income based reduction`}
      </UpfrontPaymentFinancialReceiptTitle>
      <div
        style={{
          display: "inline",
          fontSize: "16px",
          fontFamily: "light",
        }}
      >
        {financialAidResponse?.is_qualified ? (
          `Thank you for providing your ${
            medicaidStatus === "No" ? "financial assistance" : "insurance"
          } information!`
        ) : (
          <>
            {`However, you may still qualify for other discounts. Please
                    contact us at `}
            <UpfrontPaymentPhoneLink />
            {` to discuss your options with one of our team members.`}{" "}
          </>
        )}
      </div>
      <UpfrontPaymentButtonRow>
        <Button
          label={
            financialAidResponse?.is_qualified &&
            financialAidResponse?.bill.total_patient_responsibility_amount > 0
              ? `Pay ${getCentsToDollars(
                  financialAidResponse?.bill
                    .total_patient_responsibility_amount,
                )}`
              : "Done"
          }
          onClick={() => {
            setFinancialAssistanceModalOpen(false);
            if (
              financialAidResponse?.is_qualified &&
              financialAidResponse?.bill.total_patient_responsibility_amount > 0
            ) {
              setStripeModalOpen(true);
            }
          }}
          size="medium"
          backgroundColor="greenDark"
        />
      </UpfrontPaymentButtonRow>
    </UpfrontPaymentFinancialReceipt>
  );
};

export default StepQualification;
