import React from "react";
import {
  InfoBox,
  InfoList,
  UpfrontPaymentDesktopContainer,
  UpfrontPaymentFieldRow,
  UpfrontPaymentFieldRowTitle,
} from "./UpfrontPayment.styles";
import { BillingSupportNumber } from "./types";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import UpfrontPaymentPhoneLink from "./UpfrontPaymentPhoneLink";

export const OA100NoPaymentRequired: React.FC = () => {
  const { deviceType } = useDeviceHook();

  return (
    <UpfrontPaymentDesktopContainer deviceType={deviceType}>
      <UpfrontPaymentFieldRow adjustable>
        <UpfrontPaymentFieldRowTitle data-testid="oa100-header">
          Additional Responsibility
        </UpfrontPaymentFieldRowTitle>
        <div
          style={{
            color: "#5D5A5A",
            fontSize: 14,
            fontWeight: "bold",
            textTransform: "uppercase",
            wordWrap: "break-word",
            textAlign: "center",
            margin: "24px 0px",
          }}
        >
          There is no additional responsibility
        </div>
        <InfoBox>
          <InfoList>
            <li>
              We do not anticipate that you will be receiving direct payments
              from your insurance company for a portion of the above balance.
            </li>
            <br />
            <li>
              {`If you have questions about your balance above please contact us at `}
              <UpfrontPaymentPhoneLink />
              {` for assistance.`}
            </li>
          </InfoList>
        </InfoBox>
      </UpfrontPaymentFieldRow>
    </UpfrontPaymentDesktopContainer>
  );
};
