import React from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  DetailedResultsBabyPeekContent,
  DetailedResultsBabyPeekTextAneuploidy,
  DetailedResultsBabyPeekTextUpdate,
  DetailedResultsBabyPeekTextWrapper,
} from "./DetailedResults.styles";
import { BABYPEEK_URL } from "../../utils/urlConstants";
import { BabyPeekButton } from "../BabyPeek/BabyPeekButton";
import { PostBabyPeekAction } from "../BabyPeek/Services/BabyPeekService";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { getUserAuth } from "../../recoil/selectors";
import { babyPeekStatus } from "../../recoil/atoms";
import { BabyPeekGiftTraits } from "../BabyPeek/BabyPeekIcons/BabyPeekGiftTraits";
import { BabyPeekIcon } from "../BabyPeek/BabyPeekIcons/BabyPeekIcon";

export const DetailedResultsShareBabyPeek: React.FC<{
  babyPeekPK: string;
  page: "Dashboard" | "Aneuploidy";
}> = ({ babyPeekPK, page }) => {
  const history = useHistory();
  const { deviceType } = useDeviceHook();
  const babyPeekStatusFlag = useRecoilValue(babyPeekStatus);

  const auth = useRecoilValue(getUserAuth);

  return (
    <DetailedResultsBabyPeekContent
      deviceType={deviceType}
      style={{ paddingBottom: "0px" }}
      onClick={async () => {
        await PostBabyPeekAction(
          babyPeekPK,
          page === "Dashboard"
            ? "BabyPeekClickedLearnMoreDashboard"
            : "BabyPeekClickedLearnMoreAneuploidy",
          deviceType,
          undefined,
          undefined,
          { is_logged_in: auth ? "true" : "false" },
        );
        history.push(
          `${BABYPEEK_URL}/${babyPeekPK}/${
            babyPeekStatusFlag === "available" ? "summary" : "intro"
          }?loc=${page === "Dashboard" ? "dashboard" : "aneuploidy"}`,
        );
      }}
    >
      <DetailedResultsBabyPeekTextWrapper>
        <DetailedResultsBabyPeekTextAneuploidy>
          Reveal your baby’s predicted traits
        </DetailedResultsBabyPeekTextAneuploidy>
        <BabyPeekIcon />
      </DetailedResultsBabyPeekTextWrapper>
      <DetailedResultsBabyPeekTextUpdate>
        <div style={{ fontSize: "14px" }}>
          Explore beyond your UNITY results and discover your baby’s unique
          traits – all from the blood sample you already provided.
        </div>
      </DetailedResultsBabyPeekTextUpdate>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <BabyPeekButton
          variant="filled"
          color="white"
          style={{ height: "44px" }}
          content={<>Learn More</>}
          id="vwo-babypeek-info"
          onClick={() => {
            history.push(
              `${BABYPEEK_URL}/${babyPeekPK}/${
                babyPeekStatusFlag === "available" ? "summary" : "intro"
              }?loc=${deviceType === "mobile" ? "dashboard" : "aneuploidy"}`,
            );
          }}
        />
      </div>
      <BabyPeekGiftTraits />
    </DetailedResultsBabyPeekContent>
  );
};
