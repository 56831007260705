import React from "react";

import { CreateAccountFormContentPreTest } from "./CreateAccountFormContentPreTest";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";

import { TestTube } from "../../assets/iconComponents/TestTube";
import { MaleFemale } from "../../assets/iconComponents/MaleFemale";
import { PregnantWoman } from "../../assets/iconComponents/PregnantWoman";
import { BlankHeader } from "../Header/BlankHeader";
import {
  CreateAccountCenterFormPreTestInfoGrid,
  CreateAccountCenterFormPreTestInfoTitle,
  CreateAccountCenterFormPreTestInfoRow,
  CreateAccountPretestDesktopContainer,
  CreateAccountPretestMobileContainer,
  CreateAccountCenterFormPreTestInfoRowContent,
} from "./CreateAccountPreTestForm.styles";

export const CreateAccountFormPreTest: React.FC<{
  setPathname: () => void;
}> = ({ setPathname }) => {
  const { isDesktop, deviceType } = useDeviceHook();

  const InfoSummary = (
    <>
      <CreateAccountCenterFormPreTestInfoGrid deviceType={deviceType}>
        <CreateAccountCenterFormPreTestInfoTitle deviceType={deviceType}>
          Start your journey to better health{" "}
        </CreateAccountCenterFormPreTestInfoTitle>
        <CreateAccountCenterFormPreTestInfoRow deviceType={deviceType}>
          {" "}
          <TestTube />{" "}
          <CreateAccountCenterFormPreTestInfoRowContent deviceType={deviceType}>
            Non-invasive prenatal testing without needing the partner sample
          </CreateAccountCenterFormPreTestInfoRowContent>{" "}
        </CreateAccountCenterFormPreTestInfoRow>
        <CreateAccountCenterFormPreTestInfoRow deviceType={deviceType}>
          {" "}
          <MaleFemale />{" "}
          <CreateAccountCenterFormPreTestInfoRowContent
            deviceType={deviceType}
          >{`${"Predicts your baby's sex, as early as 9 weeks"}`}</CreateAccountCenterFormPreTestInfoRowContent>
        </CreateAccountCenterFormPreTestInfoRow>
        <CreateAccountCenterFormPreTestInfoRow deviceType={deviceType}>
          {" "}
          <PregnantWoman />{" "}
          <CreateAccountCenterFormPreTestInfoRowContent deviceType={deviceType}>
            Results in 2 weeks
          </CreateAccountCenterFormPreTestInfoRowContent>{" "}
        </CreateAccountCenterFormPreTestInfoRow>
      </CreateAccountCenterFormPreTestInfoGrid>
    </>
  );

  return (
    <>
      {isDesktop && (
        <div style={{ height: "100vh" }}>
          <BlankHeader link="https://unityscreen.com/" />
          <CreateAccountPretestDesktopContainer>
            {InfoSummary}
            <CreateAccountFormContentPreTest setPathname={setPathname} />
          </CreateAccountPretestDesktopContainer>
        </div>
      )}
      {!isDesktop && (
        <>
          <BlankHeader link="https://unityscreen.com/" />
          <CreateAccountPretestMobileContainer>
            {InfoSummary}
            <CreateAccountFormContentPreTest setPathname={setPathname} />
          </CreateAccountPretestMobileContainer>
        </>
      )}
    </>
  );
};
