import React, { FC } from "react";
import { GetTypographyContent } from "../shared/Typography/Typography";
import {
  CreateAccountPasswordRequirmentsContainer,
  CreateAccountPasswordRequirmentsRow,
  CreateAccountPasswordRequirmentsTitle,
  CreateAccountStyledCheckMark,
} from "./CreateAccountForm.styles";

interface CreateAccountPasswordInfoProps {
  isPw8Chars: boolean;
  isPwUpperChar: boolean;
  isPwLowerChar: boolean;
  isPwDigit: boolean;
  isFlatLayout?: boolean;
}

export const CreateAccountPasswordInfo: FC<CreateAccountPasswordInfoProps> = ({
  isPw8Chars,
  isPwUpperChar,
  isPwLowerChar,
  isPwDigit,
  isFlatLayout,
}) => (
  <>
    <CreateAccountPasswordRequirmentsTitle>
      {GetTypographyContent({
        content: "Passwords must",
        size: "medium",
        isBold: true,
        textColor: "greyBase",
      })}
      <CreateAccountPasswordRequirmentsContainer flat={isFlatLayout}>
        <CreateAccountPasswordRequirmentsRow>
          <CreateAccountStyledCheckMark $valid={isPw8Chars} />
          {GetTypographyContent({
            content: "Contain at least 8 characters",
            size: "small",
            textColor: "greyBase",
          })}
        </CreateAccountPasswordRequirmentsRow>
        <CreateAccountPasswordRequirmentsRow>
          <CreateAccountStyledCheckMark $valid={isPwUpperChar} />
          {GetTypographyContent({
            content: "Contain an uppercase letter",
            size: "small",
            textColor: "greyBase",
          })}
        </CreateAccountPasswordRequirmentsRow>
      </CreateAccountPasswordRequirmentsContainer>
      <CreateAccountPasswordRequirmentsContainer flat={isFlatLayout}>
        <CreateAccountPasswordRequirmentsRow>
          <CreateAccountStyledCheckMark $valid={isPwLowerChar} />
          {GetTypographyContent({
            content: "Contain a lowercase letter",
            size: "small",
            textColor: "greyBase",
          })}
        </CreateAccountPasswordRequirmentsRow>
        <CreateAccountPasswordRequirmentsRow>
          <CreateAccountStyledCheckMark $valid={isPwDigit} />
          {GetTypographyContent({
            content: "Contain a number",
            size: "small",
            textColor: "greyBase",
          })}
        </CreateAccountPasswordRequirmentsRow>
      </CreateAccountPasswordRequirmentsContainer>
    </CreateAccountPasswordRequirmentsTitle>
  </>
);
