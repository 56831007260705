import { CreateAccountValuesType } from "../types";

export const createAccountInitialValues: CreateAccountValuesType = {
  first_name: "",
  last_name: "",
  dob: "",
  email: "",
  password: "",
  confirm_password: "",
  middle_name: "",
  gender: "Female",
  zip: "",
  referral: "",
};
