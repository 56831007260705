import React, { useEffect, useMemo, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { AccordionDetails, AccordionSummary } from "@mui/material";

import { useHistory, useParams } from "react-router-dom";
import {
  faChevronUp,
  faClockRotateLeft,
} from "@fortawesome/pro-solid-svg-icons";
import { useRecoilState, useRecoilValue } from "recoil";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faMicroscope,
  faAtomSimple,
  faListCheck,
  faPartyHorn,
} from "@fortawesome/pro-regular-svg-icons";
import { DeviceType, useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { BabyPeekButton } from "./BabyPeekButton";
import {
  getAllRequisitions,
  getSelectedRequisition,
  getUserAuth,
} from "../../recoil/selectors";
import {
  BabyPeekTraitResponse,
  SavedCardType,
  GetBabyPeekDataRequest,
  GetSavedCardsBabyPeek,
  PayWithSavedCardBabyPeek,
  PostBabyPeekAction,
  PostTraitBabyPeekOneTime,
  PostBabyPeekUpdateGiftEmail,
} from "./Services/BabyPeekService";
import { babyPeekStatus, selectedRequisitionIndex } from "../../recoil/atoms";

import { BillingSupportNumber, StepperIndex } from "../UpfrontPayment/types";
import { SpinnerLoading } from "../shared/Spinner/SpinnerLoading";
import {
  BabyPeekFreePromotion,
  BabyPeekFreePromotionColumns,
  BabyPeekFreePromotionCrossOut,
  BabyPeekFreePromotionDescription,
  BabyPeekFreePromotionLarge,
  BabyPeekFreePromotionRed,
  BabyPeekPaymentAccordion,
  BabyPeekPaymentDropdownGrid,
  BabyPeekPaymentDropdownGridLeft,
  BabyPeekPaymentDropdownStripeWrapper,
  BabyPeekPaymentDropdownWrapper,
  BabyPeekPaymentMainGrid,
  BabyPeekPaymentSummaryDescription,
  BabyPeekPaymentSummaryGrid,
  BabyPeekPaymentSummaryItemGrid,
  BabyPeekPaymentSummaryItemGridRow,
  BabyPeekPaymentSummaryTitle,
  BabyPeekPaymentSummaryTitleTrait,
  BabyPeekPaymentValue,
  BabyPeekSummaryDropDownTitle,
  BabyPeekCheckoutTitle,
  BabyPeekFreePromotionRows,
  BabyPeekPaymentDropdownGridPaymentWrapper,
  BabyPeekMobileButtonSticky,
  BabyPeekStripeIconPointGridRow,
  BabyPeekStripeIconPointGridText,
  BabyPeekStripeIconPointGrid,
  BabyPeekPaymentSectionTitle,
  BabyPeekPaymentLogoImg,
  BabyPeekSaveCreditCardGrid,
  BabyPeekSummaryGridRow,
  BabyPeekStripeIconPointGridLargeText,
} from "./BabyPeekStripe.styles";
import { EyeCTA } from "./BabyPeekIcons/EyeCTA";

import { TasteCTA } from "./BabyPeekIcons/TasteCTA";
import { TeethCTA } from "./BabyPeekIcons/TeethCTA";
import { WaveCTA } from "./BabyPeekIcons/WaveCTA";
import BabyPeekHeader from "./BabyPeekIcons/BabyPeekHeader.png";
import { BabyPeekAccordionProps } from "./BabyPeekAccordion";
import CheckSmall from "./BabyPeekIcons/CheckSmall.png";
import { ErrorPopup } from "../shared/ErrorPopup/ErrorPopup";
import { getCentsToDollars } from "../UpfrontPayment/Services/UpfrontPaymentService";
import amex from "../../assets/images/amex.png";
import mastercard from "../../assets/images/mastercard.png";
import visa from "../../assets/images/visa.png";
import discover from "../../assets/images/discover.png";
import { BABYPEEK_URL, REGISTER_URL } from "../../utils/urlConstants";
import { BabyPeekGiftTraits } from "./BabyPeekIcons/BabyPeekGiftTraits";
import BabyPeekMultipleReqHeader from "./BabyPeekMultipleReqHeader";

export const getCardIcon = (cardType: string): string => {
  switch (cardType) {
    case "amex":
      return amex;
    case "mastercard":
      return mastercard;
    case "visa":
      return visa;
    case "discover":
      return discover;
    default:
      return visa;
  }
};

interface AckTextProps {
  isMobile: boolean;
  mobileCheckoutType: "modal" | "inline";
}
const AckText = ({ isMobile, mobileCheckoutType }: AckTextProps) => (
  <div style={{ cursor: "pointer" }}>
    <span className="ack-line">
      {`By clicking ${
        isMobile && mobileCheckoutType === "modal"
          ? "Continue to Payment, "
          : `Pay $9.99, `
      }`}
      I acknowledge the following statements:
    </span>

    <ul
      className="dashed"
      style={{
        paddingLeft: "16px",
      }}
    >
      <li>
        BabyPeek is not a medical test and does not provide information about
        the health of a baby.
      </li>
      <li>{`The baby's actual traits may vary from what is predicted. `}</li>
      <li>My doctor did not order BabyPeek.</li>
    </ul>
  </div>
);

const resourcesAccordion = (
  deviceType: DeviceType,
): BabyPeekAccordionProps => ({
  title: "12 traits included ",
  content: (
    <>
      <BabyPeekPaymentSummaryGrid>
        <BabyPeekPaymentSummaryItemGrid>
          <div>
            <WaveCTA />
          </div>
          <BabyPeekPaymentSummaryItemGridRow>
            <BabyPeekPaymentSummaryTitleTrait deviceType={deviceType}>
              Hair
            </BabyPeekPaymentSummaryTitleTrait>
            <BabyPeekPaymentSummaryDescription deviceType={deviceType}>
              Light or dark hair &bull; Red hair &bull; Hair thickness &bull;
              Hair texture
            </BabyPeekPaymentSummaryDescription>
          </BabyPeekPaymentSummaryItemGridRow>
        </BabyPeekPaymentSummaryItemGrid>
        <BabyPeekPaymentSummaryItemGrid>
          <div>
            <EyeCTA />
          </div>
          <BabyPeekPaymentSummaryItemGridRow>
            <BabyPeekPaymentSummaryTitleTrait deviceType={deviceType}>
              Eye Color
            </BabyPeekPaymentSummaryTitleTrait>
            <BabyPeekPaymentSummaryDescription deviceType={deviceType}>
              Eye color
            </BabyPeekPaymentSummaryDescription>
          </BabyPeekPaymentSummaryItemGridRow>
        </BabyPeekPaymentSummaryItemGrid>
        <BabyPeekPaymentSummaryItemGrid>
          <div>
            <TeethCTA />
          </div>

          <BabyPeekPaymentSummaryItemGridRow>
            <BabyPeekPaymentSummaryTitleTrait deviceType={deviceType}>
              Other traits
            </BabyPeekPaymentSummaryTitleTrait>
            <BabyPeekPaymentSummaryDescription deviceType={deviceType}>
              Teeth &bull; Freckles &bull; Asparagus odor detection
            </BabyPeekPaymentSummaryDescription>
          </BabyPeekPaymentSummaryItemGridRow>
        </BabyPeekPaymentSummaryItemGrid>
        <BabyPeekPaymentSummaryItemGrid>
          <div>
            <TasteCTA />
          </div>
          <BabyPeekPaymentSummaryItemGridRow>
            <BabyPeekPaymentSummaryTitleTrait deviceType={deviceType}>
              Taste
            </BabyPeekPaymentSummaryTitleTrait>
            <BabyPeekPaymentSummaryDescription deviceType={deviceType}>
              Cilantro &bull; Bitter taste &bull; Sweet &bull; Sweet vs Salty
            </BabyPeekPaymentSummaryDescription>
          </BabyPeekPaymentSummaryItemGridRow>
        </BabyPeekPaymentSummaryItemGrid>
      </BabyPeekPaymentSummaryGrid>
    </>
  ),
  key: "resourcesMoreInfo",
});

export const BabyPeekStripe: React.FC<{
  setBabyPeekData: React.Dispatch<
    React.SetStateAction<BabyPeekTraitResponse | undefined>
  >;
  babyPeekData: BabyPeekTraitResponse;
  setBabyPeekUnlockOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setBabyPeekPublicUnlockOpen: (unlock: boolean) => void;
}> = ({
  setBabyPeekData,
  babyPeekData,
  setBabyPeekUnlockOpen,
  setBabyPeekPublicUnlockOpen,
}) => {
  const { deviceType, isMobile } = useDeviceHook();
  const auth = useRecoilValue(getUserAuth);
  const { babypeekPK } = useParams<{ babypeekPK: string }>();
  const params = new URLSearchParams(window.location.search);
  const isGifting = (params.get("gifting") as "true" | "false") === "true";

  const [, setBabyPeekStatusFlag] = useRecoilState(babyPeekStatus);

  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [stripeReady, setStripeReady] = useState(false);
  const [saveCard, setSaveCard] = useState(Boolean(auth));
  const [email] = useState("");
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [savedPayments, setSavedPayments] = useState<SavedCardType[]>();
  const [useSavedPayment, setUsedSavePayment] = useState<string>();
  const [useNewCard, setUseNewCard] = useState(false);

  const [preventPaymentPageUnload, setPreventPaymentPageUnload] =
    useState(true);

  // we only want to use whole numbers for $99 or $3
  const balanceInDollars = useMemo(
    () => getCentsToDollars(babyPeekData.bill_details.balance, false),
    [babyPeekData.bill_details.balance],
  );

  const history = useHistory();
  useEffect(() => {
    // Prompt confirmation when reload page is triggered
    if (preventPaymentPageUnload) {
      window.onbeforeunload = () => "";

      // Unmount the window.onbeforeunload event
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [preventPaymentPageUnload]);

  useEffect(() => {
    if (babyPeekData?.bill_details?.signup_code) {
      history.push(`${REGISTER_URL}/${babyPeekData.bill_details.signup_code}`);
    }
  }, [babyPeekData]);

  const currentStep = StepperIndex.PaymentPlan;

  const LIST_PRICE = 249_00;

  useEffect(() => {
    if (errorMessage) {
      window.scrollTo(0, 0);
    }
  }, [errorMessage]);

  useEffect(() => {
    const getSavedCards = async () => {
      if (auth) {
        const savedCardsStepsResponse = await GetSavedCardsBabyPeek(
          babypeekPK,
          auth?.access_token,
        );
        if (savedCardsStepsResponse?.ok) {
          const savedCardsStepsResponseStepsJson =
            await savedCardsStepsResponse.json();
          setSavedPayments(savedCardsStepsResponseStepsJson.payment_methods);
        }
      }
    };

    getSavedCards();
  }, []);

  useEffect(() => {
    const filteredSavedPayments = savedPayments?.filter(
      (saved) => saved.is_chargeable,
    );
    if (
      filteredSavedPayments?.length &&
      !useNewCard &&
      !useSavedPayment &&
      !isGifting
    ) {
      setUsedSavePayment(filteredSavedPayments[0].payment_method_id);
    } else if (filteredSavedPayments || !auth) {
      setUseNewCard(true);
    }
  }, [savedPayments]);

  useEffect(() => {
    if (
      currentStep !== StepperIndex.PaymentPlan &&
      !babyPeekData.bill_details.client_secret
    ) {
      setErrorMessage(
        `There is an error - please contact us at ${BillingSupportNumber} for more details.`,
      );
    }
  }, [babyPeekData.bill_details.client_secret]);

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault();

    const supportMessage = "Please contact support at +1(650) 460-2551";

    if (!stripe || !elements) {
      setErrorMessage(`System is not ready to take payment. ${supportMessage}`);
      return;
    }

    if (loading) {
      setErrorMessage(`System is loading. ${supportMessage}`);
    }

    if (!auth) {
      setErrorMessage(`Please log in to continue. ${supportMessage}`);
      return;
    }

    if (babyPeekData.bill_details.portal_bill_status !== "outstanding") {
      setErrorMessage(
        `BabyPeek bill is not ready to be paid. ${supportMessage}`,
      );
      return;
    }

    if (useSavedPayment && auth) {
      setLoading(true);
      const savedPaymentResponse = await PayWithSavedCardBabyPeek(
        babypeekPK,
        useSavedPayment,
        auth?.email || email,
        "pay_in_full",
        auth?.access_token,
      );
      if (savedPaymentResponse?.ok) {
        const responseJson =
          (await savedPaymentResponse.json()) as BabyPeekTraitResponse;
        setBabyPeekData(responseJson);
        setBabyPeekStatusFlag(responseJson.reveal_status);
        setBabyPeekUnlockOpen(true);
        setPreventPaymentPageUnload(false);
      }
      setLoading(false);
      return;
    }

    const elementsResult = await elements.submit();

    if (elementsResult.error) {
      setErrorMessage(`${elementsResult.error.message} ${supportMessage}`);
      return;
    }
    setErrorMessage("");

    await PostBabyPeekAction(babypeekPK, "PaymentButtonClicked", deviceType);
    if (babyPeekData.bill_details.client_secret) {
      setLoading(true);

      if (isGifting) {
        await PostBabyPeekUpdateGiftEmail(
          babypeekPK,
          babyPeekData.bill_details.client_secret,
          email,
          auth?.access_token,
        );
      }
      const paymentPlanStripeResponse = await stripe.createPaymentMethod({
        elements,
        params: {
          metadata: {
            bill_pk: babyPeekData.bill_details.pk,
          },
        },
      });

      const oneTimePaymentStripeResponse = await stripe.confirmCardPayment(
        babyPeekData.bill_details.client_secret,
        {
          payment_method: paymentPlanStripeResponse.paymentMethod?.id || "",

          setup_future_usage: "off_session",
        },
      );

      if (oneTimePaymentStripeResponse.error) {
        setLoading(false);
        setErrorMessage(
          `${oneTimePaymentStripeResponse.error.message} ${supportMessage}`,
        );
      } else if (
        oneTimePaymentStripeResponse.paymentIntent.status === "succeeded"
      ) {
        const oneTimePaymentSyncResponse = await PostTraitBabyPeekOneTime(
          babypeekPK,
          saveCard,
          paymentPlanStripeResponse.paymentMethod?.id,
          auth?.email,
          auth?.access_token,
        );

        if (oneTimePaymentSyncResponse?.ok) {
          const response = await GetBabyPeekDataRequest(
            babypeekPK,
            auth?.access_token,
          );
          if (response?.ok) {
            const responseJson =
              (await response.json()) as BabyPeekTraitResponse;
            if (auth) {
              setBabyPeekData(responseJson);
            }
            setBabyPeekPublicUnlockOpen(!auth);
            setBabyPeekStatusFlag(responseJson.reveal_status);
            setBabyPeekUnlockOpen(true);
            setPreventPaymentPageUnload(false);
          } else {
            setErrorMessage(
              `We're sorry, there was an error processing payment. ${supportMessage}`,
            );
          }
        }
        setLoading(false);
      }
    }
  };

  const SummaryAccordion = (
    <BabyPeekPaymentAccordion
      deviceType={deviceType}
      onChange={() => setAccordionOpen(!accordionOpen)}
    >
      <AccordionSummary
        aria-controls={`${resourcesAccordion(deviceType).key}-panel-content`}
        id={`${resourcesAccordion(deviceType).key}-help`}
        expandIcon={<ExpandMoreIcon />}
      >
        <BabyPeekSummaryDropDownTitle>
          <div>
            <FontAwesomeIcon
              icon={faListCheck}
              style={{
                marginBottom: "-2px",
                width: "12px",
                marginRight: "5px",
              }}
            />{" "}
            {resourcesAccordion(deviceType).title}{" "}
            <FontAwesomeIcon
              icon={faChevronUp}
              style={{
                width: "12px",
                transform: `rotate(${accordionOpen ? "0deg" : "180deg"})`,
                transition: "transform",
              }}
            />
          </div>
        </BabyPeekSummaryDropDownTitle>
      </AccordionSummary>
      <AccordionDetails>
        <div>{resourcesAccordion(deviceType).content}</div>
      </AccordionDetails>
    </BabyPeekPaymentAccordion>
  );

  return (
    <>
      <BabyPeekMultipleReqHeader />
      <BabyPeekPaymentMainGrid
        deviceType={deviceType}
        termsOnly={babyPeekData.unlock_type === "terms_only"}
        checkBoxUrl={CheckSmall}
        savedPayment={Boolean(useSavedPayment)}
      >
        <BabyPeekPaymentDropdownWrapper
          termsOnly={babyPeekData.unlock_type === "terms_only"}
          deviceType={deviceType}
        >
          {!isGifting && (
            <BabyPeekCheckoutTitle> Checkout</BabyPeekCheckoutTitle>
          )}

          <BabyPeekPaymentDropdownGridPaymentWrapper deviceType={deviceType}>
            <BabyPeekPaymentDropdownGrid>
              <BabyPeekPaymentDropdownGridLeft>
                {!babyPeekData.bill_details.show_processing_fee_offer &&
                  !isGifting && (
                    <BabyPeekPaymentLogoImg
                      src={BabyPeekHeader}
                      alt="BabyPeekHeader"
                    />
                  )}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <BabyPeekPaymentSummaryTitle
                    onClick={() =>
                      history.push(`${BABYPEEK_URL}/${babypeekPK}/Intro`)
                    }
                  >
                    BabyPeek{" "}
                  </BabyPeekPaymentSummaryTitle>
                </div>
                {babyPeekData.unlock_type === "terms_only" && SummaryAccordion}
                {babyPeekData.unlock_type === "terms_only" && (
                  <BabyPeekFreePromotion>
                    <BabyPeekFreePromotionColumns freePromotion>
                      <BabyPeekFreePromotionLarge
                        style={{ justifyContent: "flex-start" }}
                      >
                        $0.00
                      </BabyPeekFreePromotionLarge>
                      <BabyPeekFreePromotionRed
                        style={{ justifyContent: "flex-end", display: "flex" }}
                      >
                        100% OFF
                      </BabyPeekFreePromotionRed>
                    </BabyPeekFreePromotionColumns>
                    <BabyPeekFreePromotionColumns freePromotion>
                      <BabyPeekFreePromotionCrossOut
                        style={{ justifyContent: "flex-start" }}
                      >
                        $149.00
                      </BabyPeekFreePromotionCrossOut>
                      <BabyPeekFreePromotionDescription
                        style={{ justifyContent: "flex-end" }}
                      >
                        <FontAwesomeIcon icon={faClockRotateLeft} /> Offer
                        expires on Oct. 15
                      </BabyPeekFreePromotionDescription>
                    </BabyPeekFreePromotionColumns>
                  </BabyPeekFreePromotion>
                )}
              </BabyPeekPaymentDropdownGridLeft>

              {(babyPeekData.bill_details.show_processing_fee_offer ||
                isGifting) && (
                <BabyPeekPaymentLogoImg
                  src={BabyPeekHeader}
                  alt="BabyPeekHeader"
                />
              )}
              {!babyPeekData.bill_details.show_processing_fee_offer &&
                !isGifting && (
                  <>
                    {babyPeekData.unlock_type === "payment" && (
                      <BabyPeekPaymentValue>
                        {balanceInDollars}
                      </BabyPeekPaymentValue>
                    )}
                    {babyPeekData.unlock_type === "discount_payment" && (
                      <BabyPeekFreePromotionRows>
                        <BabyPeekFreePromotionLarge
                          style={{ textAlign: "right" }}
                        >
                          {balanceInDollars}
                        </BabyPeekFreePromotionLarge>
                        <BabyPeekFreePromotionColumns>
                          <BabyPeekFreePromotionCrossOut>
                            {getCentsToDollars(LIST_PRICE, true)}
                          </BabyPeekFreePromotionCrossOut>
                          <BabyPeekFreePromotionRed>
                            {Math.round(
                              (babyPeekData.bill_details.balance / LIST_PRICE) *
                                100,
                            )}
                            %
                          </BabyPeekFreePromotionRed>
                        </BabyPeekFreePromotionColumns>
                      </BabyPeekFreePromotionRows>
                    )}
                  </>
                )}
            </BabyPeekPaymentDropdownGrid>

            <BabyPeekStripeIconPointGrid deviceType={deviceType}>
              <BabyPeekStripeIconPointGridRow>
                <FontAwesomeIcon icon={faAtomSimple} />
                <BabyPeekStripeIconPointGridText>
                  {` The ONLY genetic test that allows parents to discover
                      their baby's traits before birth`}
                </BabyPeekStripeIconPointGridText>
              </BabyPeekStripeIconPointGridRow>
              <BabyPeekStripeIconPointGridRow>
                <FontAwesomeIcon icon={faMicroscope} />
                <BabyPeekStripeIconPointGridText>
                  Powered by DNA sequencing technology
                </BabyPeekStripeIconPointGridText>
              </BabyPeekStripeIconPointGridRow>
              <BabyPeekStripeIconPointGridRow>
                <FontAwesomeIcon icon={faPartyHorn} />
                <BabyPeekStripeIconPointGridText id="babypeekSocialDropdown">
                  Printable games
                </BabyPeekStripeIconPointGridText>
              </BabyPeekStripeIconPointGridRow>
            </BabyPeekStripeIconPointGrid>
            <BabyPeekStripeIconPointGridRow style={{ marginBottom: "12px" }}>
              <FontAwesomeIcon icon={faBolt} />
              <BabyPeekStripeIconPointGridText>
                Results available immediately upon payment
              </BabyPeekStripeIconPointGridText>
            </BabyPeekStripeIconPointGridRow>
            {babyPeekData.unlock_type !== "terms_only" && SummaryAccordion}
            {isGifting && <BabyPeekGiftTraits />}
            <hr />
            <BabyPeekSummaryGridRow>
              <BabyPeekStripeIconPointGridLargeText>
                Total
              </BabyPeekStripeIconPointGridLargeText>
              <BabyPeekStripeIconPointGridLargeText>
                {`${balanceInDollars}`}
              </BabyPeekStripeIconPointGridLargeText>
            </BabyPeekSummaryGridRow>
          </BabyPeekPaymentDropdownGridPaymentWrapper>
        </BabyPeekPaymentDropdownWrapper>
        <BabyPeekPaymentDropdownGridPaymentWrapper
          style={{ marginTop: "24px" }}
          deviceType={deviceType}
        >
          {babyPeekData.unlock_type !== "terms_only" && (
            <BabyPeekPaymentDropdownStripeWrapper
              deviceType={deviceType}
              style={{ padding: isGifting ? "0px 0px" : undefined }}
              isGifting={isGifting}
            >
              {!isMobile && errorMessage && (
                <ErrorPopup
                  message={errorMessage}
                  hideMessage={() => setErrorMessage("")}
                />
              )}
              <BabyPeekPaymentSectionTitle
                id="babyPeekPayment"
                style={{ marginBottom: "20px" }}
              >
                Payment
              </BabyPeekPaymentSectionTitle>
              <form onSubmit={handleSubmit}>
                {!stripeReady && !isMobile && <SpinnerLoading />}
                {!isMobile && (
                  <>
                    <div
                      style={{
                        display: useNewCard ? "grid" : "none",
                        margin: "15px",
                      }}
                    >
                      <PaymentElement
                        options={{
                          paymentMethodOrder: ["card"],
                          layout: {
                            type: "tabs",
                          },
                        }}
                        onReady={() => setStripeReady(true)}
                      />

                      {!useSavedPayment && !isGifting && (
                        <BabyPeekSaveCreditCardGrid
                          onClick={() => {
                            setSaveCard(!saveCard);
                          }}
                        >
                          <input
                            type="checkbox"
                            onClick={() => setSaveCard(!saveCard)}
                            checked={saveCard}
                          />

                          <div>Save this card for faster checkout</div>
                        </BabyPeekSaveCreditCardGrid>
                      )}
                    </div>
                  </>
                )}

                {loading && !isMobile ? (
                  <SpinnerLoading />
                ) : (
                  <>
                    {!isMobile && (
                      <>
                        <div
                          style={{
                            padding: "0px 24px",
                          }}
                        >
                          <AckText
                            isMobile={isMobile}
                            mobileCheckoutType="inline"
                          />
                          <br />
                        </div>

                        <div>
                          <BabyPeekButton
                            id="vwo-babypeek-pay-now"
                            variant="filled"
                            style={{ width: "222px" }}
                            content={<> Pay {balanceInDollars} </>}
                            type="submit"
                            disabled={loading}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </form>
              {/* this form is almost the same as the one in the stripe modal */}
              {/* primary difference is that it has a sticky button */}
              {isMobile && (
                <>
                  {errorMessage && (
                    <ErrorPopup
                      message={errorMessage}
                      hideMessage={() => setErrorMessage("")}
                    />
                  )}
                  <form
                    style={{
                      margin: "-24px",
                    }}
                    onSubmit={handleSubmit}
                  >
                    <div
                      style={{
                        display: useNewCard ? "grid" : "none",
                        margin: "15px",
                      }}
                    >
                      <PaymentElement
                        options={{
                          paymentMethodOrder: ["card"],
                          layout: {
                            type: "tabs",
                          },
                        }}
                        onReady={() => setStripeReady(true)}
                      />

                      {!useSavedPayment && !isGifting && (
                        <BabyPeekSaveCreditCardGrid
                          onClick={() => {
                            setSaveCard(!saveCard);
                          }}
                        >
                          <input
                            type="checkbox"
                            onChange={() => setSaveCard(!saveCard)}
                            checked={saveCard}
                          />

                          <div>Save this card for faster checkout</div>
                        </BabyPeekSaveCreditCardGrid>
                      )}
                    </div>
                    {loading ? (
                      <SpinnerLoading />
                    ) : (
                      <>
                        <div
                          style={{
                            padding: "0px 24px",
                            background: "var(--primary-beige, #FFF6D2);",
                          }}
                        >
                          <AckText
                            isMobile={isMobile}
                            mobileCheckoutType="inline"
                          />
                          <br />
                        </div>

                        <BabyPeekMobileButtonSticky style={{ width: "auto" }}>
                          <BabyPeekButton
                            id="vwo-babypeek-pay-now"
                            type="submit"
                            variant="filled"
                            content={<>Pay {balanceInDollars} </>}
                            style={{ width: "222px" }}
                            disabled={loading}
                          />
                        </BabyPeekMobileButtonSticky>
                      </>
                    )}
                  </form>
                </>
              )}
            </BabyPeekPaymentDropdownStripeWrapper>
          )}
        </BabyPeekPaymentDropdownGridPaymentWrapper>
      </BabyPeekPaymentMainGrid>
    </>
  );
};
