import React from "react";
import { useHistory } from "react-router-dom";
import { ChatIcon } from "../../assets/iconComponents/ChatIcon";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { StyledButton } from "../shared/Button/Button.styles";
import { GetTypographyContent } from "../shared/Typography/Typography";
import {
  DetailedResultsButtonTextWrapper,
  DetailedResultsExpertWrapper,
} from "./DetailedResults.styles";

export const DetailedResultsExpert: React.FC<{ contactUs?: boolean }> = ({
  contactUs,
}) => {
  const { deviceType } = useDeviceHook();
  const history = useHistory();

  return (
    <DetailedResultsExpertWrapper deviceType={deviceType}>
      <div>
        <ChatIcon />
      </div>
      <div>
        {GetTypographyContent({
          content: contactUs
            ? "Schedule a complimentary genetic counseling session"
            : "Would you like to discuss your results with an expert?",
          size: "xlarge",
          textColor: "greyMedium",
          isBold: true,
        })}
      </div>
      <div>
        {GetTypographyContent({
          content: contactUs
            ? "Schedule a complimentary genetic counseling session to answer your questions and help you understand your results"
            : "Our board certified and licensed genetic counselors are available to answer your questions and help you understand your results.",
          size: "medium",
          textColor: "greyMedium",
        })}
      </div>
      <StyledButton
        variant="outlined"
        size="small"
        $backgroundColor="white"
        $textColor="redMedium"
        onClick={() => history.push("./scheduling")}
      >
        <DetailedResultsButtonTextWrapper>
          {GetTypographyContent({
            content: "BOOK A CONSULTATION",
            size: "medium",
            textColor: "redMedium",
            isCaps: true,
            isBold: false,
          })}
        </DetailedResultsButtonTextWrapper>
      </StyledButton>
    </DetailedResultsExpertWrapper>
  );
};
