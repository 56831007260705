import React, { useEffect, useState } from "react";

import { useWindowWidth } from "@react-hook/window-size";
import { faArrowUpFromBracket } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BabyPeekTraitWrapper,
  BabyPeekV2HeaderTitleWrapper,
  BabyPeekV2HeaderTitleTop,
  BabyPeekV2HeaderTitleBottom,
  BabyPeekV2HeaderIconGrid,
  BabyPeekV2SectionText,
  BabyPeekV2SectionTitle,
  BabyPeekV2Section,
  BabyPeekV2HeaderIconShare,
  BabyPeekV2HeaderIconShareTitle,
} from "../Shared/BabyPeekTrait.styles";
import TeethBackground from "./TeethBackground.png";
import TeethIcon from "./TeethIcon.png";

import { BabyPeekTraitResponse, Trait } from "../../Services/BabyPeekService";
import { useDeviceHook } from "../../../../utils/hooks/useDeviceHook";
import { TraitNavigationLeft } from "../Shared/TraitNavigationLeft";

import {
  BabyPeekMobileButtonSticky,
  BabyPeekTraitNavigationWrapper,
  BabyPeekTraitNavigationButtonWrapper,
  BabyPeekDesktopButtonSticky,
} from "../../BabyPeekStripe.styles";
import { BabyPeekButton } from "../../BabyPeekButton";
import { TraitMenu } from "../Shared/TraitMenu";

import { TeethGene } from "./TeethGene";
import { TeethHowItWorks } from "./TeethHowItWorks";
import { TeethEarlyProb } from "./TeethEarlyProb";
import { TeethLateProb } from "./TeethLateProb";
import { TeethOtherFactors } from "./TeethOtherFactors";
import { BabyPeekTraitAccordion } from "../Shared/BabyPeekTraitAccordion";
import { ShareUnderline } from "../../BabyPeekIcons/ShareUnderline";
import teethGif from "../../AnimatedGifs/teething.gif";
import { BabyPeekSingleFeedback } from "../../BabyPeekSingleFeedback";

const getBabyTeethDosage = (dosage: number): string => {
  if (dosage < 2) return "early";
  return "later";
};

const getBabyTeethDosageImage = (
  dosage: number,
  showGif: boolean,
): React.ReactElement => {
  if (showGif)
    return <img src={teethGif} alt="loading..." style={{ width: "328px" }} />;
  if (dosage < 2) return <TeethEarlyProb />;
  return <TeethLateProb />;
};

const HowItWorks = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>
      Why do babies get their first baby teeth at different ages?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      The eruption/emergence of baby teeth, called teething, can occur before
      birth or after Baby’s first birthday—but the average teething age is 6-12
      months.
      <br />
      Tooth buds form under the gums as early as the first trimester. Many
      different genes play a role in the development of tooth buds and when the
      first teeth erupt.
      <br />
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        {" "}
        <TeethHowItWorks />{" "}
      </div>
      <br />
    </BabyPeekV2SectionText>
    <br />
    <BabyPeekV2SectionTitle>
      How did we predict if your baby will have early or late baby teeth?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      We looked at two different genetic variations associated with teething.
      For example, the <span className="italic">EDA</span> gene plays an
      important role in the development of hair, skin, nails, and teeth. Babies
      with particular genetic variations are more likely to have their first
      teeth emerge earlier than others.
      <br />
      <BabyPeekV2SectionTitle>The gene we peeked at</BabyPeekV2SectionTitle>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {" "}
        <TeethGene />{" "}
      </div>
      <br />
    </BabyPeekV2SectionText>
  </BabyPeekV2Section>
);

const OtherFactors = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>
      What else can affect when baby teeth appear?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      A person’s traits are often determined by a combination of genetic and
      environmental factors. While many of the genetic variations that influence
      these traits are well-studied, there are some genetic factors that are
      still unknown to scientists.
      <br />
      Other factors that can affect teething include:
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}
      >
        {" "}
        <TeethOtherFactors />{" "}
      </div>
      <br />
    </BabyPeekV2SectionText>
    <br />
  </BabyPeekV2Section>
);

const AboutBabyPeek = (
  <BabyPeekV2SectionText>
    BabyPeek results are:
    <ul>
      <li>
        A <span style={{ fontWeight: 700 }}>predictor</span> of what traits your
        baby will have. These results do not mean that your baby will have these
        traits with absolute certainty and a baby may have different traits than
        what is predicted.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT medical test results.</span> These
        results should not be used to make medical decisions about your
        pregnancy. The genetic changes mentioned in these results do not affect
        a baby’s health.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT paternity testing.</span> These
        results should not be used to determine paternity. Given the complex
        genetics of these traits, a baby may be born with different traits from
        the biological mother or father.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>
          NOT managed by your healthcare provider.
        </span>{" "}
        These results are unlocked by the patient directly. Please do not
        contact your healthcare provider regarding your BabyPeek results.
      </li>
    </ul>
    If you have questions about BabyPeek results, please contact BillionToOne
    directly at
    <a href={`tel:${"(650) 460-2551"}`} target="_blank" rel="noreferrer">
      {` (650) 460-2551`}
    </a>
    .
    <br />
  </BabyPeekV2SectionText>
);

export const BabyTeethTrait: React.FC<{
  setCurrentTrait?: (trait: Trait | undefined) => void;
  traitData: BabyPeekTraitResponse;
  publicTrait?: boolean;
  setShareModal?: (share: boolean) => void;
  hideTraitMenu: boolean;
  modalPreview?: boolean;
  showAnimation?: boolean;
}> = ({
  setCurrentTrait,
  traitData,
  setShareModal,
  hideTraitMenu,
  publicTrait,
  modalPreview = false,
  showAnimation = false,
}) => {
  const { deviceType } = useDeviceHook();
  const windowWidth = useWindowWidth();
  const [showTraitMenu, setShowTraitMenu] = React.useState(false);
  const StickyWrapper =
    deviceType !== "desktop"
      ? BabyPeekMobileButtonSticky
      : BabyPeekDesktopButtonSticky;
  const [showingGifs, setShowingGifs] = useState(
    (modalPreview || showAnimation) ?? false,
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (showingGifs) {
        setShowingGifs(false);
      }
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      {" "}
      <div
        style={{
          backgroundImage: `url(${TeethBackground})`,
          height: "260px",
          backgroundSize: "cover",
          padding: "20px",
          display: "grid",
          justifyContent: deviceType !== "mobile" ? "center" : "flex-start",
          paddingBottom: "32px",
          paddingTop: "24px",
          gridGap: "12px",
        }}
        className="trait-top-background"
      >
        <BabyPeekV2HeaderIconShare>
          <BabyPeekV2HeaderIconGrid>
            <img
              src={TeethIcon}
              alt="TeethIcon"
              style={{ justifySelf: "center" }}
            />
            <div>Teething Timeline</div>
          </BabyPeekV2HeaderIconGrid>
          {setShareModal && !modalPreview && (
            <BabyPeekV2HeaderIconShareTitle onClick={() => setShareModal(true)}>
              {" "}
              <FontAwesomeIcon icon={faArrowUpFromBracket} /> Share
              <ShareUnderline />
            </BabyPeekV2HeaderIconShareTitle>
          )}
        </BabyPeekV2HeaderIconShare>

        <BabyPeekV2HeaderTitleWrapper
          deviceType={deviceType}
          windowWidth={windowWidth}
        >
          <BabyPeekV2HeaderTitleTop>
            {publicTrait
              ? "The baby is most likely to get their"
              : "Your baby is most likely to get their"}
          </BabyPeekV2HeaderTitleTop>
          <BabyPeekV2HeaderTitleBottom>
            {`first tooth ${getBabyTeethDosage(
              traitData.time_first_tooth_dosage,
            )}`}
          </BabyPeekV2HeaderTitleBottom>
        </BabyPeekV2HeaderTitleWrapper>
      </div>
      <BabyPeekTraitWrapper deviceType={deviceType}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <div>
            <BabyPeekV2SectionTitle>Teething timing</BabyPeekV2SectionTitle>
            {getBabyTeethDosageImage(
              traitData.time_first_tooth_dosage,
              showingGifs,
            )}
          </div>
        </div>
        {!modalPreview && (
          <>
            {" "}
            <hr />
            <div
              style={{
                maxWidth: `${windowWidth - 2}px`,
                padding: "25px",
                paddingTop: "0px",
              }}
            >
              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "How it works",
                    content: HowItWorks,
                    key: "howItWorksEye",
                    defaultExpanded: true,
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "Other factors",
                    content: OtherFactors,
                    key: "otherFactorsEye",
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "About BabyPeek",
                    content: AboutBabyPeek,
                    key: "aboutBabyPeekEye",
                  },
                ]}
              />
            </div>{" "}
          </>
        )}
        {!modalPreview && <BabyPeekSingleFeedback onlyFeedback />}
      </BabyPeekTraitWrapper>
      {setCurrentTrait && !modalPreview && (
        <StickyWrapper style={{ zIndex: 10 }}>
          {showTraitMenu && TraitMenu(setCurrentTrait, "BabyTeeth")}
          <BabyPeekTraitNavigationWrapper
            $width={windowWidth}
            deviceType={deviceType}
          >
            <BabyPeekTraitNavigationButtonWrapper
              onClick={() => setCurrentTrait("SweetSalty")}
            >
              <TraitNavigationLeft />
            </BabyPeekTraitNavigationButtonWrapper>{" "}
            {!hideTraitMenu && (
              <BabyPeekButton
                variant="gray"
                content={<>{showTraitMenu ? " Hide Traits" : "View Traits"}</>}
                onClick={async () => {
                  setShowTraitMenu(!showTraitMenu);
                }}
              />
            )}
            <div />
          </BabyPeekTraitNavigationWrapper>
        </StickyWrapper>
      )}
    </>
  );
};
