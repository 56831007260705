import styled from "styled-components/macro";

export const BabyPeekBarVertical = styled.div`
  display: flex;
  flex-direction: column;
  height: 350px;
  width: 45px;
  border: 1px dashed ${({ theme }) => theme.neutral300};
  border-radius: 10000px;
  overflow: auto;
  justify-content: flex-end;
`;

export const BabyPeekBarSectionVertical = styled.div<{
  $height: number;
  $color: string;
  $start: boolean;
  $end: boolean;
  selected: boolean;
}>`
  display: flex;
  height: ${({ $height }) => $height}%;
  background: ${({ $color }) => $color};
  border: ${({ $height, selected }) =>
    selected && $height > 10 ? "1px solid #000" : "none"};

  border-bottom-right-radius: ${({ selected }) =>
    selected ? "24px" : undefined};
  border-bottom-left-radius: ${({ selected }) =>
    selected ? "24px" : undefined};
`;

export const BabyPeekBarHorizontal = styled.div<{
  selected: boolean;
}>`
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 45px;

  border-radius: 10000px;
  overflow: auto;
  background: #e4eaf0;
  min-width: 350px;
`;

export const BabyPeekBarSectionHorizontal = styled.div<{
  $width: number;
  $color: string;
  $start: boolean;
  $end: boolean;
  selected: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $width }) => $width}%;
  background: ${({ $color }) => $color};
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
`;
