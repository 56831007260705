import React from "react";
import { Info } from "@mui/icons-material";
import { useRecoilValue } from "recoil";
import {
  getAllRequisitions,
  getSelectedRequisition,
} from "../../recoil/selectors";
import {
  BabyPeekMultipleReqMessage,
  BabyPeekMultipleReqMessageBorder,
} from "./BabyPeekIntro.styles";
import {
  UpfrontPaymentTooltip,
  UpfrontPaymentTooltipPopper,
  UpfrontPaymentTooltipContent,
} from "../UpfrontPayment/UpfrontPayment.styles";
import useBabyPeek from "../../utils/hooks/useBabyPeek";
import { getReqDisplayDate } from "../../utils/dateUtils";

const BabyPeekMultipleReqHeader: React.FC = () => {
  const requisitions = useRecoilValue(getAllRequisitions);
  const { isMostRecentRequisition } = useBabyPeek();
  const specificRequisition = useRecoilValue(getSelectedRequisition);
  const displayDate = specificRequisition
    ? getReqDisplayDate(specificRequisition)
    : "";

  return (
    <>
      {requisitions && requisitions?.length > 1 && (
        <>
          <BabyPeekMultipleReqMessage>
            <>{`BabyPeek for your ${
              isMostRecentRequisition
                ? "most recent test"
                : `test received on ${displayDate}`
            }`}</>
            <UpfrontPaymentTooltip
              PopperComponent={UpfrontPaymentTooltipPopper}
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              title={
                <UpfrontPaymentTooltipContent>
                  To view BabyPeek results from a different BillionToOne test,
                  navigate to the homepage and select the desired test.
                </UpfrontPaymentTooltipContent>
              }
            >
              <div>
                <Info style={{ color: "#464547" }} />
              </div>
            </UpfrontPaymentTooltip>
          </BabyPeekMultipleReqMessage>
          <BabyPeekMultipleReqMessageBorder />
        </>
      )}
    </>
  );
};

export default BabyPeekMultipleReqHeader;
