import React from "react";
import { ContactUs } from "../../components/ContactUs/ContactUs";
import { ContactUsInfo } from "../../components/ContactUs/ContactUsInfo";
import { DetailedResultsExpert } from "../../components/DetailedResults/DetailedResultsExpert";
import { GetTypographyContent } from "../../components/shared/Typography/Typography";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import {
  ContactUsComponentWrapper,
  ContactUsComponentContentWrapper,
  ContactUsPageDesktopRightWrapper,
  ContactUsGeneralContainer,
  ContactUsPageWrapper,
} from "./ContactUsPage.styles";

const ContactUsPage = (): React.ReactElement => {
  const { deviceType } = useDeviceHook();

  if (deviceType === "desktop") {
    return (
      <ContactUsPageWrapper>
        <ContactUsComponentWrapper deviceType={deviceType}>
          <ContactUsComponentContentWrapper deviceType={deviceType}>
            {GetTypographyContent({
              content: "Contact us",
              size: "h2",
              textColor: "greyMedium",
              isBold: true,
            })}
            <ContactUsGeneralContainer padding="20px">
              <ContactUs />
            </ContactUsGeneralContainer>
          </ContactUsComponentContentWrapper>
          <ContactUsPageDesktopRightWrapper deviceType={deviceType}>
            {GetTypographyContent({
              content: "Connect",
              size: "medium",
              textColor: "greyMedium",
              isBold: true,
              className: "contact-us-connect",
            })}
            <ContactUsGeneralContainer padding="20px">
              <ContactUsInfo />
            </ContactUsGeneralContainer>
            <ContactUsGeneralContainer padding="20px">
              <DetailedResultsExpert contactUs />
            </ContactUsGeneralContainer>
          </ContactUsPageDesktopRightWrapper>
        </ContactUsComponentWrapper>
      </ContactUsPageWrapper>
    );
  }
  return (
    <ContactUsPageWrapper>
      <ContactUsComponentWrapper deviceType={deviceType}>
        <ContactUsComponentContentWrapper deviceType={deviceType}>
          {GetTypographyContent({
            content: "Contact us",
            size: "h2",
            textColor: "greyMedium",
            isBold: true,
            padding: deviceType === "mobile" ? "0 0 0 16px" : "",
          })}
          <ContactUsGeneralContainer padding="20px">
            <ContactUs />
          </ContactUsGeneralContainer>
          <ContactUsPageDesktopRightWrapper deviceType={deviceType}>
            {GetTypographyContent({
              content: "Connect",
              size: "large",
              textColor: "greyMedium",
              isBold: true,
              padding: "55px 0 28px 4px",
            })}
            <ContactUsGeneralContainer padding="5px 20px 0px 20px">
              <ContactUsInfo />
            </ContactUsGeneralContainer>
            <ContactUsGeneralContainer padding="30px 30px 20px 20px">
              <DetailedResultsExpert contactUs />
            </ContactUsGeneralContainer>
          </ContactUsPageDesktopRightWrapper>
        </ContactUsComponentContentWrapper>
      </ContactUsComponentWrapper>
    </ContactUsPageWrapper>
  );
};

export default ContactUsPage;
