import React from "react";

import { Stepper, Step, StepLabel, StepContent } from "@mui/material";

import moment from "moment";
import {
  UpfrontPaymentStepperWrapper,
  UpfrontPaymentStepperRow,
  UpfrontPaymentStepperRowDate,
  UpfrontPaymentStepperRowCost,
} from "./UpfrontPayment.styles";

import {
  UpfrontPaymentPlan,
  getCentsToDollars,
} from "./Services/UpfrontPaymentService";
import { PaymentPendingCircle } from "../../assets/iconComponents/PaymentPendingCircle";
import { PaymentCompleteCircle } from "../../assets/iconComponents/PaymentCompleteCircle";

export const UpfrontPaymentPlanSteps: React.FC<{
  steps: UpfrontPaymentPlan[];
}> = ({ steps }) => {
  const stepComponents = steps?.map((payment) => ({
    component: (
      <UpfrontPaymentStepperRow>
        <UpfrontPaymentStepperRowDate>
          {moment(new Date(payment.epoch * 1000)).format("MMMM Do, YYYY")}{" "}
        </UpfrontPaymentStepperRowDate>{" "}
        <UpfrontPaymentStepperRowCost>{`${getCentsToDollars(
          payment.amount,
        )}`}</UpfrontPaymentStepperRowCost>
      </UpfrontPaymentStepperRow>
    ),
    payment,
  }));

  return (
    <>
      <UpfrontPaymentStepperWrapper>
        <Stepper activeStep={0} orientation="vertical">
          {stepComponents?.map((step) => (
            <Step key={step.payment.epoch}>
              <StepLabel
                StepIconComponent={
                  step.payment.status === "paid"
                    ? PaymentCompleteCircle
                    : PaymentPendingCircle
                }
              >
                {step.component}
              </StepLabel>
              <StepContent />
            </Step>
          ))}
        </Stepper>
      </UpfrontPaymentStepperWrapper>
    </>
  );
};
