import * as React from "react";

export const PregnantWoman = (): React.ReactElement => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_43_674)">
      <mask
        id="mask0_43_674"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <path d="M40 0H0V40H40V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_43_674)">
        <g opacity="0.7">
          <path
            opacity="0.24"
            d="M20.0019 39.9999C23.9942 40.0297 27.9056 38.8817 31.2397 36.7019C34.5737 34.522 37.1803 31.4084 38.7287 27.756C40.2772 24.1036 40.6977 20.077 39.937 16.1871C39.1762 12.2972 37.2684 8.71908 34.4556 5.90692C31.6429 3.09472 28.0519 1.17507 24.1382 0.391497C20.2245 -0.392076 16.1646 -0.00426425 12.4733 1.50574C8.7821 3.01574 5.62589 5.57987 3.40515 8.87287C1.18442 12.1659 -0.00079154 16.0394 -0.000115762 20.0019C-0.0191422 25.2861 2.0774 30.3616 5.82839 34.1119C9.57939 37.8621 14.6777 39.9801 20.0019 39.9999Z"
            fill="#F6FBFC"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0908 8.76846C13.4492 8.84996 13.6662 9.17855 13.576 9.50198C12.5623 13.1335 12.4077 15.2345 12.7563 18.2771C12.7944 18.6087 13.1474 18.8492 13.5327 18.7796L22.1869 17.2164L25.7648 16.8126C26.0977 16.775 26.2703 16.4341 26.0842 16.182C26.0004 16.0688 25.8598 16.0006 25.7087 16.0006H22.4266C22.3826 16.0006 22.3385 16.0046 22.2953 16.0123L17.3353 16.9083C16.1122 17.1292 14.8969 16.2855 14.9577 15.103C15.0074 14.1311 15.1649 13.2817 15.6236 11.6247C15.7133 11.301 16.0766 11.1041 16.435 11.1851C16.7934 11.266 17.0114 11.5941 16.9218 11.9178C16.4726 13.5404 16.3382 14.3018 16.2941 15.159C16.2766 15.5001 16.6399 15.8015 17.073 15.7233L22.033 14.8275C22.1624 14.804 22.2944 14.7922 22.4266 14.7922H25.7087C26.3071 14.7922 26.8657 15.0623 27.1975 15.5119C27.9354 16.5114 27.2505 17.8628 25.9307 18.0117L22.4012 18.4103L13.7953 19.9645C12.6602 20.1697 11.5471 19.463 11.4257 18.4017C11.0592 15.2044 11.2306 12.9614 12.2787 9.20661C12.369 8.88293 12.7326 8.68696 13.0908 8.76846Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.7188 18.7629C14.0937 18.6919 14.4586 18.9218 14.5334 19.2763L14.7983 20.5289C15.0999 21.9547 15.0629 23.4262 14.6899 24.8372L14.522 25.4722L14.5169 25.488C14.0684 26.8831 13.8774 27.8764 13.8848 28.777C13.8922 29.6718 14.0963 30.5202 14.5079 31.628C14.6345 31.9691 14.4449 32.3428 14.0842 32.4628C13.7235 32.5827 13.3283 32.4031 13.2015 32.0621C12.7636 30.8835 12.5096 29.8825 12.5002 28.7872C12.4913 27.7019 12.7233 26.569 13.1871 25.1227L13.3468 24.5194C13.67 23.2968 13.7019 22.0216 13.4407 20.7859L13.1758 19.5332C13.1009 19.1787 13.3441 18.8337 13.7188 18.7629Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.1942 8.92535C20.4533 8.69155 20.8731 8.69155 21.132 8.92535L22.3506 10.0246C23.8046 11.3366 24.1652 13.3407 23.2455 15L22.0593 14.465C22.7236 13.2661 22.4631 11.8183 21.4128 10.8707L20.1942 9.77138C19.9353 9.53758 19.9353 9.15891 20.1942 8.92535Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.57 17.8533C22.7383 17.5426 23.1574 17.4122 23.5063 17.5623C26.7662 18.9643 28.5005 21.4985 28.7253 23.8987C28.9361 26.151 27.7987 28.3391 25.3054 29.1991V31.8749C25.3054 32.22 24.9915 32.5 24.604 32.5C24.2166 32.5 23.9029 32.22 23.9029 31.8749V28.75C23.9029 28.4708 24.1105 28.2256 24.4115 28.1489C26.4737 27.6238 27.5096 25.9477 27.3277 24.0027C27.1454 22.0575 25.7239 19.9041 22.8965 18.6878C22.5478 18.5377 22.4016 18.1641 22.57 17.8533Z"
          fill="white"
        />
        <path
          d="M20.625 27.5C21.6605 27.5 22.5 26.3807 22.5 25C22.5 23.6193 21.6605 22.5 20.625 22.5C19.5895 22.5 18.75 23.6193 18.75 25C18.75 26.3807 19.5895 27.5 20.625 27.5Z"
          fill="#CFEBE8"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_43_674">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
