import * as React from "react";
import { themePalette } from "../../theme";

export const NegativeIcon = ({
  color = themePalette.resultsNegativeGreen,
  ...props
}: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 24 24"
    fill="none"
    className="negative-icon"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 0C8.58216 0.0751174 5.74648 1.23944 3.49296 3.49296C1.23944 5.74648 0.0751174 8.58216 0 12C0.0751174 15.4178 1.23944 18.2535 3.49296 20.507C5.74648 22.7606 8.58216 23.9249 12 24C15.4178 23.9249 18.2535 22.7606 20.507 20.507C22.7606 18.2535 23.9249 15.4178 24 12C23.9249 8.58216 22.7606 5.74648 20.507 3.49296C18.2535 1.23944 15.4178 0.0751174 12 0Z"
      fill={color}
    />
    <path
      d="M10.2375 17.8231L5.36248 12.9481C5.0696 12.6552 5.0696 12.1804 5.36248 11.8874L6.42312 10.8268C6.716 10.5339 7.1909 10.5339 7.48378 10.8268L10.7678 14.1108L17.8019 7.07678C18.0947 6.7839 18.5696 6.7839 18.8625 7.07678L19.9232 8.13744C20.216 8.43032 20.216 8.9052 19.9232 9.19811L11.2982 17.8231C11.0052 18.116 10.5304 18.116 10.2375 17.8231Z"
      fill="white"
    />
  </svg>
);
