import styled from "styled-components/macro";

import { DeviceType } from "../../utils/hooks/useDeviceHook";
import {
  BabyPeekAccordionAnswer,
  BabyPeekAccordionQuestion,
} from "./BabyPeekAccordion.styles";
import { BabyPeekFilledButton } from "./BabyPeekTrait.styles";
import { BabyPeekVideoDiv } from "../shared/Video/VideoEmbed.styles";

export const BabyPeekIntroWrapper = styled.div`
  display: grid;
`;

export const BabyPeekMultipleReqMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  svg {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 18px;
  }
  margin: 10px 20px 10px 20px;
`;

export const BabyPeekMultipleReqMessageBorder = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.neutral200};
`;

export const BabyPeekIntroBodyWrapper = styled.div`
  display: grid;
  gap: 0px;
`;

export const BabyPeekIntroMarqueeWrapper = styled.div`
  display: grid;
  z-index: 10;
  button {
    margin-bottom: 40px;
    margin-top: 24px;
  }
`;

export const BabyPeekMobileHeaderImageWrapper = styled.div`
  svg {
    height: 40px;
  }

  .babypeek-icon {
    margin-left: -16px;
  }
  .babypeek-ratings {
    margin-right: 24px;
    cursor: pointer;
  }
`;

export const BabyPeekRatingsWrapper = styled.div``;

export const BabyPeekIntroGrid = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  align-items: center;
  text-align: center;
  grid-gap: 24px;
  margin-bottom: 24px;
  .babypeek-ratings {
    cursor: pointer;
  }
`;

export const BabyPeekButtonGradientContent = styled.button<{
  $backgroundColor: string;
  $color: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  background: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $color }) => $color};
  border-radius: 30px;
  text-align: center;
  font-size: 16px;
  font-family: Poppins;
  line-height: 100%;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid black;

  svg {
    margin-right: 8px;
  }
`;

export const BabyPeekButtonGradient = styled.div<{
  width: number;
  $disabled?: boolean;
}>`
  height: 61px;
  width: ${({ width }) => width}px;
  border-radius: 30px;
  opacity: ${({ $disabled }) => ($disabled ? ".7" : 1)};
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : undefined)};
  ${BabyPeekButtonGradientContent} {
    width: calc(100% - 5px);
  }
`;

export const BabyPeekIntroTitle = styled.div<{ deviceType: DeviceType }>`
  font-size: ${({ deviceType }) =>
    deviceType !== "desktop" ? "36px" : "70px"};
  font-family: Poppins;
  font-weight: 800;
  line-height: 120%;
  letter-spacing: 0.96px;
  color: ${({ theme }) => theme.greyBase};
`;

export const BabyPeekIntroDescription = styled.div<{ deviceType: DeviceType }>`
  font-size: ${({ deviceType }) =>
    deviceType !== "desktop" ? "16px" : "20px"};
  font-family: Poppins;
  line-height: 150%;
  color: ${({ theme }) => theme.greyBase};
  font-size: 16px;
`;

export const BabyPeekIntroMobileDescriptionWrapper = styled.div`
  svg {
    width: 100%;
  }

  grid-gap: 0px;

  ${BabyPeekFilledButton} {
    margin-top: 16px;
    justify-self: center;
  }
`;

export const BabyPeekIntroBottomMobileGrid = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  gap: 16px;

  ${BabyPeekButtonGradient} {
    width: 90%;
  }
`;

export const BabyPeekIntroMessageWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: ${({ deviceType }) =>
    deviceType !== "desktop" ? "auto auto" : "auto auto auto"};
  padding: 24px 28px;
  border: 1px solid #dfe4ed;
  align-items: center;
  justify-self: center;
  color: ${({ theme }) => theme.black};
  background: #e0f7fa;
  border-radius: 24px;
  margin: 50px 20px;
  margin-top: 30px;
  margin-bottom: 15px;
  position: relative;
  text-align: center;

  .body {
    color: #5d5a5a;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  svg {
    position: absolute;
    justify-self: center;
    top: 0;
    margin-top: -35px;
  }
`;

export const BabyPeekIntroPaymentMobileButtonWrapper = styled.div`
  display: grid;
  ${BabyPeekButtonGradient} {
    width: 90%;
    justify-self: center;
  }
`;

export const BabyPeekIntroInfoBarWrapperNew = styled.div`
  border-radius: 24px;
  border: 1px solid #f1f1f1;
  background: var(--grey-white, #fff);
  /* Card shadow */
  box-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.04);
  display: grid;
  margin: 64px;
  padding: 32px 16px;
  grid-gap: 32px;
  margin-bottom: 0px;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    justify-self: center;
  }

  ${BabyPeekFilledButton} {
    width: 100%;
    margin-top: 10px;
  }

  ${BabyPeekVideoDiv} {
    width: 700px;
  }
`;

const getInfoMargin = (deviceType: DeviceType, windowWidth?: number) => {
  if (windowWidth && windowWidth < 400) return "0px 0px";
  if (deviceType === "desktop") return "64px";
  return "0px 16px";
};

export const BabyPeekIntroInfoBarWrapper = styled.div<{
  deviceType: DeviceType;
  windowWidth?: number;
}>`
  border-radius: 24px;
  border: 1px solid #f1f1f1;
  background: var(--grey-white, #fff);
  /* Card shadow */
  box-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.04);
  display: grid;
  grid-template-columns: ${({ deviceType }) =>
    deviceType === "desktop" ? "1fr 1fr" : undefined};
  grid-template-rows: ${({ deviceType }) =>
    deviceType === "desktop" ? undefined : "auto auto"};
  grid-gap: ${({ deviceType }) =>
    deviceType === "desktop" ? "110px" : "36px"};
  padding: ${({ deviceType }) =>
    deviceType === "desktop" ? "64px 110px" : "32px 16px"};
  margin: ${({ deviceType, windowWidth }) =>
    getInfoMargin(deviceType, windowWidth)};
  margin-bottom: 0px;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    justify-self: center;
  }

  svg {
    justify-self: center;
  }
`;

export const BabyPeekCTATitle = styled.div<{
  deviceType: DeviceType;
}>`
  text-align: center;
  font-size: ${({ deviceType }) =>
    deviceType === "desktop" ? "64px" : "36px"};
  font-family: Poppins;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 0.96px;
  color: black;
  justify-self: center;
`;

export const BabyPeekIntroInfoBarBig = styled.div`
  color: ${({ theme }) => theme.greyDark};
  font-size: 52px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.52px;
  margin-top: 12px;
  padding-bottom: 12px;
`;

export const BabyPeekIntroInfoBarBigMobile = styled.div`
  color: ${({ theme }) => theme.greyDark};
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 36px */
  letter-spacing: 0.36px;
  margin-top: 12px;
`;

export const BabyPeekIntroInfoBarDescription = styled.div`
  color: ${({ theme }) => theme.greyBase};
  font-size: 20px;
  font-family: Poppins;
  line-height: 150%;
`;

export const BabyPeekIntroInfoBarDescriptionMobile = styled.div`
  color: "#2d3748";
  font-size: 18px;
  font-family: Inter;
  line-height: 150%;
  font-weight: 400;
  color: "2d3748";
`;

export const BabyPeekIntroCTAWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  border-radius: 24px;
  border: 1px solid #f1f1f1;
  background: var(--primary-beige, #fff6d2);
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 2px 16px 0px rgba(0, 0, 0, 0.05);
  display: grid;
  grid-template-rows: auto auto auto;
  grid-gap: 20px;
  padding: ${({ deviceType }) =>
    deviceType === "desktop" ? "64px 110px" : "32px 16px"};
  margin: ${({ deviceType }) => (deviceType === "desktop" ? "64px" : "16px")};
  margin-bottom: 64px;
  text-align: center;

  ${BabyPeekIntroInfoBarDescription} {
    justify-self: center;
    text-align: center;
  }

  ${BabyPeekButtonGradient} {
    font-size: 24px;
    align-self: center;
    justify-self: center;
  }

  svg {
    justify-self: center;
  }
`;

export const BabyPeekFAQWrapper = styled.div`
  ${BabyPeekAccordionQuestion} {
    font-size: 18px;
  }

  .babypeek-icon {
    width: 114.286px;
    height: 40px;
  }
`;

export const BabyPeekIntroFAQAccordionWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  border-radius: 24px;
  border: 1px solid var(--grey-ultra, #f7f7f7);
  background: var(--grey-white, #fff);
  /* Soft shadow */
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.05);
  padding: ${({ deviceType }) =>
    deviceType === "desktop" ? "32px" : "40px 20px"};
  margin: ${({ deviceType }) => (deviceType === "desktop" ? "82px" : "16px")};
`;

export const BabyPeekMobileFAQ = styled.span`
  ${BabyPeekAccordionQuestion} {
    font-size: 18px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 125%;
    padding: 8px 0px;
  }

  ${BabyPeekAccordionAnswer} {
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 125%;
    padding: 8px 0px;
  }
`;

export const BabyPeekFAQTitle = styled.div<{
  deviceType: DeviceType;
}>`
  color: var(--base-dark-gray, #1d232c);
  text-align: center;
  /* Heading/H2/Desktop */
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 67.2px */
  letter-spacing: 0.56px;
  margin-bottom: ${({ deviceType }) =>
    deviceType === "desktop" ? "80px" : "24px"};
`;

export const BabyPeekDisclaimer = styled.div`
  background: transparent;
  padding: 24px;
`;

export const BabyPeekDisclaimerTitle = styled.div`
  color: var(--grey-base, #231f20);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`;

export const BabyPeekDisclaimerText = styled.div`
  color: var(--grey-mid, #231f20);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 12px;
`;

export const BabyPeekDisclaimerGrid = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-columns: ${({ deviceType }) =>
    deviceType === "desktop" ? "1fr 1fr" : undefined};
  grid-template-rows: ${({ deviceType }) =>
    deviceType === "desktop" ? "auto auto" : undefined};
`;
