import * as React from "react";
import { themePalette } from "../../theme";

export const Telephone = ({
  color = themePalette.neutral600,
  ...props
}: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.231 15.26L16.691 14.97C16.081 14.9 15.481 15.11 15.051 15.54L13.211 17.38C10.381 15.94 8.06102 13.63 6.62102 10.79L8.47102 8.94001C8.90102 8.51001 9.11102 7.91001 9.04102 7.30001L8.75102 4.78001C8.63102 3.77001 7.78102 3.01001 6.76102 3.01001H5.03102C3.90102 3.01001 2.96102 3.95001 3.03102 5.08001C3.56102 13.62 10.391 20.44 18.921 20.97C20.051 21.04 20.991 20.1 20.991 18.97V17.24C21.001 16.23 20.241 15.38 19.231 15.26V15.26Z"
      fill={color}
    />
  </svg>
);
