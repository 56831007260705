import React from "react";

import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import {
  FooterContactContainer,
  FooterSocialContainer,
} from "../Footer/Footer.styles";
import { FooterContactInfo } from "../Footer/FooterContactInfo";
import { FooterSocialInfo } from "../Footer/FooterSocialInfo";

export const ContactUsInfo = (): React.ReactElement => {
  const { deviceType } = useDeviceHook();

  return (
    <>
      <FooterContactContainer deviceType={deviceType}>
        <FooterContactInfo />
      </FooterContactContainer>
      <br />
      <FooterSocialContainer>
        <FooterSocialInfo />
      </FooterSocialContainer>
    </>
  );
};
