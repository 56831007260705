import * as React from "react";
import { themePalette } from "../../theme";

export const Twitter = ({
  color = themePalette.neutral600,
  ...props
}: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_76491:669)">
      <mask
        id="mask0_76491:669"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="-1"
        y="1"
        width="25"
        height="22"
      >
        <path
          d="M11.6976 7.33196C11.6976 7.73994 11.7426 8.13591 11.8251 8.51989H11.8221C8.11431 8.41639 3.77958 6.41402 1.33623 2.98973C-0.165173 5.78706 1.13375 8.89786 2.83764 10.0318C2.25418 10.0813 1.17874 9.9613 0.673275 9.43183C0.638777 11.2872 1.46973 13.7486 4.49354 14.641C3.91007 14.9785 2.88264 14.8825 2.43266 14.809C2.59016 16.3779 4.62853 18.4283 6.85889 18.4283C6.06244 19.4152 3.35361 21.2136 -0.000183105 20.6391C2.27817 22.1345 4.93451 22.997 7.74533 22.997C15.7323 22.997 21.9359 16.0269 21.603 7.42795C21.603 7.41746 21.6 7.40696 21.6 7.39946C21.6 7.37546 21.603 7.35146 21.603 7.33196C21.603 7.30646 21.6 7.27946 21.6 7.24947C22.3259 6.7185 23.3009 5.77056 23.9998 4.52264C23.5963 4.76412 22.3829 5.24409 21.2505 5.36258C21.9779 4.94261 23.0504 3.5612 23.3159 2.46776C22.6184 2.94623 21.0165 3.64369 20.2261 3.64369C20.2261 3.64369 20.2261 3.64669 20.2291 3.64969C19.3246 2.63275 18.0572 1.99979 16.6473 1.99979C13.9144 1.99979 11.6976 4.38614 11.6976 7.33196Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_76491:669)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.49628 30.4975H31.5013V-5.50021H-7.49628V30.4975Z"
          fill={color}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_76491:669">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
