import React from "react";
import {
  UpfrontPaymentButtonRow,
  UpfrontPaymentCost,
  UpfrontPaymentDashed,
  UpfrontPaymentDesktopContainer,
  UpfrontPaymentFinancialAssistance,
  UpfrontPaymentFinancialAssistanceWrapper,
  UpfrontPaymentInfoBillContent,
  UpfrontPaymentMainText,
  UpfrontPaymentReceiptTitle,
} from "./UpfrontPayment.styles";

import { useDeviceHook } from "../../utils/hooks/useDeviceHook";

import { Button } from "../shared/Button/Button";
import {
  getCentsToDollars,
  UpfrontPaymentType,
} from "./Services/UpfrontPaymentService";
import { CentsConversion, PaymentPlanMinDollars, StepperIndex } from "./types";
import { UpfrontPaymentPaperStatements } from "./UpfrontPaymentPaperStatements";

export const UpfrontPaymentBillingSummary: React.FC<{
  setStep: React.Dispatch<React.SetStateAction<StepperIndex>>;
  setPaymentOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setStripeOpen: (open: boolean) => Promise<void>;
  upfrontBill: UpfrontPaymentType;
}> = ({ setPaymentOpen, setStripeOpen, upfrontBill, setStep }) => {
  const { deviceType } = useDeviceHook();

  return (
    <>
      <UpfrontPaymentDesktopContainer deviceType={deviceType}>
        <UpfrontPaymentReceiptTitle>Bill Summary</UpfrontPaymentReceiptTitle>

        {upfrontBill && (
          <>
            <UpfrontPaymentInfoBillContent>
              <UpfrontPaymentMainText>
                {upfrontBill?.date_of_service}
              </UpfrontPaymentMainText>
              <UpfrontPaymentCost>
                {getCentsToDollars(
                  upfrontBill?.total_patient_responsibility_amount,
                )}
              </UpfrontPaymentCost>
            </UpfrontPaymentInfoBillContent>
            <UpfrontPaymentInfoBillContent>
              <UpfrontPaymentMainText>Total charges</UpfrontPaymentMainText>
              <UpfrontPaymentMainText>
                {getCentsToDollars(upfrontBill?.total_insurance_allowed_amount)}
              </UpfrontPaymentMainText>
            </UpfrontPaymentInfoBillContent>
            <UpfrontPaymentInfoBillContent>
              <UpfrontPaymentMainText>
                {upfrontBill?.insurance_adjustment_wording}
              </UpfrontPaymentMainText>
              <UpfrontPaymentMainText $color="greenDark">
                -{getCentsToDollars(upfrontBill?.total_insurance_paid_amount)}
              </UpfrontPaymentMainText>
            </UpfrontPaymentInfoBillContent>
            {Boolean(upfrontBill?.bto_discount) && (
              <UpfrontPaymentInfoBillContent>
                <UpfrontPaymentMainText>
                  {upfrontBill?.is_qualified_for_financial_assistance
                    ? "Financial Assistance Adjustment"
                    : "Courtesy Adjustment"}
                </UpfrontPaymentMainText>
                <UpfrontPaymentMainText $color="greenDark">
                  -{getCentsToDollars(upfrontBill?.bto_discount)}
                </UpfrontPaymentMainText>
              </UpfrontPaymentInfoBillContent>
            )}
            <UpfrontPaymentInfoBillContent>
              <UpfrontPaymentMainText>
                Already paid by you
              </UpfrontPaymentMainText>
              {upfrontBill?.paid_amount ? (
                <UpfrontPaymentMainText $color="greenDark">
                  -{getCentsToDollars(upfrontBill?.paid_amount)}
                </UpfrontPaymentMainText>
              ) : (
                <UpfrontPaymentMainText>
                  {getCentsToDollars(upfrontBill?.paid_amount)}
                </UpfrontPaymentMainText>
              )}
            </UpfrontPaymentInfoBillContent>
            <UpfrontPaymentDashed />
            <UpfrontPaymentInfoBillContent style={{ marginTop: "10px" }}>
              <UpfrontPaymentMainText>Balance due</UpfrontPaymentMainText>
              <UpfrontPaymentMainText>
                {getCentsToDollars(
                  upfrontBill?.total_patient_responsibility_amount,
                )}
              </UpfrontPaymentMainText>
            </UpfrontPaymentInfoBillContent>
            {upfrontBill.portal_bill_status === "outstanding" && (
              <>
                {" "}
                <UpfrontPaymentButtonRow>
                  <Button
                    type="submit"
                    label={`Pay ${getCentsToDollars(
                      upfrontBill?.total_patient_responsibility_amount,
                    )} `}
                    size="medium"
                    onClick={() => setStripeOpen(true)}
                    backgroundColor="greenDark"
                  />
                </UpfrontPaymentButtonRow>
                {upfrontBill.total_patient_responsibility_amount /
                  CentsConversion >
                  PaymentPlanMinDollars && (
                  <UpfrontPaymentButtonRow bottomRow>
                    <Button
                      type="submit"
                      label="Build Payment Plan"
                      size="medium"
                      reverse
                      onClick={() => setStep(StepperIndex.PaymentPlan)}
                      backgroundColor="greenDark"
                    />
                  </UpfrontPaymentButtonRow>
                )}
              </>
            )}

            {upfrontBill.show_financial_assistance && (
              <UpfrontPaymentFinancialAssistanceWrapper
                onClick={() => setPaymentOpen(true)}
              >
                <UpfrontPaymentFinancialAssistance>
                  Apply For Financial Assistance
                </UpfrontPaymentFinancialAssistance>
              </UpfrontPaymentFinancialAssistanceWrapper>
            )}
          </>
        )}
      </UpfrontPaymentDesktopContainer>

      <UpfrontPaymentPaperStatements upfrontBill={upfrontBill} />
    </>
  );
};
