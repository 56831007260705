import { BASE_URL } from "../utils/requestUtils";

const VerifyEmailRequest = async (
  verifyJwt: string,
): Promise<Response | undefined> => {
  const payload = {
    email_token: verifyJwt,
  };
  try {
    const response = await fetch(`${BASE_URL}/api/v1/verify_email`, {
      body: JSON.stringify(payload),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (e) {
    return undefined;
  }
};

export default VerifyEmailRequest;
