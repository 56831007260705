import * as React from "react";

export const Warning = (): React.ReactElement => (
  <svg
    width="22.5"
    height="20"
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4501 2.81876L24.076 21.1777H2.82422L13.4501 2.81876ZM0.382943 19.7666C-0.703636 21.6434 0.65106 24 2.82422 24H24.076C26.2492 24 27.6039 21.6434 26.5173 19.7666L15.8914 1.40761C14.8048 -0.469205 12.0954 -0.469205 11.0088 1.40761L0.382943 19.7666ZM12.039 9.88858V12.7109C12.039 13.487 12.674 14.122 13.4501 14.122C14.2262 14.122 14.8613 13.487 14.8613 12.7109V9.88858C14.8613 9.11245 14.2262 8.47744 13.4501 8.47744C12.674 8.47744 12.039 9.11245 12.039 9.88858ZM12.039 16.9443H14.8613V19.7666H12.039V16.9443Z"
      fill="#C0250F"
    />
  </svg>
);
