import React, { useEffect, useState } from "react";
import { Stepper, Step, StepLabel } from "@mui/material";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useParams } from "react-router-dom";
import { StepHouseholdInfo } from "./StepHouseholdInfo";
import { StepOptionalQuestions } from "./StepOptionalQuestions";
import { StepDocumentUploader } from "./StepDocumentUploader";
import { StepConfirmation } from "./StepConfirmation";
import {
  UpfrontPaymentFinancialQuestionsWrapper,
  UpfrontPaymentFinancialStepperWrapper,
} from "../UpfrontPayment.styles";
import { useDeviceHook } from "../../../utils/hooks/useDeviceHook";
import {
  ProfileInsuranceModalButtons,
  ProfileInsuranceModalTitle,
} from "../../Profile/ProfileInsuranceModal.styles";
import { Button } from "../../shared/Button/Button";
import { BillingSupportNumber, FinancialAssistanceIncomeTiers } from "../types";
import { SpinnerLoading } from "../../shared/Spinner/SpinnerLoading";
import {
  GetUpfrontPaymentFinancialAssistance,
  UpfrontPaymenFinancialAssistanceResponse,
  UpfrontPaymentType,
} from "../Services/UpfrontPaymentService";
import { getStepIconComponent } from "./utils";

interface NonMedicaidFlowProps {
  setMedicaidStatus: React.Dispatch<React.SetStateAction<"Yes" | "No" | null>>;
  incomeTiers: FinancialAssistanceIncomeTiers;
  localities: string[];
  setUpfrontBill: React.Dispatch<
    React.SetStateAction<UpfrontPaymentType | undefined>
  >;
  setFinancialAidResponse: React.Dispatch<
    React.SetStateAction<UpfrontPaymenFinancialAssistanceResponse | undefined>
  >;
}

export const NonMedicaidFlow: React.FC<NonMedicaidFlowProps> = ({
  setMedicaidStatus,
  incomeTiers,
  localities,
  setUpfrontBill,
  setFinancialAidResponse,
}) => {
  const { deviceType } = useDeviceHook();
  const { billingPK, adminID } = useParams<{
    billingPK: string;
    adminID?: string;
  }>();
  const [activeStep, setActiveStep] = useState(0);
  const [householdInfo, setHouseholdInfo] = useState<{
    size?: number;
    income?: number;
    incomeDisplay?: string;
  }>({});
  const [optionalQuestions, setOptionalQuestions] = useState<{
    locality?: string;
    extenuatingCircumstances: string[];
    otherCircumstance?: string;
  }>({
    locality: undefined,
    extenuatingCircumstances: [],
    otherCircumstance: undefined,
  });
  const [selectedFAFile, setSelectedFAFile] = useState<IFileWithMeta | null>(
    null,
  );
  const [rulesAccepted, setRulesAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!incomeTiers || !localities) {
      setLoading(true);
      return;
    }
    setLoading(false);
  }, [incomeTiers, localities]);

  const steps = [
    "Household Info",
    "Optional Questions",
    "Document Upload",
    "Confirmation",
  ];

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => {
    if (activeStep === 0) {
      setMedicaidStatus(null);
      setActiveStep(0);
      return;
    }
    setActiveStep((prev) => prev - 1);
  };
  const onSubmit = async () => {
    setLoading(true);
    const financialAssistanceResponse =
      await GetUpfrontPaymentFinancialAssistance(
        billingPK,
        {
          has_medicaid: false,
          household_size: householdInfo.size || 0,
          income: householdInfo.income || 0,
          medicaid_insurance_card_urls: [],
          extenuating_circumstances: (() => {
            const circumstances =
              optionalQuestions.extenuatingCircumstances.filter(
                (circumstance) => circumstance !== "Other",
              ) as string[];
            if (optionalQuestions.otherCircumstance) {
              circumstances.push(
                `Other: '${optionalQuestions.otherCircumstance}'`,
              );
            }
            return circumstances;
          })(),
          locality: optionalQuestions.locality || null,
        },
        selectedFAFile,
        adminID,
      );
    setLoading(false);
    if (financialAssistanceResponse?.ok) {
      const financialAssistanceJson =
        (await financialAssistanceResponse.json()) as UpfrontPaymenFinancialAssistanceResponse;
      if (financialAssistanceJson) {
        setFinancialAidResponse(financialAssistanceJson);
        setUpfrontBill(financialAssistanceJson.bill);
      }
    } else {
      setError(
        `Error occured submitting application, please try again later.  If the issue persists, please contact support at ${BillingSupportNumber}.`,
      );
    }
  };

  const blockNext = () => {
    switch (activeStep) {
      case 0:
        return !householdInfo.size || !householdInfo.income;
      case 3:
        return !rulesAccepted;
      default:
        return false;
    }
  };

  const renderStepContent = () => {
    if (loading) {
      return <SpinnerLoading />;
    }
    if (error) {
      return <div>{error}</div>;
    }
    switch (activeStep) {
      case 0:
        return (
          <StepHouseholdInfo
            householdInfo={householdInfo}
            setHouseholdInfo={setHouseholdInfo}
            incomeTiers={incomeTiers}
          />
        );
      case 1:
        return (
          <StepOptionalQuestions
            localities={localities}
            optionalQuestions={optionalQuestions}
            setOptionalQuestions={setOptionalQuestions}
          />
        );
      case 2:
        return (
          <StepDocumentUploader
            selectedFile={selectedFAFile}
            setSelectedFile={setSelectedFAFile}
          />
        );
      case 3:
        return (
          <StepConfirmation
            medicaidInfo={{ hasMedicaid: "No" }}
            householdInfo={householdInfo}
            optionalQuestions={optionalQuestions}
            rulesAccepted={rulesAccepted}
            setRulesAccepted={setRulesAccepted}
            selectedFAFile={selectedFAFile}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <UpfrontPaymentFinancialQuestionsWrapper>
        {renderStepContent()}
        <UpfrontPaymentFinancialStepperWrapper marginTop={15}>
          <Stepper>
            {steps.map((value, index) => (
              <Step key={value}>
                <StepLabel
                  StepIconComponent={getStepIconComponent(index, activeStep)}
                />
              </Step>
            ))}
          </Stepper>
        </UpfrontPaymentFinancialStepperWrapper>
      </UpfrontPaymentFinancialQuestionsWrapper>
      <ProfileInsuranceModalTitle deviceType={deviceType}>
        <ProfileInsuranceModalButtons deviceType={deviceType}>
          <Button
            className="upfront-cancel-button"
            label="Back"
            onClick={handleBack}
            disabled={loading}
            size="medium"
            reverse
            textColor="greyMedium"
            backgroundColor="greyMedium"
          />
          <Button
            className="upfront-submit-button"
            label={activeStep === steps.length - 1 ? "Submit" : "Next"}
            data-testid="submit-button"
            disabled={blockNext() || loading}
            onClick={activeStep === steps.length - 1 ? onSubmit : handleNext}
            size="medium"
            backgroundColor="greenDark"
          />
        </ProfileInsuranceModalButtons>
      </ProfileInsuranceModalTitle>
    </>
  );
};
