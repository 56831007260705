import React from "react";

import {
  SelectProps,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  Box,
  Chip,
  styled,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import {
  StyledInputWrapper,
  StyledInputInputTitle,
  StyledInputRowWrapper,
  StyledInputOptional,
} from "./UI-Library.styles";

const StyledChip = styled(Chip)`
  margin: 2px;
`;

interface StyledMUIMultiSelectProps extends SelectProps {
  fieldTitle: string;
  options: Array<{ label: string; value: string }>;
  selectedValues: string[];
  customOnChange?: (selected: string[]) => void;
  optional?: boolean;
}

const StyledMUIMultiSelect: React.FC<StyledMUIMultiSelectProps> = ({
  fieldTitle,
  options,
  selectedValues,
  customOnChange,
  optional,
  ...selectProps
}) => {
  const handleChange = (event: SelectChangeEvent<Array<string>>) => {
    const value = event.target.value as string[];
    if (customOnChange) {
      customOnChange(value);
    }
  };

  return (
    <StyledInputRowWrapper>
      <StyledInputWrapper>
        <StyledInputInputTitle>
          {`${fieldTitle} `}{" "}
          {optional && <StyledInputOptional>(optional)</StyledInputOptional>}
        </StyledInputInputTitle>
        <Select
          {...selectProps}
          style={{
            height: "44px",
            borderRadius: "16px",
            overflow: "hidden",
            whiteSpace: "text-overflow",
          }}
          multiple
          value={selectedValues}
          defaultValue={[]}
          onChange={handleChange}
          renderValue={(selected) => selected.join(", ")}
          IconComponent={() => (
            <div style={{ fontSize: "30px" }}>
              <ArrowDropDownIcon
                fontSize="inherit"
                style={{ marginTop: "3px", marginRight: "3px" }}
              />
            </div>
          )}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300,
                width: "inherit",
              },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              style={{ whiteSpace: "normal" }}
              key={option.value}
              value={option.value}
            >
              <Checkbox checked={selectedValues.indexOf(option.value) > -1} />
              <ListItemText
                style={{ maxWidth: "90%" }}
                secondary={option.label}
              />
            </MenuItem>
          ))}
        </Select>
      </StyledInputWrapper>
    </StyledInputRowWrapper>
  );
};

export default StyledMUIMultiSelect;
