import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
  UpfrontPaymentFinancialModalWrapper,
  UpfrontPaymentFinancialQuestionsWrapper,
} from "../UpfrontPayment.styles";
import {
  GetFinancialAssistanceIncomeTiers,
  GetFinancialAssistanceLocalities,
  UpfrontPaymenFinancialAssistanceResponse,
  UpfrontPaymentType,
} from "../Services/UpfrontPaymentService";
import { useDeviceHook } from "../../../utils/hooks/useDeviceHook";
import {
  ProfileInsuranceModalTitle,
  ProfileModalTitleWrapper,
} from "../../Profile/ProfileInsuranceModal.styles";
import { StyledUISelectRequired } from "../../shared/UI-Library/StyledUISelect";
import { MedicaidFlow } from "./MedicaidFlow";
import { NonMedicaidFlow } from "./NonMedicaidFlow";
import { BillingSupportNumber, FinancialAssistanceIncomeTiers } from "../types";
import StepQualification from "./StepQualification";

interface FinancialAssistanceModalProps {
  financialAssistanceModalOpen: boolean;
  setFinancialAssistanceModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setStripeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUpfrontBill: React.Dispatch<
    React.SetStateAction<UpfrontPaymentType | undefined>
  >;
}
export const FinancialAssistanceModal: React.FC<
  FinancialAssistanceModalProps
> = ({
  financialAssistanceModalOpen,
  setFinancialAssistanceModalOpen,
  setUpfrontBill,
  setStripeModalOpen,
}) => {
  const { billingPK } = useParams<{ billingPK: string }>();
  const { deviceType } = useDeviceHook();
  const [medicaidStatus, setMedicaidStatus] = useState<"Yes" | "No" | null>(
    null,
  );
  const [incomeTiers, setIncomeTiers] =
    useState<FinancialAssistanceIncomeTiers>({});
  const [localities, setLocalities] = useState<string[]>([]);
  const [financialAidResponse, setFinancialAidResponse] =
    useState<UpfrontPaymenFinancialAssistanceResponse>();

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getIncomeTiers = async () => {
      const incomeTiersResponse =
        await GetFinancialAssistanceIncomeTiers(billingPK);
      if (incomeTiersResponse?.ok) {
        const incomeTiersJson = await incomeTiersResponse.json();
        setIncomeTiers(incomeTiersJson);
      } else {
        setError(
          `Error occured, please try again later.  If the issue persists, please contact support at ${BillingSupportNumber}.`,
        );
      }
    };
    const getLocalities = async () => {
      const localitiesResponse =
        await GetFinancialAssistanceLocalities(billingPK);
      if (localitiesResponse?.ok) {
        const localitiesJson = await localitiesResponse.json();
        setLocalities(localitiesJson);
      } else {
        setError(
          `Error occured, please try again later.  If the issue persists, please contact support at ${BillingSupportNumber}.`,
        );
      }
    };

    // We're requesting information here because we need them to render the NonMedicaidFlow component
    // and we want to ensure that we have them before rendering the component.  It also ensures when 'back' is
    // selected in the NonMedicaidFlow component, we have the currently selected option (the component gets unmounted).
    getIncomeTiers();
    getLocalities();
  }, []);

  const renderContent = () => {
    if (medicaidStatus === "Yes") {
      return (
        <MedicaidFlow
          setMedicaidStatus={setMedicaidStatus}
          setUpfrontBill={setUpfrontBill}
          setFinancialAidResponse={setFinancialAidResponse}
        />
      );
    }
    if (medicaidStatus === "No") {
      return (
        <NonMedicaidFlow
          setMedicaidStatus={setMedicaidStatus}
          incomeTiers={incomeTiers}
          localities={localities}
          setUpfrontBill={setUpfrontBill}
          setFinancialAidResponse={setFinancialAidResponse}
        />
      );
    }

    return (
      <UpfrontPaymentFinancialQuestionsWrapper>
        <StyledUISelectRequired
          fieldTitle="Do you also have Medicaid or an HMO?"
          value={medicaidStatus || ""}
          onChange={(e) => setMedicaidStatus(e.target.value as "Yes" | "No")}
          data-testid="medicaid-select"
        >
          <option value="" disabled>
            Select an option
          </option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </StyledUISelectRequired>
      </UpfrontPaymentFinancialQuestionsWrapper>
    );
  };

  return (
    <Modal
      open={financialAssistanceModalOpen}
      onClose={() => setFinancialAssistanceModalOpen(false)}
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <UpfrontPaymentFinancialModalWrapper
        deviceType={deviceType}
        allowScroll
        receipt={Boolean(financialAidResponse)}
      >
        <ProfileInsuranceModalTitle deviceType={deviceType}>
          <ProfileModalTitleWrapper deviceType={deviceType}>
            <div>Financial Assistance</div>
            <CloseIcon onClick={() => setFinancialAssistanceModalOpen(false)} />
          </ProfileModalTitleWrapper>
        </ProfileInsuranceModalTitle>
        {financialAidResponse ? (
          <StepQualification
            financialAidResponse={financialAidResponse}
            setStripeModalOpen={setStripeModalOpen}
            setFinancialAssistanceModalOpen={setFinancialAssistanceModalOpen}
            medicaidStatus={medicaidStatus}
          />
        ) : (
          <>
            {error ? (
              <div style={{ padding: "15px" }}>{error}</div>
            ) : (
              <div>{renderContent()}</div>
            )}
          </>
        )}
      </UpfrontPaymentFinancialModalWrapper>
    </Modal>
  );
};
