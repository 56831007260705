import React, { useEffect } from "react";

import { useWindowWidth } from "@react-hook/window-size";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import {
  BannerContentAction,
  BannerContentText,
  BannerContentTitle,
  BannerContentWrapper,
  BannerContentWrapperGrid,
  BannerType,
  BannerWrapper,
} from "./Banner.styles";
import { BannerInformationIcon } from "./BannerInformationIcon";
import { BannerBillingIcon } from "./BannerBillingIcon";
import { BannerWarningIcon } from "./BannerWarningIcon";

const getBannerIcon = (type: BannerType) => {
  switch (type) {
    case "information":
      return <BannerInformationIcon />;
    case "billing":
      return <BannerBillingIcon />;
    case "warning":
      return <BannerWarningIcon />;
    default:
      return <BannerInformationIcon />;
  }
};

export const Banner: React.FC<{
  bannerType: BannerType;
  bannerTitle: string;
  bannerText: string;
  bannerActionCTA: string | React.ReactNode;
  bannerActionOnClick?: () => void;
}> = ({
  bannerType,
  bannerActionCTA,
  bannerText,
  bannerTitle,
  bannerActionOnClick,
}): React.ReactElement => {
  const { deviceType } = useDeviceHook();
  const windowWidth = useWindowWidth();

  return (
    <BannerWrapper deviceType={deviceType} bannerType={bannerType}>
      <BannerContentWrapperGrid deviceType={deviceType} bannerType={bannerType}>
        <div>{getBannerIcon(bannerType)}</div>
        <BannerContentWrapper>
          <BannerContentTitle>{bannerTitle}</BannerContentTitle>
          <BannerContentText>{bannerText}</BannerContentText>
          <BannerContentAction
            onClick={() => {
              if (bannerActionOnClick) {
                bannerActionOnClick();
              }
            }}
          >
            {bannerActionCTA}
          </BannerContentAction>
        </BannerContentWrapper>
      </BannerContentWrapperGrid>
    </BannerWrapper>
  );
};
