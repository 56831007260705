import React from "react";
import { BillingSupportNumber } from "./types";
import { UpfrontPaymentPhoneLinkStyle } from "./UpfrontPayment.styles";

const UpfrontPaymentPhoneLink: React.FC = () => (
  <a
    href={`tel:${BillingSupportNumber}`}
    target="_blank"
    rel="noreferrer"
    data-testid="billing-support-phone-link"
  >
    <UpfrontPaymentPhoneLinkStyle>
      {BillingSupportNumber}
    </UpfrontPaymentPhoneLinkStyle>
  </a>
);

export default UpfrontPaymentPhoneLink;
