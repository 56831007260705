import React from "react";
import ReactPlayer from "react-player/vimeo";
import { useRecoilValue } from "recoil";
import { useDeviceHook } from "../../../utils/hooks/useDeviceHook";
import aneuploidyVideo from "../../../assets/images/unityAneuploidyInfoVideo.png";
import { getUserAuth } from "../../../recoil/selectors";
import { SendActionType } from "../Services/SharedServices";
import VideoFeedbackWidget from "./VideoFeedback";

const AneuploidyVideoEmbed: React.FC<{
  videoLocation: "aneuploidy_page" | "aneuploidy_learn_more";
  reqID: string;
}> = ({ videoLocation, reqID }) => {
  const [showFeedback, setShowFeedback] = React.useState(false);
  const auth = useRecoilValue(getUserAuth);
  const { deviceType } = useDeviceHook();

  return (
    <>
      <ReactPlayer
        playing
        url="https://player.vimeo.com/video/960668298?playsinline=0"
        light={aneuploidyVideo}
        id="aneuploidy-video-player"
        height={`${deviceType === "mobile" ? "328px" : "295px"}`}
        width={`${deviceType === "mobile" ? "100%" : "528px"}`}
        style={{ justifySelf: "center" }}
        controls
        vimeoConfig={{ iframeParams: { fullscreen: 1 } }}
        data-testid="aneuploidy-video-player"
        data-vimeo-playsinline
        data-vimeo-autoplay
        playsinline={false}
        onPlay={async () => {
          await SendActionType(
            "AneuploidyVideoClicked",
            reqID,
            auth?.access_token,
            {
              videoLocation,
            },
          );
          setShowFeedback(true);
        }}
      />
      {showFeedback && (
        <VideoFeedbackWidget
          videoType="aneuploidy"
          showFeedback={showFeedback}
          setShowFeedback={setShowFeedback}
          reqID={reqID}
          videoLocation={videoLocation}
        />
      )}
    </>
  );
};

export default AneuploidyVideoEmbed;
