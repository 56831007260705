import React from "react";
import { DisplayWithIcon } from "../shared/Typography/types";
import { GetTypographyContent } from "../shared/Typography/Typography";
import {
  TestResultIconAndTypography,
  TestResultsIcon,
} from "./TestResults.styles";

export const TestResultsAneuploidyRisk = (
  displayWithIcon: DisplayWithIcon,
): React.ReactElement => (
  <>
    {GetTypographyContent({
      content: "Aneuploidy Screen",
      size: "large",
      textColor: "greyMedium",
      isBold: true,
      isCaps: true,
    })}

    <TestResultIconAndTypography>
      <TestResultsIcon marginLeft={displayWithIcon.display === "See Report"}>
        {displayWithIcon.icon}
      </TestResultsIcon>
      {GetTypographyContent({
        content: displayWithIcon.display,
        size: "medium",
        textColor: "greyMedium",
      })}
    </TestResultIconAndTypography>
  </>
);
