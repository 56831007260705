import React from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import {
  FinancialAssistanceTitle,
  UpfrontPaymentFinancialQuestionsWrapper,
  UpfrontPaymentInputRowWrapper,
  UpfrontPaymentInputTitle,
  UpfrontPaymentInputWrapper,
} from "../UpfrontPayment.styles";
import { FADocumentUploader } from "../FADocumentUploader";
import { StyledInputOptional } from "../../shared/UI-Library/UI-Library.styles";

interface DocumentUploaderProps {
  selectedFile: IFileWithMeta | null;
  setSelectedFile: React.Dispatch<React.SetStateAction<IFileWithMeta | null>>;
}

export const StepDocumentUploader: React.FC<DocumentUploaderProps> = ({
  selectedFile,
  setSelectedFile,
}) => (
  <UpfrontPaymentFinancialQuestionsWrapper>
    <UpfrontPaymentInputTitle>
      Upload most recent paystub, W2, or any other financial information that we
      should consider. <StyledInputOptional>(optional)</StyledInputOptional>
    </UpfrontPaymentInputTitle>
    <UpfrontPaymentInputWrapper>
      <div>
        <FADocumentUploader
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      </div>
    </UpfrontPaymentInputWrapper>
  </UpfrontPaymentFinancialQuestionsWrapper>
);
