import styled from "styled-components/macro";
import { TextField } from "../shared/TextField/TextField";
import { CheckMark } from "../../assets/iconComponents/CheckMark";
import { Password } from "../shared/TextField/Password";
import { DeviceType } from "../../utils/hooks/useDeviceHook";

const formFieldWidth = (deviceType: string) => {
  if (deviceType === "desktop") return "447px";
  if (deviceType === "tablet") return "421px";
  return "327px";
};

export const CreateAccountLoginMessageContainer = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
`;

export const FormFieldInput = styled.div<{
  deviceType: DeviceType;
}>`
  width: ${({ deviceType }) => formFieldWidth(deviceType)};
`;

export const CreateAccountDesktopContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: ${({ theme }) => theme.white};
`;

export const CreateAccountMobileContainer = styled.div`
  background-color: ${({ theme }) => theme.white};
`;

export const CreateAccountPasswordRequirmentsTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;
  grid-gap: 7px;
`;

export const CreateAccountPasswordRequirmentsContainer = styled.div<{
  flat?: boolean;
}>`
  display: grid;
  grid-template-rows: ${({ flat }) => (flat ? undefined : "auto auto")};
  grid-template-columns: ${({ flat }) => (flat ? "1fr 1fr" : undefined)};
  place-items: start;
  grid-gap: 7px;
`;

export const CreateAccountPasswordRequirmentsRow = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  place-items: start;
  grid-gap: 7px;
`;

export const CreateAccountStyledCheckMark = styled(CheckMark)<{
  $valid: boolean;
}>`
  margin-top: 4px;
  path {
    fill: ${({ $valid, theme }) =>
      $valid ? theme.greenDark : theme.greyLight} !important;
  }
`;

export const CreateAccountButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const CreateAccountCenterContainer = styled.div<{
  deviceType: string;
}>`
  position: relative;
  display: flex;
  background-color: white;
  height: ${({ deviceType }) => (deviceType === "mobile" ? "100%" : "100vh")};
  overflow: scroll;
`;

export const CreateAccountCenterFormContainer = styled.div`
  display: grid;
  margin: auto;
  grid-gap: 5px;
  padding: 10px;
  place-items: center;
  grid-template-rows: auto auto auto auto auto 1fr;
`;

export const CreateAccountCenterFormPreTestTitle = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.greyBase};
`;

export const CreateAccountCenterContainerPaddedTop = styled.div<{
  isDesktop: boolean;
}>`
  margin: auto;
  height: 100vh !important;
  max-width: 480px !important;
  display: grid;
  grid-gap: 5px;
  padding: 10px;
  place-items: center;
  background-color: ${({ theme }) => theme.gradient};
  align-content: center;
  height: 100vh;
  text-align: center;
`;

export const CreateAccountFormImage = styled.img<{ width: string }>`
  width: ${({ width }) => width};
  margin: auto;
`;

export const CreateAccountFormVector = styled.img`
  position: absolute;
  left: 28.4%;
`;

export const CreateAccountFormDesktopColorbackground = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100vh;
  background: ${({ theme }) => theme.gradient};
`;

export const CreateAccountDatePicker = styled(TextField)<{
  $noDateValue?: boolean;
}>`
  width: 100%;

  .Mui-error input::-webkit-datetime-edit {
    color: ${({ theme }) => theme.redDark};
  }
`;

export const VerifyEmailSendAgainInput = styled(TextField)`
  width: 100%;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.greyLight};
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.greenMedium};
  padding: 14px;
  padding-top: 17px;
  border-radius: 5px;
  margin-bottom: 40px;
`;

export const Spinner = styled.div`
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  img {
    width: 50px;
  }
`;

export const CreateAccountFormStyled = styled.div<{
  deviceType: DeviceType;
  isPasswordSection?: boolean;
}>`
  display: grid;
  grid-template-rows: ${({ isPasswordSection }) =>
    isPasswordSection ? "20px" : "40px"};
  grid-row-gap: ${({ deviceType, isPasswordSection }) => {
    if (isPasswordSection) return "20px";
    return deviceType === "mobile" ? "12px" : "20px";
  }};
  padding-top: 12px;
  padding-bottom: 40px;
  place-items: center;
`;

export const CreateAccountInput = styled(TextField)`
  width: 100%;
`;

export const CreateAccountPassword = styled(Password)`
  width: 100%;
`;

export const CreateAccountPasswordInfoContainer = styled.div`
  width: 100%;
`;

export const CreateAccountPasswordValidationContainer = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export const CreateAccountFormStyledContainer = styled.div<{
  deviceType: DeviceType;
}>`
  width: ${({ deviceType }) => formFieldWidth(deviceType)};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const CreateAccountPrivacyPolicy = styled.div`
  margin-top: 5px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.greyLight};

  a {
    color: ${({ theme }) => theme.greyLight};
  }
`;

export const CreateAccountMismatchMessage = styled.div`
  background: ${({ theme }) => theme.redPastel};
  border-left: 3px solid ${({ theme }) => theme.redDark};
  padding: 12px 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  white-space: pre-wrap;
`;

export const MismatchErrorTitle = styled.strong`
  color: ${({ theme }) => theme.redDark};
`;

export const MismatchErrorBody = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
`;
