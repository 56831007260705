import styled from "styled-components/macro";
import { StyledButton } from "../../components/shared/Button/Button.styles";
import { DeviceType } from "../../utils/hooks/useDeviceHook";

export const SchedulingPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getSchedulingWidth = (deviceType: DeviceType) => {
  if (deviceType === "desktop") {
    return "920px";
  }
  if (deviceType === "tablet") {
    return "570px";
  }
  return "360px";
};

const getSchedulingPadding = (deviceType: DeviceType) => {
  if (deviceType === "desktop") {
    return "90px 65px 142px 40px";
  }
  if (deviceType === "tablet") {
    return "60px 18px 112px 18px";
  }
  return "40px 0px 92px 0px";
};

export const SchedulingPageComponentWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-columns: ${({ deviceType }) => getSchedulingWidth(deviceType)};
  padding: ${({ deviceType }) => getSchedulingPadding(deviceType)};
`;

const getImageWidth = (deviceType: DeviceType) => {
  if (deviceType === "desktop") {
    return "860px";
  }
  if (deviceType === "tablet") {
    return "540px";
  }
  if (deviceType === "mobile") {
    return "330px";
  }
};

const getImageHeight = (deviceType: DeviceType) => {
  if (deviceType === "desktop") {
    return "573px";
  }
  if (deviceType === "tablet") {
    return "360px";
  }
  if (deviceType === "mobile") {
    return "215px";
  }
};

const getCouncelingSessionPadding = (deviceType: DeviceType) => {
  if (deviceType === "desktop") {
    return "60px";
  }
  if (deviceType === "tablet") {
    return "30px";
  }
  if (deviceType === "mobile") {
    return "75px";
  }
};

export const SchedulingPageImageWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: flex;
  justify-content: center;

  img {
    width: ${({ deviceType }) => getImageWidth(deviceType)};
    height: ${({ deviceType }) => getImageHeight(deviceType)};
  }
`;

export const SchedulingPageComponentGrid = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: 60px auto 25px;
`;

export const SchedulingPageContentWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: ${({ deviceType }) =>
    `${getImageHeight(deviceType)} ${getCouncelingSessionPadding(
      deviceType,
    )} 100px auto`};
  background-color: ${({ theme }) => theme.white};
  justify-content: center;
  grid-row-gap: 20px;
  padding: 16px 16px;

  .scheduling-page-top {
    align-items: center;
    max-width: ${({ deviceType }) => (deviceType === "desktop" ? "75%" : "")};
    justify-self: ${({ deviceType }) =>
      deviceType === "desktop" ? "center" : ""};
  }

  .scheduling-page-bottom {
    line-height: 24px !important;
    padding: 55px 0 0 0;
  }
`;

export const SchedulingReturnButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  ${StyledButton} {
    border: 1px solid ${({ theme }) => theme.redMedium};
  }
`;
