import React, { useEffect, useMemo, useRef, useState } from "react";

import { Sidetab } from "@typeform/embed-react";
import Cookies from "js-cookie";
import { themePalette } from "../../theme";

export const Typeform: React.FC<{
  typeformID: string;
  onSubmit?: () => void;
}> = ({ typeformID, onSubmit }) => {
  const [typeformSubmitted, setTypeformSubmitted] = useState(false);
  const [typeformSubmitTimeout, setTypeformSubmitTimeout] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (typeformSubmitTimeout) {
      timerRef.current = setTimeout(() => {
        setTypeformSubmitted(true);
      }, 2000);
      Cookies.set("typeform-submitted", typeformID);
      if (onSubmit) {
        onSubmit();
      }
      setTypeformSubmitTimeout(false);
    }
  }, [typeformSubmitTimeout]);

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    },
    [],
  );

  const typeformButton = useMemo(
    () =>
      !typeformSubmitted &&
      !(Cookies.get("typeform-submitted") === typeformID) && (
        <Sidetab
          id={typeformID}
          buttonText="Please answer"
          // autoClose={1500}
          buttonColor={themePalette.redMedium}
          onSubmit={() => setTypeformSubmitTimeout(true)}
        />
      ),
    [typeformSubmitted],
  );

  return <>{typeformButton}</>;
};
