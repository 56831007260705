import * as React from "react";

export const PaidIcon = (): React.ReactElement => (
  <svg
    width="56"
    height="16"
    viewBox="0 0 56 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16ZM11.5312 6.53125H11.5C11.8125 6.25 11.8125 5.78125 11.5 5.46875C11.2188 5.1875 10.75 5.1875 10.4688 5.46875L7 8.96875L5.53125 7.5C5.21875 7.1875 4.75 7.1875 4.46875 7.5C4.15625 7.78125 4.15625 8.25 4.46875 8.53125L6.46875 10.5312C6.75 10.8438 7.21875 10.8438 7.53125 10.5312L11.5312 6.53125Z"
      fill="#6ACC74"
    />
    <path
      d="M25.3485 2.976H29.7325C32.0685 2.976 33.0125 4.704 33.0125 6.416C33.0125 8.16 32.0685 9.856 29.7325 9.856H26.8045V14H25.3485V2.976ZM26.8045 8.496H29.6365C31.0125 8.496 31.5565 7.472 31.5565 6.416C31.5565 5.392 31.0285 4.32 29.7165 4.32H26.8045V8.496ZM40.3853 7.696V6.528H41.7773V14H40.3853V12.672C39.8893 13.568 38.8493 14.192 37.6493 14.192C35.6973 14.192 33.9853 12.544 33.9853 10.256C33.9853 8.048 35.6013 6.336 37.7133 6.336C38.8333 6.336 39.8893 6.816 40.3853 7.696ZM37.8573 12.88C39.1853 12.88 40.3853 11.776 40.3853 10.256C40.3853 8.72 39.0893 7.648 37.8413 7.648C36.4813 7.648 35.3932 8.848 35.3932 10.256C35.3932 11.68 36.4813 12.88 37.8573 12.88ZM44.904 4.704C44.376 4.704 43.928 4.304 43.928 3.776C43.928 3.248 44.376 2.848 44.904 2.848C45.448 2.848 45.896 3.248 45.896 3.776C45.896 4.304 45.448 4.704 44.904 4.704ZM45.608 14H44.216V6.528H45.608V14ZM53.9325 7.696V2.464H55.3245V14H53.9325V12.832C53.4365 13.68 52.3485 14.192 51.2765 14.192C49.2285 14.192 47.5325 12.608 47.5325 10.256C47.5325 7.888 49.2125 6.336 51.2605 6.336C52.3805 6.336 53.4365 6.816 53.9325 7.696ZM51.3885 12.88C52.7325 12.88 53.9325 11.84 53.9325 10.24C53.9325 8.624 52.6205 7.648 51.3885 7.648C50.0285 7.648 48.9405 8.736 48.9405 10.24C48.9405 11.728 50.0285 12.88 51.3885 12.88Z"
      fill="#231F20"
    />
    <path
      d="M25.3485 2.976H29.7325C32.0685 2.976 33.0125 4.704 33.0125 6.416C33.0125 8.16 32.0685 9.856 29.7325 9.856H26.8045V14H25.3485V2.976ZM26.8045 8.496H29.6365C31.0125 8.496 31.5565 7.472 31.5565 6.416C31.5565 5.392 31.0285 4.32 29.7165 4.32H26.8045V8.496ZM40.3853 7.696V6.528H41.7773V14H40.3853V12.672C39.8893 13.568 38.8493 14.192 37.6493 14.192C35.6973 14.192 33.9853 12.544 33.9853 10.256C33.9853 8.048 35.6013 6.336 37.7133 6.336C38.8333 6.336 39.8893 6.816 40.3853 7.696ZM37.8573 12.88C39.1853 12.88 40.3853 11.776 40.3853 10.256C40.3853 8.72 39.0893 7.648 37.8413 7.648C36.4813 7.648 35.3932 8.848 35.3932 10.256C35.3932 11.68 36.4813 12.88 37.8573 12.88ZM44.904 4.704C44.376 4.704 43.928 4.304 43.928 3.776C43.928 3.248 44.376 2.848 44.904 2.848C45.448 2.848 45.896 3.248 45.896 3.776C45.896 4.304 45.448 4.704 44.904 4.704ZM45.608 14H44.216V6.528H45.608V14ZM53.9325 7.696V2.464H55.3245V14H53.9325V12.832C53.4365 13.68 52.3485 14.192 51.2765 14.192C49.2285 14.192 47.5325 12.608 47.5325 10.256C47.5325 7.888 49.2125 6.336 51.2605 6.336C52.3805 6.336 53.4365 6.816 53.9325 7.696ZM51.3885 12.88C52.7325 12.88 53.9325 11.84 53.9325 10.24C53.9325 8.624 52.6205 7.648 51.3885 7.648C50.0285 7.648 48.9405 8.736 48.9405 10.24C48.9405 11.728 50.0285 12.88 51.3885 12.88Z"
      fill="black"
      fillOpacity="0.88"
    />
  </svg>
);
