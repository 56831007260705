import React, { useEffect, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams } from "react-router-dom";
import { CallMade } from "@mui/icons-material";

import {
  UpfrontPaymentAccordionContainer,
  UpfrontPaymentEmailText,
  UpfrontPaymentReceiptsDownloadRow,
} from "./UpfrontPayment.styles";

import { useDeviceHook } from "../../utils/hooks/useDeviceHook";

import {
  GetUpfrontPaymentReceipt,
  UpfrontPaymentReceiptType,
  getCentsToDollars,
} from "./Services/UpfrontPaymentService";

export const UpfrontPaymentReceiptView: React.FC = () => {
  const { deviceType } = useDeviceHook();
  const [finalReceipt, setFinalReceipt] = useState<UpfrontPaymentReceiptType>();
  const { billingPK, adminID } = useParams<{
    billingPK: string;
    adminID?: string;
  }>();

  useEffect(() => {
    const getReceipt = async () => {
      if (billingPK) {
        const receiptResponse = await GetUpfrontPaymentReceipt(
          billingPK,
          undefined,
          adminID,
        );
        const receiptJson = await receiptResponse.json();
        setFinalReceipt(receiptJson);
      }
    };
    getReceipt();
  }, []);

  return (
    <>
      <UpfrontPaymentAccordionContainer deviceType={deviceType} padding>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <UpfrontPaymentEmailText>Download receipt</UpfrontPaymentEmailText>
          </AccordionSummary>
          <AccordionDetails>
            <a
              href={finalReceipt?.receipt_url}
              target="_blank"
              rel="noreferrer"
            >
              <UpfrontPaymentReceiptsDownloadRow>
                <CallMade />
                <div>{finalReceipt?.date_of_service}</div>
                <div>{getCentsToDollars(finalReceipt?.amount_paid)}</div>
              </UpfrontPaymentReceiptsDownloadRow>
            </a>
          </AccordionDetails>
        </Accordion>
      </UpfrontPaymentAccordionContainer>
    </>
  );
};
