import React from "react";

import { useWindowWidth } from "@react-hook/window-size";
import { faArrowUpFromBracket } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BabyPeekTraitWrapper,
  BabyPeekV2HeaderTitleWrapper,
  BabyPeekV2HeaderTitleTop,
  BabyPeekV2HeaderTitleBottom,
  BabyPeekV2HeaderIconGrid,
  BabyPeekV2SectionText,
  BabyPeekV2SectionTitle,
  BabyPeekV2Section,
  BabyPeekV2HeaderIconShare,
  BabyPeekV2HeaderIconShareTitle,
} from "../Shared/BabyPeekTrait.styles";
import FrecklesBackground from "./FrecklesBackground.png";
import FreckleIcon from "./FreckleIcon.png";

import {
  BabyPeekTraitResponse,
  Trait,
  convertPercentage,
} from "../../Services/BabyPeekService";
import { useDeviceHook } from "../../../../utils/hooks/useDeviceHook";
import { TraitNavigationLeft } from "../Shared/TraitNavigationLeft";
import { TraitNavigationRight } from "../Shared/TraitNavigationRight";
import {
  BabyPeekMobileButtonSticky,
  BabyPeekTraitNavigationWrapper,
  BabyPeekTraitNavigationButtonWrapper,
  BabyPeekDesktopButtonSticky,
} from "../../BabyPeekStripe.styles";
import { BabyPeekButton } from "../../BabyPeekButton";
import { TraitMenu } from "../Shared/TraitMenu";
import { BabyPeekTraitAccordion } from "../Shared/BabyPeekTraitAccordion";
import { FrecklesGene } from "./FrecklesGene";
import { FrecklesHowItWorks } from "./FrecklesHowItWorks";
import { FrecklesOtherFactors } from "./FrecklesOtherFactors";
import { BabyPeekGauge } from "../Shared/BabyPeekGauge";
import { ShareUnderline } from "../../BabyPeekIcons/ShareUnderline";
import { BabyPeekSingleFeedback } from "../../BabyPeekSingleFeedback";

const HowItWorks = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>
      Why do people have freckles?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      Freckles are small spots that form on areas of the body that are
      frequently exposed to the sun. Freckles form when tiny cells in our skin
      called <span>melanocytes</span> make a protein called <span>melanin</span>
      . Melanin gives the skin a dark brown color.
      <br />
      Exposure to sunlight causes the melanocytes to produce more melanin.
      Sometimes, the melanin is heavily concentrated in a single area – leading
      to the tiny dark spots that we call freckles.
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        {" "}
        <FrecklesHowItWorks />{" "}
      </div>
      <br />
    </BabyPeekV2SectionText>
    <br />
    <BabyPeekV2SectionTitle>
      How did we determine your baby’s likelihood to have freckles?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      We looked at four different genetic variations that help determine how
      likely a person is to develop freckles. For example, the{" "}
      <span className="italic">MC1R</span> gene helps melanocytes naturally
      produce high levels of melanin. Some variations in this gene lead to lower
      levels of natural melanin in the skin and sun exposure is more likely to
      lead to concentrated spots of melanin – AKA, freckles!
      <br />
      <BabyPeekV2SectionTitle>The gene we peeked at</BabyPeekV2SectionTitle>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {" "}
        <FrecklesGene />{" "}
      </div>
      <br />
    </BabyPeekV2SectionText>
  </BabyPeekV2Section>
);

const OtherFactors = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>
      What else can affect freckling?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      A person’s traits are often determined by a combination of genetic and
      environmental factors. While many of the genetic variations that influence
      these traits are well-studied, there are some genetic factors that are
      still unknown to scientists.
      <br />
      Other factors that can affect freckling include:
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}
      >
        {" "}
        <FrecklesOtherFactors />{" "}
      </div>
      <br />
    </BabyPeekV2SectionText>
    <br />
  </BabyPeekV2Section>
);

const AboutBabyPeek = (
  <BabyPeekV2SectionText>
    BabyPeek results are:
    <ul>
      <li>
        A <span style={{ fontWeight: 700 }}>predictor</span> of what traits your
        baby will have. These results do not mean that your baby will have these
        traits with absolute certainty and a baby may have different traits than
        what is predicted.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT medical test results.</span> These
        results should not be used to make medical decisions about your
        pregnancy. The genetic changes mentioned in these results do not affect
        a baby’s health.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT paternity testing.</span> These
        results should not be used to determine paternity. Given the complex
        genetics of these traits, a baby may be born with different traits from
        the biological mother or father.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>
          NOT managed by your healthcare provider.
        </span>{" "}
        These results are unlocked by the patient directly. Please do not
        contact your healthcare provider regarding your BabyPeek results.
      </li>
    </ul>
    If you have questions about BabyPeek results, please contact BillionToOne
    directly at
    <a href={`tel:${"(650) 460-2551"}`} target="_blank" rel="noreferrer">
      {` (650) 460-2551`}
    </a>
    .
    <br />
  </BabyPeekV2SectionText>
);

export const FreckleTrait: React.FC<{
  setCurrentTrait?: (trait: Trait | undefined) => void;
  traitData: BabyPeekTraitResponse;
  setShareModal?: (share: boolean) => void;
  publicTrait?: boolean;
  hideTraitMenu: boolean;
  modalPreview?: boolean;
  showAnimation?: boolean;
}> = ({
  setCurrentTrait,
  traitData,
  setShareModal,
  hideTraitMenu,
  publicTrait,
  modalPreview = false,
  showAnimation = false,
}) => {
  const { deviceType } = useDeviceHook();
  const windowWidth = useWindowWidth();
  const [showTraitMenu, setShowTraitMenu] = React.useState(false);
  const StickyWrapper =
    deviceType !== "desktop"
      ? BabyPeekMobileButtonSticky
      : BabyPeekDesktopButtonSticky;
  const freckleTraits = [
    {
      color: "#DB855E",
      gradient:
        "linear-gradient(90deg, rgba(222, 158, 116, 0.48) 0%, #DB855E 100%, #DB855E 100%)",
      name: "Freckles",
      percentage: convertPercentage(traitData.freckles_prob),
      textColor: "black",
    },
  ].sort((a, b) => b.percentage - a.percentage);

  return (
    <>
      {" "}
      <div
        style={{
          backgroundImage: `url(${FrecklesBackground})`,
          height: "260px",
          backgroundSize: "cover",
          padding: "20px",
          display: "grid",
          justifyContent: deviceType !== "mobile" ? "center" : "flex-start",
          paddingBottom: "32px",
          paddingTop: "24px",
          gridGap: "12px",
        }}
        className="trait-top-background"
      >
        <BabyPeekV2HeaderIconShare>
          <BabyPeekV2HeaderIconGrid>
            <img
              src={FreckleIcon}
              alt="FreckleIcon"
              style={{ justifySelf: "center" }}
            />
            <div>Freckles</div>
          </BabyPeekV2HeaderIconGrid>
          {setShareModal && !modalPreview && (
            <BabyPeekV2HeaderIconShareTitle onClick={() => setShareModal(true)}>
              {" "}
              <FontAwesomeIcon icon={faArrowUpFromBracket} /> Share
              <ShareUnderline />
            </BabyPeekV2HeaderIconShareTitle>
          )}
        </BabyPeekV2HeaderIconShare>

        <BabyPeekV2HeaderTitleWrapper
          deviceType={deviceType}
          windowWidth={windowWidth}
        >
          <BabyPeekV2HeaderTitleTop>
            {publicTrait
              ? `The baby is more likely to`
              : `Your baby is more likely to`}
          </BabyPeekV2HeaderTitleTop>
          <BabyPeekV2HeaderTitleBottom>
            {`${freckleTraits[0].percentage >= 50 ? "" : "not"} have freckles`}
          </BabyPeekV2HeaderTitleBottom>
        </BabyPeekV2HeaderTitleWrapper>
      </div>
      <BabyPeekTraitWrapper deviceType={deviceType}>
        <div style={{ paddingBottom: "10px" }} />
        <BabyPeekGauge
          showGif={modalPreview || showAnimation}
          percentage={freckleTraits[0].percentage}
          bottomText="chance to have freckles"
        />
        {!modalPreview && (
          <>
            {" "}
            <hr />
            <div
              style={{
                maxWidth: `${windowWidth - 2}px`,
                padding: "25px",
                paddingTop: "0px",
              }}
            >
              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "How it works",
                    content: HowItWorks,
                    key: "howItWorksEye",
                    defaultExpanded: true,
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "Other factors",
                    content: OtherFactors,
                    key: "otherFactorsEye",
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "About BabyPeek",
                    content: AboutBabyPeek,
                    key: "aboutBabyPeekEye",
                  },
                ]}
              />
            </div>{" "}
          </>
        )}
        {!modalPreview && <BabyPeekSingleFeedback onlyFeedback />}
      </BabyPeekTraitWrapper>
      {setCurrentTrait && !modalPreview && (
        <StickyWrapper style={{ zIndex: 10 }}>
          {showTraitMenu && TraitMenu(setCurrentTrait, "Freckle")}
          <BabyPeekTraitNavigationWrapper
            $width={windowWidth}
            deviceType={deviceType}
          >
            <BabyPeekTraitNavigationButtonWrapper
              onClick={() => setCurrentTrait("EyeColor")}
            >
              <TraitNavigationLeft />
            </BabyPeekTraitNavigationButtonWrapper>{" "}
            {!hideTraitMenu && (
              <BabyPeekButton
                variant="gray"
                content={<>{showTraitMenu ? " Hide Traits" : "View Traits"}</>}
                onClick={async () => {
                  setShowTraitMenu(!showTraitMenu);
                }}
              />
            )}
            <BabyPeekTraitNavigationButtonWrapper
              onClick={() => setCurrentTrait("CurlyHair")}
            >
              <TraitNavigationRight />
            </BabyPeekTraitNavigationButtonWrapper>
          </BabyPeekTraitNavigationWrapper>
        </StickyWrapper>
      )}
    </>
  );
};
