import Cookies, { CookieAttributes } from "js-cookie";

export const BASE_URL = import.meta.env.VITE_BACKEND_BASE_URL;
const COOKIE_NAME_ACCESS_TOKEN = "access_token";

const COOKIE_OPTIONS_ACCESS_TOKEN: CookieAttributes = {
  // it is not possible to deliberately set `httpOnly` to true
  //  - HttpOnly can only be set to true by the browser at the behest of the server:
  //  - a request's response must contain the header Set-Cookie with the HttpOnly flag set to true
  //  - https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie#httponly
  // it is not possible to set a cookie's `domain` attribute to something other than the current domain
  //  - https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie#domaindomain-value
  //  - NOTE: a server-side cookie would set the domain to the server's domain
  //
  // NOTE: since we are deliberately setting the `Authorization` header in the request with the `access_token`'s value,
  //       `sameSite`/`secure` are set as good measure to tell the browser how to [not-]use this cookie
  //       `sameSite`/`secure` have no effect on our application code that deliberately sets ths `Authorization` header
  sameSite: "strict",
  secure: true,
};

export const setCookieAccessToken = (access_token: string): void => {
  Cookies.set(
    COOKIE_NAME_ACCESS_TOKEN,
    access_token,
    COOKIE_OPTIONS_ACCESS_TOKEN,
  );
};

export const removeCookieAccessToken = (): void => {
  Cookies.remove(COOKIE_NAME_ACCESS_TOKEN, COOKIE_OPTIONS_ACCESS_TOKEN);
};

export const getCookieAccessToken = (): string | undefined =>
  Cookies.get(COOKIE_NAME_ACCESS_TOKEN);
