import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CallMade } from "@mui/icons-material";
import {
  UpfrontPaymentFieldRowTitle,
  UpfrontPaymentInfoRow,
  UpfrontPaymentReceiptWrapper,
  UpfrontPaymentFieldRow,
  UpfrontPaymentAccordionContainer,
  UpfrontPaymentEmailText,
  UpfrontPaymentReceiptsDownloadRow,
} from "./UpfrontPayment.styles";

import { useDeviceHook } from "../../utils/hooks/useDeviceHook";

import { GetTypographyContent } from "../shared/Typography/Typography";

import {
  GetOA100Receipt,
  UpfrontPaymentReceiptType,
  getCentsToDollars,
} from "./Services/UpfrontPaymentService";

export const OA100Receipt: React.FC = () => {
  const { deviceType } = useDeviceHook();

  const [finalReceipt, setFinalReceipt] = useState<UpfrontPaymentReceiptType>();
  const { billingPK } = useParams<{ billingPK: string }>();

  useEffect(() => {
    const getReceipt = async () => {
      if (billingPK) {
        const receiptResponse = await GetOA100Receipt(billingPK, undefined);
        if (!receiptResponse?.ok) {
          setFinalReceipt(undefined);
          return;
        }
        const receiptJson = await receiptResponse.json();
        setFinalReceipt(receiptJson);
      }
    };
    getReceipt();
  }, []);

  if (!finalReceipt) {
    return null;
  }

  return (
    <>
      <UpfrontPaymentReceiptWrapper
        deviceType={deviceType}
        noConfetti
        isPaymentPlan={false}
      >
        <UpfrontPaymentInfoRow>
          <UpfrontPaymentFieldRow>
            <UpfrontPaymentFieldRowTitle>
              Date of Service
            </UpfrontPaymentFieldRowTitle>
            {GetTypographyContent({
              content: finalReceipt?.date_of_service || "",
              size: "medium",
              textColor: "black",
            })}
          </UpfrontPaymentFieldRow>
          <UpfrontPaymentFieldRow>
            <UpfrontPaymentFieldRowTitle>Provider</UpfrontPaymentFieldRowTitle>
            {GetTypographyContent({
              content: finalReceipt?.provider_name || "",
              size: "medium",
              textColor: "black",
            })}
          </UpfrontPaymentFieldRow>
        </UpfrontPaymentInfoRow>
        <UpfrontPaymentInfoRow>
          <UpfrontPaymentFieldRow>
            <UpfrontPaymentFieldRowTitle>
              Amount Paid
            </UpfrontPaymentFieldRowTitle>
            {GetTypographyContent({
              content: `${getCentsToDollars(finalReceipt?.amount_paid)}`,
              size: "medium",
              textColor: "black",
            })}
          </UpfrontPaymentFieldRow>
          <UpfrontPaymentFieldRow>
            <UpfrontPaymentFieldRowTitle>
              Payment Date
            </UpfrontPaymentFieldRowTitle>
            {GetTypographyContent({
              content: finalReceipt?.payment_date || "",
              size: "medium",
              textColor: "black",
            })}
          </UpfrontPaymentFieldRow>
        </UpfrontPaymentInfoRow>
        <UpfrontPaymentInfoRow>
          <UpfrontPaymentFieldRow>
            <UpfrontPaymentFieldRowTitle>
              Payment Method
            </UpfrontPaymentFieldRowTitle>
            {GetTypographyContent({
              content: `${finalReceipt?.card_brand || ""} - ${
                finalReceipt?.last_four_digits || ""
              }`,
              size: "medium",
              textColor: "black",
            })}
          </UpfrontPaymentFieldRow>
          <UpfrontPaymentFieldRow>
            <UpfrontPaymentFieldRowTitle>
              Transaction ID
            </UpfrontPaymentFieldRowTitle>
            {GetTypographyContent({
              content: finalReceipt?.payment_intent_id || "",
              size: "medium",
              textColor: "black",
            })}
          </UpfrontPaymentFieldRow>
          <UpfrontPaymentFieldRow />
        </UpfrontPaymentInfoRow>
      </UpfrontPaymentReceiptWrapper>
      <UpfrontPaymentAccordionContainer
        data-testid="download-receipt-accordion"
        deviceType={deviceType}
      >
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <UpfrontPaymentEmailText>Download receipt</UpfrontPaymentEmailText>
          </AccordionSummary>
          <AccordionDetails>
            <a
              href={finalReceipt?.receipt_url}
              target="_blank"
              rel="noreferrer"
            >
              <UpfrontPaymentReceiptsDownloadRow>
                <CallMade />
                <div>{finalReceipt?.date_of_service}</div>
                <div>{getCentsToDollars(finalReceipt?.amount_paid)}</div>
              </UpfrontPaymentReceiptsDownloadRow>
            </a>
          </AccordionDetails>
        </Accordion>
      </UpfrontPaymentAccordionContainer>
    </>
  );
};
