import { Box } from "@mui/material";
import {
  FileUploaderDropzoneAntigen,
  FileUploaderButton,
  FileUploaderGreyText,
} from "./DocumentUploader.styles";

export const GenericFileUploadInputContent = () => (
  <FileUploaderDropzoneAntigen key="custom">
    <FileUploaderButton>Choose File</FileUploaderButton>
    <br />
    <Box>
      <FileUploaderGreyText>or</FileUploaderGreyText>
      <FileUploaderGreyText>
        Drag and drop an image or pdf here
      </FileUploaderGreyText>
    </Box>
  </FileUploaderDropzoneAntigen>
);
