import React from "react";
import { useRecoilValue } from "recoil";
import { getUserAuth } from "../../recoil/selectors";
import { BlankHeader } from "../Header/BlankHeader";
import { ContentDiv, StyledH2 } from "./TermsOfService.styles";

export const TermsOfServicePage: React.FC = () => {
  const auth = useRecoilValue(getUserAuth);

  return (
    <>
      {!auth && <BlankHeader link="https://results.unityscreen.com/" />}
      <br />
      <br />
      <ContentDiv>
        <StyledH2>BILLIONTOONE PATIENT PORTAL TERMS OF USE </StyledH2>
        <p>
          These BillionToOne Patient Portal Terms of Use (the &quot;Terms&quot;
          or the &quot;Agreement&quot;) are applicable to your access and use of
          the BillionToOne Patient Portal (&quot;Portal&quot;), provided by
          BillionToOne, Inc., our subsidiaries and our affiliates (collectively,
          &quot;BillionToOne,&quot; &quot;we,&quot; or &quot;us&quot;). Your
          access to and use of the Portal are subject to the terms, conditions,
          and notices contained in or referenced by these Terms. Please read
          these Terms carefully. By accessing or using the Portal, you agree to
          comply with and be bound by the Terms. If you do not agree with these
          terms, you are not authorized to use the Portal.
        </p>
        <br />
        <StyledH2>
          THIS AGREEMENT CONTAINS A MANDATORY ARBITRATION PROVISION THAT WAIVES
          YOUR RIGHT TO A JURY TRIAL AND CLASS ACTION AND REQUIRES USE OF
          ARBITRATION ON AN INDIVIDUAL BASIS.
        </StyledH2>
        <br />
        <StyledH2>ACCEPTANCE OF TERMS</StyledH2>
        <p>
          By accessing the Portal, you agree to be bound by these Terms. These
          Terms constitute a legally binding agreement between you and
          BillionToOne. If you do not agree to these Terms, you may not use the
          Portal. In the event of any inconsistency between these Terms and any
          written, executed contract between you and BillionToOne, the terms of
          the written contract shall govern.
        </p>
        <StyledH2>ACCESS AND USE</StyledH2>
        <p>
          <b>Eligibility:</b> You must be at least 18 years old or the legal age
          of majority in your jurisdiction to access the Portal. In rendering
          services, you may be asked to electronically sign certain
          documentation. Clicking &quot;I agree&quot; or a similar prompt shall
          constitute your electronic signature and legally bind you to the
          applicable document or provision.
          <br />
          <b>Registration:</b> To access certain features of the Portal, you may
          be required to register an account. You agree to provide accurate,
          current, and complete information during the registration process and
          to update such information to keep it accurate, current, and complete.
          If you provide any information that is false, inaccurate, or
          deceptive, as determined by BillionToOne in its sole discretion, we
          may terminate or suspend your account and limit your access to the
          Portal.
          <br />
          <b>User Account:</b> You are responsible for maintaining the
          confidentiality of your account and password and for restricting
          access to your account. You agree to accept responsibility for all
          activities that occur under your account, even in the event not
          authorized by you. You must notify BillionToOne immediately upon any
          breach of security or unauthorized use of your account.
          <br />
          <b>Description of Product:</b> BillionToOne offers various products,
          including but not limited to, access to and use of the Portal. The
          Portal provides patients the ability to access test results, schedule
          consultations, and access additional information made available from
          time-to-time by BillionToOne. We provide the Portal to you as a
          patient and consumer for your personal use and not as a service to any
          healthcare provider or insurance carrier. Specific features,
          functions, and services provided via the Portal may be changed by
          BillionToOne without notice. Your continued use of the Portal after
          any changes to these Terms will signify your acceptance of such
          changes.
          <br />
          <b>Use of the Portal:</b> As a condition of use of the Portal, you
          agree to comply with all laws, regulations, and rules applicable to
          your use of and access to the Portal. You agree that you may not
          engage in any action, or otherwise attempt to: breach the security or
          bypass the authentication of the Portal; probe, scan, or test the
          vulnerability of the Portal; engage in or solicit the participation in
          any illegal activity or any action that infringes on the intellectual
          property rights of BillionToOne or others through use of the Portal;
          upload or transmit any virus, malware, or other program or file
          designed to interrupt, corrupt, destroy, or limit the functionality of
          the Portal or any hardware or software; utilize automated means to
          collect information or content from the Portal; provide inaccurate or
          non-current information, including without limitation attempting to
          impersonate or gain access to the information of another individual;
          or otherwise use the Portal in such a fashion that is unlawful,
          violative of these Terms, or intended to access information to which
          you do not have a legal right.
          <br />
          <b>Communication and Marketing Authorization:</b> By providing your
          contact information (including, e.g., email address, mobile phone
          number, and address), you agree that BillionToOne may send you
          periodic notifications for purposes of order notifications marketing,
          and quality improvement, other than marketing messages sent via SMS
          (i.e. text messages). For marketing messages sent via SMS, you must
          elect (i.e. opt-in) to be contacted by BillionToOne. You may change
          your settings for messages in the &quot;Notifications&quot; section.
          You may also unsubscribe at any time by responding to a BillionToOne
          SMS message with the word &quot;STOP.&quot; Message and data rates may
          apply. BillionToOne may collect information, including your contact
          information, information about how you utilize the Portal, and other
          information about your interactions with the Portal. You may opt out
          of the sale of information or opt to have your information deleted
          (subject to state and federal data retention requirements) by
          contacting BillionToOne at support@billiontoone.com. BillionToOne does
          not discriminate against consumers for exercising any rights related
          to the collection or sale of personal information.
          <br />
          <b>Void Where Prohibited:</b> BillionToOne administers and operates
          the Portal from its location in the United States. Although the Portal
          may be accessible worldwide, not all features or information are
          available to all persons in all geographic locations. We reserve the
          right to limit, at our sole discretion, the access rights to any
          feature or information to any person or geographic area, subject to
          applicable law. Any offer of any feature or access to information is
          void where prohibited.
        </p>
        <StyledH2>DISCLAIMER OF WARRANTIES</StyledH2>
        <p>
          The Portal is provided on an &quot;as-is&quot; and
          &quot;as-available&quot; basis. BillionToOne makes no warranties,
          expressed or implied, regarding the accuracy, completeness,
          reliability, or suitability of the information and services provided
          through the Portal.
        </p>
        <StyledH2>LIMITATION OF LIABILITY</StyledH2>
        <p>
          If you are dissatisfied with the portal or any of BillionToOne&apos;s
          practices, or if you otherwise have a dispute with BillionToOne, your
          sole and exclusive remedy is to discontinue use of the portal. In no
          event shall BillionToOne or any of its affiliates, joint ventures,
          service providers, and employees, contractors, agents, officers, and
          directors be liable for any direct, indirect, incidental, special, or
          consequential damages arising out of or in any way connected with the
          use of the Portal, whether based on contract, tort, strict liability,
          or otherwise. The foregoing limitations of liability only apply to the
          extent permitted by applicable law. BillionToOne does not assume any
          responsibility for, and will not be liable for, any damages to, or any
          viruses that may infect, your computer, device, or telecommunication
          equipment caused by or arising from your access to or use of the
          portal, or your downloading of any information or materials from the
          portal. In no event shall BillionToOne&apos;s total liability to you
          for all damages, losses, and causes of action, whether in contract,
          tort (including, but not limited to, negligence), or otherwise exceed
          fifty united states dollars (USD $50).
        </p>
        <StyledH2>INDEMNIFICATION</StyledH2>
        <p>
          You agree to indemnify and hold BillionToOne, its affiliates, joint
          ventures, service providers, and employees, contractors, agents,
          officers, and directors harmless from any claim or demand, including
          reasonable attorneys&apos; fees, made by any third party due to or
          arising out of your use of the Portal, your violation of these Terms,
          or your violation of any rights of another user or third party.
        </p>
        <StyledH2>COPYRIGHT AND INTELLECTUAL PROPERTY</StyledH2>
        <p>
          <b>Ownership:</b> All content and materials on the Portal, including
          but not limited to text, graphics, logos, and software, are the
          property of BillionToOne or its licensors and are protected by
          intellectual property laws. The coding, look, and feel of the Portal
          provided by BillionToOne are copyrighted by and the property of
          BillionToOne, Inc.
          <br />
          <b>Use Restrictions:</b> You may not reproduce, distribute, modify,
          create derivative works, publicly display, publicly perform, or
          otherwise use any content from the Portal without the express written
          consent of BillionToOne.
        </p>
        <StyledH2>ARBITRATION AND CLASS ACTION WAIVER</StyledH2>
        <p>
          Any dispute arising out of or in connection with these Terms,
          including the existence, validity, or termination thereof, shall be
          resolved through binding arbitration in accordance with the rules of
          the American Arbitration Association, and judgment upon the award
          rendered by the arbitrator(s) may be entered in any court having
          jurisdiction thereof. You hereby waive, with respect to any dispute,
          the right to participate in a class action, private attorney general
          action, or other representative action in court or in arbitration,
          either as a class representative or class member, and you additionally
          waive the right to consolidate claims with any other person.
        </p>
        <StyledH2>CYBERSECURITY</StyledH2>
        <p>
          <b>Security Measures:</b> BillionToOne employs industry-standard
          security measures to protect the confidentiality and integrity of your
          personal information. However, you acknowledge that no method of
          transmission over the Internet or electronic storage is 100% secure.
          <br />
          <b>Data Breach:</b> In the event of a data breach, BillionToOne will
          promptly notify affected individuals as required by applicable laws
          and regulations.
        </p>
        <StyledH2>GOVERNING LAW</StyledH2>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of Delaware, without regard to its conflict of law principles.
        </p>
        <StyledH2>NO WAIVER</StyledH2>
        <p>
          If BillionToOne does not enforce any rights under these Terms at any
          point, it will not be deemed a waiver of any provision or right
          granted by the Terms.
        </p>
        <StyledH2>ENTIRE AGREEMENT</StyledH2>
        <p>
          These Terms represent the entire agreement of the parties with respect
          to the use of the portal.
        </p>
        <StyledH2>CONTACT INFORMATION</StyledH2>
        <p>
          If you have any questions about these Terms, please contact us at
          support@billiontoone.com. By using the BillionToOne Portal, you
          acknowledge that you have read, understood, and agree to be bound by
          these Terms.
        </p>
        <p>
          <br />
          <b>Last Updated:</b> April 17th 2024
        </p>
      </ContentDiv>
    </>
  );
};
