import { selector, selectorFamily } from "recoil";
import {
  patientProfileState,
  patientState,
  selectedRequisitionIndex,
} from "./atoms";

const allDetailedResultsState = selector({
  key: "allDetailedResults",
  get: ({ get }) => get(patientState),
});

export const getAllRequisitions = selector({
  key: "allRequisitions",
  get: ({ get }) => get(allDetailedResultsState)?.requisitions,
});

export const getSelectedRequisitionIndex = selector({
  key: "selectedRequsitionIndex",
  get: ({ get }) => get(selectedRequisitionIndex),
});

export const getSelectedRequisition = selector({
  key: "getSelectedRequisition",
  get: ({ get }) => get(getAllRequisitions)?.[get(selectedRequisitionIndex)],
});

export const getFirstRequisitionID = selector({
  key: "firstRequisitionID",
  get: ({ get }) =>
    get(allDetailedResultsState)?.requisitions[0]?.req_identifier,
});

export const getSpecificRequsition = selectorFamily({
  key: "specificRequistion",
  get:
    (req_identifier: string) =>
    ({ get }) =>
      get(getAllRequisitions)?.find(
        (requistion) => requistion?.req_identifier === req_identifier,
      ),
});

export const getSpecificUnity = selectorFamily({
  key: "specificUnity",
  get:
    (req_identifier: string) =>
    ({ get }) => {
      const requisitions = get(getAllRequisitions)?.find(
        (requistion) => requistion?.req_identifier === req_identifier,
      );
      return requisitions?.unity_reports && requisitions?.unity_reports[0];
    },
});

export const getSpecificUnityLowRisk = selectorFamily({
  key: "specificUnityLowRisk",
  get:
    (req_identifier: string) =>
    ({ get }) => {
      const requisitions = get(getAllRequisitions)?.find(
        (requistion) => requistion?.req_identifier === req_identifier,
      );
      return (
        requisitions?.unity_reports &&
        requisitions?.unity_reports[0]?.summary === "Negative"
      );
    },
});

export const getSpecificUnityStatus = selectorFamily({
  key: "specificUnityLowRiskStatus",
  get:
    (req_identifier: string) =>
    ({ get }) => {
      const requisitions = get(getAllRequisitions)?.find(
        (requistion) => requistion?.req_identifier === req_identifier,
      );
      return (
        requisitions?.unity_reports && requisitions?.unity_reports[0]?.status
      );
    },
});

export const getSpecificUnityReportID = selectorFamily({
  key: "specificUnityReportID",
  get:
    (req_identifier: string) =>
    ({ get }) => {
      const requisitions = get(getAllRequisitions)?.find(
        (requistion) => requistion?.req_identifier === req_identifier,
      );
      return requisitions?.unity_reports && requisitions?.unity_reports[0]?.id;
    },
});

export const getSpecificIsFragileX = selectorFamily({
  key: "specifiIsFragileX",
  get:
    (req_identifier: string) =>
    ({ get }) => {
      const requisitions = get(getAllRequisitions)?.find(
        (requistion) => requistion?.req_identifier === req_identifier,
      );
      return (
        requisitions?.unity_reports &&
        requisitions?.unity_reports[0]?.report_data.fmr1_carrier
      );
    },
});

export const getSpecificFetalSexCs = selectorFamily({
  key: "specificFetalSexCs",
  get:
    (req_identifier: string) =>
    ({ get }) => {
      const requisitions = get(getAllRequisitions)?.find(
        (requistion) => requistion?.req_identifier === req_identifier,
      );
      return (
        requisitions?.unity_reports &&
        requisitions?.unity_reports[0]?.report_data.fetal_sex
      );
    },
});

export const getSpecificAneuploidy = selectorFamily({
  key: "specificAneuploidy",
  get:
    (req_identifier: string) =>
    ({ get }) => {
      const requisitions = get(getAllRequisitions)?.find(
        (requistion) => requistion?.req_identifier === req_identifier,
      );
      return (
        requisitions?.aneuploidy_reports && requisitions?.aneuploidy_reports[0]
      );
    },
});

export const getSpecificAneuploidyReportID = selectorFamily({
  key: "specificAneuploidyReportID",
  get:
    (req_identifier: string) =>
    ({ get }) => {
      const requisitions = get(getAllRequisitions)?.find(
        (requistion) => requistion?.req_identifier === req_identifier,
      );
      return (
        requisitions?.aneuploidy_reports &&
        requisitions?.aneuploidy_reports[0]?.id
      );
    },
});

export const getSpecificAneuploidyLowRisk = selectorFamily({
  key: "specificAneuploidyLowRisk",
  get:
    (req_identifier: string) =>
    ({ get }) => {
      const requisitions = get(getAllRequisitions)?.find(
        (requistion) => requistion?.req_identifier === req_identifier,
      );
      return (
        requisitions?.aneuploidy_reports &&
        requisitions?.aneuploidy_reports[0]?.summary === "Low Risk"
      );
    },
});

export const getSpecificAneuploidyStatus = selectorFamily({
  key: "specificAneuploidyStatus",
  get:
    (req_identifier: string) =>
    ({ get }) => {
      const requisitions = get(getAllRequisitions)?.find(
        (requistion) => requistion?.req_identifier === req_identifier,
      );
      return (
        requisitions?.aneuploidy_reports &&
        requisitions?.aneuploidy_reports[0]?.status
      );
    },
});

export const getSpecificRhD = selectorFamily({
  key: "specificRhD",
  get:
    (req_identifier: string) =>
    ({ get }) => {
      const requisitions = get(getAllRequisitions)?.find(
        (requistion) => requistion?.req_identifier === req_identifier,
      );
      return (
        requisitions?.aneuploidy_reports &&
        requisitions?.aneuploidy_reports[0]?.report_data.fetal_rhd_fa
      );
    },
});

export const getSpecific22q = selectorFamily({
  key: "specific22q",
  get:
    (req_identifier: string) =>
    ({ get }) => {
      const requisitions = get(getAllRequisitions)?.find(
        (requistion) => requistion?.req_identifier === req_identifier,
      );
      return (
        requisitions?.aneuploidy_reports &&
        requisitions?.aneuploidy_reports[0]?.report_data.del22q
      );
    },
});

export const getSpecificFetalSex = selectorFamily({
  key: "specificFetalSex",
  get:
    (req_identifier: string) =>
    ({ get }) => {
      const requisitions = get(getAllRequisitions)?.find(
        (requistion) => requistion?.req_identifier === req_identifier,
      );
      return (
        requisitions?.aneuploidy_reports &&
        requisitions?.aneuploidy_reports[0]?.report_data.fetal_sex
      );
    },
});

export const getUserAuth = selector({
  key: "userAuth",
  get: ({ get }) => get(patientState)?.auth0,
});

export const getUser = selector({
  key: "user",
  get: ({ get }) => get(patientState)?.user,
});

export const getUserProfile = selector({
  key: "userProfile",
  get: ({ get }) => get(patientProfileState)?.phi,
});
