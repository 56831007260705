import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { Info, InfoOutlined } from "@mui/icons-material";
import { useHistory, useParams } from "react-router-dom";
import {
  UpfrontPaymentDesktopContainer,
  UpfrontPaymentFieldRowTitle,
  UpfrontPaymentInfoRow,
  UpfrontPaymentReceiptTitle,
  UpfrontPaymentDashed,
  UpfrontPaymentButtonRow,
  UpfrontPaymentFieldRow,
  UpfrontBillingCreateAccount,
  UpfrontBillingCreateAccountText,
  UpfrontBillingCreateAccountWrapper,
  UpfrontPaymentMainBanner,
  UpfrontPaymentMainBannerBottomRow,
  UpfrontPaymentMainBannerTopRow,
  UpfrontPaymentYouHaveABill,
  UpfrontPaymentHMOMedicaidWrapper,
} from "./UpfrontPayment.styles";

import { useDeviceHook } from "../../utils/hooks/useDeviceHook";

import { GetTypographyContent } from "../shared/Typography/Typography";
import { getUserAuth, getUserProfile } from "../../recoil/selectors";
import { Button } from "../shared/Button/Button";
import { UpfrontSecondaryInsuranceModal } from "./UpfrontPaymentSecondaryInsurance";
import {
  PostUpfrontPaymentMedicaidHMODismiss,
  UpfrontPaymentType,
} from "./Services/UpfrontPaymentService";
import { REGISTER_URL, LOGIN_PAGE_URL } from "../../utils/urlConstants";

export const UpfrontPaymentInsurance: React.FC<{
  setUpfrontBill: React.Dispatch<
    React.SetStateAction<UpfrontPaymentType | undefined>
  >;
  upfrontBill: UpfrontPaymentType;
  setHomeScreen: () => void;
}> = ({ setUpfrontBill, upfrontBill, setHomeScreen }) => {
  const { deviceType } = useDeviceHook();
  const history = useHistory();
  const profileInfo = useRecoilValue(getUserProfile);
  const [openSecondaryInsurance, setOpenSecondaryInsurance] = useState<
    "primary" | "secondary" | "medicaid_or_hmo" | undefined
  >(undefined);
  const auth = useRecoilValue(getUserAuth);
  const { adminID, billingPK } = useParams<{
    billingPK: string;
    adminID?: string;
  }>();
  return (
    <>
      {!auth?.access_token ? (
        <UpfrontPaymentDesktopContainer deviceType={deviceType}>
          <UpfrontBillingCreateAccountWrapper>
            <UpfrontBillingCreateAccount>
              <UpfrontBillingCreateAccountText>
                <Info />{" "}
                {`Please ${
                  upfrontBill.signup_url ? "create an account" : "login"
                } to see insurance info.`}
              </UpfrontBillingCreateAccountText>
              <Button
                type="submit"
                label={upfrontBill.signup_url ? "Create Account" : "Login"}
                size="medium"
                onClick={() =>
                  upfrontBill.signup_url
                    ? history.push(
                        `${REGISTER_URL}${
                          upfrontBill.signup_url.split("c/s")[1]
                        }`,
                      )
                    : history.push(`${LOGIN_PAGE_URL}`)
                }
                backgroundColor="greenDark"
              />
            </UpfrontBillingCreateAccount>
          </UpfrontBillingCreateAccountWrapper>
        </UpfrontPaymentDesktopContainer>
      ) : (
        <>
          <UpfrontPaymentDesktopContainer deviceType={deviceType}>
            <UpfrontSecondaryInsuranceModal
              setSecondaryModalOpen={setOpenSecondaryInsurance}
              setUpfrontBill={setUpfrontBill}
              secondayModalOpen={openSecondaryInsurance}
              setHomeScreen={setHomeScreen}
            />
            <UpfrontPaymentReceiptTitle>Insurance</UpfrontPaymentReceiptTitle>
            <UpfrontPaymentFieldRow>
              <UpfrontPaymentFieldRowTitle>
                Insurance Company Name
              </UpfrontPaymentFieldRowTitle>
              {GetTypographyContent({
                content: profileInfo?.ins_provider || "-",
                size: "medium",
                textColor: "black",
              })}
            </UpfrontPaymentFieldRow>
            <UpfrontPaymentInfoRow>
              <UpfrontPaymentFieldRow>
                <UpfrontPaymentFieldRowTitle>
                  Group ID
                </UpfrontPaymentFieldRowTitle>
                {GetTypographyContent({
                  content: profileInfo?.ins_group || "-",
                  size: "medium",
                  textColor: "black",
                })}
              </UpfrontPaymentFieldRow>
              <UpfrontPaymentFieldRow>
                <UpfrontPaymentFieldRowTitle>
                  Member ID
                </UpfrontPaymentFieldRowTitle>
                {GetTypographyContent({
                  content: profileInfo?.ins_number || "-",
                  size: "medium",
                  textColor: "black",
                })}
              </UpfrontPaymentFieldRow>
            </UpfrontPaymentInfoRow>
            <br />
            <UpfrontPaymentDashed />
            <br />
            <UpfrontPaymentReceiptTitle>
              Policy Owner
            </UpfrontPaymentReceiptTitle>
            <UpfrontPaymentFieldRow>
              <UpfrontPaymentFieldRowTitle>
                Relationship To Policy Owner
              </UpfrontPaymentFieldRowTitle>
              {GetTypographyContent({
                content: profileInfo?.relationship_to_insured || "-",
                size: "medium",
                textColor: "black",
              })}
            </UpfrontPaymentFieldRow>
            <UpfrontPaymentInfoRow>
              <UpfrontPaymentFieldRow>
                <UpfrontPaymentFieldRowTitle>
                  First Name
                </UpfrontPaymentFieldRowTitle>
                {GetTypographyContent({
                  content: profileInfo?.first_name || "-",
                  size: "medium",
                  textColor: "black",
                })}
              </UpfrontPaymentFieldRow>
              <UpfrontPaymentFieldRow>
                <UpfrontPaymentFieldRowTitle>
                  Last Name
                </UpfrontPaymentFieldRowTitle>
                {GetTypographyContent({
                  content: profileInfo?.last_name || "-",
                  size: "medium",
                  textColor: "black",
                })}
              </UpfrontPaymentFieldRow>
            </UpfrontPaymentInfoRow>
            <UpfrontPaymentInfoRow>
              <UpfrontPaymentFieldRow>
                <UpfrontPaymentFieldRowTitle>
                  Date of Birth
                </UpfrontPaymentFieldRowTitle>
                {GetTypographyContent({
                  content: profileInfo?.dob || "-",
                  size: "medium",
                  textColor: "black",
                })}
              </UpfrontPaymentFieldRow>
            </UpfrontPaymentInfoRow>
            {upfrontBill?.portal_bill_status !== "secondary_ins_provided" && (
              <UpfrontPaymentButtonRow tertiary>
                <Button
                  type="submit"
                  label="Add Secondary Insurance"
                  size="medium"
                  reverse
                  onClick={() => setOpenSecondaryInsurance("secondary")}
                  backgroundColor="greyMedium"
                />
              </UpfrontPaymentButtonRow>
            )}
            {upfrontBill.show_medicaid_or_hmo_prompt && (
              <UpfrontPaymentHMOMedicaidWrapper>
                <UpfrontPaymentYouHaveABill>
                  <UpfrontPaymentMainBanner>
                    <UpfrontPaymentMainBannerTopRow>
                      <InfoOutlined /> Are the insurance details above covered
                      by Medicaid or an HMO plan?
                    </UpfrontPaymentMainBannerTopRow>
                    <UpfrontPaymentMainBannerBottomRow>
                      <UpfrontPaymentButtonRow tertiary>
                        <Button
                          type="submit"
                          label="No"
                          size="medium"
                          reverse
                          onClick={async () => {
                            const response =
                              await PostUpfrontPaymentMedicaidHMODismiss(
                                billingPK,
                                adminID,
                              );
                            if (response?.ok) {
                              const responseJson = await response.json();
                              setUpfrontBill(responseJson);
                            }
                          }}
                          backgroundColor="greyMedium"
                        />
                      </UpfrontPaymentButtonRow>
                      <UpfrontPaymentButtonRow tertiary>
                        <Button
                          type="submit"
                          label="Upload my medicaid or HMO card"
                          size="medium"
                          reverse
                          onClick={() =>
                            setOpenSecondaryInsurance("medicaid_or_hmo")
                          }
                          backgroundColor="greyMedium"
                        />
                      </UpfrontPaymentButtonRow>
                    </UpfrontPaymentMainBannerBottomRow>
                  </UpfrontPaymentMainBanner>
                </UpfrontPaymentYouHaveABill>
              </UpfrontPaymentHMOMedicaidWrapper>
            )}
          </UpfrontPaymentDesktopContainer>
        </>
      )}
    </>
  );
};
