import * as React from "react";
import { themePalette } from "../../theme";

export const Fax = ({
  color = themePalette.neutral600,
  ...props
}: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19 9H18V6C18 4.9 17.1 4 16 4H10C8.9 4 8 4.9 8 6V20H20C21.1 20 22 19.1 22 18V12C22 10.34 20.66 9 19 9ZM10 6H16V9H10V6ZM14 17H10V12H14V17ZM16 17C15.45 17 15 16.55 15 16C15 15.45 15.45 15 16 15C16.55 15 17 15.45 17 16C17 16.55 16.55 17 16 17ZM16 14C15.45 14 15 13.55 15 13C15 12.45 15.45 12 16 12C16.55 12 17 12.45 17 13C17 13.55 16.55 14 16 14ZM19 17C18.45 17 18 16.55 18 16C18 15.45 18.45 15 19 15C19.55 15 20 15.45 20 16C20 16.55 19.55 17 19 17ZM19 14C18.45 14 18 13.55 18 13C18 12.45 18.45 12 19 12C19.55 12 20 12.45 20 13C20 13.55 19.55 14 19 14Z"
      fill={color}
    />
    <path
      d="M4.5 8C3.12 8 2 9.12 2 10.5V18.5C2 19.88 3.12 21 4.5 21C5.88 21 7 19.88 7 18.5V10.5C7 9.12 5.88 8 4.5 8Z"
      fill={color}
    />
  </svg>
);
