import React from "react";
import { CardProps as MuiCardProps } from "@mui/material";
import { Button } from "../Button/Button";
import {
  CardContainer,
  CardMedia,
  CardContentMobile,
  CardContentDesktopTablet,
  CardActions,
  CardStyledQuestionMark,
  StyledCard,
  CardPaddedContainer,
} from "./Card.styles";
import { ButtonSize, CardButton, ComponentType, GridDimensions } from "./types";
import { Typography } from "../Typography/Typography";
import { TypographySize } from "../Typography/Typography.styles";
import { useDeviceHook } from "../../../utils/hooks/useDeviceHook";

interface CardTypographyStyle {
  size: TypographySize;
  isBold: boolean;
  isCaps: boolean;
  content: string;
  padding?: string;
  isItalic?: boolean;
}

export interface CardProps extends Omit<MuiCardProps, "children"> {
  titleTypography: CardTypographyStyle;
  bodyTypography: CardTypographyStyle;
  buttons: CardButton[];
  gridDimension: GridDimensions;
  src: string;
  component: ComponentType;
  buttonSize: ButtonSize;
}

function GetCardTypographyContent(
  props: CardTypographyStyle,
  gutterBottom = false,
): React.ReactElement {
  return (
    <Typography
      gutterBottom={gutterBottom}
      size={props.size}
      isBold={props.isBold}
      isCaps={props.isCaps}
      isItalic={props.isItalic || false}
      content={props.content}
      padding={props.padding}
    />
  );
}

export const Card = (props: CardProps): React.ReactElement => {
  const buttons = props.buttons ? (
    props.buttons.map((button) => (
      <Button
        key={button.buttonText}
        size={props.buttonSize}
        onClick={button.buttonAction}
        label={button.buttonText}
      />
    ))
  ) : (
    <></>
  );

  const { deviceType } = useDeviceHook();

  const mediaContent = (
    <div>
      <CardMedia component={props.component} src={props.src} />
    </div>
  );

  return (
    <>
      {deviceType === "mobile" && (
        <StyledCard>
          <CardContentMobile>
            {mediaContent}
            <CardStyledQuestionMark />
            {GetCardTypographyContent(props.titleTypography, true)}
            {GetCardTypographyContent(props.bodyTypography)}
            <CardActions $deviceType={deviceType}>{buttons}</CardActions>
          </CardContentMobile>
        </StyledCard>
      )}
      {deviceType !== "mobile" && (
        <StyledCard>
          <CardContentDesktopTablet $gridDimension={props.gridDimension}>
            <CardContainer>{mediaContent}</CardContainer>
            <CardPaddedContainer deviceType={deviceType}>
              <CardStyledQuestionMark />
              {GetCardTypographyContent(props.titleTypography, true)}
              {GetCardTypographyContent(props.bodyTypography)}
              <CardActions $deviceType={deviceType}>{buttons}</CardActions>
            </CardPaddedContainer>
          </CardContentDesktopTablet>
        </StyledCard>
      )}
    </>
  );
};
