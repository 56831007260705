import styled from "styled-components/macro";
import { StyledTypography } from "../../components/shared/Typography/Typography.styles";
import { DeviceType } from "../../utils/hooks/useDeviceHook";

export const CarrierWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CarrierDesktopWrapper = styled.div`
  display: grid;
  grid-template-columns: 800px 330px;
  grid-column-gap: 24px;
  padding: 70px 142px 142px 142px;
`;

export const CarrierBackToResults = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  max-width: 120px;
  margin-bottom: 20px;
  cursor: pointer;
`;

export const CarrierTitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const DetailedReportTop = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
`;

export const CarrierDesktopLeftWrapper = styled.div<{ multipleReqs: boolean }>`
  display: grid;
  grid-template-rows: ${({ multipleReqs }) =>
    `40px${multipleReqs ? " 40px" : ""} 30px auto auto auto`};
  grid-column-gap: 24px;
`;

export const CarrierDesktopRightWrapper = styled.div`
  display: grid;
  grid-template-rows: 40px 40px 415px 200px;
  grid-column-gap: 24px;
  margin-top: 33px;

  ${StyledTypography} {
    align-self: flex-end;
  }
`;

export const CarrierGeneralContainer = styled.div<{ maxHeight?: number }>`
  margin-top: 20px;
  padding: 20px;
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${({ theme }) => theme.boxShadow};
  max-width: 802px;
  max-height: ${({ maxHeight }) => maxHeight && `${maxHeight}px`};
  border-radius: 20px;
`;

export const CarrierTabletMobileWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-columns: ${({ deviceType }) =>
    deviceType === "tablet" ? "570px" : "360px"};
  grid-column-gap: 24px;
  padding: ${({ deviceType }) =>
    deviceType === "tablet" ? "60px 18px 112px 18px" : "40px 0px 92px 0px"};
`;
