export const BannerInformationIcon = (): React.ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 16.9883C12.3026 16.9883 12.5562 16.8859 12.7609 16.6812C12.9656 16.4765 13.068 16.2229 13.068 15.9203V12.0879C13.068 11.7853 12.9656 11.5317 12.7609 11.327C12.5562 11.1223 12.3026 11.0199 12 11.0199C11.6974 11.0199 11.4438 11.1223 11.2391 11.327C11.0344 11.5317 10.932 11.7853 10.932 12.0879V15.9203C10.932 16.2229 11.0344 16.4765 11.2391 16.6812C11.4438 16.8859 11.6974 16.9883 12 16.9883ZM12 9.17696C12.3109 9.17696 12.5715 9.07181 12.7818 8.8615C12.9921 8.65119 13.0973 8.39059 13.0973 8.07969C13.0973 7.76879 12.9921 7.50819 12.7818 7.29788C12.5715 7.08757 12.3109 6.98242 12 6.98242C11.6891 6.98242 11.4285 7.08757 11.2182 7.29788C11.0079 7.50819 10.9027 7.76879 10.9027 8.07969C10.9027 8.39059 11.0079 8.65119 11.2182 8.8615C11.4285 9.07181 11.6891 9.17696 12 9.17696ZM12 22C10.613 22 9.31156 21.7377 8.09574 21.213C6.87992 20.6884 5.82233 19.9764 4.92296 19.077C4.02359 18.1777 3.31159 17.1201 2.78695 15.9043C2.26232 14.6884 2 13.387 2 12C2 10.613 2.26232 9.31156 2.78695 8.09574C3.31159 6.87992 4.02359 5.82233 4.92296 4.92296C5.82233 4.02359 6.87992 3.31159 8.09574 2.78695C9.31156 2.26232 10.613 2 12 2C13.387 2 14.6884 2.26232 15.9043 2.78695C17.1201 3.31159 18.1777 4.02359 19.077 4.92296C19.9764 5.82233 20.6884 6.87992 21.213 8.09574C21.7377 9.31156 22 10.613 22 12C22 13.387 21.7377 14.6884 21.213 15.9043C20.6884 17.1201 19.9764 18.1777 19.077 19.077C18.1777 19.9764 17.1201 20.6884 15.9043 21.213C14.6884 21.7377 13.387 22 12 22ZM12 19.7703C14.1732 19.7703 16.0116 19.0186 17.5151 17.5151C19.0186 16.0116 19.7703 14.1732 19.7703 12C19.7703 9.82679 19.0186 7.98844 17.5151 6.48493C16.0116 4.98145 14.1732 4.2297 12 4.2297C9.82679 4.2297 7.98844 4.98145 6.48493 6.48493C4.98144 7.98844 4.2297 9.82679 4.2297 12C4.2297 14.1732 4.98144 16.0116 6.48493 17.5151C7.98844 19.0186 9.82679 19.7703 12 19.7703Z"
      fill="#064682"
    />
  </svg>
);
