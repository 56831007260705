import styled from "styled-components/macro";
import { DeviceType } from "../../utils/hooks/useDeviceHook";

export type BannerType = "information" | "billing" | "warning";

const getBackGroundColor = (type: BannerType) => {
  switch (type) {
    case "information":
      return "#EEF5FA";
    case "billing":
      return "#EFF8F5";
    case "warning":
      return "#FFF2F0";
    default:
      return "#EEF5FA";
  }
};

const getTextColor = (type: BannerType) => {
  switch (type) {
    case "information":
      return "#064682";
    case "billing":
      return "#017E72";
    case "warning":
      return "#C0250F";
    default:
      return "#064682";
  }
};

export const BannerWrapper = styled.div<{
  deviceType: DeviceType;
  bannerType: BannerType;
}>`
  background: ${({ bannerType }) => getBackGroundColor(bannerType)};
  color: ${({ bannerType }) => getTextColor(bannerType)};
  padding: 16px 16px 0px 0px;
  display: grid;
  justify-content: center;
  margin-bottom: 24px;
  border-left: ${({ bannerType, deviceType }) =>
    deviceType !== "desktop"
      ? `3px solid ${getTextColor(bannerType)}`
      : "none"};
  border-bottom: ${({ bannerType, deviceType }) =>
    deviceType === "desktop"
      ? `3px solid ${getTextColor(bannerType)}`
      : "none"};
`;

export const BannerContentWrapperGrid = styled.div<{
  deviceType: DeviceType;
  bannerType: BannerType;
}>`
  display: grid;
  grid-template-columns: min-content minmax(auto, 900px);
  grid-gap: 12px;
  max-width: 900px;
  padding-bottom: 16px;
  padding-left: 16px;
`;

export const BannerContentWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-gap: 16px;
  width: inherit;
`;
export const BannerContentTitle = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
`;

export const BannerContentText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const BannerContentAction = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-variant: all-small-caps;
  letter-spacing: 0.18px;
  text-align: end;
  cursor: pointer;
`;
