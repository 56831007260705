import React from "react";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import {
  BabyPeekFAQTitle,
  BabyPeekFAQWrapper,
  BabyPeekIntroFAQAccordionWrapper,
} from "./BabyPeekIntro.styles";
import { BabyPeekIcon } from "./BabyPeekIcons/BabyPeekIcon";

import { BabyPeekAccordions, babyPeekFAQAccordion } from "./BabyPeekAccordion";

export const BabyPeekFAQ: React.FC = () => {
  const { deviceType } = useDeviceHook();

  return (
    <BabyPeekFAQWrapper
      style={{
        display: "grid",
        background:
          "linear-gradient(180deg, #FFF6CF 0%, rgba(255, 253, 244, 0.60) 20.5%, #FFFDF4 100%, #FFFDF4 100%), #FFF",
      }}
    >
      <div
        style={{
          marginTop: "30px",
          display: "flex",
          alignItems: "center",
          justifySelf: "center",
        }}
      >
        <BabyPeekIcon />
      </div>
      <BabyPeekIntroFAQAccordionWrapper deviceType={deviceType}>
        <BabyPeekFAQTitle deviceType={deviceType}>
          Frequently Asked Questions
        </BabyPeekFAQTitle>
        <BabyPeekAccordions data={babyPeekFAQAccordion} />
      </BabyPeekIntroFAQAccordionWrapper>
    </BabyPeekFAQWrapper>
  );
};
