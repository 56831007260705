import * as React from "react";
import { themePalette } from "../../theme";

export const Facebook = ({
  color = themePalette.neutral600,
  ...props
}: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    width="13"
    height="24"
    viewBox="0 0 13 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_76491:686"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="11"
      height="24"
    >
      <path
        d="M3.25592 5.58116V8.61649H0.4375V12.5195H3.25592V23.0526H7.0829V12.5195H10.3365L10.9292 8.61649H7.0829V5.80912C7.0829 4.9484 7.9754 4.75084 8.38987 4.75084H10.8726V0.962546L8.02651 0.94873C4.14151 0.94873 3.25592 3.77268 3.25592 5.58116Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_76491:686)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-6.46875 29.9606H17.8387V-5.96045H-6.46875V29.9606Z"
        fill={color}
      />
    </g>
  </svg>
);
