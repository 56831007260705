import styled from "styled-components/macro";
import { DeviceType } from "../../utils/hooks/useDeviceHook";
import { BabyPeekV2HeaderTitleWrapper } from "./Traits/Shared/BabyPeekTrait.styles";

export const BabyPeekDescriptionSectionText = styled.div<{
  removeTopPadding?: boolean;
}>`
  color: ${({ theme }) => theme.greyBase};
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding: 32px 0px;
  padding-top: ${({ removeTopPadding }) => (removeTopPadding ? "0px" : "32px")};
  img {
    align-self: center;
  }

  li {
    margin-top: 4px;
  }
`;

export const BabyPeekShareTraitModalTitle = styled.div`
  color: var(--neutral-neutral-8, #000);
  /* Heading/H3/Mobile */
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
`;

export const BabyPeekShareTraitModalSubTitle = styled.div`
  color: var(--base-medium-gray, #626a77);
  font-feature-settings: "case" on;
  /* Text/Small/Desktop */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const BabyPeekShareTraitModalSubTitleInput = styled.input`
  border-radius: 8px;
  border: 1px solid var(--neutral-neutral-4, #a0a0a0);
  background: var(--base-white, #fff);
  display: flex;
  padding: var(--Spacing, 12px) 16px;

  align-items: center;
  align-self: stretch;
  width: 100%;
`;

export const BabyPeekShareTraitModalHelp = styled.div`
  color: var(--Grey-500, #718096);
  font-feature-settings: "case" on;
  /* Text/Extra Small/Desktop */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
`;

export const BabyPeekShareErrorText = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

export const BabyPeekShareOutlineButton = styled.button`
  display: flex;
  max-height: var(--Max-Height, 54px);
  padding: var(--Padding-Vertical, 18px) var(--Padding-Horizontal, 32px);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing, 10px);
  border-radius: 40px;
  border-radius: 40px;
  border: 2px solid var(--primary-blue, #2b3ff6);
  color: var(--primary-blue, #2b3ff6);
  text-align: center;
  font-feature-settings:
    "ss02" on,
    "ss01" on,
    "ss04" on;
  /* Button/Mobile */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 112.5% */
  letter-spacing: 0.48px;
  cursor: pointer;
  background: white;
`;

export const BabyPeekFilledButton = styled.button`
  display: flex;
  max-height: var(--Max-Height, 54px);
  padding: var(--Padding-Vertical, 18px) var(--Padding-Horizontal, 32px);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing, 10px);
  border-radius: 40px;
  background: var(--primary-blue, #2b3ff6);
  color: var(--base-white, #fff);
  text-align: center;
  font-feature-settings:
    "ss02" on,
    "ss01" on,
    "ss04" on;
  /* Button/Mobile */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 112.5% */
  letter-spacing: 0.48px;
  cursor: pointer;
  border: none;
`;

export const BabyPeekGrayButton = styled.button`
  display: flex;
  max-height: var(--Max-Height, 54px);
  padding: var(--Padding-Vertical, 18px) var(--Padding-Horizontal, 32px);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing, 10px);
  border-radius: 40px;
  background: #edf0f4;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: 0.48px;
  cursor: pointer;
  border: none;
`;

export const BabyPeekSingleTraitModalConfettiWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;

  ${BabyPeekV2HeaderTitleWrapper} {
    width: 300px !important;
  }

  ${BabyPeekShareOutlineButton} {
    width: ${({ deviceType }) =>
      deviceType === "mobile" ? undefined : "400px"} !important;
    margin-bottom: ${({ deviceType }) =>
      deviceType === "mobile" ? "10px" : undefined} !important;
  }

  ${BabyPeekFilledButton} {
    width: ${({ deviceType }) =>
      deviceType === "mobile" ? undefined : "400px"} !important;
  }
`;

export const BabyPeekShareTraitModalWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  border-radius: var(--Media-Border-Radius, 16px);

  border: 0px solid var(--neutral-neutral-7, #272727);
  background: var(--basic-white, #fff);
  /* Drop shadow */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  display: flex;

  padding: 24px 16px;

  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: ${({ deviceType }) => (deviceType !== "mobile" ? "500px" : "90%")};
  overflow: scroll;
  max-height: 99%;

  ${BabyPeekGrayButton} {
    background-color: #a0aec0;
    color: #fff;
  }

  canvas {
    position: relative !important;
    z-index: 0 !important;
    width: 100%;
    justify-self: center;
  }
`;

export const BabyPeekShareReceiptWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  margin-top: -220px;
  z-index: 10;
  position: relative;
  overflow: hidden;
  max-height: ${({ deviceType }) =>
    deviceType === "mobile" ? "80%" : undefined};
`;

export const BabyPeekReceiptContainer = styled.div<{
  deviceType: DeviceType;
}>`
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 6px 11px rgba(0, 0, 0, 0.04);
  border-radius: ${({ deviceType }) =>
    deviceType !== "mobile" ? "24px" : undefined};
  width: ${({ deviceType }) => (deviceType !== "mobile" ? "532px" : "100%")};
  justify-self: center;
  height: 250px;

  canvas {
    position: relative !important;
    z-index: 0 !important;
    width: 100%;
    justify-self: center;
  }
`;

export const BabyPeekShareTitle = styled.div`
  color: var(--Neutral-Neutral-8, #000);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px; /* 133.333% */
`;

export const BabyPeekReceiptHeader = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 20px;
  justify-content: center;
  align-items: center;

  svg {
    justify-self: center;
  }
`;
