import React from "react";
import { GetTypographyContent } from "../shared/Typography/Typography";
import { ContactUsMessageWrapper, ContactUsWrapper } from "./ContactUs.styles";
import { ContactUsContent } from "./ContactUsContent";

export const ContactUs = (): React.ReactElement => (
  <ContactUsWrapper data-testid="contactUsTestId">
    {GetTypographyContent({
      content: "Get in touch",
      size: "label",
      textColor: "greyMedium",
      isBold: true,
      isCaps: true,
    })}
    <ContactUsMessageWrapper>
      {GetTypographyContent({
        content:
          "If you have a question, please fill in the form below or call us at ",
        size: "medium",
        textColor: "greyMedium",
      })}
      {GetTypographyContent({
        content: "+1(650) 460-2551",
        size: "medium",
        isPhone: true,
      })}
      {GetTypographyContent({
        content: ".",
        size: "medium",
        textColor: "greyMedium",
      })}
      {GetTypographyContent({
        content:
          " You can also chat with a representative in real time by clicking ",
        size: "medium",
        textColor: "greyMedium",
        isBold: true,
      })}
      {GetTypographyContent({
        content: "here",
        size: "medium",
        isBold: true,
        link: "https://billiontoone.com/contact/",
        externalLink: true,
        textColor: "greyMedium",
      })}
      {GetTypographyContent({
        content: " to start a conversation.",
        size: "medium",
        textColor: "greyMedium",
        isBold: true,
      })}
    </ContactUsMessageWrapper>
    <ContactUsContent />
  </ContactUsWrapper>
);
