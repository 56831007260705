import styled from "styled-components/macro";
import CurrencyInput from "react-currency-input-field";

import Tooltip from "@mui/material/Tooltip";
import { Input, Popper } from "@mui/material";
import { DeviceType } from "../../utils/hooks/useDeviceHook";
import { StyledButton } from "../shared/Button/Button.styles";
import { ColorPalette } from "../../theme";

import {
  ProfileInsuranceInsurancePadding,
  ProfileInsuranceModalButtons,
  ProfileModalTitleWrapper,
} from "../Profile/ProfileInsuranceModal.styles";
import { ErrorPopupWrapper } from "../shared/ErrorPopup/ErrorPopup.styles";
import { FileUploaderTitle } from "../Profile/ProfileInsuranceFileUploader.styles";

export const UpfrontPaymentHelpGeneralContainer = styled.div<{
  padding: string;
  paddingBottom?: string;
}>`
  margin-top: 16px;
  padding: ${({ padding }) => padding};
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${({ theme }) => theme.boxShadow};
  max-width: 920px;
  border-radius: 24px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom};

  .MuiTypography-root {
    font-family: "light" !important;
    font-weight: 500;
  }
`;

const getHelpWidth = (deviceType: DeviceType) => {
  if (deviceType === "desktop") {
    return "310px";
  }
  if (deviceType === "tablet") {
    return "532px";
  }
  if (deviceType === "mobile") {
    return "100%";
  }
};

export const UpfrontPaymentPageWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: ${({ deviceType }) =>
    deviceType !== "desktop" ? undefined : "auto auto"};
  grid-template-rows: ${({ deviceType }) =>
    deviceType !== "desktop" ? "auto auto" : undefined};
  align-items: baseline;
  justify-content: center;
  grid-gap: 24px;
  text-underline-offset: 5px;
  padding: ${({ deviceType }) =>
    deviceType !== "mobile" ? "32px 32px" : "32px 0px"};
  font-family: "light" !important;
  ${UpfrontPaymentHelpGeneralContainer} {
    margin-bottom: -16px;
    width: ${({ deviceType }) => getHelpWidth(deviceType)};
    justify-self: ${({ deviceType }) =>
      deviceType !== "desktop" ? "center" : "start"};

    .MuiPaper-root.MuiAccordion-root:first-of-type {
      border-top-left-radius: 24px !important;
      border-top-right-radius: 24px !important;
    }

    .MuiTypography-root {
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
    }
  }

  button {
    text-transform: none !important;
  }
`;

export const UpfrontPaymentHelpWrapper = styled.div`
  display: grid;
  grid-template-rows: 40px auto;
`;

export const UpfrontPaymentTitle = styled.div<{ pointer?: boolean }>`
  svg {
    margin-bottom: -5px;
  }
  font-size: 16px;
  font-family: light;
  font-weight: 800;
  line-height: 24px;
  color: ${({ theme }) => theme.greyMedium};
  cursor: ${({ pointer }) => (pointer ? "pointer" : undefined)};
  margin-left: 15px;
`;

export const UpfrontPaymentTitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const UpfrontPaymentDesktopContainer = styled.div<{
  deviceType: DeviceType;
  overflowCheck?: boolean;
}>`
  padding: ${({ deviceType }) => (deviceType === "mobile" ? "16px" : "32px")};
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 6px 11px rgba(0, 0, 0, 0.04);
  border-radius: ${({ deviceType }) =>
    deviceType !== "mobile" ? "24px" : undefined};
  width: ${({ deviceType }) => (deviceType !== "mobile" ? "532px" : undefined)};
  justify-self: center;
  margin-top: 16px;
  min-height: 300px;
  max-height: ${({ deviceType, overflowCheck }) =>
    deviceType === "mobile" && overflowCheck ? "80%" : undefined};
  overflow-y: ${({ deviceType, overflowCheck }) =>
    deviceType === "mobile" && overflowCheck ? "scroll" : undefined};

  ${ErrorPopupWrapper} {
    width: 100%;
    margin-bottom: 25px;
  }

  canvas {
    position: relative !important;
    z-index: 0 !important;
    width: 100%;
    justify-self: center;
  }
`;

export const UpfrontPaymentMultipleContainer = styled.div<{
  deviceType: DeviceType;
  overflowCheck?: boolean;
}>`
  padding: ${({ deviceType }) =>
    deviceType === "mobile" ? "16px 16px" : "32px 32px"};
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 6px 11px rgba(0, 0, 0, 0.04);
  border-radius: ${({ deviceType }) =>
    deviceType !== "mobile" ? "24px" : "20px"};
  width: ${({ deviceType }) => (deviceType !== "mobile" ? "532px" : "325px")};
  justify-self: center;
  margin: 16px 0px;
`;

export const UpfrontPaymentMultipleContent = styled.div`
  color: ${({ theme }) => theme.black};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  display: flex;
  align-items: center;
`;

export const UpfrontPaymentMultipleGrid = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
`;

export const UpfrontPaymentPlanAccordionContainer = styled.div<{
  deviceType: DeviceType;
}>`
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 6px 11px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  width: ${({ deviceType }) => (deviceType !== "mobile" ? "532px" : "100%")};
  justify-self: center;
  margin-top: 16px;
  box-shadow: 0px 6px 11px rgba(0, 0, 0, 0.04);
`;

const getMarginReceipt = (
  deviceType: DeviceType,
  noConfetti: boolean,
  isPaymentPlan: boolean,
) => {
  if (noConfetti) {
    return undefined;
  }
  if (isPaymentPlan) {
    return "-555px";
  }
  if (deviceType !== "mobile") {
    return "-350px";
  }
  return "-450px";
};

export const UpfrontPaymentReceiptWrapper = styled.div<{
  deviceType: DeviceType;
  noConfetti: boolean;
  isPaymentPlan: boolean;
}>`
  margin-top: ${({ deviceType, noConfetti, isPaymentPlan }) =>
    getMarginReceipt(deviceType, noConfetti, isPaymentPlan)};
  z-index: 10;
  position: relative;
  overflow: hidden;
  max-height: ${({ deviceType }) =>
    deviceType === "mobile" ? "80%" : undefined};
`;

export const UpfrontPaymentMainNoticeBanner = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: ${({ deviceType }) =>
    deviceType !== "desktop" ? "auto auto" : undefined};
  grid-template-columns: ${({ deviceType }) =>
    deviceType === "desktop" ? "auto auto" : undefined};
  background-color: ${({ theme }) => theme.blueSubtle};
  border-radius: 16px;
  justify-content: ${({ deviceType }) =>
    deviceType !== "desktop" ? "center" : undefined};
  .eob-icon {
    align-self: center;
    margin-left: 10px;
    justify-self: center;
    margin-top: ${({ deviceType }) =>
      deviceType !== "desktop" ? "-15px" : undefined};
    margin-bottom: ${({ deviceType }) =>
      deviceType !== "desktop" ? "10px" : undefined};
    margin-right: ${({ deviceType }) =>
      deviceType !== "desktop" ? undefined : "10px"};
  }
`;

export const UpfrontPaymentOptional = styled.span`
  color: ${({ theme }) => theme.neutral600};
  font-size: 13px;
`;

export const UpfrontPaymentStripeInputRow = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  color: var(--Grey-700, #2d3748);
  font-feature-settings: "case" on;
  /* Text/Regular/Mobile */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-bottom: 15px;
  font-family:
    -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;

export const UpfrontPaymentStripePaymentDisclosure = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.greyBase};
  line-height: 20px;
  text-align: center;
  margin-top: 25px;
`;

export const UpfrontPaymentStripeInputError = styled.div`
  color: #df1b41;
  font-size: 0.93rem;
  margin-top: 0.25rem;
`;

export const UpfrontPaymentStripeInput = styled.input`
  padding: 0.6rem;
  background-color: #fff;
  border-radius: 5px;
  transition:
    background 0.15s ease,
    border 0.15s ease,
    box-shadow 0.15s ease,
    color 0.15s ease;
  border: 1px solid #e6e6e6;
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.03),
    0px 3px 6px rgba(0, 0, 0, 0.02);

  .p-Input--placeholder {
    color: #757680;
  }

  :focus {
    outline: 0;
    border-color: hsla(210, 96%, 45%, 50%);
    box-shadow:
      0px 1px 1px rgba(0, 0, 0, 0.03),
      0px 3px 6px rgba(0, 0, 0, 0.02),
      0 0 0 3px hsla(210, 96%, 45%, 50%),
      0 1px 1px 0 rgba(0, 0, 0, 0.08);
  }
`;

export const UpfrontPaymentMainNoticeRow = styled.div`
  align-self: center;
  justify-self: end;
  padding-left: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.blueDark};
  display: contents;
  padding-left: 20px;

  span {
    font-weight: 800;
    display: contents;
  }
`;

export const UpfrontPaymentMainBanner = styled.div`
  padding: 16px 16px;
  display: grid;
  grid-template-rows: auto auto auto;
  background-color: ${({ theme }) => theme.blueSubtle};
  grid-gap: 12px;
  border-radius: 16px;
  margin-bottom: 24px;

  svg {
    margin-right: 10px;
  }

  path {
    fill: ${({ theme }) => theme.blueDark};
  }
`;

export const UpfrontPaymentSmallMainBanner = styled.div`
  padding: 16px 16px;
  grid-template-rows: auto auto auto;
  background-color: ${({ theme }) => theme.blueSubtle};
  grid-gap: 12px;
  border-radius: 16px;
  margin-bottom: 24px;

  svg {
    margin-right: 10px;
  }

  path {
    fill: ${({ theme }) => theme.blueDark};
  }
`;

export const UpfrontPaymentMainBannerTopRow = styled.div`
  align-self: center;
  justify-self: start;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.blueDark};
  align-items: center;
  display: flex;
`;

export const UpfrontPaymentMainBannerBottomRow = styled.div<{
  isBold?: boolean;
  justify?: string;
}>`
  align-self: center;
  justify-self: ${({ justify }) => justify || "end"};
  padding-left: 30px;
  font-weight: ${({ isBold }) => (isBold ? 800 : 500)};
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.blueDark};

  p {
    display: contents;
    text-decoration: underline;
    text-decoration-style: dashed;
    font-weight: 500;
  }
  a {
    display: contents;
    text-decoration: underline;
    text-decoration-style: dashed;
    font-weight: 500;
  }
`;

export const UpfrontPaymentAccordionContainer = styled.div<{
  deviceType: DeviceType;
  padding?: boolean;
}>`
  background-color: transparent;
  border-radius: 24px;
  width: ${({ deviceType }) => (deviceType !== "mobile" ? "532px" : "100%")};
  justify-self: center;
  margin-top: 16px;

  svg {
    color: ${({ theme }) => theme.greyBase};
  }

  .MuiPaper-root.Mui-expanded {
    padding-left: ${({ padding }) => (padding ? "24px" : undefined)};
    padding-right: ${({ padding }) => (padding ? "24px" : undefined)};
  }

  .MuiPaper-root {
    box-shadow: none !important;
  }

  .MuiAccordion-root {
    border-radius: 24px !important;
  }

  .MuiAccordionSummary-root {
    padding: 0px 24px !important;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    padding: 0px ${({ padding }) => (padding ? "0px" : "24px")} !important;
  }
`;

export const UpfrontPaymentPlanAccordionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  margin: 10px 0px;
`;

export const UpfrontPaymentYouHaveABill = styled.div`
  display: grid;
  grid-template-rows: auto auto;
`;

export const UpfrontPaymentReceiptTitle = styled.div`
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const UpfrontPaymentReceiptMessage = styled.div`
  justify-content: center;
  display: flex;
  margin-top: -10px;
  margin-bottom: 24px;
  p {
    display: inline;
  }
`;

export const UpfrontPaymentReceiptHeader = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 20px;
  justify-content: center;
  align-items: center;

  svg {
    justify-self: center;
  }
`;

export const UpfrontPaymentReceiptThankYou = styled.div`
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
`;

export const UpfrontPaymentDashed = styled.div`
  border-bottom: 1px dashed ${({ theme }) => theme.greyLight};
`;

export const UpfrontPaymentSolidLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.neutral200};
  margin: 10px 0px;
`;

export const UpfrontPaymentInfoRow = styled.div<{ adjustable?: boolean }>`
  display: grid;
  grid-template-columns: ${({ adjustable }) =>
    adjustable ? "auto auto" : "50% 50%"};
  grid-column-gap: 20px;
  margin-top: 16px;
`;

export const UpfrontPaymentItemizedSectionWrapper = styled.div`
  @media print {
    @page {
      margin: 100px 100px;
    }
  }
`;

export const UpfrontPaymentStepperWrapper = styled.div`
  .MuiStepLabel-vertical {
    padding: 0px 0px !important;
  }

  .MuiStepConnector-line {
    margin-left: -2px !important;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const UpfrontPaymentInfoBillContent = styled.div<{
  marginBottom?: number;
}>`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: ${({ marginBottom }) => marginBottom || 4}px;

  ${UpfrontPaymentStepperWrapper} {
    width: 100%;
  }
`;

export const UpfrontPaymentPlanTitle = styled.div`
  div {
    display: inline;
  }
`;

export const UpfrontPaymentPlanAccordionSummary = styled.div`
  display: grid;
  grid-template-rows: auto auto;
`;

export const UpfrontPaymentInfoBillContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 16px;
  margin-top: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.greyMedium};
`;

export const UpfrontPaymentContentSecondaryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const UpfrontPaymentButtonRow = styled.div<{
  bottomRow?: boolean;
  tertiary?: boolean;
}>`
  display: flex;
  ${StyledButton} {
    width: 100%;
    padding: 11px 18px;
    font-size: 16px !important;
    border-width: 1px;
    font-weight: ${({ tertiary }) => (tertiary ? 500 : 800)};
    text-transform: none !important;
  }
  margin-top: ${({ bottomRow }) => (!bottomRow ? "32px" : "16px")};
`;

export const UpfrontPaymentHMOMedicaidWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  ${UpfrontPaymentMainBannerBottomRow} {
    justify-self: auto;
    padding: 0px 24px;
  }
`;

export const UpfrontPaymentFinancialAssistanceWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const UpfrontPaymentSaveCreditCardGrid = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  padding-top: 32px;
  color: var(--grey-700, #2d3748);
  font-feature-settings: "case" on;
  /* Text/Regular/Mobile */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  grid-gap: 10px;
  input[type="checkbox"] {
    width: 24px;
    height: 24px;
    border-radius: 8px;
    accent-color: ${({ theme }) => theme.greenDark};
  }
`;

export const UpfrontPaymentFinancialAssistance = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  justify-self: center;
  justify-content: center;
  text-decoration: underline;
  text-decoration-style: dashed;
  color: ${({ theme }) => theme.greenDark};
  cursor: pointer;
  height: 40px;
  align-items: center;
  margin-top: 24px;
  @media only screen and (max-width: 400px) {
    font-size: 14px;
  }
  :hover {
    opacity: 0.7;
  }
`;

export const FinancialAssistanceTitle = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
`;

export const InNetworkDisclaimer = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.greyMedium};
  cursor: pointer;
  height: auto;
  align-items: center;
  margin-top: 24px;
  :hover {
    opacity: 0.7;
  }
`;

export const UpfrontPaymentMainText = styled.div<{
  $color?: keyof ColorPalette;
}>`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme, $color }) => ($color ? theme[$color] : theme.greyMedium)};
  margin-bottom: 12px;
`;

export const UpfrontPaymentMainTextCentered = styled.div<{
  $color?: keyof ColorPalette;
}>`
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme, $color }) => ($color ? theme[$color] : theme.greyMedium)};
  margin-bottom: 24px;
`;

export const UpfrontPaymentFinancialReceipt = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: auto auto auto auto;
  padding: 25px 25px;
  align-items: center;
  grid-gap: 8px;
  margin-top: "0px";

  svg {
    justify-self: center;
  }

  div {
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;

export const UpfrontPaymentFinancialReceiptTitle = styled.div`
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
`;

export const UpfrontPaymentFinancialWarningRoundedWrapper = styled.div`
  path {
    fill: ${({ theme }) => theme.greyMedium};
  }
  svg {
    width: 30px;
    height: 30px;
  }
`;

export const UpfrontPaymentSecondaryReceiptTitle = styled.div`
  margin-top: 20px;
`;

export const UpfrontPaymentEmailText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.greyBase};
`;

export const UpfrontPaymentCost = styled.div`
  font-weight: 800;
  font-size: 32px;
  line-height: 36px;
  color: ${({ theme }) => theme.greyBase};
  display: flex;
  align-items: center;

  svg {
    margin-left: 15px;
  }
`;

export const UpfrontPaymentBigCost = styled.div<{ marginTop?: number }>`
  font-weight: 800;
  font-size: 48px;
  line-height: 100%;
  color: ${({ theme }) => theme.greyBase};
  display: flex;
  align-items: center;
  margin-top: ${({ marginTop }) => marginTop ?? 16}px;
  svg {
    margin-left: 15px;
  }
`;

export const UpfrontPaymentGeneExplanation = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.greyBase};
`;

export const UpfrontPaymentPageLink = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.greyBase};
  cursor: pointer;
  align-items: center;

  div {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.greyDark};
    text-decoration-style: dashed;
  }

  :hover {
    opacity: 0.8;
  }
`;

export const UpfrontBillingCreateAccountWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const UpfrontBillingCreateAccount = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  align-self: center;
`;

export const UpfrontBillingCreateAccountText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.greyDark};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  svg {
    margin-right: 10px;
  }
`;

export const UpfrontPaymentBillingGridHeader = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 35px;
`;

export const UpfrontPaymentBillingTitle = styled.div`
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.greyMedium};
`;

export const UpfrontPaymentLink = styled.div`
  color: ${({ theme }) => theme.greenDark};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  text-decoration-style: dashed;
  cursor: pointer;
  display: inline;
`;

export const UpfrontPaymentPhoneLinkStyle = styled.span`
  color: ${({ theme }) => theme.greenDark};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  text-decoration-style: dashed;
  cursor: pointer;
  display: inline;
`;

export const UpfrontPaymentFinancialAssitancePhone = styled.div`
  font-size: 16px;
  font-family: "light";
  line-height: 24px;
  color: ${({ theme }) => theme.greyBase};
  margin-top: 16px;
  text-align: center;
`;

export const UpfrontPaymentFieldRow = styled.div<{ adjustable?: boolean }>`
  display: grid;
  grid-template-rows: 20px ${({ adjustable }) => (adjustable ? "auto" : "20px")};
  grid-row-gap: 2px;

  .edit-button {
    cursor: pointer;
  }
`;

export const UpfrontPaymentItemizedBillDownload = styled.div`
  display: flex;
  text-align: center;
  justify-content: flex-end;
  font-size: 16px;
  color: ${({ theme }) => theme.greyDark};
  line-height: 28px;
  cursor: pointer;
  margin-right: 25px;
  margin-bottom: 25px;

  svg {
    margin-left: 5px;
  }

  :hover {
    opacity: 0.7;
  }
`;

export const UpfrontPaymentFieldRowTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.neutral600};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  .edit-button {
    display: inline;
    text-transform: capitalize;
    justify-self: flex-end;
    margin-left: 15px;
  }

  svg {
    margin-left: 5px;
    margin-bottom: -2px;
  }
`;

export const PastDueText = styled.span`
  color: ${({ theme }) => theme.redDark};
  font-weight: bold;
`;

export const PastDueDate = styled.span`
  color: ${({ theme }) => theme.redDark};
`;

export const UpfrontPaymentFinancialExplanationCrossOut = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  margin: 12px 0px;

  color: #ef413d;
  p {
    display: inline;
  }

  .cross-out {
    text-decoration-line: line-through;
    margin-left: 10px;
    display: inline;
    margin-top: 6px;
  }
`;

export const UpfrontPaymentFinancialExplanation = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.greyMedium};
  grid-gap: 15px;
  align-items: center;
  margin-top: 40px;
  svg {
    width: 20px;
  }
`;

export const UpfrontPaymentComponentContentWrapper = styled.div`
  display: grid;
  grid-template-rows: 40px auto auto;
`;

export const UpfrontPaymentAnswerWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  p {
    display: inline;
  }
  margin-top: 16px;
  margin-bottom: 32px;
  width: ${({ deviceType }) => (deviceType === "desktop" ? "532px" : "95%")};
  justify-content: center;
  margin-left: 10px;
  text-align: center;
`;

export const UpfrontPaymentFinancialQuestionsWrapper = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  grid-row-gap: 16px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  select {
    width: 90%;
  }
  input {
    width: 90%;
  }
`;

export const UpfrontPaymentFinancialStepperWrapper = styled.div<{
  marginTop: number;
}>`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: ${({ marginTop }) => marginTop || 15}px;
  .MuiStepper-root {
    width: 50% !important;
  }
  .MuiStep-root {
    padding: 0px 0px !important;
  }
  .MuiStepLabel-iconContainer {
    padding: 0px 0px !important;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const UpfrontPaymentFinancialSummaryWrapper = styled.div`
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
`;

export const UpfrontPaymentFinancialSummaryAttestation = styled.div`
  font-size: 16px;
  line-height: 24px;
  padding-top: 16px;
`;

export const UpfrontPaymentMedicaidWarningWrapper = styled.div`
  font-size: 16px;
  font-family: "light";
  line-height: 24px;
  color: ${({ theme }) => theme.greyBase};
`;

export const UpfrontPaymentFinancialSummary = styled.div`
  padding: 16px;
  gap: 8px;
  background: ${({ theme }) => theme.blueSubtle};
  border-radius: 24px;
`;

export const UpfrontPaymentFinancialSummaryTitle = styled.div`
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  color: ${({ theme }) => theme.greyBase};
  margin-bottom: 4px;
`;

export const UpfrontPaymentFinancialSummaryRow = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  margin-bottom: 7px;
`;

export const UpfrontPaymentFinancialSummaryRowHeader = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.neutral600};
`;

export const UpfrontPaymentFinancialSummaryRowText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const getModalDeviceWidth = (
  deviceType: DeviceType,
  receipt?: boolean,
): string => {
  if (deviceType === "mobile") return "calc(100% - 10px);";
  if (receipt) return "420px";
  return "550px";
};

export const UpfrontPaymentFinancialModalWrapper = styled.div<{
  deviceType: DeviceType;
  allowScroll: boolean;
  receipt?: boolean;
}>`
  width: ${({ deviceType, receipt }) =>
    getModalDeviceWidth(deviceType, receipt)};
  margin: ${({ deviceType }) =>
    deviceType === "desktop" ? undefined : "10px 10px"};
  height: fit-content;
  max-height: 80%;
  justify-content: center;
  background: white;
  border-radius: 16px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  overflow-y: ${({ allowScroll }) => (allowScroll ? "scroll" : "hidden")};
  overflow-x: hidden;

  ${ProfileModalTitleWrapper} {
    padding: 24px;
  }

  ${FileUploaderTitle} {
    margin-top: 24px;
  }

  ${ProfileInsuranceInsurancePadding} {
    padding: 0px 0px !important;
  }

  ${ProfileInsuranceModalButtons} {
    justify-content: flex-end !important;

    ${StyledButton} {
      border-width: 1px;
      width: ${({ deviceType }) =>
        deviceType === "desktop" ? "45%" : undefined};
      height: ${({ deviceType }) =>
        deviceType === "mobile" ? "42px" : undefined};
    }
    .upfront-submit-button {
    }
    .upfront-cancel-button {
      font-weight: 500;
    }
  }

  .profile-modal-title {
    margin-top: 40px;
    padding-left: ${({ deviceType }) =>
      deviceType === "desktop" ? undefined : "10px"};
  }

  .profile-insurance-title {
    margin-bottom: 20px;
  }

  #date-time-edit {
    justify-self: flex-start;
  }

  && {
    .Mui-checked {
      color: ${({ theme }) => theme.greenDark} !important;
    }
  }

  canvas {
    position: relative !important;
    z-index: 0 !important;
    justify-self: center;
  }
`;

export const UpfrontPaymentInputRowWrapper = styled.div`
  display: grid;
`;

export const UpfrontPaymentInputWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 8px;
  margin-top: 20px;

  input {
    width: 100%;
  }
`;

export const UpfrontPaymentInputTitle = styled.div<{
  isError?: string;
}>`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme, isError }) => (isError ? theme.redDark : theme.greyBase)};
`;

export const UpfrontPaymentTextInputComponent = styled(Input)<{
  error?: string;
}>`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 16px;
  gap: 16px;
  width: 90%;
  height: 44px;
  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ error, theme }) => (error ? theme.redDark : "#dadada")};
  border-radius: 16px;
`;

export const UpfrontPaymentInputComponent = styled(CurrencyInput)<{
  error?: string;
}>`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 16px;
  gap: 16px;
  width: 206px;
  height: 44px;
  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ error, theme }) => (error ? theme.redDark : "#dadada")};
  border-radius: 16px;
`;

export const UpfrontPaymentStepperRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const UpfrontPaymentStepperRowDate = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.greyMedium};
`;

export const UpfrontPaymentStepperRowCost = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.black};
`;

export const UpfrontPaymentReceiptsDownloadWrapper = styled.div<{
  deviceType: DeviceType;
  minHeight?: number;
}>`
  width: ${({ deviceType }) => getModalDeviceWidth(deviceType)};
  margin: ${({ deviceType }) =>
    deviceType === "desktop" ? undefined : "10px 10px"};
  height: fit-content;
  max-height: 80%;
  justify-content: center;
  background: white;
  border-radius: 16px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  min-height: ${({ minHeight }) => `${minHeight || 300}px`};
  padding: 32px 48px;
`;

export const UpfrontPaymentReceiptsDownloadTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    margin-right: -5px;
    margin-top: -10px;
    :hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;

export const UpfrontPaymentReceiptsDownloadTitle = styled.div`
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.greyDark};
  margin-bottom: 20px;
`;

export const UpfrontPaymentReceiptsDownloadRow = styled.div`
  display: grid;
  grid-template-columns: 30px auto auto;
  grid-gap: 15px;

  :hover {
    opacity: 0.7;
  }

  div {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.greyBase};
    width: 80%;
  }

  path {
    fill: ${({ theme }) => theme.greenDark};
  }
`;

export const UpfrontPaymentTooltipContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  padding: 5px 5px;
  color: ${({ theme }) => theme.greyDark} !important;
  background-color: ${({ theme }) => theme.white} !important;

  .email-notification-tooltip {
    background-color: ${({ theme }) => theme.white} !important;
  }
`;

export const UpfrontPaymentTooltip = styled(Tooltip)`
  margin-top: -15px;
  display: inline;
  justify-content: end;
`;

export const UpfrontPaymentTooltipPopper = styled(Popper)`
  .MuiTooltip-tooltip {
    background-color: white !important;
    box-shadow: 0px 10px 20px rgb(0 0 0 / 10%) !important;
    border-radius: 8px !important;
    font-family: "light";
  }
`;

export const UpfrontPaymentCloseIcon = styled.span`
  display: flex;
  padding: 8px 20px;
  padding-right: 20px;
  justify-content: flex-end;
  width: 100%;
  align-items: center;

  svg {
    display: flex;
    cursor: pointer;
    justify-self: flex-end !important;
  }

  :hover {
    opacity: 0.7;
  }
`;

export const InfoBox = styled.div`
  background-color: #ececec;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  padding-left: 10px;

  @media (max-width: 768px) {
    padding: 12px;
    max-width: 100%;
  }
`;

export const InfoList = styled.ul`
  list-style-type: disc;
  padding-left: 10px;
`;

export const BreakBar = styled.hr`
  border: none;
  border-top: 1px solid #ccc;
  margin: 25px 0;
`;

export const UpfrontPaymentDueDateTag = styled.div`
  background-color: ${({ theme }) => theme.redPastel};
  border: 1px solid ${({ theme }) => theme.redMedium};
  border-radius: 14px;
  padding: 1px 10px 0px 10px;
  margin-left: 10px;
  margin-bottom: 2px;
  font-size: 12px;
  color: ${({ theme }) => theme.redMedium};
  display: inline-flex;
  align-items: center;
  text-transform: none;
  height: 18px;
`;
