import React from "react";
import { TypographyProps as MuiTypographyProps } from "@mui/material";
import {
  TypographyLink,
  StyledTypography,
  TypographySize,
} from "./Typography.styles";
import { ColorPalette } from "../../../theme";

interface TypographyProps
  extends Omit<MuiTypographyProps, "children" | "content"> {
  size: TypographySize;
  content: React.ReactNode;
  isBold: boolean;
  isCaps: boolean;
  isItalic: boolean;
  padding?: string;
  textColor?: keyof ColorPalette;
  externalLink?: boolean;
}

interface TypographyOptions {
  content: React.ReactNode;
  size: TypographySize;
  isBold?: boolean;
  isCaps?: boolean;
  isItalic?: boolean;
  padding?: string;
  textColor?: keyof ColorPalette;
  link?: string;
  linkInNewWindow?: boolean;
  isDownload?: boolean;
  className?: string;
  externalLink?: boolean;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  isEmail?: boolean;
  isPhone?: boolean;
}

export const GetTypographyContent = (
  options: TypographyOptions,
): React.ReactElement => {
  const textColor = options.textColor || "neutral700";
  const typography = (
    <Typography
      size={options.size}
      isBold={options.isBold || false}
      isCaps={options.isCaps || false}
      isItalic={options.isItalic || false}
      padding={options.padding || "0"}
      content={options.content}
      textColor={textColor}
      className={options.className}
      onClick={options.onClick}
    />
  );
  if (options.isEmail) {
    return (
      <a href={`mailto:${options.content}`} target="_blank" rel="noreferrer">
        <Typography
          size={options.size}
          isBold={options.isBold || false}
          isCaps={options.isCaps || false}
          isItalic={options.isItalic || false}
          padding={options.padding || "0"}
          content={options.content}
          textColor={options.textColor || "redMedium"}
          className={options.className}
          onClick={options.onClick}
          externalLink={options.isEmail || false}
        />
      </a>
    );
  }
  if (options.isPhone) {
    return (
      <a href={`tel:${options.content}`} target="_blank" rel="noreferrer">
        <Typography
          size={options.size}
          isBold={options.isBold || false}
          isCaps={options.isCaps || false}
          isItalic={options.isItalic || false}
          padding={options.padding || "0"}
          content={options.content}
          textColor={options.textColor || "redMedium"}
          className={options.className}
          onClick={options.onClick}
          externalLink={options.isPhone || false}
        />
      </a>
    );
  }
  if (options.externalLink) {
    return (
      <a href={options.link} target="_blank" rel="noreferrer">
        <Typography
          size={options.size}
          isBold={options.isBold || false}
          isCaps={options.isCaps || false}
          isItalic={options.isItalic || false}
          padding={options.padding || "0"}
          content={options.content}
          textColor={textColor}
          className={options.className}
          onClick={options.onClick}
          externalLink={options.externalLink || false}
        />
      </a>
    );
  }
  if (options.link) {
    if (options.linkInNewWindow) {
      return (
        <TypographyLink
          to={options.link}
          $textColor={textColor}
          target="_blank"
          download={options.isDownload}
          className={options.className}
        >
          {typography}
        </TypographyLink>
      );
    }
    return (
      <TypographyLink
        to={options.link}
        $textColor={textColor}
        download={options.isDownload}
        className={options.className}
      >
        {typography}
      </TypographyLink>
    );
  }
  return typography;
};

export const Typography = (props: TypographyProps): React.ReactElement => (
  <StyledTypography
    size={props.size}
    $isBold={props.isBold}
    $isCaps={props.isCaps}
    $isItalic={props.isItalic}
    padding={props.padding}
    $textColor={props.textColor}
    className={props.className}
    onClick={props.onClick}
    $externalLink={props.externalLink}
  >
    {props.content}
  </StyledTypography>
);
