import React from "react";
import { GetTypographyContent } from "../Typography/Typography";
import { useDeviceHook } from "../../../utils/hooks/useDeviceHook";
import { ErrorPopupRetry, ErrorPopupWrapper } from "./ErrorPopup.styles";

export const ErrorPopup: React.FC<{
  message: string;
  hideMessage: (message: string) => void;
  closeMethod?: () => void;
  onTop?: boolean;
  isSticky?: boolean;
}> = ({
  message,
  hideMessage,
  onTop = false,
  isSticky = false,
  closeMethod,
}): React.ReactElement => {
  const { deviceType } = useDeviceHook();
  return (
    <>
      {message && (
        <ErrorPopupWrapper
          onTop={onTop}
          deviceType={deviceType}
          isSticky={isSticky}
        >
          {GetTypographyContent({
            content: message,
            size: "medium",
            textColor: "white",
            padding: "14px",
          })}
          <ErrorPopupRetry>
            {GetTypographyContent({
              content: "Close",
              size: "medium",
              textColor: "greenMedium",
              padding: "14px",
              isCaps: true,
              onClick: () => {
                hideMessage("");
                if (closeMethod) {
                  closeMethod();
                }
              },
            })}
          </ErrorPopupRetry>
        </ErrorPopupWrapper>
      )}
    </>
  );
};
