import styled from "styled-components/macro";

export const BabyPeekGaugeGrid = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  justify-self: center;
  grid-gap: 8px;
  justify-content: center;
  margin-top: 16px;
`;

export const BabyPeekGaugeMask = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  width: min-content !important;
  height: 6.25rem;
  justify-self: center;
`;

export const BabyPeekGaugeBottomText = styled.div`
  color: var(--Grey-500, #718096);
  text-align: right;
  font-feature-settings: "case" on;
  /* Text/Small/Mobile */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  text-align: center;
`;

export const BabyPeekSemiCircle = styled.div`
  position: relative;
  display: block;
  width: 12.5rem;
  height: 6.25rem;
  background: #ffb086;
  border-radius: 50% 50% 50% 50% / 100% 100% 0 0;
  ::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 2;
    display: block;
    width: 8.75rem;
    height: 4.375rem;
    margin-left: -4.375rem;
    background: #fff;
    border-radius: 50% 50% 50% 50% / 100% 100% 0% 0%;
  }
`;

export const BabyPeekSemiCircleMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 12.5rem;
  height: 12.5rem;
  background: transparent;
  transform-origin: center center;
  backface-visibility: hidden;
  transition: all 0.3s ease-in-out;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0%;
    z-index: 2;
    display: block;
    width: 12.625rem;
    height: 6.375rem;
    margin: -1px 0 0 -1px;
    background: #e2e8f0;
    border-radius: 50% 50% 50% 50% / 100% 100% 0% 0%;
  }
`;

export const BabyPeekGaugeData = styled.div`
  z-index: 10;
  position: absolute;
  width: 100%;
  top: 50%;
  margin-left: auto;
  margin-right: auto;
  transition: all 1s ease-out;
  text-align: center !important;
  line-height: 25px;
  color: var(--Grey-700, #2d3748);
  /* Heading/H2/Mobile */
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 38.4px */
  letter-spacing: 0.32px;
`;
