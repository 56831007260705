import * as React from "react";

export const MaleFetus = (): React.ReactElement => (
  <svg
    width="62"
    height="63"
    viewBox="0 0 62 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="31" cy="31.675" rx="31" ry="31.2878" fill="#E7EBF2" />
    <ellipse cx="23.5" cy="42.2723" rx="9.5" ry="9.5882" fill="#03CBBA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.2783 29.242L41.3671 20.0689H37C36.1761 20.0689 35.5 19.3864 35.5 18.5549C35.5 17.7234 36.1761 17.041 37 17.041H45C45.8239 17.041 46.5 17.7234 46.5 18.5549V26.6292C46.5 27.4607 45.8239 28.1431 45 28.1431C44.1761 28.1431 43.5 27.4607 43.5 26.6292V22.2237L34.4125 31.3725L34.6476 31.7187C35.8133 33.4353 36.5 35.4964 36.5 37.7313C36.5 43.589 31.8039 48.3287 26 48.3287C20.1961 48.3287 15.5 43.589 15.5 37.7313C15.5 31.8736 20.1961 27.1338 26 27.1338C28.2147 27.1338 30.2559 27.8271 31.9352 29.0019L32.2783 29.242ZM18.5 37.7313C18.5 41.9058 21.8639 45.3009 26 45.3009C30.1361 45.3009 33.5 41.9058 33.5 37.7313C33.5 33.5567 30.1361 30.1617 26 30.1617C21.8639 30.1617 18.5 33.5567 18.5 37.7313Z"
      fill="#5D5A5A"
    />
  </svg>
);
