import React from "react";
import unityRound from "../../assets/logos/unityRound.svg";
import vector from "../../assets/icons/vector.svg";
import {
  ResetPasswordDesktopContainer,
  ResetPasswordFormDesktopColorbackground,
  ResetPasswordFormImage,
  ResetPasswordFormVector,
  ResetPasswordMobileContainer,
} from "./ResetPassword.styles";
import { ResetPasswordSentContent } from "./ResetPasswordSentContent";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";

export const ResetPasswordSent = (): React.ReactElement => {
  const { isDesktop } = useDeviceHook();

  return (
    <>
      {isDesktop && (
        <ResetPasswordDesktopContainer>
          <ResetPasswordFormDesktopColorbackground>
            <ResetPasswordFormVector src={vector} />
            <ResetPasswordFormImage src={unityRound} width="321px" />
          </ResetPasswordFormDesktopColorbackground>
          <ResetPasswordSentContent />
        </ResetPasswordDesktopContainer>
      )}
      {!isDesktop && (
        <ResetPasswordMobileContainer>
          <ResetPasswordSentContent />
        </ResetPasswordMobileContainer>
      )}
    </>
  );
};
