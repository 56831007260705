import React, { useEffect, useState } from "react";

import { useWindowWidth } from "@react-hook/window-size";
import { faArrowUpFromBracket } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BabyPeekTraitWrapper,
  BabyPeekV2HeaderTitleWrapper,
  BabyPeekV2HeaderTitleTop,
  BabyPeekV2HeaderTitleBottom,
  BabyPeekV2HeaderIconGrid,
  BabyPeekV2SectionText,
  BabyPeekV2SectionTitle,
  BabyPeekV2Section,
  BabyPeekV2HeaderIconShare,
  BabyPeekV2HeaderIconShareTitle,
} from "../Shared/BabyPeekTrait.styles";
import SweetBackground from "./SweetBackground.png";
import SweetIcon from "./SweetIcon.png";

import { BabyPeekTraitResponse, Trait } from "../../Services/BabyPeekService";
import { useDeviceHook } from "../../../../utils/hooks/useDeviceHook";
import { TraitNavigationLeft } from "../Shared/TraitNavigationLeft";
import { TraitNavigationRight } from "../Shared/TraitNavigationRight";
import {
  BabyPeekMobileButtonSticky,
  BabyPeekTraitNavigationWrapper,
  BabyPeekTraitNavigationButtonWrapper,
  BabyPeekDesktopButtonSticky,
} from "../../BabyPeekStripe.styles";
import { BabyPeekButton } from "../../BabyPeekButton";
import { TraitMenu } from "../Shared/TraitMenu";
import { BabyPeekTraitAccordion } from "../Shared/BabyPeekTraitAccordion";
import { SweetGene } from "./SweetGene";
import { SweetOtherFactors } from "./SweetOtherFactors";
import { SweetTasteMore } from "./SweetTasteMore";
import { SweetTasteLess } from "./SweetTasteLess";
import { ShareUnderline } from "../../BabyPeekIcons/ShareUnderline";
import sweetProbability from "../../AnimatedGifs/meter.gif";
import { BabyPeekSingleFeedback } from "../../BabyPeekSingleFeedback";

const getSweetDosage = (dosage: number): string => {
  if (dosage > 1) return "not likely";
  return "likely";
};

const getSweetDosageGif = (
  dosage: number,
  gif: boolean,
): React.ReactElement => {
  if (gif)
    return (
      <img
        src={sweetProbability}
        alt="loading..."
        style={{ width: "160px", height: "100px" }}
      />
    );
  if (dosage > 1) return <SweetTasteLess />;
  return <SweetTasteMore />;
};

const HowItWorks = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>How do we taste?</BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      {`We owe our ability to taste different flavors to tiny cells in our taste buds called `}{" "}
      <span>{`"taste receptors" `}. </span> Human taste receptors can sense 5
      flavor groups: sweet, bitter, salty, sour, and savory.
      <br />
      <br />
      Our genes determine which taste receptors we have, and the presence or
      absence of these receptors affects what flavors we can taste.
      <br />
      <br />
      People who have particular receptors for sweet chemicals are more
      sensitive to sweet flavors. The more sensitive they are to sweet flavors,
      the more they may enjoy sweets!
    </BabyPeekV2SectionText>
    <br />
    <br />
    <BabyPeekV2SectionTitle>
      {` How did we determine your baby's likelihood to sensitive to sweet taste? `}
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      We looked at one genetic change in a gene called{" "}
      <span className="italic">TAS1R3 </span>that encodes taste receptors to
      determine if your baby is more or less sensitive than average to sweet
      flavors. This simple genetic change can affect the ability of a person’s
      taste buds to detect/taste sucrose, one of the main chemicals that makes
      foods sweet.
      <br />
      <br />
      <BabyPeekV2SectionTitle>The genes we peeked at</BabyPeekV2SectionTitle>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {" "}
        <SweetGene />{" "}
      </div>
      <br />
    </BabyPeekV2SectionText>
  </BabyPeekV2Section>
);

const OtherFactors = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>
      What else can affect our senses of taste?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      What else can affect our senses of taste? A person’s traits are often
      determined by a combination of genetic and environmental factors. While
      many of the genetic variations that influence these traits are
      well-studied, there are some genetic factors that are still unknown to
      scientists.
      <br />
      Other factors that can affect our perception of sweet tastes include:
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}
      >
        {" "}
        <SweetOtherFactors />{" "}
      </div>
      <br />
    </BabyPeekV2SectionText>
    <br />
  </BabyPeekV2Section>
);

const AboutBabyPeek = (
  <BabyPeekV2SectionText>
    BabyPeek results are:
    <ul>
      <li>
        A <span style={{ fontWeight: 700 }}>predictor</span> of what traits your
        baby will have. These results do not mean that your baby will have these
        traits with absolute certainty and a baby may have different traits than
        what is predicted.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT medical test results.</span> These
        results should not be used to make medical decisions about your
        pregnancy. The genetic changes mentioned in these results do not affect
        a baby’s health.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT paternity testing.</span> These
        results should not be used to determine paternity. Given the complex
        genetics of these traits, a baby may be born with different traits from
        the biological mother or father.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>
          NOT managed by your healthcare provider.
        </span>{" "}
        These results are unlocked by the patient directly. Please do not
        contact your healthcare provider regarding your BabyPeek results.
      </li>
    </ul>
    If you have questions about BabyPeek results, please contact BillionToOne
    directly at
    <a href={`tel:${"(650) 460-2551"}`} target="_blank" rel="noreferrer">
      {` (650) 460-2551`}
    </a>
    .
    <br />
  </BabyPeekV2SectionText>
);

export const SweetTrait: React.FC<{
  setCurrentTrait?: (trait: Trait | undefined) => void;
  traitData: BabyPeekTraitResponse;
  hideTraitMenu: boolean;
  publicTrait?: boolean;
  setShareModal?: (share: boolean) => void;
  modalPreview?: boolean;
  showAnimation?: boolean;
}> = ({
  setCurrentTrait,
  traitData,
  setShareModal,
  hideTraitMenu,
  publicTrait,
  modalPreview = false,
  showAnimation = false,
}) => {
  const { deviceType } = useDeviceHook();
  const windowWidth = useWindowWidth();
  const [showTraitMenu, setShowTraitMenu] = React.useState(false);
  const StickyWrapper =
    deviceType !== "desktop"
      ? BabyPeekMobileButtonSticky
      : BabyPeekDesktopButtonSticky;

  const [showingGifs, setShowingGifs] = useState(
    (modalPreview || showAnimation) ?? false,
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (showingGifs) {
        setShowingGifs(false);
      }
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      {" "}
      <div
        style={{
          backgroundImage: `url(${SweetBackground})`,
          height: "315px",
          backgroundSize: "cover",
          padding: "20px",
          display: "grid",
          justifyContent: deviceType !== "mobile" ? "center" : "flex-start",
          paddingBottom: "32px",
          paddingTop: "24px",
          gridGap: "12px",
        }}
        className="trait-top-background"
      >
        <BabyPeekV2HeaderIconShare>
          <BabyPeekV2HeaderIconGrid>
            <img
              src={SweetIcon}
              alt="SweetIcon"
              style={{ justifySelf: "center" }}
            />
            <div>Sweet Taste</div>
          </BabyPeekV2HeaderIconGrid>
          {setShareModal && !modalPreview && (
            <BabyPeekV2HeaderIconShareTitle onClick={() => setShareModal(true)}>
              {" "}
              <FontAwesomeIcon icon={faArrowUpFromBracket} /> Share
              <ShareUnderline />
            </BabyPeekV2HeaderIconShareTitle>
          )}
        </BabyPeekV2HeaderIconShare>

        <BabyPeekV2HeaderTitleWrapper
          deviceType={deviceType}
          windowWidth={windowWidth}
        >
          <BabyPeekV2HeaderTitleTop>
            {publicTrait
              ? "The baby is likely to be"
              : "Your baby is likely to be"}
          </BabyPeekV2HeaderTitleTop>
          <BabyPeekV2HeaderTitleBottom>
            {`${
              getSweetDosage(traitData.sugar_sensitivity_dosage) === "likely"
                ? "more"
                : "less"
            } sensitive to sweet flavors`}
          </BabyPeekV2HeaderTitleBottom>
        </BabyPeekV2HeaderTitleWrapper>
      </div>
      <BabyPeekTraitWrapper deviceType={deviceType}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            paddingLeft: "25px",
          }}
        >
          {getSweetDosageGif(traitData.sugar_sensitivity_dosage, showingGifs)}
        </div>
        {!modalPreview && (
          <>
            {" "}
            <hr />
            <div
              style={{
                maxWidth: `${windowWidth - 2}px`,
                padding: "25px",
                paddingTop: "0px",
              }}
            >
              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "How it works",
                    content: HowItWorks,
                    key: "howItWorksEye",
                    defaultExpanded: true,
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "Other factors",
                    content: OtherFactors,
                    key: "otherFactorsEye",
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: "About BabyPeek",
                    content: AboutBabyPeek,
                    key: "aboutBabyPeekEye",
                  },
                ]}
              />
            </div>{" "}
          </>
        )}
        {!modalPreview && <BabyPeekSingleFeedback onlyFeedback />}
      </BabyPeekTraitWrapper>
      {setCurrentTrait && !modalPreview && (
        <StickyWrapper style={{ zIndex: 10 }}>
          {showTraitMenu && TraitMenu(setCurrentTrait, "Sweet")}
          <BabyPeekTraitNavigationWrapper
            $width={windowWidth}
            deviceType={deviceType}
          >
            <BabyPeekTraitNavigationButtonWrapper
              onClick={() => setCurrentTrait("RedHair")}
            >
              <TraitNavigationLeft />
            </BabyPeekTraitNavigationButtonWrapper>{" "}
            {!hideTraitMenu && (
              <BabyPeekButton
                variant="gray"
                content={<>{showTraitMenu ? " Hide Traits" : "View Traits"}</>}
                onClick={async () => {
                  setShowTraitMenu(!showTraitMenu);
                }}
              />
            )}
            <BabyPeekTraitNavigationButtonWrapper
              onClick={() => setCurrentTrait("SweetSalty")}
            >
              <TraitNavigationRight />
            </BabyPeekTraitNavigationButtonWrapper>
          </BabyPeekTraitNavigationWrapper>
        </StickyWrapper>
      )}
    </>
  );
};
