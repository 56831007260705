import * as React from "react";

export const EyeCTA = (): React.ReactElement => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 25 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5625 9.625C7.5625 6.75391 9.90039 4.375 12.8125 4.375C15.6836 4.375 18.0625 6.75391 18.0625 9.625C18.0625 12.5371 15.6836 14.875 12.8125 14.875C9.90039 14.875 7.5625 12.5371 7.5625 9.625ZM12.8125 12.9062C14.6172 12.9062 16.0938 11.4707 16.0938 9.625C16.0938 7.82031 14.6172 6.34375 12.8125 6.34375C12.7715 6.34375 12.7305 6.34375 12.6895 6.34375C12.7715 6.58984 12.8125 6.79492 12.8125 7C12.8125 8.47656 11.623 9.625 10.1875 9.625C9.94141 9.625 9.73633 9.625 9.53125 9.54297C9.53125 9.58398 9.53125 9.625 9.53125 9.625C9.53125 11.4707 10.9668 12.9062 12.8125 12.9062ZM4.89648 3.75977C6.82422 1.95508 9.49023 0.4375 12.8125 0.4375C16.0938 0.4375 18.7598 1.95508 20.6875 3.75977C22.6152 5.52344 23.8867 7.65625 24.502 9.13281C24.625 9.46094 24.625 9.83008 24.502 10.1582C23.8867 11.5938 22.6152 13.7266 20.6875 15.5312C18.7598 17.3359 16.0938 18.8125 12.8125 18.8125C9.49023 18.8125 6.82422 17.3359 4.89648 15.5312C2.96875 13.7266 1.69727 11.5938 1.08203 10.1582C0.958984 9.83008 0.958984 9.46094 1.08203 9.13281C1.69727 7.65625 2.96875 5.52344 4.89648 3.75977ZM12.8125 2.40625C10.1055 2.40625 7.93164 3.63672 6.25 5.19531C4.65039 6.67188 3.58398 8.39453 3.00977 9.625C3.58398 10.8555 4.65039 12.6191 6.25 14.0957C7.93164 15.6543 10.1055 16.8438 12.8125 16.8438C15.4785 16.8438 17.6523 15.6543 19.334 14.0957C20.9336 12.6191 22 10.8555 22.5742 9.625C22 8.39453 20.9336 6.67188 19.334 5.19531C17.6523 3.63672 15.4785 2.40625 12.8125 2.40625Z"
      fill="black"
    />
  </svg>
);
