import styled from "styled-components/macro";
import MuiAccordion from "@mui/material/Accordion";

export const BabyPeekStyledAccordion = styled(MuiAccordion)`
  padding: 0 !important;
  margin: 2px 0 0 0 !important;
  box-shadow: none !important;
  background: ${({ theme }) => theme.white};

  &:before {
    margin: auto;
    width: calc(100%);
    opacity: 1 !important;
  }
`;

export const BabyPeekAccordionQuestion = styled.div`
  color: var(--Grey-700, #2d3748);
  /* Heading/H4/Mobile */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
  letter-spacing: 0.2px;
`;

export const BabyPeekAccordionAnswer = styled.div`
  color: var(--grey-800, #1a202c);
  font-feature-settings: "case" on;
  /* Text/Medium/Desktop */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
`;
