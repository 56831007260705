import { AccordionProps } from "../../components/shared/Accordion/Accordions";
import { GetTypographyContent } from "../../components/shared/Typography/Typography";
import CarrierVideoEmbed from "../../components/shared/Video/CarrierVideoEmbed";

import { HelpFAQAnswerWrapper } from "./Help.styles";

const LINE_BREAK = <br />;

export const carrierHelpAccordions = (reqID: string): AccordionProps[] => [
  {
    title: "What does the UNITY Carrier Screen test for?",
    content: (
      <HelpFAQAnswerWrapper>
        <div style={{ display: "grid" }}>
          <CarrierVideoEmbed
            type="carrier"
            reqID={reqID}
            videoLocation="carrier_learn_more"
          />
        </div>
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `Carrier screening through UNITY determines your carrier status for commonly inherited genetic conditions. 
          Everyone has two copies of each gene, one inherited from each parent. 
          Carriers are individuals who are not affected with the condition (not expected to have symptoms) but 
          who carry a genetic change that causes one of their two gene copies to not work properly. 
          If both you and your reproductive partner are carriers of the same condition, there is a 25% (1 in 4) 
          chance to have an affected child with each pregnancy.`,
          size: "medium",
        })}
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `Most carriers of these conditions will not have symptoms or known family history of the condition.
             Therefore, medical guidelines recommend all pregnant individuals be given the option to screen for 
             the following conditions covered by the UNITY Carrier Screen:`,
          size: "medium",
        })}
        <ul>
          <li>
            {GetTypographyContent({
              content: "Alpha-thalassemia ",
              size: "medium",
            })}
            {GetTypographyContent({
              content: "(HBA1, HBA2)",
              size: "medium",
              isItalic: true,
            })}
          </li>
          <li>
            {GetTypographyContent({
              content:
                "Sickle cell disease / beta-thalassemia / beta-hemoglobinopathy ",
              size: "medium",
            })}
            {GetTypographyContent({
              content: "(HBB)",
              size: "medium",
              isItalic: true,
            })}
          </li>
          <li>
            {GetTypographyContent({
              content: "Cystic fibrosis ",
              size: "medium",
            })}
            {GetTypographyContent({
              content: "(CFTR)",
              size: "medium",
              isItalic: true,
            })}
          </li>
          <li>
            {GetTypographyContent({
              content: "Spinal muscular atrophy ",
              size: "medium",
            })}
            {GetTypographyContent({
              content: "(SMN1)",
              size: "medium",
              isItalic: true,
            })}
          </li>
        </ul>
        {GetTypographyContent({
          content: `If you are at least 9 weeks pregnant, and you are found to be a carrier, 
          we will perform reflex single-gene NIPT to evaluate your baby’s risk of having that condition.`,
          size: "medium",
        })}
      </HelpFAQAnswerWrapper>
    ),
    key: "carrierTestFor",
  },
  {
    title: "What types of results can I get from the UNITY Carrier Screen?",
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content: `The most common result from the UNITY Carrier Screen is a “negative” result. 
          This means that you were not identified as a carrier of 
          any of the conditions screened. Therefore, you have a very low chance of having a baby affected with these conditions.`,
          size: "medium",
        })}
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `If you are identified as a carrier of one of the conditions screened, 
          and at least 9 weeks pregnant, we will perform single-gene NIPT (sgNIPT) to 
          evaluate your baby’s risk of having that condition. The possible sgNIPT results include:`,
          size: "medium",
        })}
        <ul>
          <li>
            {GetTypographyContent({
              content: `Low or decreased risk: this means your baby has a very low chance of having the condition screened. 
              This significantly reduces but does not completely eliminate the chance of the baby having the condition. 
              A low risk result is the most common result when sgNIPT is performed.`,
              size: "medium",
            })}
          </li>
          <li>
            {GetTypographyContent({
              content: `High or increased risk: this means your baby has a significantly increased chance of being 
                affected with the condition screened. The “Risk After sgNIPT” on your test report will 
                indicate the chance for your baby to be affected with the condition. Because the UNITY Screen™
                 is not diagnostic, diagnostic testing (such as CVS or amniocentesis) is recommended following a 
                 high risk result to confirm if the baby does or does not have the condition. 
                 No irreversible pregnancy decisions, such as pregnancy termination, 
                 should be considered based on UNITY Screen™ results alone.`,
              size: "medium",
            })}
          </li>
          <li>
            {GetTypographyContent({
              content: `No result: this means we are unable to determine if the risk is high or low 
              for your baby to have the condition screened.
               There are various reasons a “no result” can occur,
               such as low amounts of DNA from the pregnancy in the blood sample provided. 
               We can accept a new blood sample to perform repeat sgNIPT. `,
              size: "medium",
            })}
          </li>
        </ul>
        {GetTypographyContent({
          content: `If you are found to be a carrier, we recommend your reproductive partner completes 
          carrier screening prior to a future pregnancy to clarify the risk for an affected child. 
          Additionally, your first-degree relatives (e.g., brothers, sisters, children, and parents) 
          would have a 50% chance to be a carrier of the condition as well. More distant relatives also have a chance to be a carrier. 
          If you are found to be a carrier, we recommend that you share these results with blood relatives, especially those of reproductive age.`,
          size: "medium",
        })}
      </HelpFAQAnswerWrapper>
    ),
    key: "carrierScreenResults",
  },
  {
    title:
      "I don’t have any family history of the conditions being tested for. Is this test still recommended?",
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content: `Most carriers of the conditions screened with UNITY have no symptoms, 
          and 80% of carriers have no known family history of the condition. 
          Therefore, medical guidelines recommend all pregnant individuals be 
          given the option to screen for the conditions on the UNITY Screen™. `,
          size: "medium",
        })}
      </HelpFAQAnswerWrapper>
    ),
    key: "carrierFamilyHistory",
  },
  {
    title: "Am I eligible for the UNITY Carrier Screen?",
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content: `All individuals (male or female) are eligible for the UNITY Carrier Screen. However, 
          the reflex single-gene NIPT (sgNIPT) is only available for patients at least 9 weeks pregnant with a singleton (one baby).`,
          size: "medium",
        })}
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `Note, individuals who are pregnant with twins or higher order multiples, 
          or individuals who became pregnant using an egg donor or gestational carrier, are not eligible for sgNIPT.`,
          size: "medium",
        })}
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `If you are found to be a carrier but are not eligible for the reflex
           sgNIPT or not currently pregnant, it is recommended for your reproductive partner
            to have carrier screening to clarify the fetal risk.`,
          size: "medium",
        })}
      </HelpFAQAnswerWrapper>
    ),
    key: "carrierScreenEligible",
  },
  {
    title: "How long do UNITY Carrier Screen results take to come back?",
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content: `Most patients receive Carrier Screen results about two weeks after our 
          laboratory receives the blood sample. Please contact your provider for the test results,
           as they will be provided a copy of the results as soon as they are ready.`,
          size: "medium",
        })}
      </HelpFAQAnswerWrapper>
    ),
    key: "carrierResultTime",
  },
];
