import { ActionSerializer, UiActionType } from "../../../recoil/types";
import { BASE_URL } from "../../../utils/requestUtils";

export const SendActionType = async (
  actionType: UiActionType,
  requisitionID: string,
  access_token?: string,
  meta_data?: { [field: string]: string },
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/actions/`, {
    body: JSON.stringify({
      action_type: actionType,
      requisition: requisitionID,
      meta_data,
    } as ActionSerializer),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });
