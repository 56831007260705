import { Modal, RadioGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import CloseIcon from "@mui/icons-material/Close";
import { useDeviceHook } from "../../utils/hooks/useDeviceHook";
import { Button } from "../shared/Button/Button";
import {
  ProfileInsuranceModalWrapper,
  ProfileInsuranceModalTitle,
  ProfileInsuranceModalButtons,
  BillingRadioControlDropdown,
  ProfileInsuranceRequiredMessage,
  ProfileModalWarningText,
  ProfileModalWarningWrapper,
  ProfileModalTitleWrapper,
} from "./ProfileInsuranceModal.styles";
import {
  getRelationshipToInsured,
  PatientInsuranceInfo,
  insuranceAddressUploadValidationCheck,
  getMissingProfileInfoMessage,
  FullAddress,
  isAddressOrInsuranceRequired,
} from "./helpers";
import { submitProfileInsuranceInfo } from "./Services/UpdateProfileInfoService";
import { patientProfileState } from "../../recoil/atoms";
import { UserProfileAuthClientResponse } from "../../recoil/types";
import { getUserAuth } from "../../recoil/selectors";
import { WarningRounded } from "../../assets/iconComponents/WarningRounded";
import { SpinnerLoading } from "../shared/Spinner/SpinnerLoading";
import ProfileInsuranceUpload from "./ProfileInsuranceUpload";
import ProfileInsuranceDetailsEntry from "./ProfileInsuranceDetailsEntry";
import PatientInformationAddress from "./PatientInformationAddress";
import { GetTypographyContent } from "../shared/Typography/Typography";

export const ProfileInsuranceModal: React.FC<{
  profileModalInsuranceOpen: boolean;
  setProfileModalInsuranceOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setInfoSubmitted: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  profileModalInsuranceOpen,
  setProfileModalInsuranceOpen,
  setInfoSubmitted,
}) => {
  const { deviceType } = useDeviceHook();
  const [isError, setCheckError] = useState(false);
  const auth = useRecoilValue(getUserAuth);
  const [patientInfo, setPatientInfo] = useRecoilState(patientProfileState);
  const [fullAddress, setFullAddress] = useState<FullAddress>({
    fullAddress: "",
    streetAddress: "",
    aptUnit: "",
    state: "",
    city: "",
    zipCode: "",
  });
  const [loading, setLoading] = useState(false);

  const [patientInsuranceInfo, setPatientInsuranceInfo] =
    React.useState<PatientInsuranceInfo>({
      billToInsuranceType: "Upload",
      insuranceCompanyName: "",
      groupId: "",
      memberId: "",
      policyOwnerName: "",
      policyOwnerDob: "",
      relationshipToPolicyOwnerSelf: true,
      relationshipToPolicyOwnerDependent: false,
      relationshipToPolicyOwnerOther: false,
      insuranceFileUrls: {},
      checkError: false,
    });

  const submitInsuranceInfo = async () => {
    if (auth && patientInfo) {
      if (
        insuranceAddressUploadValidationCheck(
          patientInfo.phi.ins_status === "Required",
          patientInfo.phi.address_status === "Required",
          patientInsuranceInfo,
          fullAddress,
        )
      ) {
        setCheckError(true);
      } else {
        setLoading(true);
        const newProfileResponse = await submitProfileInsuranceInfo(
          patientInsuranceInfo.insuranceCompanyName,
          patientInsuranceInfo.groupId,
          patientInsuranceInfo.memberId,
          Object.keys(patientInsuranceInfo.insuranceFileUrls).map(
            (file) => patientInsuranceInfo.insuranceFileUrls[file].fileName,
          ),
          `${patientInsuranceInfo.policyOwnerName}`,
          patientInsuranceInfo.policyOwnerDob,
          getRelationshipToInsured(
            patientInsuranceInfo.relationshipToPolicyOwnerSelf,
            patientInsuranceInfo.relationshipToPolicyOwnerDependent,
            patientInsuranceInfo.relationshipToPolicyOwnerOther,
          ),
          patientInsuranceInfo.billToInsuranceType === "Entry"
            ? "Manual"
            : "Image",
          fullAddress.streetAddress,
          fullAddress.aptUnit,
          fullAddress.city,
          fullAddress.state,
          fullAddress.zipCode,
          auth?.access_token,
          patientInfo.phi.ins_status,
          patientInfo.phi.address_status,
          patientInfo.phi.prebilling_status,
        );
        setLoading(false);
        if (newProfileResponse?.ok) {
          const newProfile =
            (await newProfileResponse.json()) as UserProfileAuthClientResponse;
          setPatientInfo(newProfile);
          setProfileModalInsuranceOpen(false);
          setInfoSubmitted("Info submitted");
        }
      }
    }
  };

  useEffect(() => {
    setCheckError(false);
  }, [patientInsuranceInfo]);

  return (
    <Modal
      open={profileModalInsuranceOpen}
      onClose={() => setProfileModalInsuranceOpen(false)}
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <ProfileInsuranceModalWrapper deviceType={deviceType}>
        <ProfileInsuranceModalTitle deviceType={deviceType}>
          <ProfileModalTitleWrapper deviceType={deviceType}>
            <div> Missing Information </div>
            <CloseIcon onClick={() => setProfileModalInsuranceOpen(false)} />
          </ProfileModalTitleWrapper>
        </ProfileInsuranceModalTitle>
        {!isError &&
          isAddressOrInsuranceRequired(patientInfo) &&
          deviceType !== "mobile" && (
            <ProfileModalWarningWrapper deviceType={deviceType}>
              <WarningRounded />
              <ProfileModalWarningText>
                {getMissingProfileInfoMessage(
                  patientInfo?.phi.ins_status === "Required",
                  patientInfo?.phi.address_status === "Required",
                  patientInfo?.phi.prebilling_status === "Required",
                )}
              </ProfileModalWarningText>
            </ProfileModalWarningWrapper>
          )}
        {isError && (
          <ProfileInsuranceRequiredMessage>
            {isError && <WarningRounded />}
            Please fill out all required fields marked with an asterisks (*)
          </ProfileInsuranceRequiredMessage>
        )}
        <>
          <div>
            <BillingRadioControlDropdown $hideBorder>
              {patientInfo?.phi.address_status === "Required" && (
                <>
                  {GetTypographyContent({
                    content: "Personal Details",
                    size: "h3",
                    textColor: "greyMedium",
                    isBold: true,
                    className: "profile-modal-title",
                  })}

                  {
                    // ADDRESS
                    <PatientInformationAddress
                      fullAddress={fullAddress}
                      setFullAddress={setFullAddress}
                      isError={isError}
                    />
                  }
                </>
              )}
              {(patientInfo?.phi.ins_status === "Required" ||
                patientInfo?.phi.prebilling_status === "Required") && (
                <RadioGroup
                  value={patientInsuranceInfo.billToInsuranceType}
                  onChange={(e) =>
                    setPatientInsuranceInfo({
                      ...patientInsuranceInfo,
                      billToInsuranceType: e.target.value as "Entry" | "Upload",
                    })
                  }
                >
                  {GetTypographyContent({
                    content: "Insurance Information",
                    size: "h3",
                    textColor: "greyMedium",
                    isBold: true,
                    className: "profile-modal-title profile-insurance-title",
                  })}

                  {
                    // UPLOAD
                    <ProfileInsuranceUpload
                      patientInsuranceInfo={patientInsuranceInfo}
                      setPatientInsuranceInfo={setPatientInsuranceInfo}
                      isError={isError}
                    />
                  }
                  {patientInfo?.phi.prebilling_status !== "Required" && (
                    // ENTRY
                    <ProfileInsuranceDetailsEntry
                      patientInsuranceInfo={patientInsuranceInfo}
                      setPatientInsuranceInfo={setPatientInsuranceInfo}
                      isError={isError}
                    />
                  )}
                </RadioGroup>
              )}
            </BillingRadioControlDropdown>
          </div>
        </>
        {loading ? (
          <SpinnerLoading />
        ) : (
          <ProfileInsuranceModalTitle deviceType={deviceType}>
            <ProfileInsuranceModalButtons deviceType={deviceType}>
              <Button
                label="CLOSE"
                onClick={() => setProfileModalInsuranceOpen(false)}
                size="medium"
                reverse
              />
              <Button
                label="SUBMIT"
                onClick={() => submitInsuranceInfo()}
                size="medium"
              />
            </ProfileInsuranceModalButtons>
          </ProfileInsuranceModalTitle>
        )}
      </ProfileInsuranceModalWrapper>
    </Modal>
  );
};
