import React from "react";

import {
  AccordionSummary,
  AccordionDetails,
  AccordionProps as MuiAccordionProps,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  AccordionCollapsedIcon,
  AccordionExpandedIcon,
  StyledAccordion,
} from "./Accordion.styles";
import { Typography } from "../Typography/Typography";
import { HELP_PAGE_URL } from "../../../utils/urlConstants";
import { ArrowIcon } from "../../../assets/iconComponents/ArrowIcon";

export const LINE_BREAK = <br />;

export interface AccordionProps
  extends Omit<MuiAccordionProps, "children" | "content" | "title"> {
  title: React.ReactNode;
  content: React.ReactNode;
  key: string;
  padding?: string;
}

export const Accordions: React.FC<{
  data: AccordionProps[];
  isLinked: boolean;
  size: "small" | "large";
}> = ({ isLinked, size, data }) => {
  const [expanded, setExpanded] = React.useState<string | false>("");
  const history = useHistory();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const getTypographySections = (
    content: React.ReactNode,
    isTitle: boolean,
    padding?: string,
  ): React.ReactElement => (
    <Typography
      key={content?.toString()}
      size={isTitle ? "large" : "medium"}
      isBold={isTitle}
      isCaps={false}
      isItalic={false}
      padding={padding}
      content={content}
    />
  );
  if (!isLinked) {
    const defaultAccordions = data.map((accordionSections) => (
      <StyledAccordion
        expanded={expanded === accordionSections.key}
        onChange={handleChange(accordionSections.key)}
        key={accordionSections.key}
      >
        <AccordionSummary
          expandIcon={
            expanded === accordionSections.key ? (
              <AccordionExpandedIcon />
            ) : (
              <AccordionCollapsedIcon />
            )
          }
          aria-controls={`${accordionSections.key}-panel-content`}
          id={`${accordionSections.key}-help`}
        >
          {getTypographySections(accordionSections.title, true)}
        </AccordionSummary>
        <AccordionDetails>
          {typeof accordionSections.content === "string"
            ? getTypographySections(
                accordionSections.content,
                false,
                accordionSections.padding,
              )
            : accordionSections.content}
        </AccordionDetails>
      </StyledAccordion>
    ));

    return <>{defaultAccordions}</>;
  }

  const linkedAccordions = data.map((accordionSections) => (
    <StyledAccordion
      expanded={false}
      onChange={handleChange(accordionSections.key)}
      key={accordionSections.key}
    >
      <AccordionSummary
        expandIcon={<ArrowIcon />}
        aria-controls={`${accordionSections.key}-panel-content`}
        id={`${accordionSections.key}-help`}
        onClick={() => {
          history.push(`${HELP_PAGE_URL}#${accordionSections.key}-help`);
        }}
      >
        {getTypographySections(
          accordionSections.title,
          size === "large",
          accordionSections.padding,
        )}
      </AccordionSummary>
    </StyledAccordion>
  ));

  return <>{linkedAccordions}</>;
};
