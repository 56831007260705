import styled from "styled-components/macro";

export const StyledInputRowWrapper = styled.div`
  display: grid;
  margin-bottom: 20px;
`;

export const StyledInputWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 8px;
`;

export const StyledInputInputTitle = styled.div<{
  isError?: string;
}>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme, isError }) => (isError ? theme.redDark : theme.greyBase)};
`;

export const StyledInputInputError = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.redDark};
`;

export const StyledInputOptional = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: ${({ theme }) => theme.greyBase};
  opacity: 0.3;
`;

export const StyledInputComponent = styled.input<{
  error?: string;
}>`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 16px;
  gap: 16px;
  width: 206px;
  height: 44px;
  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ error, theme }) => (error ? theme.redDark : "#dadada")};
  border-radius: 16px;
`;

export const StyledSelectComponent = styled.select<{
  error?: string;
}>`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  gap: 16px;
  width: 206px;
  height: 44px;
  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ error, theme }) => (error ? theme.redDark : "#dadada")};
  border-radius: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
`;
