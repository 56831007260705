import { BASE_URL } from "../utils/requestUtils";

export const TrackAnalyticsEvent = async (
  event_name: string,
  token?: string,
  distinct_id?: string,
  properties?: { [key: string]: string | number },
): Promise<Response | undefined> => {
  if (!token && !distinct_id) {
    // eslint-disable-next-line no-console
    console.error(
      `Either token or distinct_id must be provided for event '${event_name}'`,
    );
    return;
  }

  const payload: {
    properties?: { [key: string]: string | number };
    distinct_id?: string;
    event_name: string;
  } = { event_name };

  if (properties) {
    payload.properties = properties;
  }

  if (distinct_id) {
    payload.distinct_id = distinct_id;
  }

  let headers: { "Content-Type": string; Authorization?: string } = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }

  try {
    const response = await fetch(`${BASE_URL}/api/v1/analytics/events/`, {
      body: JSON.stringify(payload),
      method: "POST",
      headers,
    });
    return response;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Error sending analytics event: '${e}'`);
    return undefined;
  }
};

export const SetAnalyticsUser = async (
  token?: string,
  distinct_id?: string,
  properties?: { [key: string]: string | number },
): Promise<Response | undefined> => {
  if (!token && !distinct_id) {
    // eslint-disable-next-line no-console
    console.error("Either token or distinct_id must be provided");
    return;
  }

  const payload: {
    properties?: { [key: string]: string | number };
    distinct_id?: string;
  } = {};

  if (properties) {
    payload.properties = properties;
  }

  if (distinct_id) {
    payload.distinct_id = distinct_id;
  }

  let headers: { "Content-Type": string; Authorization?: string } = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }

  try {
    const response = await fetch(`${BASE_URL}/api/v1/analytics/users/`, {
      body: JSON.stringify(payload),
      method: "POST",
      headers,
    });
    return response;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Error setting analytics user: '${e}'`);
    return undefined;
  }
};
