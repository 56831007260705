import React from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { HelpMainOutterContainer, HelpStandardImage } from "./Help.styles";
import getInTouch from "../../assets/images/getInTouch.png";
import { GetTypographyContent } from "../../components/shared/Typography/Typography";
import { Button } from "../../components/shared/Button/Button";

const SimpleDiv = styled.div`
  padding-top: 20px;
  max-width: 300px;
  text-align: center;
`;

export const GetInTouch = (): React.ReactElement => {
  const history = useHistory();
  return (
    <HelpMainOutterContainer>
      <HelpStandardImage src={getInTouch} alt="Get in touch" />
      <SimpleDiv>
        {GetTypographyContent({
          content: `Didn't find what you were looking for?`,
          size: "h3",
          isBold: true,
          padding: "10px 0",
        })}
      </SimpleDiv>
      <SimpleDiv>
        <Button
          label="Get in touch"
          size="medium"
          onClick={() => history.push("contactUs")}
          reverse
        />
      </SimpleDiv>
    </HelpMainOutterContainer>
  );
};
