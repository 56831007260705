import styled from "styled-components/macro";
import { DeviceType } from "../../utils/hooks/useDeviceHook";

export const ResourcesSingleFrameContainter = styled.div`
  display: grid;
  grid-template-columns: auto;
`;

export const ResourcesTitleContainter = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  place-items: start;
  padding-bottom: 20px;
`;

export const ResourcesInfoContainer = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-columns: ${({ deviceType }) =>
    deviceType === "mobile" ? "1fr 4fr" : "1fr 6fr"};
  grid-gap: 27px 16px;
`;

export const ResourceDescriptionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: min-content max-content;
  grid-gap: 0px 7px;
  padding-top: 7px;
`;

export const ResourcesLanguageContainer = styled.div`
  display: inline-grid;
  grid-template-columns: min-content min-content min-content;
  grid-gap: 7px;
`;

export const ResourcesReportImage = styled.img``;
