import React from "react";
import ReactPlayer from "react-player/vimeo";
import { useRecoilValue } from "recoil";
import { useDeviceHook } from "../../../utils/hooks/useDeviceHook";
import carrierVideo from "../../../assets/images/allAboutCarrierVideo.png";
import carrierNegative from "../../../assets/images/carrierNegative.png";
import { getUserAuth } from "../../../recoil/selectors";
import { SendActionType } from "../Services/SharedServices";
import VideoFeedbackWidget from "./VideoFeedback";
import { useBooleanFeatureFlag } from "../../../utils/hooks/useFeatureFlag";
import { FeatureFlags } from "../../../utils/fliptUtils";
import BrowseVideoButton from "../../../pages/Video/BrowseVideoButton";

const getWidth = (deviceType: string) => {
  if (deviceType === "mobile") return "328px";
  if (deviceType === "tablet") return "528px";
  if (deviceType === "desktop") return "760px";
};

const getHeight = (deviceType: string) => {
  if (deviceType === "mobile") return "328px";
  if (deviceType === "tablet") return "296px";
  if (deviceType === "desktop") return "442px";
};

const CarrierVideoEmbed: React.FC<{
  videoLocation: "carrier_page" | "carrier_learn_more";
  reqID: string;
  type: "carrier" | "negativeCarrier";
}> = ({ videoLocation, reqID, type }) => {
  const [showFeedback, setShowFeedback] = React.useState(false);
  const auth = useRecoilValue(getUserAuth);
  const { deviceType } = useDeviceHook();
  const { enabled: videoPageFlagEnabled, loading: videoPageFlagLoading } =
    useBooleanFeatureFlag(FeatureFlags.VideoLibrary);

  return (
    <>
      <ReactPlayer
        playing
        url={
          type === "carrier"
            ? "https://player.vimeo.com/video/960668319?playsinline=0"
            : "https://player.vimeo.com/video/1057943467?playsinline=0"
        }
        light={type === "carrier" ? carrierVideo : carrierNegative}
        id="carrier-video-player"
        height={getHeight(deviceType)}
        width={getWidth(deviceType)}
        style={{ justifySelf: "center" }}
        controls
        vimeoConfig={{ iframeParams: { fullscreen: 1 } }}
        data-testid="carrier-video-player"
        data-vimeo-playsinline
        data-vimeo-autoplay
        playsinline={false}
        onPlay={async () => {
          await SendActionType(
            type === "carrier"
              ? "NegativeCarrierVideoClicked"
              : "CarrierVideoClicked",
            reqID,
            auth?.access_token,
            {
              videoLocation,
            },
          );
          setShowFeedback(true);
        }}
      />
      {showFeedback && (
        <VideoFeedbackWidget
          videoType="carrier"
          showFeedback={showFeedback}
          setShowFeedback={setShowFeedback}
          reqID={reqID}
          videoLocation={videoLocation}
        />
      )}
      {videoPageFlagEnabled && !videoPageFlagLoading && (
        <div
          style={{
            textAlign: "center",
            paddingTop: "10px",
            marginBottom: "-18px",
          }}
        >
          <BrowseVideoButton />
        </div>
      )}
    </>
  );
};

export default CarrierVideoEmbed;
