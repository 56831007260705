import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { datadogRum } from "@datadog/browser-rum";
import { getFliptClient } from "../fliptUtils";
import { getUserAuth } from "../../recoil/selectors";
import { SetAnalyticsUser } from "../../services/Analytics";

/**
 * React hook to check if a feature flag is enabled for a session.
 *
 * Auth context or entity ID is required to evaluate the feature flag.
 *
 * @param flagKey The key of the feature flag to evaluate
 * @param additionalContext Additional context to pass to Flipt
 * @param entityIdInput Optional entity ID (defaults to auth.user_id if available)
 * @returns An object containing the flag state and loading state
 */
export const useBooleanFeatureFlag = (
  flagKey: string,
  additionalContext: Record<string, unknown> = {},
  entityIdInput?: string,
): { enabled: boolean; loading: boolean } => {
  const [enabled, setEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const auth = useRecoilValue(getUserAuth);

  useEffect(() => {
    const evaluateFlag = async () => {
      setLoading(true);
      let entityId = entityIdInput;
      let evalEnabled = false;

      if (!entityId) {
        if (auth) {
          entityId = auth.user_id;
        } else {
          setEnabled(evalEnabled);
          setLoading(false);
          return;
        }
      }

      if (entityId) {
        try {
          const client = await getFliptClient();
          const result = await client.evaluateBoolean(
            flagKey,
            entityId,
            additionalContext,
          );
          evalEnabled = result.enabled;
          setEnabled(evalEnabled);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(
            `Error evaluating feature flag: '${flagKey}' error: '${error.message}'`,
          );
          evalEnabled = false;
          setEnabled(evalEnabled);
        } finally {
          setLoading(false);
        }
        SetAnalyticsUser(auth?.access_token, entityId, {
          [flagKey]: evalEnabled.toString(),
        });
      }

      datadogRum.addFeatureFlagEvaluation(flagKey, evalEnabled);
    };

    evaluateFlag();
  }, [flagKey, auth, JSON.stringify(additionalContext), entityIdInput]);

  return { enabled, loading };
};
