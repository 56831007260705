import styled from "styled-components/macro";
import { PlayVideo } from "../../../assets/iconComponents/PlayVideo";
import { DeviceType } from "../../../utils/hooks/useDeviceHook";

export const VideoDiv = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
`;

export const BabyPeekVideoDiv = styled.div<{
  deviceType: DeviceType;
}>`
  text-align: center;
  width: 100%;
  height: ${({ deviceType }) => (deviceType === "desktop" ? "328px" : "100%")};

  .react-player__preview {
    border-radius: 24px;
  }
`;

export const IconAndTypographyContainer = styled.div`
  display: grid;
  grid-template-columns: 1tr;
  place-items: center;
`;

export const VideoIconAndTypography = styled.div`
  display: inline-grid;
  grid-template-columns: auto 100%;
`;

export const VideoPlayContainer = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  height: 87px;
  width: ${({ deviceType }) => (deviceType === "desktop" ? "510px" : "400px")};
`;

export const StyledPlayVideo = styled(PlayVideo)`
  margin-bottom: 2px;
`;

export const VideoFeedback = styled.div`
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Cyan-Cyan-2, #c0e6f6);
  background: var(--Cyan-Cyan-1, #f4fcff);
  margin-top: 12px;
`;

export const VideoFeedbackText = styled.div`
  color: var(--Neutral-Neutral-7, #272727);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
`;

export const VideoFeedbackGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 12px;
  align-items: center;

  svg {
    margin-right: 16px;
    width: 18px;
    height: 18px;
  }
`;

export const VideoFeedBackTitleText = styled.div`
  color: var(--Neutral-Neutral-6, #464547);
  /* Body Bold */
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 150% */
`;
