import { BASE_URL } from "../../../utils/requestUtils";

export interface LoginRequestType {
  email: string;
  password: string;
}

export const LoginInitialValues: LoginRequestType = {
  email: "",
  password: "",
};

const LoginRequest = async (loginInfo: LoginRequestType): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/login`, {
    body: JSON.stringify(loginInfo),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

export const GetNewPatientInfoFromToken = async (
  token: string,
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/login/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

export default LoginRequest;
