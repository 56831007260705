import React from "react";
import { Facebook } from "../../assets/iconComponents/Facebook";
import { Twitter } from "../../assets/iconComponents/Twitter";
import { LinkedIn } from "../../assets/iconComponents/LinkedIn";
import { FooterSocialContainer } from "./Footer.styles";

export const FooterSocialInfo = (): React.ReactElement => (
  <FooterSocialContainer>
    <a
      href="https://www.facebook.com/unityscreen/"
      target="_blank"
      rel="noreferrer"
      data-testid="facebook-social-link"
    >
      <Facebook />
    </a>
    <a
      href="https://twitter.com/billiontooneinc"
      target="_blank"
      rel="noreferrer"
      data-testid="twitter-social-link"
    >
      <Twitter />
    </a>
    <a
      href="https://www.linkedin.com/company/billiontoone/"
      target="_blank"
      rel="noreferrer"
      data-testid="linkedin-social-link"
    >
      <LinkedIn />
    </a>
  </FooterSocialContainer>
);
