import React from "react";

import {
  AccordionSummary,
  AccordionDetails,
  AccordionProps as MuiAccordionProps,
} from "@mui/material";
import { Typography } from "../shared/Typography/Typography";
import {
  AccordionCollapsedIcon,
  AccordionExpandedIcon,
  StyledAccordion,
} from "../shared/Accordion/Accordion.styles";

interface AccordionProps
  extends Omit<MuiAccordionProps, "children" | "content" | "title"> {
  title: React.ReactNode;
  content: React.ReactNode;
  key: string;
  padding?: string;
}

export const BabyPeekAccordionHeader: React.FC<{
  data: AccordionProps[];
}> = ({ data }) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const getTypographySections = (
    content: React.ReactNode,
    isTitle: boolean,
    padding?: string,
  ): React.ReactElement => (
    <Typography
      key={content?.toString()}
      size={isTitle ? "large" : "medium"}
      isBold={isTitle}
      isCaps={false}
      isItalic={false}
      padding={padding}
      content={content}
    />
  );

  const defaultAccordions = data.map((accordionSections) => (
    <StyledAccordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      key={accordionSections.key}
      onMouseLeave={() => setExpanded(false)}
      onClick={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        expandIcon={
          expanded ? <AccordionExpandedIcon /> : <AccordionCollapsedIcon />
        }
        aria-controls={`${accordionSections.key}-panel-content`}
        id={`${accordionSections.key}-help`}
      >
        {getTypographySections(accordionSections.title, true)}
      </AccordionSummary>
      <AccordionDetails>
        {typeof accordionSections.content === "string"
          ? getTypographySections(
              accordionSections.content,
              false,
              accordionSections.padding,
            )
          : accordionSections.content}
      </AccordionDetails>
    </StyledAccordion>
  ));

  return <>{defaultAccordions}</>;
};
